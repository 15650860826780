import { Injectable } from '@angular/core';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { DataStorageService } from '../../../../services/data-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateInterviewService {

  constructor(private sendReceiveService: SendReceiveService, private dataStorage: DataStorageService) { }

  UpdateInterview(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.updateInterview, requestData);
  }

}
