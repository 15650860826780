import { Component, OnInit,Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-ref-fb',
  templateUrl: './view-ref-fb.component.html',
  styleUrls: ['./view-ref-fb.component.scss']
})
export class ViewRefFBComponent implements OnInit {

  form: FormGroup;
  description: any;
  math = Math;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ViewRefFBComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.description = data.title;
   }


  ngOnInit() {
    // console.log("-------0000000------>",this.description);
    // console.log("---------1111111---->",this.description.skills);
    // console.log("--------22222222----->",this.description.rreferablename);


  }
  save() {
    this.dialogRef.close(this.form.value);
  }
  close() {
    this.dialogRef.close();
  }

}
