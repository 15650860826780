import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ZoomServiceService } from 'src/app/services/zoom-service.service';
@Component({
  selector: 'app-common-confirm-modal',
  templateUrl: './common-confirm-modal.component.html',
  styleUrls: ['./common-confirm-modal.component.scss']
})
export class CommonConfirmModalComponent implements OnInit {
  heading;
dialogData: any;
  constructor(public zss: ZoomServiceService ,public dialogRef: MatDialogRef<CommonConfirmModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    
    this.dialogData = data;
  }

  ngOnInit() {
    this.heading = 'Confirmation'
  }

  submit(value) {
    // this.zss.confirm_popup_returns = value
    // if(!value){this.zss.meetinglink_input_radio_checked=1}
    this.dialogRef.close(value);
  }

}
