import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { MyResourcesService } from '../my-resources.service';
import { environment } from 'src/environments/environment';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { TypeaheadSettings } from 'ngx-type-ahead';

@Component({
  selector: 'app-up-single-profile',
  templateUrl: './up-single-profile.component.html',
  styleUrls: ['./up-single-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpSingleProfileComponent implements OnInit {

  skillForm: FormGroup;
  SkillForm: FormGroup;
  SKillForm: FormGroup;

  
  submitted: boolean = false;

  skills = ['Our Company', 'Other Company'];
  Skills1 = ['Available','Working on Project'];
  Skills2 = ['Hadoop','Cassandra','Hive','Python','Salesforce', 'AZURE', 'Amazon cloud', 'Workday', 'Oracle Fusion', 'C++', 'Digital Marketing', 'UX / UI Designer','Web Designer','Graphic Designer','Oracle Ebussiness Suite', 'Oracle Apps Functional','Oracle Apps Technical','PeopleSoft - Technical','Peoplesoft - Functional'];


  validation_messages = {
    'name': [
      { type: 'required', message: "Please enter valid Name" },
      { type: 'pattern', message: 'Please enter valid pattern' },
    ],
    'mobileNumber': [
      { type: 'required', message: "Please enter Mobile Number" },
      { type: 'pattern', message: 'Please enter valid number' },
    ],
    'technologyIds': [
      { type: 'required', message: "Please select Skills" },
      // { type: 'pattern', message: 'Please select valid Skills' },
    ],
    'email': [
      { type: 'required', message: "Please enter Email" },
      { type: 'pattern', message: 'Please enter valid email' },
    ],
    'designation': [
     // { type: 'minlength', message: 'PAGES.REGISTRATION.ERRORS.JOB_TITLE.MINLENGTH.LABEL' },
      //{ type: 'pattern', message: 'PAGES.REGISTRATION.ERRORS.JOB_TITLE.PATTERN.LABEL' },
      { type: 'required', message: "Please enter Job Title" },
    ],
  };
  
  
  //inputData: string;
  dateForm: FormGroup;
  minDate : string = ('2019-12-07')
  maxDate : Date = new Date ();
  // minDate1 : Date = new Date ('2000-01-01');
  // maxDate1: Date = new Date('2023-04-08');
  days:any;
  // @Input() max: any;
  // today = new Date();
   

  form: FormGroup;
  jobTitlesList: any = [];
  singleUploadFormGroup: FormGroup;
  disableschedule: boolean = false;
  fileName: any = '';
  userData: any;
  
  workingForList = [
    {id: 'Our Company', name: 'In House Resource'},
    {id: 'Other Company', name: 'Partner Resource'}
  ];

  availabilityList = [
    {id: 'Available', name: 'Available'},
    {id: 'Working On Project', name: 'Working On Project'}
  ];
  enterpriseUserId: any;
  resume: any;
  // myAppService: any;
  data: any;
  resumePath: any;
  fetchedUserDetails: any;
  userMobile: any;

  customSettings: Partial<TypeaheadSettings> = {
    suggestionsLimit: Infinity
  };

  onJobTitleValueChanges(e)
  {
    console.log(e)
  }

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data: { message: string },
    public dialogRef: MatDialogRef<UpSingleProfileComponent>,
    private calendar: NgbCalendar,
    public dataStorage: DataStorageService,
    public myResourcesService: MyResourcesService,
    private myAppService: MyAppHttpService
  ) 
  {
    
   
  }

  ngOnInit() {
    // this.form = this.fb.group({
    //   name: ['', Validators.required],
    //   address: ['', Validators.required],
    //   country: [''],
    // }); 

    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.enterpriseUserId = this.userData.enterpriseUserId;
    let company = this.userData.company;

    this.singleUploadFormGroup = this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.alphanumericWithOneSpace)])],
      email: [, Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.EMAIL)])],
      mobileNumber: ['', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.MOBILE_NUMBER)])],
      designation: [, Validators.compose([Validators.required])],//['', Validators.compose([Validators.required, Validators.minLength(2)])],
      technologyIds: [, Validators.compose([Validators.required])],
      companyName: [],
      currentProject: [],
      previousProject: [],
      availability: [],
      experience: [],
      resumePath: [],
      rating: [],
      feedback: [],
      workingFor: [],
      workingSince: []
      
    })

    this.jobTitlesList =  this.dataStorage.globalDesignations;
    console.log('designation', this.jobTitlesList)

    this.singleUploadFormGroup.controls['workingFor'].valueChanges.subscribe(valueChanged => {
      console.log('Working for Value changed: ', valueChanged);
      if(valueChanged == 'Our Company') {
        this.singleUploadFormGroup.controls['companyName'].setValue(company);
      }
      else if(valueChanged == 'Other Company') {
        this.singleUploadFormGroup.controls['companyName'].setValue('');
      }
    })
    
    this.singleUploadFormGroup.controls['designation'].valueChanges.subscribe(valueChanged => {
      console.log('Working for Value changed: ', valueChanged);
    })
    this.singleUploadFormGroup.controls['mobileNumber'].valueChanges.subscribe(mobileNo => {
      console.log('Entered Mobile number: ', mobileNo);
      if(mobileNo.length == 10) {
        this.userMobile = mobileNo;
        // this.getUserDetails();
      }
    })
  }

  

  submit(form: NgForm) {
    this.dialogRef.close({
      clicked: 'submit',
      form: form,
    });
  }
  // calcDate(minDate:Date,maxDate:Date) {
  //     console.log(this.minDate,this.maxDate);
  //     let d1 = new Date(this.minDate);
  //     let d2 = new Date(this.maxDate);
  //     const diff = Math.floor(d1.getTime() - d2.getTime());
  //     const day = 1000 * 60 * 60 * 24;
    
  //     const days = Math.floor(diff/day);
  //     const months = Math.floor(days/31);
  //     const years = Math.floor(months/12);
      
      
    
  //     let message = d2.toDateString();
  //     //message += " "
  //     //message +=  + " "
  //     message = years + "years" 
  //     message += months + " months "
  //     this.days = message
  //     console.log(this.days);
      
  //     //return message
  //   }

    calcExp(event) {
      let exp = this.myResourcesService.calcExp(event);
      this.singleUploadFormGroup.controls['experience'].setValue(exp);
    }

    onSkillsTypeAheadChange($event) {

    }

    close() {
      this.dialogRef.close(false);
    }

    uploadCandidate() {
      console.log('Upload Candidate Form value: ',  this.singleUploadFormGroup, this.singleUploadFormGroup.valid, this.resumePath);

      if (this.singleUploadFormGroup.invalid) {
        this.submitted = true;
        console.log('invalid')
      } 
      else {
        this.submitted = false;
        console.log('valid')

        let inputData = {
          "enterpriseUserId": this.enterpriseUserId,
          "name": this.singleUploadFormGroup.value.name,
          "email": this.singleUploadFormGroup.value.email,
          "availability": this.singleUploadFormGroup.value.availability, 
          "mobileNumber": this.singleUploadFormGroup.value.mobileNumber,
          "companyName": this.singleUploadFormGroup.value.companyName,
          "currentProject": this.singleUploadFormGroup.value.currentProject,
          "previousProject": this.singleUploadFormGroup.value.previousProject,
          "designation": this.singleUploadFormGroup.value.designation,
          "experience":  this.myResourcesService.convertYearsAndMonthsToMonths(this.singleUploadFormGroup.value.experience), //this.singleUploadFormGroup.value.experience,
          "resumePath": this.resumePath ? this.resumePath : '',
          "rating": this.singleUploadFormGroup.value.rating,
          "feedback": this.singleUploadFormGroup.value.feedback,
          "workingFor": this.singleUploadFormGroup.value.workingFor,
          "workingSince": this.singleUploadFormGroup.value.workingSince,
          "technologyIds" : this.singleUploadFormGroup.value.technologyIds
        }
        console.log('Add resource request data: ', inputData);
  
        this.myResourcesService.addNewResource(inputData).subscribe(res => {
          console.log('Response: ', res);
          if(res && res.statusCode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
            this.myAppService.showAlertMessage('User added successfully');
            this.dialogRef.close(true);
          }
          else if(res && res.statusCode ==  MyAppHttpService.RESPONSE_CODES.CONFLICT) {
            this.myAppService.showAlertMessage('User already exists');
          }
          else {
            this.myAppService.showAlertMessage('Something went wrong');
          }
        })
      }
    }

    getFile(event) {
   
      this.fileName = '';
      this.resume = event.target.files;
      let name = '';
      let fileExt = '';
      let size = 0;
      let mobile: any;
      if (!!this.resume) {
        name = this.resume[0].name;
        fileExt = name.split('.').pop();
        size = this.resume[0].size;
  
      }
      console.log("resume : ", this.resume);
      if (this.resume === undefined) {
        this.singleUploadFormGroup.controls['resume'].reset();
  
      } else if (this.resume.length == 0) {
        this.singleUploadFormGroup.controls['resume'].reset();
        this.myAppService.showAlertMessage("File not selected.");
  
      } else if (size == 0) {
        this.singleUploadFormGroup.controls['resume'].reset();
        this.myAppService.showAlertMessage("File not supported, Please select another file.");
  
      } else if (fileExt.toLowerCase() != "pdf" && fileExt.toLowerCase() != "docx" && fileExt.toLowerCase() != "doc") {
        this.singleUploadFormGroup.controls['resume'].reset();
        this.myAppService.showAlertMessage("Please select only pdf or word file.");
  
      } else if (size > 2097152) {
        this.singleUploadFormGroup.controls['resume'].reset();
        this.myAppService.showAlertMessage("Please select file less than 2 MB.");
  
      }
    
      else {
        mobile = this.singleUploadFormGroup.controls['mobileNumber'].value;
        this.fileName = name;
        let fileData = new FormData();
        let fileName: string = '';
      
  
        if (this.resume.length > 0) {
          fileName = mobile + '_' + this.resume[0].name;
       
          fileData.append("file", this.resume[0], fileName);
        }
  
        let xhr = new XMLHttpRequest();
        let apiUrl = '';
        // if(this.data.dialogFor == this.dataStorage.scheduleInterviewPopupFor.applyJob){
          apiUrl = environment.url + MyAppHttpService.REQUESTS.uploadResumeForApplyJob + '/' + this.enterpriseUserId;
        // }
        // else{
        //   apiUrl = environment.url + MyAppHttpService.REQUESTS.UploadResume;
        // }
        
        var accessToken = localStorage.getItem('token');
  
        xhr.open('POST', apiUrl, true);
        xhr.setRequestHeader("token", accessToken);
        xhr.onload = () => {
          if (xhr.status == 200) {
            let response = JSON.parse(xhr.response);
            this.resumePath = response.uploadedURL;
           
          }
        }
        xhr.send(fileData);
      }
    }

    ratingChanged(event) {
      console.log('Event: ', event);
      if(event) {
        this.singleUploadFormGroup.controls['rating'].setValue(event.rating);
      }
    }

    getUserDetails() {
      console.log('Mobile number: ', this.singleUploadFormGroup.value.mobileNumber);
      if(this.singleUploadFormGroup.controls['mobileNumber'].valid) {
        let number = this.userMobile;
        this.myResourcesService.getUserDetailsByMobileNumber(number).subscribe(res=> {
          if(res && res.status == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
            this.fetchedUserDetails = res.userDetails;
            console.log('Fetched user details: ', this.fetchedUserDetails);
            this.setUserData()
          }
        })
      }
    }

    setUserData() {

    }

    onlyDecimalNumberKey(event) {
      let charCode = (event.which) ? event.which : event.keyCode;
      if ( charCode > 31
        && (charCode < 48 || charCode > 57))
        return false;
      return true;
    }
}

