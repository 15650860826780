import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../../../../app-material.module';
import { CommonPipesModule } from '../../../../pipes/common-pipes/common-pipes.module';
import { StarRatingModule } from 'angular-star-rating';
import { TrainingDetailsComponent } from './training-details.component';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    CommonPipesModule,
    StarRatingModule.forRoot()
  ],
  declarations: [TrainingDetailsComponent],
  exports: [TrainingDetailsComponent],
  entryComponents: [TrainingDetailsComponent]
})
export class TrainingDetailsModule { }
