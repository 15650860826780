import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { LoginModalService } from '../login/login-modal/login-modal.service';
import { DownloadBrochureComponent } from '../download-brochure/download-brochure.component';
import { DialogService } from '../login-dialog.service';
import { CommonfunctionService } from '../../../../services/common/commonfunction.service';

@Component({
  selector: 'app-tour-modal',
  templateUrl: './tour-modal.component.html',
  styleUrls: ['./tour-modal.component.scss']
})
export class TourModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TourModalComponent>, private router: Router, private dialogService: DialogService,
    private commonFunction: CommonfunctionService) { }

  ngOnInit() {
  }

  next(){
    this.dialogRef.close();
    setTimeout(() => {
      this.router.navigate(['/tour-home']);  
    }, 200);
    
  }

  skip(){
    this.dialogRef.close();
    this.commonFunction.openDownloadBrochure();
  }
  


}
