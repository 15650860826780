import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { GlobalscheduleService } from 'src/app/components/views/globalscheduleinterview/globalscheduleinterview.service';
import { HomeService } from 'src/app/components/views/home/home.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { ScheduleMockinterviewService } from '../schedule-mockinterview/schedule-mockinterview.service';
import { AddmentorService } from './addinterviewer.service';

@Component({
  selector: 'app-addinterviewer',
  templateUrl: './addinterviewer.component.html',
  styleUrls: ['./addinterviewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddinterviewerComponent implements OnInit {
  mentorForm: FormGroup;
  validation_messages = {
    'name': [
      { type: 'required', message: 'Please enter Name' },
      { type: 'pattern', message: 'PAGES.SCHEDULE_INTERVIEW.ERRORS.USERNAME.PATTERN' },

    ],
    'email': [
      { type: 'required', message: 'Please enter Email' },
      { type: 'email', message: 'PAGES.SCHEDULE_INTERVIEW.ERRORS.EMAIL.PATTERN' },
      { type: 'pattern', message: 'PAGES.SCHEDULE_INTERVIEW.ERRORS.EMAIL.PATTERN' },
      { type: 'minlength', message: 'PAGES.REGISTRATION.ERRORS.EMAIL.MINLENGTH.LABEL' },
      { type: 'maxlength', message: 'PAGES.REGISTRATION.ERRORS.EMAIL.MAXLENGTH.LABEL' }
    ],
    'mobileNumber': [
      { type: 'required', message: 'PAGES.REGISTRATION.ERRORS.MOBILENUMBER.REQUIRED.LABEL' },
      { type: 'pattern', message: 'PAGES.REGISTRATION.ERRORS.MOBILENUMBER.PATTERN.LABEL' },
      { type: 'minlength', message: 'PAGES.REGISTRATION.ERRORS.MOBILENUMBER.MINLENGTH.LABEL' },
      { type: 'maxlength', message: 'PAGES.REGISTRATION.ERRORS.MOBILENUMBER.MAXLENGTH.LABEL' }
    ],
    'year': [
      { type: 'required', message: 'Please select Years' }
    ],
    'month': [
      { type: 'required', message: 'Please select Months' }
    ],
    'technologies': [
      { type: 'required', message: "Please enter Technologies" }
    ],
    'intDateAndTime': [
      { type: 'required', message: "Please select Date & Time" }
    ],
    'resume': [
      { type: 'required', message: 'Please upload Resume' }
    ],
    'comment': [
      { type: 'maxlength', message: 'Comments should be only 500 characters.' }
    ],
  }

  year: any[] = [
    { id: 0, value: "0 Years" },
    { id: 1, value: "1 Year" },
    { id: 2, value: "2 Years" },
    { id: 3, value: "3 Years" },
    { id: 4, value: "4 Years" },
    { id: 5, value: "5 Years" },
    { id: 6, value: "6 Years" },
    { id: 7, value: "7 Years" },
    { id: 8, value: "8 Years" },
    { id: 9, value: "9 Years" },
    { id: 10, value: "10 Years" },
    { id: 11, value: "11 Years" },
    { id: 12, value: "12 Years" },
    { id: 13, value: "13 Years" },
    { id: 14, value: "14 Years" },
    { id: 15, value: "15 Years" },
    { id: 16, value: "16 Years" },
    { id: 17, value: "17 Years" },
    { id: 18, value: "18 Years" },
    { id: 19, value: "19 Years" },
    { id: 20, value: "20 Years" },
    { id: 21, value: "21 Years" },
    { id: 22, value: "22 Years" },
    { id: 23, value: "23 Years" },
    { id: 24, value: "24 Years" },
    { id: 25, value: "25 Years" },
    { id: 26, value: "26 Years" },
    { id: 27, value: "27 Years" },
    { id: 28, value: "28 Years" },
    { id: 29, value: "29 Years" },
    { id: 30, value: "30 Years" },
    { id: 31, value: "31 Years" },
    { id: 32, value: "32 Years" },
    { id: 33, value: "33 Years" },
    { id: 34, value: "34 Years" },
    { id: 35, value: "35 Years" },
    { id: 36, value: "36 Years" },
    { id: 37, value: "37 Years" },
    { id: 38, value: "38 Years" },
    { id: 39, value: "39 Years" },
    { id: 40, value: "40 Years" }
  ];

  month: any[] = [
    { id: 0, value: "0 Months" },
    { id: 1, value: "1 Month" },
    { id: 2, value: "2 Months" },
    { id: 3, value: "3 Months" },
    { id: 4, value: "4 Months" },
    { id: 5, value: "5 Months" },
    { id: 6, value: "6 Months" },
    { id: 7, value: "7 Months" },
    { id: 8, value: "8 Months" },
    { id: 9, value: "9 Months" },
    { id: 10, value: "10 Months" },
    { id: 11, value: "11 Months" }

  ];
  skills: any;
  submitted: boolean = false;
  userData: any;
  enterpriseUserId: any;
  mobileError;

  editdata: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public globalservice: GlobalscheduleService, private fb: FormBuilder,
    public dataStorage: DataStorageService, private homeService: HomeService, private myAppService: MyAppHttpService,
    private scheduleInterviewService: ScheduleMockinterviewService,) {
    if (this.data != null) {
      this.editdata = true;
      this.skills = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));
      this.data.email = this.data.emailId;
      this.data.year = Math.floor(this.data.experience / 12);
      this.data.month = this.data.experience % 12;
      this.data.technologies = this.GetSkillIdfromSkills();
      this.data.companiesworked = this.data.companiesWorked;
    }
    this.mentorForm = this.fb.group({
      'name': ['', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.alphanumericWithOneSpace)])],
      'email': ['', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.EMAIL)])],
      'mobileNumber': ['', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.MOBILE_NUMBER)])],
      'year': ['', Validators.compose([Validators.required])],
      'month': ['', Validators.compose([Validators.required])],
      'technologies': ['', Validators.compose([Validators.required])],
      'companiesworked': ['',],
    });

    if (this.data != null) {
      this.mentorForm.reset(this.data);
      this.mentorForm.controls['mobileNumber'].disable();
    }
  }

  GetSkillIdfromSkills() {
    let skillssplitted = this.data.skills.split(',');
    // console.log('skills list', this.skills, skillssplitted);
    let skillIds = [];
    for (let i = 0; i < skillssplitted.length; i++) {
      for (let j = 0; j < this.skills.length; j++) {
        if (skillssplitted[i] == this.skills[j].name) {
          skillIds.push(this.skills[j].id);
        }
      }
    }
    return skillIds;
  }

  ngOnInit() {


    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.enterpriseUserId = this.userData.enterpriseUserId;
    // console.log("User data : ", this.userData, this.enterpriseUserId);

    if (this.dataStorage.globalSkillsAndJobTitles.length == 0) {
      this.homeService.getMasterSkillsDesignationTools().subscribe(success => {
        if (!!success) {
          this.dataStorage.globalSkills = JSON.parse(JSON.stringify(success.technologyList));
          this.skills = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));
          this.dataStorage.globalSkillsAndJobTitles = JSON.parse(JSON.stringify(this.dataStorage.globalSkills))
        }
      }, error => {
        this.myAppService.showAlertMessage(error.message);
      });
    }
    else {
   
      this.skills = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));


    }

    this.mentorForm.controls['mobileNumber'].valueChanges.subscribe((value) => {
    
      if (value) {
        if (value.toString().charAt(0) <= 4) {
          this.mobileError = true;
        }
        else {
          this.mobileError = false;

          if (value.length == 10) {
            // this.isUserExistsToSchedule(value);
          }
        }
      }
    });


  }

  closedialog(bool) {
    if (this.globalservice.openedfrom == 1) {
      this.globalservice.Scheduledialog.close(bool);
    } else {
      this.globalservice.Schedulenewdialog.close(bool);
    }
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  AddInterviewer() {
    if (this.mentorForm.invalid) {
      this.submitted = true;
    }
    else {
      this.submitted = false;

      let dataSending = this.mentorForm.value;

      if (this.editdata == false) {

        let requestData = {
          name: dataSending.name,
          mobileNumber: dataSending.mobileNumber,
          email: dataSending.email,
          enterpriseUserId: this.enterpriseUserId,
          //isMentor:dataSending.isMentor ? dataSending.isMentor:1,
          experience: this.getExperienceMonthsWithInputs(dataSending.year, dataSending.month),
          // experience:this.getYears(dataSending.year) + ' Years ' + this.getMonths(dataSending.month) + ' Months',
          skills: dataSending.technologies,
          companiesWorked: dataSending.companiesworked,

        }
        this.scheduleInterviewService.AddMentorByUserId(requestData).subscribe(response => {
         
          if (response.statusCode == 200) {

            this.myAppService.showAlertMessage(response.statusMessage);

            this.mentorForm.controls['name'].reset();
            this.mentorForm.controls['email'].reset();
            this.mentorForm.controls['mobileNumber'].reset();
            this.mentorForm.controls['technologies'].reset();
            this.mentorForm.controls['year'].reset();
            this.mentorForm.controls['month'].reset();
            this.mentorForm.controls['companiesworked'].reset();

            this.closedialog(true);
          }
          else if (response.statusCode == 409) {
            this.myAppService.showAlertMessage(response.statusMessage);

          }

        })

      } else {

        let requestData = {
          name: dataSending.name,
          mobileNumber: dataSending.mobileNumber,
          email: dataSending.email,
          enterpriseUserId: this.enterpriseUserId,
          //isMentor:dataSending.isMentor ? dataSending.isMentor:1,
          experience: this.getExperienceMonthsWithInputs(dataSending.year, dataSending.month),
          // experience:this.getYears(dataSending.year) + ' Years ' + this.getMonths(dataSending.month) + ' Months',
          skills: dataSending.technologies,
          companiesWorked: dataSending.companiesworked,
          userId: this.data.userId
        }
        this.scheduleInterviewService.UpdateMentorByUserId(requestData).subscribe(response => {
        
          if (response.statusCode == 200) {

            this.myAppService.showAlertMessage('Interviewer data updated successfully');

            this.mentorForm.controls['name'].reset();
            this.mentorForm.controls['email'].reset();
            this.mentorForm.controls['mobileNumber'].reset();
            this.mentorForm.controls['technologies'].reset();
            this.mentorForm.controls['year'].reset();
            this.mentorForm.controls['month'].reset();
            this.mentorForm.controls['companiesworked'].reset();

            this.closedialog(true);
          }
          else if (response.statusCode == 409) {
            this.myAppService.showAlertMessage(response.statusMessage);
          }

        })

      }



    }
  }

  getExperienceMonthsWithInputs(lintYears, lintMonths): number {
    // We are parsing the input values to Int as they might be string values from calling environment
    lintYears = parseInt(lintYears);
    lintMonths = parseInt(lintMonths);

    if (lintYears != null && lintYears != '' && lintYears != undefined) {
      if (lintYears == -1) {
        lintYears = 0;
      }
    } else {
      lintYears = 0;
    }
    if (lintMonths != null && lintMonths != '' && lintMonths != undefined) {
      if (lintMonths == -1) {
        lintMonths = 0;
      }
    } else {
      lintMonths = 0;
    }
    return (lintYears * 12) + lintMonths;
  };

  onSkillsTypeAheadChange(event) {

  }

}
