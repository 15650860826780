import { Injectable } from '@angular/core';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';

@Injectable({
  providedIn: 'root'
})
export class ShareProfileService {

  constructor(public sendReceiveService: SendReceiveService) { }

  shareProfile(requestData){
    console.log("Request data : ", requestData);
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.ShareProfileService, requestData);
  }
}
