import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { DownloadBrochureService } from './download-brochure.service';
@Component({
  selector: 'app-download-brochure',
  templateUrl: './download-brochure.component.html',
  styleUrls: ['./download-brochure.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DownloadBrochureComponent implements OnInit {

  downloadForm : FormGroup;


  submitError: boolean = false;

  validation_messages = {

    'name': [
      { type: 'required', message: 'Please enter your Name' },
      { type: 'pattern', message: 'Please enter a valid name' },
      { type: 'minLength', message: 'Name minimum length should be greate than 0' },
      { type: 'maxLength', message: 'Name maximum length should not be less than 50 characters' }
    ],
    'emailId': [
      { type: 'required', message: 'Please enter your Email Address' },
      { type: 'pattern', message: 'Please enter a valid Email' },
      { type: 'minLength', message: 'Email minimum length should be greate than 0' },
      { type: 'maxLength', message: 'Email maximum length should not be less than 500 characters' }
    ],
    'contactNo': [
      { type: 'pattern', message: 'Please enter a valid Contact No' }
    ],
    'company': [
      { type: 'pattern', message: 'Company Name should be alphanumberic with 1 space in between.' }
    ],
  };

  constructor(public downloadBrochureService : DownloadBrochureService, public myAppService: MyAppHttpService, public dialogRef: MatDialogRef<DownloadBrochureComponent>) { }

  ngOnInit() {
    this.downloadForm = new FormBuilder().group({
      'name': ["", Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.NAME)])],
      'emailId': ["", Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.EMAIL)])],
      'contactNo': ["", Validators.compose([Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.MOBILE_NUMBER)])],
      'company': ["", Validators.compose([Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.alphanumericWithOneSpace), Validators.maxLength(50)])],
    });
  }


  download(){

    let formValue = this.downloadForm.value;
    if(this.downloadForm.invalid){
      this.submitError = true;
    }
    else{
      this.submitError = false;
      this.downloadBrochureService.send(formValue).subscribe((response)=> {
       
        try
        {
        if(response.statuscode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
          this.myAppService.showAlertMessage(response.message);
          this.dialogRef.close();
        }
        else if(response.statuscode == MyAppHttpService.RESPONSE_CODES.NOT_ACCEPTABLE){
          this.myAppService.showAlertMessage("Personal email not acceptable.Please enter valid company Email.");
        }
        else if(response.statuscode == 417){
          this.myAppService.showAlertMessage("Name and EmailId are required");
        }
        else{
          this.myAppService.showAlertMessage("Unable to send the Brochure now, please try after sometime");
        }
      }
      catch(e)
      {
        this.myAppService.showAlertMessage("Unable to send the Brochure now, please try after sometime");
      }
      });
    }
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if ( charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

}
