import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffingAgenciesRoutes } from './staffing-agencies.route';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from 'src/app/app-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CommonPipesModule } from 'src/app/pipes/common-pipes/common-pipes.module';
import { TypeaheadModule } from 'ngx-type-ahead';
import { MultiSelectAllModule, DropDownListModule } from '@syncfusion/ej2-ng-dropdowns';
import { StaffingAgenciesComponent } from './staffing-agencies.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatMenuModule } from '@angular/material';

@NgModule({
  declarations: [StaffingAgenciesComponent],
  imports: [
    RouterModule.forChild(StaffingAgenciesRoutes),
        TranslateModule,
        AppMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgxMatSelectSearchModule,
        // BrowserModule
        CommonPipesModule,
        TypeaheadModule,
        // MultiSelectAllModule,
        // DropDownListModule,
        NgbModule,
        NgSelectModule,
        MatMenuModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class StaffingAgenciesModule { }
