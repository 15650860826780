import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material'

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  stack: MatDialogRef<any>[] = [];

  constructor(private dialog: MatDialog) {}

  /**
   * Open a new dialog window with the specified configuration.
   */
  open(content: any, config: MatDialogConfig = {}, disableClose = true): MatDialogRef<any> {
    // config.disableClose = true;
    config.disableClose = disableClose;
    const dialog = this.dialog.open(content, config);

    this.stack.push(dialog);

    return dialog;
  }
  /**
   * Clear all active dialogs from the stack.
   */
  clear() {
    this.stack.forEach(dialogRef => dialogRef.close());
    this.stack = [];
  }

  clearDialog(componentName){
      this.stack.forEach(dialogRef=>{
        if(dialogRef.componentInstance.data == componentName){
            dialogRef.close();
        }
      });
    this.stack = this.stack.filter(x=> x.componentInstance.data != componentName)
  }

}