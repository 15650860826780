import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-employer-details',
  templateUrl: './employer-details.component.html',
  styleUrls: ['./employer-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployerDetailsComponent implements OnInit {

  jobDetails: any = [{
    name: 'Sneha', companyName: 'Rock INterview', jobTitle: 'UI Developer', openJobs: 10, websiteLink: 'https://www.rockinterview.in',
    videoLink: 'https://www.youtube.com/watch?v=Cud39QOR_Pk', aboutCompany: 'Nothing',
    skills: 'Angular,C,C++,Spring boot, Core java, Advance Java, Advanced java', experience: 'Fresher',
    location: 'Bangalore', jobType: 'Full Time', salaryRange: '4-6 LPA', jobDescription: 'Job description'
  }];

  constructor(public dialogRef: MatDialogRef<EmployerDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    console.log('Employer details : ', data);
    this.jobDetails = data.myProfilleList;
    this.jobDetails[0].name =  `${this.jobDetails[0].firstname} ${this.jobDetails[0].lastname}`;
  }

  ngOnInit() {
  }

  returnSkillsArray(skills) {
    return  skills ? skills.split(',') : [];
  }

}
