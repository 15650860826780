import { Injectable } from '@angular/core';
import { SendReceiveService } from '../../../services/common/sendReceive.service';
import { MyAppHttpService } from '../../../services/common/my-app-http.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalscheduleService {
  openedfrom: any = 1;
  Scheduledialog: any;

  Schedulenewdialog: any;

  constructor(public sendReceiveService: SendReceiveService) { }

  getAllInterviewReportsForShare(requestData) {

    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetAllInterviewReportsForShare, requestData);

  }

}