import { Injectable } from '@angular/core';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';

@Injectable({
  providedIn: 'root'
})
export class PricingService {

  constructor(private sendReceiveService: SendReceiveService) { }

  getAllPlans(){
   
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, `${MyAppHttpService.REQUESTS.GetAllMembershipPlans}`,{});
  }

  subscribeToPlan(requestObj){
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.SubscribeToPlan,requestObj);
  }

  getMembershipCurrentPlan(){
    var enterpriseUserId = 0;
    if(!!localStorage.userData){
        enterpriseUserId = JSON.parse(localStorage.userData).enterpriseUserId;
    }
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, `${MyAppHttpService.REQUESTS.GetMembershipCurrentPlan}?enterpriseId=${enterpriseUserId}`,{});
  }

  getAllFeatures(){
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllMembershipFeatures, {});
  }

  membershipPaymentCancelled(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.MembershipPaymentCancelled, requestData);
  }
}
