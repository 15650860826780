import { Injectable } from '@angular/core';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { MatDialogRef } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReskillingpageService {
  Feedbackdialog: MatDialogRef<any>;
  Profiledialog: MatDialogRef<any>;
  Scheduledialog: MatDialogRef<any>;
  Paymentdialog: MatDialogRef<any>;

  Skills: any = [];
  constructor(private sendReceiveService: SendReceiveService, private http: HttpClient) { }


  CreateEmployee(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.CreateEmployee, requestData);
  }

  GetAllEmployees() {
    var enterpriseUserId = 0;
    if (!!localStorage.userData) {
      enterpriseUserId = JSON.parse(localStorage.userData).enterpriseUserId;
      // enterpriseUserId = 43097;
    }
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllEmployees + '?enterpriseId=' + enterpriseUserId + '');
  }

  GetEmployeeData(enterpriseUserId, userId) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetEmployee + '?enterpriseId=' + enterpriseUserId + '&userId=' + userId);
  }

  UpdateEmployee(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.UpdateEmployee, requestData);
  }

  RecommendedTrainings(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.EmployeeRecommendedTrainings, requestData);
  }

  ScheduleAssesment(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.ScheduleAssesment, requestData);
  }

  EmployeeInterviews(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.EmployeeInterviews, requestData);
  }

  GetInterviewFeedback(interviewid) {
    if (!!localStorage.userData) {
      // enterpriseUserId = JSON.parse(localStorage.userData).enterpriseUserId;
    }
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.EmployeeInterviewFeedback + '?interviewid=' + interviewid + '');
  }

  RegisterTraining(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.ReskillingRegisterTraining, requestData);
  }

  deleteEmployee(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.DeleteEmployee, requestData);
  }

  getTrainingDetails(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetTrainingDetails, requestData);
  }

  getTrainerDetails(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetTrainerDetails + '?mentorId=' + requestData.mentorId);
  }

  cancelInterview(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.cancelInterview, requestData);
  }

}
