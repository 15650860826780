import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { AutoTestSearchFilterPipe } from 'src/app/pipes/autotest.search.filter';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TypeaheadSettings } from 'ngx-type-ahead';
import { EnumResultCategory } from '../../find-profile/find-profile.component';
import { CommonfunctionService } from '../../../../services/common/commonfunction.service';

@Component({
  selector: 'app-tour-findprofiles',
  templateUrl: './tour-findprofiles.component.html',
  styleUrls: ['./tour-findprofiles.component.scss'],
  providers: [AutoTestSearchFilterPipe],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),    
  ]
})
export class TourFindprofilesComponent implements OnInit {

  @ViewChild('scrollMeInternal') private scrollProfileDetailsRightContent: ElementRef;
  @ViewChild('scrollMeProfileInfo') private scrollProfileDetailsPage: ElementRef;

  fpFilters = [
    { id: 1, name: 'All Profiles', count: 2354, countColor: '#083aac', key: EnumResultCategory.ALL, countKey: 10},
    { id: 2, name: 'Exact Match', count: 2354, countColor: 'green', key: EnumResultCategory.EXACT, countKey: 5},
    { id: 3, name: 'Rock Screened Profiles', count: 2354, countColor: '#99226e', key: EnumResultCategory.SCR, countKey: 4},
    { id: 4, name: 'Non Screened Profiles', count: 2354, countColor: '#083aac', key: EnumResultCategory.NSCR, countKey: 3}
  ];
  selectedFilter: any = this.fpFilters[0];
  profileFilterCounts : any = 10;
  

    advFltr: boolean = false;
    booleanArray = [{ value: true, display: 'Yes' }, { value: false, display: 'No' }, { value: '', display: 'None' }];
    searchTypeArray = [{ value: true, display: 'Exact' }, { value: false, display: 'Broad' }];
    experiences: any[];
    noticePeriods: any[];
    rockRatings: any[];
    jobTypes: any[]
    expectedCTCs: any[];
    probabilityofJoinings: any[];
    lastUpdates: any[];
    searchForm: FormGroup;
    searchData: any = {};
    profiles: ProfileElement[] = [];
    profilesCount: any;
    profileId;
    expandedData: ProfileElement[] = new Array();
    status;
    skillsTechDesignation1: any[];
    skillsTechDesignation2: any[];
    skillsTechDesignation: any[];
    mail = 'manish.k@rockinterview.in';
    isDefaultSearch: boolean = false;
  
    
    isTooltipSkills;
  
    selectedSkills: any = [];
    selectedTitles: any = [];
    selectedLocations: any = [];
    isToPerformDefaultSearch: boolean = false;
  
    locations: any = [];
    skills: any[];
    isDataLoaded: boolean = false;
  
    txtExperience: any;
    txtNoticePeriod: any;
    txtRockRatings: any;

    isTourProfile: boolean = false;
    isTourPOJ: boolean = false;
    isTourClickAndHire: boolean = false;
    isTourScheduleInterview: boolean = false;
  
    customSettings: Partial<TypeaheadSettings> = {
      suggestionsLimit: Infinity
    };
  
    skillsDescription = ['Testing', 'Java', 'QTP', 'Selenium'];
  
    constructor(private router: Router,
      private route: ActivatedRoute,
    public dataStorage: DataStorageService,
    private commonFunction: CommonfunctionService      ) {
      // console.log("Search data : ", this.searchData);
  
      var tempMinDate, tempMaxDate;
      tempMinDate = new Date();
      tempMaxDate = new Date();
      tempMinDate.setDate(tempMinDate.getDate());
      tempMaxDate.setDate(tempMaxDate.getDate() + 90);
  
  
    }
  
    validation_messages = {
      'jobTitle': [
        { type: 'required', message: 'PAGES.FIND_PROFILES.ERRORS.JOB_TITLE.REQUIRED.LABEL' },
      ],
      'proposedSalary': [
        { type: 'required', message: 'PAGES.FIND_PROFILES.ERRORS.PROPOSED_SALARY.REQUIRED.LABEL' },
      ],
      'joiningDate': [
        { type: 'required', message: 'PAGES.FIND_PROFILES.ERRORS.JOINING_DATE.REQUIRED.LABEL' }
      ]
    };
  
    ngOnInit() {

      this.isTourProfile = true;
      this.isTourPOJ = false;
      this.isTourClickAndHire = false;        
      this.isTourScheduleInterview = false;
  
      this.searchForm = new FormBuilder().group({
        'skillsTechDesignation': '',//[this.skillsFromPreviousPage],
        'location': '',//[this.getLocationsDataForTypeAhead()],//[[{locationId: 1, locationName: "Bangalore"},{locationId: 2, locationName: "Chennai"}]], // this.getLocationsDataForTypeAhead(),//[this.locationsFromPreviousPage], //'',// [this.searchData.location],
        'preScreened': [(!!this.searchData ? this.searchData.preScreened : "")],
        'experiences': [(!!this.searchData ? (!!this.searchData.experiences ? this.searchData.experiences : "") : "")],
        'noticePeriods': [(!!this.searchData ? (!!this.searchData.noticePeriods ? this.searchData.noticePeriods : "") : "")],
        'rockRatings': [(!!this.searchData ? (!!this.searchData.rockRatings ? this.searchData.rockRatings : "") : "")],
        'jobTypes': [(!!this.searchData ? (!!this.searchData.jobTypes ? this.searchData.jobTypes : "") : "")],
        'expectedCTS': [(!!this.searchData ? (!!this.searchData.expectedCTS ? this.searchData.expectedCTS : "") : "")],
        'probabilityofJoining': [(!!this.searchData ? (!!this.searchData.probabilityofJoining ? this.searchData.probabilityofJoining : "") : "")],
        'trainedProfiles': [(!!this.searchData ? (!!this.searchData.trainedProfiles ? this.searchData.trainedProfiles : "") : "")],
        'exactSearch': [(!!this.searchData ? this.searchData.exactSearch : "")],
        'brandSearch': [(!!this.searchData ? (!!this.searchData.brandSearch ? this.searchData.brandSearch : "") : "")],
        'lastUpdates': [(!!this.searchData ? (!!this.searchData.lastUpdates ? this.searchData.lastUpdates : "") : "")],
      });
  
  
    }
    
    ngAfterViewInit(){
      setTimeout(() => {
        this.scrollProfileDetailsRightContent.nativeElement.scrollTop = this.scrollProfileDetailsRightContent.nativeElement.scrollHeight;
      }, 1);
    }

    openDownloadBrochure(){
      this.commonFunction.openDownloadBrochure();
    }
  
   
    next(tourName){
      
      
      switch(tourName){
        case 'poj': this.isTourProfile = false;
                    this.isTourPOJ = true;
                    this.isTourClickAndHire = false;
                    this.isTourScheduleInterview = false;
                    window.scrollTo(0,200)
                    break;
        case 'clickandhire': this.isTourProfile = false;
                             this.isTourPOJ = false;
                             this.isTourClickAndHire = true;
                             this.isTourScheduleInterview = false;
                             window.scrollTo(0,1000)
                             break;
        case 'scheduleinterview': this.isTourProfile = false;
                                  this.isTourPOJ = false;
                                  this.isTourClickAndHire = false;
                                  this.isTourScheduleInterview = true;
                                  window.scrollTo(0,1)
                                  break;
        case 'postajob': this.isTourProfile = false;
                         this.isTourPOJ = false;
                         this.isTourClickAndHire = false;        
                         this.isTourScheduleInterview = false;
                         this.router.navigate(['/tour-postajob']);
                         break;
        default : break;
      }
    }
    back(tourName){
      // console.log("Tour name: ", tourName);
      switch(tourName){
        case 'home': this.isTourProfile = false;
                     this.router.navigate(['/tour-home']);
                     break;
        case 'profile': this.isTourPOJ = false;
                        this.isTourProfile = true;
                        window.scrollTo(0,1)
                        break;
        case 'poj': this.isTourClickAndHire = false;
                    this.isTourPOJ = true;
                    window.scrollTo(0,200)
                    break;
        case 'clickandhire': this.isTourScheduleInterview = false;
                             this.isTourClickAndHire = true;
                             window.scrollTo(0,1000)
                             break;
        default: break;                             
      }
    }

    
  }
  
  
  export interface ProfileElement {
    currentEmployer: any
    educationalDetails: any
    emailId: any
    experience: any
    isExactMatch: any
    isInFavourites: any
    noticePeriod: any
    probabiltyOfJoining: any,
    profileDetails: any,
    profileId: any
    profileName: any
    rating: any
    role: any
    salary: any
    userName: any
    show: boolean
    isProfileChecked: boolean,
    esId: any
  }