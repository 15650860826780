import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobinfoModalComponent } from './jobinfo-modal.component';
import { AppMaterialModule } from 'src/app/app-material.module';
import { CommonPipesModule } from 'src/app/pipes/common-pipes/common-pipes.module';
import { StarRatingModule } from 'angular-star-rating';
import { MatTabsModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    CommonPipesModule,
    MatTabsModule
  ],
  declarations: [JobinfoModalComponent],
  exports: [JobinfoModalComponent],
  entryComponents: [JobinfoModalComponent]
})
export class JobinfoModalModule { }
