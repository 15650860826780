import { Injectable } from '@angular/core';
import {SendReceiveService} from '../../../services/common/sendReceive.service';
import {MyAppHttpService} from '../../../services/common/my-app-http.service';
import { DataStorageService } from '../../../services/data-storage.service';


@Injectable({
  providedIn: 'root'
})
export class ProfileInfoService {
  collapse() {
    throw new Error('Method not implemented.');
  }

  constructor(private sendReceiveService: SendReceiveService, private dataStorage: DataStorageService) { }

  addToFavourites(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.AddToFavourites, requestData);
  }
//priya  
  shareFeedback(requestData) {
    var interviewId=requestData.interviewId;
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post,`${MyAppHttpService.REQUESTS.mentorFeedbakToJSUpdate}?interviewId=${interviewId}`, {});
  }
  deleteFromFavourites(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.DeleteFromFavourites, requestData);
  }

  clickAndHire(requestData) {
    requestData.featureId = this.dataStorage.membershipCurrentPlan.featureList.find((features) => {
      return features.featureCode == this.dataStorage.globalFeatureCodes.PROPOSE_OFFER;
    }).featureId
    requestData.subscriptionId = this.dataStorage.membershipCurrentPlan.subscriptionId;

    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.ClickAndHireFromSearchProfiles, requestData);
  }

  downloadFile(requestData){
    requestData.featureId = this.dataStorage.membershipCurrentPlan.featureList.find((features) => {
      return features.featureCode == this.dataStorage.globalFeatureCodes.DOWNLOAD_PROFILE;
    }).featureId
    requestData.subscriptionId = this.dataStorage.membershipCurrentPlan.subscriptionId;

    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post,MyAppHttpService.REQUESTS.DownloadFile,requestData);
  }

  updateprofileInteresed(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.updateProfileInterestedIn, requestData);
  }

  getProbabilityOfJoining(requestData){
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetProbabilityOfJoiningValue, requestData, MyAppHttpService.MWType.PYTHON);
  }
  


  getInterviewDetailsByEntUserIdAndProfileId(requestData){
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetInterviewDetailsByEntUserIdAndProfileId, requestData);
  }

  getAllNoticePeriodDetails(){
     return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllNoticePeriodDetails, {});
  }

  getProfileInformation(requestData){
    requestData.isFromUi = true;
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetProfileInformation, requestData );
  }

   getEmployerDetails(requestData){
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetEmployerDetails, requestData);
  }

  downloadProfileVideo(requestData){
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.DownloadVideoProfile, requestData);
  }

  getOfferSummary(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetOfferSummary, requestData);
  }

  getAllInterviewReportsForShare(requestData){
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetAllInterviewReportsForShare, requestData);
  }

  // new
  getReferenceDetail(requestData){
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetReferenceDetail, requestData);
  }

  

}
