import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DialogService } from '../../common/modal/login-dialog.service';
import { ResubscribeComponent } from '../../common/modal/resubscribe/resubscribe.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonfunctionService } from 'src/app/services/common/commonfunction.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { WebsocketService } from 'src/app/ws/websocket.service';
import { environment } from 'src/environments/environment';
import { LoginModalComponent } from '../../common/modal/login/login-modal/login-modal.component';
import { PaymentComponent } from '../../common/modal/payment/payment/payment.component';
import { BuildTeamsRemotelyComponent } from '../home/build-teams-remotely/build-teams-remotely.component';
import { HomeService } from '../home/home.service';
import { ReskillingComponent } from '../home/reskilling/reskilling.component';
import { NotificationsInboxlistService } from '../notifications-inboxlist/notifications-inboxlist.service';
import { UnsubscribeService } from './unsubscribe.service';
import { CommonConfirmModalComponent } from '../../common/modal/common-confirm-modal/common-confirm-modal.component';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UnsubscribeComponent implements OnInit {
  isTopSectionChanged: boolean;
  advFltr: boolean;
  advFilter: boolean;
  isLogin: boolean = false;
  isMenuClicked: boolean;
  element: string;
  dropDownLocationObject: any;
  skillsTechDesignation: any;
  searchForm: any;
  EnterpriseId: any;
  notificationsList: any = [];
  name: any;
  showLoginMessage: boolean = false;
  eventId: any = 34;
  emailId: any;
  showErrorMessage: boolean = false;
  showSubscribedSuceesful: boolean;
  showUnsubscribeSuccessful: boolean;
  eventIdFromRoute: string;
  selectedTalentArea;

  constructor(private dialogService: DialogService, public myAppService: MyAppHttpService,
    public homeService: HomeService, public router: Router, private sendReceiveService: SendReceiveService,
    public dataStorage: DataStorageService, private cdr: ChangeDetectorRef,
    public wsService: WebsocketService, public notificationsService: NotificationsInboxlistService,
    public dialog: MatDialog, public myAppHttp: MyAppHttpService,
    public commonfunction: CommonfunctionService, private route: ActivatedRoute,
    private unsubscribeService: UnsubscribeService
  ) {
    if (!!localStorage.getItem('token') && localStorage.getItem('token') != 'anonymous' && localStorage.getItem('token') != 'null'
      && !!localStorage.getItem('userData')) {
      this.isLogin = true;
      this.commonfunction.userLogedin = true;
      this.name = JSON.parse(localStorage.getItem('userData')).firstName;
      this.emailId = JSON.parse(localStorage.getItem('userData')).email;
      this.EnterpriseId = JSON.parse(localStorage.getItem('userData')).enterpriseUserId;

    } else {
      this.isLogin = false;
      this.commonfunction.userLogedin = false;
    }
  }

  ngOnInit() {
    console.log("Username: ", this.name);
    if (this.isLogin) {
      this.showLoginMessage = false;
      this.checkRouteParams();
    }
    else {
      this.showLoginMessage = true;
      this.openLoginModal();
    }
  }

  openLoginModal() {
    let modalRef = this.dialogService.open(LoginModalComponent, {
    });
    modalRef.afterClosed().subscribe(value => {
      // console.log('Value: ', value);
      setTimeout(() => {
        if (!!localStorage.getItem('token') && localStorage.getItem('token') != 'anonymous' && localStorage.getItem('token') != 'null'
          && !!localStorage.getItem('userData')) {
          this.isLogin = true;
          this.commonfunction.userLogedin = true;
          // this.showLoginMessage = false;
          this.name = JSON.parse(localStorage.getItem('userData')).firstName;
          this.emailId = JSON.parse(localStorage.getItem('userData')).email;
          this.EnterpriseId = JSON.parse(localStorage.getItem('userData')).enterpriseUserId;
          this.checkRouteParams();

        } else {
          this.isLogin = false;
          this.commonfunction.userLogedin = false;
          this.showLoginMessage = true;
        }
        this.cdr.detectChanges();
      }, 100);
    })

  }

  checkRouteParams() {
    if (this.route.snapshot.queryParamMap.has('eventId') == true) {
      let eventId = this.route.snapshot.queryParamMap.get('eventId');
      this.eventIdFromRoute = eventId;
      console.log('Event id: ', this.eventIdFromRoute);
      this.unsubscribeToEmails();
    }
    else {
      // this.eventId = 0;
    }
  }

  unsubscribeToEmails() {
    if (this.eventId == this.eventIdFromRoute) {
      const dialogRef = this.dialogService.open(CommonConfirmModalComponent, {
        data: {
          message: 'Are you sure you want to unsubscribe to the emails ?'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('localstorage data: ', JSON.parse(localStorage.getItem('userData')));
        let userdata = JSON.parse(localStorage.getItem('userData'));

        if (!!result) {
          let requestData = {
            eventId: JSON.parse(this.eventId),
            userEmail: this.emailId ? this.emailId : userdata.email
          }
          console.log('Request data: ', requestData);
          this.unsubscribeService.unsubscribeToEmails(requestData).subscribe((response: any) => {
            console.log('Response: ', response);
            if (response && response.statusCode == 200) {
              this.showUnsubscribeSuccessful = true;
              this.showLoginMessage = false;
              this.showSubscribedSuceesful = false;
              this.showErrorMessage = false;
            }
            else {
              this.showErrorMessage = true;
            }
            this.cdr.detectChanges();
          })
        }
        else {
          this.showUnsubscribeSuccessful = false;
          this.showLoginMessage = false;
          this.showSubscribedSuceesful = false;
          this.showErrorMessage = false;
        }
      })

    }
  }

  resubscribeToEmails() {
    // if(this.eventId == 34) {
    const dialogRef = this.dialogService.open(CommonConfirmModalComponent, {
      data: {
        message: 'Are you sure you want to resubscribe to the emails ?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      let userdata = JSON.parse(localStorage.getItem('userData'));

      if(!!result) {
        let requestData = {
          eventId: JSON.parse(this.eventId),
          userEmail: this.emailId ? this.emailId : userdata.email
        }
        console.log('Request data: ', requestData);
        this.unsubscribeService.subscribeToEmails(requestData).subscribe((response: any) => {
          console.log('Response: ', response);
          if (response && response.statusCode == 200) {
            let modalRef = this.dialogService.open(ResubscribeComponent, {
              // data: { message: 'Hey! You have already applied this profile for another job.', message1: 'Do you want to fill the same data?' }
            });
            modalRef.afterClosed().subscribe(value => {
              this.showSubscribedSuceesful = true;
              this.showUnsubscribeSuccessful = false;
              this.showErrorMessage = false;
              this.showLoginMessage = false;
            });
          }
          else {
            this.showSubscribedSuceesful = false;
            this.showUnsubscribeSuccessful = false;
            this.showErrorMessage = true;
            this.showLoginMessage = false;
          }
        });  
      }
      else {
          this.showUnsubscribeSuccessful = true;
          this.showLoginMessage = false;
          this.showSubscribedSuceesful = false;
          this.showErrorMessage = false;
      }
      
    });
    // }
  }

  Homepage() {
    this.isTopSectionChanged = false;
    this.router.navigate(['/']);
  }

  advanceFilter() {
    if (this.advFltr === false) {
      this.advFilter = true;
      this.advFltr = true;
      return this.advFltr;
    }
    else {
      this.advFilter = false;
      this.advFltr = false;
      return this.advFltr;
    }

  }
  login() {
    if (this.isLogin) {
      this.router.navigate(['/pricing']);
    }
    else {
      this.router.navigate(['/login']);
      localStorage.setItem('nextPage', 'home');
    }
  }
  closeNav() {
    this.isMenuClicked = false;
    this.element = document.getElementById("mySidenav").style.width = "0";
  }
  openNav() {
    this.element = document.getElementById("mySidenav").style.width = "260px";

  }

  public dashboard() {
    this.router.navigate(['dashboard']);
  }

  public ReskillingNew() {
    if (this.isLogin) {
      this.router.navigate(['reskillingpage']);
    }
    else {
      this.dialogService.open(LoginModalComponent, {

      });
    }
  }

  public myjobs() {
    this.router.navigateByUrl('jobs?tab=myjobs');
  }

  public favourite() {
    this.router.navigate(['favouriteprofile']);
  }

  public myprofile() {


    this.router.navigate(['myaccount']);
  }

  public offersReleased() {
    this.router.navigate(['rolledoutOffers']);
  }

  public chat() {
    this.router.navigate(['chat']);
  }

  logout() {
    this.sendReceiveService.logoutService();
  }

  clearDropdownLocationValue() {
    this.dropDownLocationObject.value = null;
  }

  help() {
    this.router.navigate([]).then(result => { window.open('/help', '_blank'); });
  }

  public postajob() {
    this.router.navigate(['/postajob']);
  }

  public findprofiles() {
    this.router.navigate(['/findprofile']);
  }
  // globalinterviews

  public globalInterviews() {
    this.router.navigate(['/globalscheduleinterview']);
  }

  public findpartners() {
    this.router.navigate(['/find-partners']);
  }

  public resumebuilder() {
    this.router.navigate(['/resume-builder']);
  }



  screenedProfiles() {
    var skills = this.dataStorage.globalTechnologyAreas.SCREENED.skill_set;
    var skillQueryParams = this.dataStorage.globalSkills.filter(x => skills.indexOf(x.name) > -1).map(x => x.id).join();
    this.onSkillsTypeAheadChange(this.skillsTechDesignation.filter(x => skills.indexOf(x.name) > -1));

    this.searchForm.value.selectedSkills = this.skillsTechDesignation.filter(x => skills.indexOf(x.name) > -1);
    this.dataStorage.previousSearchCriteria = this.searchForm.value;

    if (skillQueryParams) {
      this.router.navigate(['findprofile'], {
        queryParams: {
          'skillIds': skillQueryParams,
          'selectedFilter': 2
        }
      });
    }
    else {
      this.router.navigate(['findprofile']);
    }
  }
  onSkillsTypeAheadChange(arg0: any) {
    throw new Error('Method not implemented.');
  }

  AIUtilities() {
    var skills = this.dataStorage.globalTechnologyAreas.SCREENED.skill_set;
    var skillQueryParams = this.dataStorage.globalSkills.filter(x => skills.indexOf(x.name) > -1).map(x => x.id).join();
    this.onSkillsTypeAheadChange(this.skillsTechDesignation.filter(x => skills.indexOf(x.name) > -1));

    this.searchForm.value.selectedSkills = this.skillsTechDesignation.filter(x => skills.indexOf(x.name) > -1);
    this.dataStorage.previousSearchCriteria = this.searchForm.value;


    if (skillQueryParams) {
      this.router.navigate(['findprofile'], {
        queryParams: {
          'skillIds': skillQueryParams
        }
      });
    }
    else {
      this.router.navigate(['findprofile']);
    }
  }

  public pricing() {
    this.router.navigate(['/pricing']);
  }

  public staffingAgencies() {

    this.router.navigate([]).then(result => { window.open('/staffingAgencies', '_blank'); });
  }

  changePassword() {
    this.router.navigate(['/changepassword']);
  }

  scheduledInterviews() {
    this.router.navigate(['/scheduledInterviews']);
  }

  postJobs() {
    this.router.navigate(['/postajob']);
  }

  globalinterviews() {
    // if(this.isLogin) {
    //   this.router.navigate(['/globalscheduleinterview']);
    // }
    // else {
    //   this.dialogService.open(LoginModalComponent, {

    //   });
    // }
    this.router.navigate(['/globalscheduleinterview']);

  }

  goToContractorsPage(enterpriseRole) {
    this.router.navigate(['/contractors'], {
      queryParams: {
        'enterpriseRole': enterpriseRole
      }
    });
  }

  getAllNotifications(menuTrigger) {
    if (this.isLogin) {
      this.dataStorage.unreadNotificationsCountBoolean = false;
      let requestData = {
        enterpriseUserId: this.EnterpriseId,
        limit: 4
      };

      this.notificationsService.getAllNotifications(requestData).subscribe(response => {
        if (response.statusCode == 200) {
          this.notificationsList = response.notifications;
          if (this.notificationsList.length == 0) {
            this.router.navigate(['/notifications'], {
              queryParams: {
                'noticationId': 0
              }
            });
          }
          else {
            menuTrigger.openMenu();
          }
        }
      });
    }
  }

  showMoreNotifications(notf) {
    if (notf != null) {
      let inputData = {
        id: notf.id,
        read: true
      };
      this.notificationsService.readNotification(inputData).subscribe(response => {
      });
    }
    this.router.navigate(['/notifications'], {
      queryParams: {
        'noticationId': notf ? notf.id : 0
      }
    });
  }

  scheduleInterview() {
    if (this.isLogin) {
      if (!!this.dataStorage.membershipCurrentPlan.planId && this.dataStorage.membershipCurrentPlan.planDurationNum > -1) {
        this.fnOpenScheduleMockInterviewDialog();
      }
      else {
        this.dialogService.open(PaymentComponent, {
          data: { actionName: this.dataStorage.globalFeatureCodes.INTERVIEW_OWN, popupFlag: true, details: null, flag: null, eventType: null }
        });
      }

    }
    else {
      console.log("navigate")
      this.fnOpenScheduleMockInterviewDialog();

    }
  }

  fnOpenScheduleMockInterviewDialog() {
    this.router.navigate(['/globalscheduleinterview']);
  }

  onFeatureClick(el: HTMLElement | null) {
    // el.scrollIntoView({ behavior: 'smooth' });
  }

  isMenuOpened() {
    this.isMenuClicked = true;
  }
  isMenuClosed() {
    this.isMenuClicked = false;
  }

  goToWebsite() {
    window.open(environment.websiteURL, '_blank');
  }

  teams() {


    const dialogRef: MatDialogRef<any> = this.dialog.open(BuildTeamsRemotelyComponent,
      {
        height: 'auto 850px',
        width: '900px',
        panelClass: 'custom-dialog-container',
        autoFocus: false,
        data: { EnterpriseId: this.EnterpriseId },
        disableClose: true
      });


  }

  Reskilling(selectedOption) {
    this.Openreskilling(selectedOption);
  }

  Openreskilling(selectedOption) {
    const dialogRef: MatDialogRef<any> = this.dialog.open(ReskillingComponent,
      {
        height: 'auto',
        width: '880px',
        panelClass: 'custom-dialog-container',
        autoFocus: false,
        data: { EnterpriseId: this.EnterpriseId, selectedOption: selectedOption },
        disableClose: true
      });

  }

  convertToPlainString(content) {
    content = content ? content.replace(/(<([^>]+)>)/ig, '') : '';

    return content ? content.replace(/&nbsp;/g, ' ') : '';
  }


}
