import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnsubscribeRoutes } from './unsubscribe.route';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppMaterialModule } from 'src/app/app-material.module';
import { UnsubscribeComponent } from './unsubscribe.component';
import { UnsubscribeService } from './unsubscribe.service';

@NgModule({
  declarations: [UnsubscribeComponent],
  imports: [
    RouterModule.forChild(UnsubscribeRoutes),
    TranslateModule,
    AppMaterialModule,
    CommonModule
  ],
  exports: [],   
  schemas: [],
  providers:[UnsubscribeService]
})
export class UnsubscribeModule { }
