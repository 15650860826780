import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/app-material.module';
import { CommonPipesModule } from 'src/app/pipes/common-pipes/common-pipes.module';
import { MatTabsModule } from '@angular/material';
import { ShareOpportunityComponent } from './share-opportunity.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    CommonPipesModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  declarations: [ShareOpportunityComponent],
  exports: [ShareOpportunityComponent],
  entryComponents: [ShareOpportunityComponent]
})
export class ShareOpportunityModule { }
