import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoTestSearchFilterPipe } from '../autotest.search.filter';
import { AutoSearchFilterPipe } from '../auto.search.filter';
import { AvatarFilter } from '../avtar';
import { NameByIdFilterPipe } from '../namebyid.filter';
import { SubStringByLength } from '../substringbylength.filter';


@NgModule({
  declarations: [
    AutoSearchFilterPipe,
    AutoTestSearchFilterPipe,
    AvatarFilter,
    NameByIdFilterPipe,
    SubStringByLength
  ],
  imports: [
    CommonModule,  
  ],
  exports: [
    AutoSearchFilterPipe,
    AutoTestSearchFilterPipe,
    AvatarFilter,
    NameByIdFilterPipe,
    SubStringByLength
  ]
})
export class CommonPipesModule { }
