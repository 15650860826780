import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material';

@Injectable()

export class MyAppHttpService {
    snackref: MatSnackBarRef<any>;

    constructor(private snackBar: MatSnackBar) { }
    onInit() {

    }
    public static ERRORS = {
        // LOGIN_INVALID_CREDENTIALS:{CODE:401,PHRASE:"PAGES.LOGIN.ERRORS.INVALID_CREDENTIALS.LABEL"}
        //   GET_ALL_MASTER_DATA: { CODE: 10101, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },

        //   REG_TOKEN_NOT_EXIST: { CODE: 14001, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   NO_MOBILE_OR_EMAIL_EXIST_FOR_TOKEN: { CODE: 14002, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },

        //   USER_REGISTRATION_FAILED: { CODE: 16101, PHRASE: "LOGIN.ERRORS.USER_REGISTRATION_FAILURE.LABEL" },
        //   ADDITION_OF_USER_DETAILS_FAILED: { CODE: 16201, PHRASE: "LOGIN.ERRORS.ADD_USER_DETAILS_FAILURE.LABEL" },
        //   USER_DOES_NOT_EXIST_FOR_THIS_MOBNO: { CODE: 16202, PHRASE: "LOGIN.ERRORS.USER_DOES_NOT_EXIST_FOR_THIS_MOBNO.LABEL" },
        //   USER_DOES_NOT_EXIST_FOR_THIS_EMAIL: { CODE: 16203, PHRASE: "LOGIN.ERRORS.USER_DOES_NOT_EXIST_FOR_THIS_EMAIL.LABEL" },
        //   MOBILE_DOES_NOT_EXIST_FOR_THIS_EMAIL: { CODE: 16204, PHRASE: "LOGIN.ERRORS.MOBILE_DOES_NOT_EXIST_FOR_THIS_EMAIL.LABEL" },
        //   EMAIL_MOBNO_DOES_NOT_MATCH: { CODE: 16205, PHRASE: "LOGIN.ERRORS.EMAIL_MOBNO_DOES_NOT_MATCH.LABEL" },
        //   GET_USER_DETAILS: { CODE: 16301, PHRASE: "LOGIN.ERRORS.GET_USER_DETAILS_FAILURE.LABEL" },

        //   GENERATE_OTP_TECHNICAL_EXCEPTION: { CODE: 18001, PHRASE: "OTP.ERRORS.GENERIC_ERROR.LABEL" },
        //   VERIFY_OTP_TECHNICAL_EXCEPTION: { CODE: 18101, PHRASE: "OTP.ERRORS.INVALID_OTP.LABEL" },
        //   OTP_NOT_MATCH: { CODE: 18102, PHRASE: "OTP.ERRORS.INVALID_OTP.LABEL" },
        //   OTP_EXPIRED: { CODE: 18103, PHRASE: "OTP.ERRORS.OTP_EXPIRED.LABEL" },
        //   DEVICE_ALREADY_EXIST: { CODE: 18104, PHRASE: "OTP.ERRORS.GENERIC_ERROR.LABEL" },

        //   SETUP_INTERVIEW_DATE_SHOULD_BE_MORE_THAN_24HRS_FROM_NOW: { CODE: 20101, PHRASE: "SETUP_MOCK_INTERVIEW.ERRORS.DATE_SHOULD_BE_MORE_THAN_24HRS_FROM_NOW.LABEL" },
        //   SETUP_INTERVIEW_TIME_SHOULD_NOT_BE_BETWEEN_12AM_AND_6AM: { CODE: 20102, PHRASE: "SETUP_MOCK_INTERVIEW.ERRORS.TIME_SHOULD_NOT_BE_BETWEEN_12AM_AND_6AM.LABEL" },
        //   SETUP_INTERVIEW_TIME_SHOULD_HAVE_2HR_GAP_FOR_INTERVIEW: { CODE: 20103, PHRASE: "SETUP_MOCK_INTERVIEW.ERRORS.TIME_SHOULD_HAVE_2HR_GAP_FOR_INTERVIEW.LABEL" },
        //   EMPTY_TIMESTAMP_LIST: { CODE: 20104, PHRASE: "SETUP_MOCK_INTERVIEW.ERRORS.GENERIC.LABEL" },
        //   INVALID_COMPLETE_INTERVIEW_FLAG: { CODE: 20105, PHRASE: "SETUP_MOCK_INTERVIEW.ERRORS.GENERIC.LABEL" },
        //   SETUP_INTERVIEW_DATE_IS_MORE_THAN_MAX_DAYS_FROM_TOMORROW: { CODE: 20106, PHRASE: "SETUP_MOCK_INTERVIEW.ERRORS.DATE_90_DAYS.LABEL" },
        //   SETUP_INTERVIEW_SLOT_ALREADY_ALLOTTED: { CODE: 28001, PHRASE: "This slot is already allotted" },
        //   INVALID_IFSC_CODE: { CODE: 22101, PHRASE: "BANKDETAILS.ERRORS.INVALID_IFSC_CODE.LABEL" },
        //   INVALID_BANK_NAME: { CODE: 22102, PHRASE: "BANKDETAILS.ERRORS.INVALID_BANK_NAME.LABEL" },

        //   FILE_UPLOAD: { CODE: 62001, PHRASE: "PROFILE.ERRORS.GENERIC_FILE_UPLOAD.LABEL" },
        //   FILE_SIZE_SHOULD_BE_LESS_THAN_2MB: { CODE: 62002, PHRASE: "PROFILE.ERRORS.FILE_SIZE_ALLOWED.LABEL" },
        //   INVALID_FILE_EXTENSION: { CODE: 62003, PHRASE: "PROFILE.ERRORS.FILE_EXTENSION_ALLOWED.LABEL" },
        //   EMPTY_FILE: { CODE: 62004, PHRASE: "PROFILE.ERRORS.GENERIC_FILE_UPLOAD.LABEL" },

        //   UNKNOWN: { CODE: 99999, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   INPUT_REQUIRED: { CODE: 100000, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   DB_EXCEPTION: { CODE: 100001, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   INVALID_MOBILE_NUMBER: { CODE: 100002, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   INVALID_EMAIL_ID: { CODE: 100003, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   EMAIL_DOESNOT_EXIST: { CODE: 100004, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   USERID_DOESNOT_EXIST: { CODE: 100005, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   DATE_PARSE_EXCEPTION: { CODE: 100006, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   INVALID_LOGIN_TYPE: { CODE: 100007, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   INVALID_MODULE_ID: { CODE: 100008, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   MOBILE_NO_DOES_NOT_EXIST: { CODE: 100009, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   INVALID_USER_TYPE: { CODE: 100010, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   EMAIL_ALREADY_EXIST: { CODE: 100011, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   MOBILE_NO_ALREADY_EXIST: { CODE: 100012, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   MALFORMED_EXCEPTION: { CODE: 100013, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   PROTOCOL_EXCEPTION: { CODE: 100014, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   IO_EXCEPTION: { CODE: 100015, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   INVALID_JWT_TOKEN: { CODE: 100016, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   JWT_UNSUPPORTED_ENCODING: { CODE: 100017, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   JWT_VERIFICATION_EXCEPTION: { CODE: 100018, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" },
        //   DEVICE_DOES_NOT_EXIST: { CODE: 100019, PHRASE: "COMMON.ERRORS.GENERIC_ERROR.LABEL" }
    };

    public static FLOW_TYPES = {
        REGISTRATION: 1,
        LOGIN: 2
    };

    public static FEEDBACK_STATUS = {
        PENDING: 1,
        COMPLETED: 2
    };

    public static RESUME_STATUS = {
        NOT_UPLOADED: 1,
        UPLOADED: 2
    };

    public static FILE_UPLOAD_EXTENSIONS = {
        DOC: "doc",
        DOCX: "docx",
        PDF: "pdf"
    };


    public static VALIDATION_PATTERNS = {
        // NAME: /^[a-z]+$/i,
        NAME: /^([a-zA-Z]+\s)*[a-zA-Z]+$/,
        EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        MOBILE_NUMBER: /^([0|\+[0-9]{1,5})?([5-9][0-9]{9})$/,
        LOCATION: /^[a-z ]+$/i,
        DESIGNATION: /^[a-z. ]+$/i,
        QUALIFICATION: /^[a-z. ]+$/i,
        // Metting_link:/^((https?):\/\/)?[a-z0-9]+\.(google.com|zoom.us|microsoft.com)\/.+$/,
        Metting_link:/^((https?):\/\/).+$/,
        CURRENT_COMPANY: /^[a-z. ]+$/i,
        BANK_ACCOUNT_NUMBER: /^[0-9]+$/,
        BANK_IFSC_CODE: /^[a-zA-Z0-9]+$/,
        BANK_BRANCH_NAME: /^[a-zA-Z\s]+$/,
        PREVIOUS_COMPANY: /^[a-z. , ]+$/i,
        //PASSWORDS:/(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        //   PASSWORD: /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
        PASSWORD: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}/,
        NUM_OF_OPENJOBS: /\d/,
        alphanumericWithOneSpace: /^[a-z](\w+\s)*\w+$/i,
        ONLY_ALPHABETS: /^[a-zA-Z ]*$/,
        ALPHABETS_WITH_ONE_SPACE: /[a-zA-Z][a-zA-Z ]+$/,
        YOUTUBE_PATTERN: /^((https?|ftp|smtp):\/\/)?(www.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/,
        WEBSITE_PATTERN: /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        NAME_WITH_SPACE_AND_FULL_STOP: /^[a-z ][a-z. ]+$/i
    }

    public static ACTION_TYPES = {
        SUCCESS: 0,
        FAILURE: 1
    };

    public static MESSAGE_SHOW_TYPES = {
        SETUP_MOCK_INTERVIEW_SUCCESS: {
            value: 1,
            phrase: "SETUP_MOCK_INTERVIEW.SUCCESS.LABEL",
            type: MyAppHttpService.ACTION_TYPES.SUCCESS
        },
        EDITPROFILE_SUCCESS: {
            value: 2,
            phrase: "EDITPROFILE.SUCCESS.LABEL",
            type: MyAppHttpService.ACTION_TYPES.SUCCESS
        },
        PROFILE_UPDATE_SUCCESS: {
            value: 3,
            phrase: "PROFILE.UPDATE_SUCCESS.LABEL",
            type: MyAppHttpService.ACTION_TYPES.SUCCESS
        },
        OTP_REGISTRATION_UPDATE_SUCCESS: {
            value: 4,
            phrase: "OTP.UPDATE_SUCCESS.LABEL",
            type: MyAppHttpService.ACTION_TYPES.SUCCESS
        },
        BANK_DETAILS_UPDATE_SUCCESS: {
            value: 5,
            phrase: "BANKDETAILS.UPDATE_SUCCESS.LABEL",
            type: MyAppHttpService.ACTION_TYPES.SUCCESS
        },
        FEEDBACK_JOBSEEKER_SUCCESS: {
            value: 6,
            phrase: "FEEDBACK_VIEW.UPDATE_SUCCESS.LABEL",
            type: MyAppHttpService.ACTION_TYPES.SUCCESS
        }
    };


    public static APP_UPGRADE_TYPES = {
        NONE: 0,
        OPTIONAL: 1,
        MANDATORY: 2
    };

    public static CALENDAR_DAYS = [{
        "id": 1,
        "name_short_en": "Sun",
        "name_long_en": "Sunday"
    }, {
        "id": 2,
        "name_short_en": "Mon",
        "name_long_en": "Monday"
    }, {
        "id": 3,
        "name_short_en": "Tue",
        "name_long_en": "Tuesday"
    }, {
        "id": 4,
        "name_short_en": "Wed",
        "name_long_en": "Wednesday"
    }, {
        "id": 5,
        "name_short_en": "Thu",
        "name_long_en": "Thursday"
    }, {
        "id": 6,
        "name_short_en": "Fri",
        "name_long_en": "Friday"
    }, {
        "id": 7,
        "name_short_en": "Sat",
        "name_long_en": "Saturday"
    }];

    public static CALENDAR_MONTHS = [{
        "id": 1,
        "name_short_en": "Jan",
        "name_long_en": "January",
        "number_of_days": 31
    }, {
        "id": 2,
        "name_short_en": "Feb",
        "name_long_en": "February",
        "number_of_days": 28
    }, {
        "id": 3,
        "name_short_en": "Mar",
        "name_long_en": "March",
        "number_of_days": 31
    }, {
        "id": 4,
        "name_short_en": "Apr",
        "name_long_en": "April",
        "number_of_days": 30
    }, {
        "id": 5,
        "name_short_en": "May",
        "name_long_en": "May",
        "number_of_days": 31
    }, {
        "id": 6,
        "name_short_en": "Jun",
        "name_long_en": "June",
        "number_of_days": 30
    }, {
        "id": 7,
        "name_short_en": "Jul",
        "name_long_en": "July",
        "number_of_days": 31
    }, {
        "id": 8,
        "name_short_en": "Aug",
        "name_long_en": "August",
        "number_of_days": 31
    }, {
        "id": 9,
        "name_short_en": "Sep",
        "name_long_en": "September",
        "number_of_days": 30
    }, {
        "id": 10,
        "name_short_en": "Oct",
        "name_long_en": "October",
        "number_of_days": 31
    }, {
        "id": 11,
        "name_short_en": "Nov",
        "name_long_en": "November",
        "number_of_days": 30
    }, {
        "id": 12,
        "name_short_en": "Dec",
        "name_long_en": "December",
        "number_of_days": 31
    }];


    public static UPLOAD_ERRORS = {
        INVALID_FILE_FORMAT: { value: 1 },
        FILESIZE_TOO_HIGH: { value: 2 }
    };


    public static PAGES = {
        WELCOME: {
            component: 'WelcomePage',
            showMenu: false,
            addToStack: false,
            baseStackPage: true
        },
        OTP: {
            component: 'OTPPage',
            showMenu: false,
            addToStack: false,
            baseStackPage: true
        },
        PROFILE: {
            component: 'ProfilePage',
            showMenu: false,
            addToStack: false,
            baseStackPage: true
        },
        CREATEPROFILE: {
            component: 'CreateProfilePage',
            showMenu: false,
            addToStack: false,
            baseStackPage: true
        },
        EDITPROFILE: {
            component: 'EditProfilePage',
            showMenu: true,
            addToStack: true,
            baseStackPage: false
        },
        DASHBOARD: {
            component: 'DashboardPage',
            showMenu: true,
            addToStack: true,
            baseStackPage: true
        },
        SETUP_MOCK_INTERVIEW: {
            component: 'SetupMockInterviewPage',
            showMenu: true,
            addToStack: true,
            baseStackPage: false
        },
        BANK_DETAILS: {
            component: 'BankDetailsPage',
            showMenu: true,
            addToStack: true,
            baseStackPage: false
        },
        SCHEDULED_INTERVIEWS: {
            component: 'ScheduledInterviews',
            showMenu: true,
            addToStack: true,
            baseStackPage: false
        },
        COMPLETED_INTERVIEWS: {
            component: 'CompletedInterviews',
            path: 'completedInterview',
            showMenu: true,
            addToStack: true,
            baseStackPage: false
        },

        FORCE_UPGRADE: {
            component: 'ForceUpgrade',
            showMenu: false,
            addToStack: false,
            baseStackPage: true
        },
        SYSTEM_MAINTENANCE: {
            component: 'SystemMaintenancePage',
            showMenu: false,
            addToStack: false,
            baseStackPage: true
        },
        SPLASH: {
            component: 'SplashPage',
            showMenu: false,
            addToStack: false,
            baseStackPage: true
        },
        VIEW_FEEDBACK: {
            component: 'Viewfeedback',
            path: 'viewfeedback',
            showMenu: true,
            addToStack: true,
            baseStackPage: false
        },
        INTERVIEWERS_LIST: {
            component: 'Interviewslist',
            showMenu: false,
            addToStack: true,
            baseStackPage: false
        },
        TIME_SLOT: {
            component: 'TimeSlotPage',
            showMenu: false,
            addToStack: true,
            baseStackPage: false
        },
        INTERVIEW_CONFIRMATION: {
            component: 'InterviewConformation',
            showMenu: false,
            addToStack: true,
            baseStackPage: false
        },
        YOUR_INTERVIEWS: {
            component: 'YourInterviews',
            showMenu: true,
            addToStack: true,
            baseStackPage: false
        },
        NOTIFICATION: {
            component: 'NotificationsListComponent',
            showMenu: true,
            addToStack: true,
            baseStackPage: false
        }
    };

    public static PAYMENT_MODES = {
        PAY_NOW: 'Pay Now',
        PAY_LATER: 'Pay Later'
    }

    //   public static TECHNOLOGY_AREAS ={
    //     TECH_TALENT: 'TECH_TALENT', ACCOUNTING_TALENT: 'ACCOUNTING_TALENT', SALES_TALENT: 'SALES_TALENT',
    //     MARKETING_TALENT: 'MARKETING_TALENT', RECRUIT_TALENT: 'RECRUIT_TALENT'
    //   }

    public static TECHNOLOGY_AREAS = {
        TECH_TALENT: { key: 'TECH_TALENT', skill_set: ['Full Stack Developer'] },
        ACCOUNTING_TALENT: { key: 'ACCOUNTING_TALENT', skill_set: ['Finance'] },
        SALES_TALENT: { key: 'SALES_TALENT', skill_set: ['Sales and Marketing'] },
        MARKETING_TALENT: { key: 'MARKETING_TALENT', skill_set: ['Digital Marketing'] },
        RECRUIT_TALENT: { key: 'RECRUIT_TALENT', skill_set: ['HR Function'] },
        ITES_TALENT: { key: 'ITES_TALENT', skill_set: ['ITES'] },
        SCREENED: { key: 'SCREENED', skill_set: ['Core Java', 'Selenium'] }
    }

    public static RESPONSE_CODES = {
        SUCCESS: 200,
        UNAUTHORIZED: 401,
        CONFLICT: 409,
        NOT_FOUND: 404,
        ALREADY_LOGGED_IN: 208,
        EMAIL_NOT_VERIFIED: 423,
        BAD_REQUEST: 400,
        NOT_ACCEPTABLE: 406,
        INVALID_TOKEN: 304,
        SCHEDULE_INTERVIEW_IS_INTERVIEWER: 888888,
        SCHEDULE_INTERVIEW_IS_JOBSEEKER: 888887,
        SCHEDULE_INTERVIEW_IS_PRESCREENED: 888886,
        SCHEDULE_INTERVIEW_IS_INTERVIEW_ALREADY_ASSIGNED: 888885,
        // SCHEDULE_INTERVIEW_IS_INTERVIEW_ALREADY_ASSIGNED_UPDATED: 888884,
        SCHEDULE_INTERVIEW_IS_EXISTING_RI_USER: 888884,
        SCHEDULE_INTERVIEW_DATE_IS_BEFORE_SCHEDULED_DATE: 888889,
        SCHEDULE_INTERVIEW_GAP_2_HOURS: 888890,
        SCHEDULE_INTERVIEW_IS_PREVIOUS_INTERVIEW_NOT_COMPLETED: 888891,
        MEMBER_NOT_A_MEMBER: 800001,
        MEMBER_PLAN_EXPIRED: 800002,
        MEMBER_FEATURE_NOT_AVAILABLE: 800003,
        MEMBER_QUOTA_COMPLETE: 800004,
        MEMBER_UNKNOWN: 800005,
        JOB_APPLIED_ALREADY: 800006,
        NO_CONTENT: 204
    };

    public static REQUESTS = {
        Registration: "register",
        Login: "Login",
        VerifiEmail: "verifyemail",
        MasterServices: "getAllMasterDataDetails",
        SearchProfiles: "getAllSearchProfileResults",
        Logout: "Logout",
        AddToFavourites: "addToFavourites",
        DeleteFromFavourites: "removeFromFavourites",
        GetAllFavourite: "getAllFavourites",
        ForgotPassword: "forgotPassword",
        ResetPassword: "forgotpasswordchange",
        DownloadFile: 'downloadfile',
        getAllEntPostedJobs: 'getAllEntPostedJobs',
        getAllAppliedProfilesForJob: 'getAllAppliedProfilesForJob',
        GetAllTechnologies: "getAllSkillsAndToolsForEnterprise",
        GetAllExperienceDetails: "getAllExperienceDetails",
        GetAllJobDescriptionTemplatesForGuestUser: "getAllJobDescTemplates",
        GetAllJobDescriptionTemplatesForEnterpriseUser: "getAllJobDescTempByEntUser",
        GetAllJobDescriptionTemplatesWithFilter: "getAllJobDescTemplatesWithFilter",
        GetAllLocations: "getAllLocations",
        GetAllJobTitles: "getAllDesignations",
        GetAllSkillsToolsDesignations: "getAllSkillsToolsDesignations",
        GetAllTechnologiesWithKeywords: 'technologies',
        // GetAllInterviewLevels: 'interviewlevel',
        GetAllInterviewLevels: 'interviewLevel',    //priya
        GetAllProfilesCount: "getAllProfilesCount",
        updateJobActiveOrInactive: "updateJobActiveOrInactive",
        clickAndHireAProfile: "clickAndHireAProfile",
        updateProfileInterestedIn: "updateProfileInterestedIn",
        addJobTemplate: 'addToJobTemplates',
        postJob: 'addToJobs',
        GetAllTimeDifferences: "getAllTimeDifferences",
        GetAllDashboardDetailsCounts: "getAllDashboardDetails",
        GetAllDashboardPostedJobs: "getAllDashboardPostedJobs",
        GetAllDashboardOfferReleased: "getAllDashboardOfferReleased",
        GetAllRecentlyPostedJobs: "getAllRecentlyPostedJobs",
        GetMyProfileDetails: "getMyProfileDetails",
        UpdateMyProfile: "updateMyProfile",
        GetAllRoles: "getAllRoles",
        ClickAndHireFromSearchProfiles: "clickAndHireFromSearchProfile",
        paidUserCheck: "paidUserCheck",
        updatePaymentId: "updatePaymentId",
        ChangePassword: "changePassword",
        GetAllOffersReleasedJobs: "getAllOffersReleasedJobs",
        GetAllProfilesByJobId: "getAllProfilesByJobId",
        ScheduleInterviewforExistingUser: "scheduleInterviewForExistingUser",
        GetAllInterviewsScheduledByEntUser: "getAllInterviewsScheduledByEntUserId",
        GetAllScheduledProfilesByEntId: "getAllScheduledProfilesByEntId",
        //GetProbabilityOfJoiningValue: 'api/profile/get_joining_probability/v1',  //QA
        GetProbabilityOfJoiningValue: 'api/profile/get_joining_probability',  //PROD
        //GetProbabilityOfJoiningValue: 'getProbablityOfJoining',
        ValidateScheduleInterviewForNonExistingUser: 'validateScheduleInterview',
        ScheduleInterviewForNonExistingUser: 'scheduleInterviewForNonExistingUser',
        UploadResume: 'uploadResume',
        uploadResumeForApplyJob: 'uploadResumeByEntUser',
        GetInterviewDetailsByEntUserIdAndProfileId: 'getInterviewDetailsByEntUserIdAndProfileId',
        GetAllNoticePeriodDetails: 'getAllNoticePeriodDetails',
        HelpService: 'helpService',
        DownloadBrochure: 'downloadBrochure',
        ShareProfileService: 'shareProfile',
        GetProfileInformation: 'getProfileInformation',
        IsUserExistsToSchedule: 'isUserExistsToSchedule',
        GetAllMembershipPlans: 'membership/plans',
        SubscribeToPlan: 'membership/subscribeToPlan',
        GetAllMembershipFeatures: 'membership/features',
        GetMembershipCurrentPlan: 'membership/currentPlan',
        SaveEntUserFCMToken: 'addEntUserFcmToken',
        GetMemberFeatureEligibility: 'membership/featureEligibility',
        Chat: "chat",
        ConversationByChatId: "conversationbychatid",
        GetAllOpportunities: "getAllOpportunities",
        GetOpportunitiesDetails: "getOpportunitiesDetailsByJobId",
        ApplyJob: "applyJob",
        GetEmployerDetails: "getEmployerDetails",
        IsExistingUserForJobApply: "isExistingUser",
        DownloadVideoProfile: "downloadVideoProfile",
        GetJobDetailsByJobId: 'getJobDetailsByJobId',
        AddToProposedJobs: 'addToProposedJobs',
        GetMyPlanData: 'myPlan',
        GetJobTitlesBySkills: 'getJobTitlesBySkills',
        GetAllNotifications: 'inbox/getAllNotifications',
        ReadNotification: 'inbox/read',
        GetUnreadCountForNotification: 'inbox/getUnreadCount',
        ShareAnOpportunity: 'shareAnOpportunity',
        ChatEnterprisePostedJobs: 'chat/entPostedJobs',
        MembershipPaymentCancelled: 'membership/paymentCancelled',
        GetOfferSummary: 'getOfferSummary',
        saveReskillingDetails: 'saveReskillingDetails',
        BuildTeamsRemotelyDetails: 'saveRemoteTeamsDetails',
        CreateEmployee: 'createEmployee',
        UpdateEmployee: 'updateEmployee',
        GetAllEmployees: 'getAllEmployees',
        EmployeeRecommendedTrainings: 'employee/recommended-trainings',
        EmployeeInterviews: 'employee-interviews',
        ScheduleAssesment: 'scheduleInterviewForExistingUser',
        EmployeeInterviewFeedback: 'interview-feedback',
        ReskillingRegisterTraining: 'register-training',
        DeleteEmployee: 'deleteEmployee',
        GetTrainingDetails: 'training-details',
        GetTrainerDetails: 'getTrainerDetails',
        getAllMentorsAddedByEnterprise: 'getAllMentorsAddedByEnterprise',
        AddMentorByEnterpriseUserId: 'addMentorByEnterpriseUser',
        UpdateInterviewerData: 'updateInterviewer',
        GetAllInterviewReportsForShare: 'getAllInterviewReportsForShare',
        GetEmployee: 'getEmployee',
        DeleteInterviewer: 'deleteScheduleInterviewer',
        cancelInterview: 'cancelInterview',
        updateInterview: 'updateInterview',
        GetReferenceDetail : 'viewRefferalUsers',
        mentorFeedbakToJSUpdate :'mentorFeedbakToJS' ,
        Zoom:'generateZoomMeetingLink ',  //priya
        createZoom:'createZoomMeeting ',
        updateZoom:'updateZoomMeeting' ,
        deleteZoom:'deleteZoomMeeting',
        getAllEnterpriseUsers:'getAllEnterpriseUsers',
        getOpportunitiesDetailsByJobId:'getOpportunitiesDetailsByJobId',
        saveEnterpriseSingleResource: 'entResourceSave',
        getEnterpriseResources: 'entResourcesSearch',
        deleteMyResource: 'entResourceDelete',
        updateMyResource: 'entResourceUpdate',
        getMeetingLinksStatus: 'api/v2/MeetingStatus',
        getUserDetailsByNumber: 'getUserDetails',
        getUserdetailsForPostAJOB: 'getUserdetailsForPostAJOB',
        parsewithFile: 'ParseWithFile',
        getJobDescFromChatGPT: 'chat-gpt/prep-job-desc',
        subscribeToEmails: 'event/subscribe',
        unsubscribeToEmails: 'event/unSubscribe',
        googleCalendar: 'googlecalendar',
        // GetCountries: 'api/v2/shared/getcountries',
        GetCountries: 'getCountries',
        // GetCities: 'api/v2/shared/getCities',
        GetCities: 'getCities?stateId=',
        // GetStatesAndCities: 'api/v2/shared/getStatesAndCities'
        GetStatesAndCities: 'getCitiesAndStates?countryId=',
        GetAllCountries: 'getAllCountries',
        GetAllStates: 'getAllStates',
        GetAllCities: 'getAllCities'
    };

    public static FEATURE_CODES = {
        POST_A_JOB: 'POST_A_JOB',
        DOWNLOAD_PROFILE: 'DOWNLOAD_PROFILE',
        PROPOSE_OFFER: 'PROPOSE_OFFER',
        INTERVIEW_RI: 'INTERVIEW_RI',
        INTERVIEW_OWN: 'INTERVIEW_OWN',
        COMMUNICATION: 'COMMUNICATION',
        POJ: 'POJ',
        JOB_OF_THE_DAY_TEMPLATE: 'JOB_OF_THE_DAY_TEMPLATE',
        OPPORTUNITIES: 'OPPORTUNITIES',
        VIDEO_PROFILE: 'VIDEO_PROFILE',
        RESKILLING_ASSESSMENT: 'RESKILLING_ASSESSMENT'
    };

    public static AVAILABLE_FEATURE_INFO = [
        { isAvailable: false, featureCode: MyAppHttpService.FEATURE_CODES.POST_A_JOB },
        { isAvailable: false, featureCode: MyAppHttpService.FEATURE_CODES.DOWNLOAD_PROFILE },
        { isAvailable: false, featureCode: MyAppHttpService.FEATURE_CODES.PROPOSE_OFFER },//(Click and Hire)
        { isAvailable: false, featureCode: MyAppHttpService.FEATURE_CODES.INTERVIEW_RI },
        { isAvailable: false, featureCode: MyAppHttpService.FEATURE_CODES.INTERVIEW_OWN },
        { isAvailable: false, featureCode: MyAppHttpService.FEATURE_CODES.COMMUNICATION },// (Send Email, Share Profile, Chat)
        { isAvailable: false, featureCode: MyAppHttpService.FEATURE_CODES.POJ },
        { isAvailable: false, featureCode: MyAppHttpService.FEATURE_CODES.JOB_OF_THE_DAY_TEMPLATE },
        { isAvailable: false, featureCode: MyAppHttpService.FEATURE_CODES.OPPORTUNITIES },
        { isAvailable: false, featureCode: MyAppHttpService.FEATURE_CODES.VIDEO_PROFILE }
    ];

    public static MWType = {
        JAVA: 'JAVA',
        PYTHON: 'PYTHON',
        CHAT: 'CHAT'
    }

    public static chatUserTypes = {
        RE: 'RE',
        RI: 'RI',
        READMIN: 'READMIN',
        RIADMIN: 'RIADMIN'
    }

    public static chatEventNames = {
        UPDATE: 'update',
        SEND_MESSAGE: 'sendMessage',
        UPDATE_READ_COUNT: 'updateReadCount',
        LOAD_ADMIN_CONVERSATION: 'loadAdminConversation',
        CHAT_ID_BY_USER: 'chatIdByUser',
        INVITE_STATUS_UPDATE: 'inviteStatusUpdate'
    }

    public static httpVerb = {
        Get: 'Get',
        Post: 'Post',
        Put: 'Put',
        Delete: 'Delete'
    };

    public static expandedScheduledInterviewsType = {
        RI: 'RI Profiles',
        OWN: 'OWN Profiles',
        COMPANY: 'COMPANY_PROFILES',
        RESKILLING: 'RESKILLING_PROFILES'
    };

    showAlertMessage(message: string) {
        this.snackref = this.snackBar.open(message, "x", {
            duration: 8000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['magenta-snackbar']
        });
    }

   
      
}
