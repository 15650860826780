// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


const firebaseConfig = {
  apiKey: "AIzaSyD9dO6o_OFtHN60T_v_RJ_FQGvfKAQgpmI",
  authDomain: "rock-enterprise-7d4ce.firebaseapp.com",
  databaseURL: "https://rock-enterprise-7d4ce.firebaseio.com",
  projectId: "rock-enterprise-7d4ce",
  storageBucket: "rock-enterprise-7d4ce.appspot.com",
  messagingSenderId: "300040466122",
  appId: "1:300040466122:web:859ed15012c96a4f000e33",
  measurementId: "G-TTSPH7XN3H"
};

export const environment = {
  production: false,
  // url : 'http://localhost:8080/',
  // url: 'http://rientdev-env.rvi4bhm3pw.ap-south-1.elasticbeanstalk.com/',  //DEV
  //url: 'http://rientqa.7edk2hpvnn.ap-south-1.elasticbeanstalk.com/',
  url: 'https://reapiqa.rockinterview.in/',   // QA
  pythonurl: 'https://qa-util-py.rockinterview.in/',
  websiteURL: 'https://test.rockinterview.in',
  // WS_CHAT_URL: 'wss://9cu71dp6cf.execute-api.ap-south-1.amazonaws.com/dev/',   //DEV
  WS_CHAT_URL: 'wss://9cu71dp6cf.execute-api.ap-south-1.amazonaws.com/qa/',  //QA
  //WS_CHAT_URL: 'wss://9cu71dp6cf.execute-api.ap-south-1.amazonaws.com/prd/', //PROD
  // chatListURL: "https://cvgzllug55.execute-api.ap-south-1.amazonaws.com/dev/",  //DEV
  chatListURL: "https://cvgzllug55.execute-api.ap-south-1.amazonaws.com/qa/",   //QA
  //chatListURL: 'https://cvgzllug55.execute-api.ap-south-1.amazonaws.com/prd/', //PROD
  // Dev Firebase

  //sai
  chat_attachment_url: 'https://i3mq9up7o3.execute-api.ap-south-1.amazonaws.com/qa/richatattachments/',
  env: 'qa',
  
  firebase: firebaseConfig,
  payment: {
    // QA an DEV
    // //image: 'https://s3-ap-southeast-1.amazonaws.com/rockinterview/images.png',
    image: 'https://s3.ap-south-1.amazonaws.com/riglobal/template/app/image/Rupee_icon.svg',
    key: 'rzp_test_1DP5mmOlF5G5ag'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.