import { Component, OnInit, Inject, ViewEncapsulation,ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-job-preview',
  templateUrl: './job-preview.component.html',
  styleUrls: ['./job-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class JobPreviewComponent implements OnInit, AfterViewInit  {

  jobDetails: any = [{
    skills: 'angular,java',
    location: 'bangalore',
    jobType: 'Fulltime / Work Remote',
    salary: '3-5 Lakhs',
    startDate: '16 Jan 2020',
    endDate: '20 Jan 2020',
    rate: 100,
    hours: 20,
    jobDescription: 'There you dont need to use this.requests= when you are making get call(then requests will have observable subscription). You will get a response in observable success so setting requests value in success make sense(which you are already doing). There you dont need to use this.requests= when you are making get call(then requests will have observable subscription). You will get a response in observable success so setting requests value in success make sense(which you are already doing).',
    websiteLink: 'https://www.rockinterview.in',
    videoLink: 'https://www.youtube.com/watch?v=Cud39QOR_Pk',
    aboutCompany: 'There you dont need to use this.requests= when you are making get call(then requests will have observable subscription). You will get a response in observable success so setting requests value in success make sense(which you are already doing). There you dont need to use this.requests= when you are making get call(then requests will have observable subscription). You will get a response in observable success so setting requests value in success make sense(which you are already doing).',
    company: 'Rock Interview',
    jobTitle: 'Software Developer',
    experience: 'Fresher',
    workRemote: true,
    workFromOffice: false,
    hybrid: false,
    onSite: false,
    // countries: [],
    // states: [],
    // cities: [],
    countries122: [],
    states122: [],
    cities122: []
  }];

  @ViewChild('scrollableContent') private scrollableContent: ElementRef;
  isPageLoaded = false;

  constructor(public dialogRef: MatDialogRef<JobPreviewComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    // console.log('Job data : ', data.jobDetails);
  }

  ngAfterViewInit() {
    //alert('')
    setTimeout(() => {
   
    this.isPageLoaded = true;
    if(!!this.scrollableContent) {
      this.scrollableContent.nativeElement.scrollTop = 0;
    }
    
    },1000);
  }

  ngOnInit() {
    setTimeout(() => {
     
    }, 1000);
  
    this.jobDetails = [this.data.jobDetails];
    console.log('Job details: ', this.jobDetails);
    this.jobDetails[0].onSite = this.jobDetails[0].onSite == '0' ? 0 : this.jobDetails[0].onSite;
    console.log(this.jobDetails, this.data.jobDetails)
    const regex = /^(.*?):(.*)$/gm;
    // let formattedDetails = this.jobDetails[0].jobDescriptionData.replace(regex, "<span><span style='color: red !important;font-weight: 600 !important;font-size: 16px !important;'>$1:</span> <span style='font-size: 16px !important;'>$2</span></span>");
    // formattedDetails = formattedDetails.replace(/\n{2,}/g, '\n')
    // formattedDetails = formattedDetails.replace(/\n(?!\s*$)/g, "<div class='description' style='margin-top: -16px; !important;'>$&</div>");
    // this.postAJobForm.controls['jobDescriptionData'].setValue(formattedDetails);
    // this.jobDetails[0].jobDescriptionData = formattedDetails;
    // console.log(formattedDetails);
    setTimeout(() => {
      let jobJD: any = document.getElementById('jobJD');
      jobJD.innerHTML = this.data.jobDetails.jobDescriptionData;
      jobJD.classList.add('class-from-other-component');
      // jobJD.innerHTML = `<div class="job-description">${this.data.jobDetails.jobDescriptionData}</div>`;
     }, 1500);

  }

  returnSkillsArray(skills) {
    return  skills ? skills.split(',') : [];
  }

  closeDialog(value) {
    if(value) {
      this.dialogRef.close(true);
    }
    else{
      this.dialogRef.close(false);
    }
  }

  convertToLocaleString(value){
    return value ? parseFloat(value).toLocaleString('en-IN') : 0;
  }

  arrayToCommaString(value){
    return value ? value.join(', '): '';
  }

  
  formatUrl(url){
    //return url? url.match(/^https?:/) ? url : '//' + url : '';
    //return url? url.match(/^https?:/) ? url : '//' +url.replace('www.', '') : '';
    if(url){
      if (!url.match(/^https?:\/\//i)) {
        url = 'http://' + url;
      }
      return url;
    }
    else{
      return '';
    }
  }

  filterLHSRHS(jobDetails) {
    const regex = /^(.*?):(.*)$/gm;
    let formattedDetails = this.jobDetails.replace(regex, '<span><span style="color: black !important;font-weight: 600 !important;font-size: 16px !important;">$1:</span> <span style="font-size: 10px !important;">$2</span></span>');
    formattedDetails = formattedDetails.replace(/\n(?!\s*$)/g, '<div class="description" style="margin-top: -16px;">$&</div>');
    document.body.innerHTML = formattedDetails;
    console.log(formattedDetails);
  }

  // seemoreless(details:any){details.seemore=!details.seemore}

}
