import { Injectable } from '@angular/core';
import { SendReceiveService } from '../../../services/common/sendReceive.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';

@Injectable({
  providedIn: 'root'
})
export class ChangepasswordService {

  constructor(private sendReceiveService: SendReceiveService) { }

  changePassword(requestData){
 
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post,MyAppHttpService.REQUESTS.ChangePassword, requestData);
  }
}
