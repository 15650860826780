import { Injectable } from '@angular/core';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';

@Injectable({
  providedIn: 'root'
})
export class MyResourcesService {

  maxDate: any = new Date();
  jobTitlesList: any;

  constructor(private sendReceiveService: SendReceiveService) { }

  getMyResourcesData(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.getEnterpriseResources, requestData);
  }

  addNewResource(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.saveEnterpriseSingleResource, requestData);
  }

  deleteMyResource(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.deleteMyResource, requestData);
  }

  updateMyResource(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.updateMyResource, requestData);
  }

  getAllJobTitles() {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllJobTitles)
  }

  calcExp(selectedDate: any) {
    console.log('Dates: ', selectedDate.value, this.maxDate);
    let date1 = selectedDate.value;
    let date2 = this.maxDate;
    var diff = Math.abs(date1.getTime() - date2.getTime());
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 
    console.log("Diff in Days: " + diffDays);

    let years = Math.floor(diffDays / 365);
    let months = Math.floor(diffDays % 365 / 30);
    let days = Math.floor(diffDays % 365 % 30);
    let experience;
    console.log('Years, Months, Days: ', years, months, days, [years, months, days].join(':'));
    if(years == 0 && months == 0) {
      experience = 'Fresher';
    }
    else if(years == 0 && months != 0) {
      experience = '< 1 Year';
    }
    else {
      experience = years + (years != 1 ? ' Years ' : ' Year ') + months + (months != 1 ? ' Months' : ' Month');
    }
    
    console.log('Experience: ', experience);
    // this.singleUploadFormGroup.controls['experience'].setValue(experience);
    return experience
    // return [years, months, days].join(':');
  }

  getAllJobtitles() {
    this.getAllJobTitles().subscribe(jtResponse => {
      console.log('Job titles list: ', jtResponse);
      this.jobTitlesList = jtResponse.jobTitleList;
    })

    return this.jobTitlesList;
  }

  convertDaysToMonths(expInDays) {
    console.log('Exp in days: ', expInDays);
    let expInMonths = Math.floor(expInDays / 30);
    console.log('Exp in Months from days: ', expInMonths)
    return expInMonths;

  }

  convertYearsAndMonthsToMonths(exp) {
    console.log('Exp in years and months: ', exp);
    let e, years, months, expInMonths;
    if(!!exp) {
      e = exp.split(' ');
      if(e.length == 4) {
        let y = e[0];
        let m = e[2];
        years = y * 12;
        months = e[2];
        expInMonths = JSON.parse(years) + JSON.parse(months);
      }
    }
    console.log('Exp in Months from Years and Months: ', expInMonths);
    return expInMonths;
  }

  convertMonthsToYearsAndMonths(expInMonths) {
    console.log('Exp in months: ', expInMonths);
    let years, months, expInYearsAndMonths;
    if(!!expInMonths) {
      years = Math.floor(expInMonths / 12);
      months = expInMonths % 12
      expInYearsAndMonths = years + (years > 1 ? ' Years ' : ' Year ') + months + (months > 1 ? ' Months' : ' Month');
      console.log('Experience: ', expInYearsAndMonths);
      return expInYearsAndMonths
    }
  }

  getUserDetailsByMobileNumber(number) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.getUserDetailsByNumber + '?mobileno=' + number);
  }

  
}
