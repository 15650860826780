import { Injectable } from '@angular/core';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadBrochureService {

  constructor(public sendReceiveService : SendReceiveService) { }

  send(inputData){
    console.log("DownloadBrochureService",inputData);
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.DownloadBrochure, inputData);  
  }
}
