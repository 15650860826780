import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowUserprofileComponent } from './show-userprofile.component';
import { AppMaterialModule } from 'src/app/app-material.module';
import { CommonPipesModule } from 'src/app/pipes/common-pipes/common-pipes.module';
import { StarRatingModule } from 'angular-star-rating';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    CommonPipesModule,
    StarRatingModule.forRoot()
  ],
  declarations: [ShowUserprofileComponent],
  exports: [ShowUserprofileComponent],
  entryComponents: [ShowUserprofileComponent]
})
export class ShowUserprofileModule { }
