import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareProfileComponent } from './share-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppMaterialModule } from 'src/app/app-material.module';
import { TypeaheadModule } from 'ngx-type-ahead';
import { CommonPipesModule } from 'src/app/pipes/common-pipes/common-pipes.module';
import { ShareProfileService } from './share-profile.service';
import { PopoverModule } from 'ngx-smart-popover';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TypeaheadModule,
    CommonPipesModule,
    PopoverModule
  ],
  declarations: [ShareProfileComponent],
  exports: [ShareProfileComponent],
  entryComponents: [ShareProfileComponent],
  schemas: [],
  providers:[ShareProfileService]
})
export class ShareProfileModule { }
