import { Component, OnInit, ViewEncapsulation, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ReskillingpageService } from '../reskilling-page.service';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { HomeService } from '../../home/home.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { ScheduleMockinterviewService } from 'src/app/components/common/modal/schedule-mockinterview/schedule-mockinterview.service';
import { environment } from 'src/environments/environment';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';
import { CommonfunctionService } from 'src/app/services/common/commonfunction.service';
import { GlobalscheduleService } from '../../globalscheduleinterview/globalscheduleinterview.service';
import { DialogService } from 'src/app/components/common/modal/login-dialog.service';
import { AddinterviewerComponent } from 'src/app/components/common/modal/addinterviewer/addinterviewer.component';
import { CommonConfirmModalComponent } from 'src/app/components/common/modal/common-confirm-modal/common-confirm-modal.component';
import { ScheduleinterviewConfirmationComponent } from 'src/app/components/common/modal/scheduleinterview-confirmation/scheduleinterview-confirmation.component';
import { ScheduleinterviewPaymodeComponent } from 'src/app/components/common/modal/scheduleinterview-paymode/scheduleinterview-paymode.component';
import { PopoverContentComponent } from 'ngx-smart-popover';
import { Router, NavigationExtras } from '@angular/router';
import {MatSelectModule} from '@angular/material/select';
import { ZoomServiceService } from 'src/app/services/zoom-service.service';
import { NgZone } from '@angular/core';
declare var Razorpay: any;

@Component({
  selector: 'app-reskilling-schedule-interview',
  templateUrl: './reskilling-schedule-interview.component.html',
  styleUrls: ['./reskilling-schedule-interview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReskillingScheduleInterviewComponent implements OnInit {
  @ViewChild('skillsPopover') skillsPopover: PopoverContentComponent;
  skills: any = [];
  scheduleInterviewForm: FormGroup;
  userForm: FormGroup;
  submitted: boolean = false;
  activeTab = 1;
  Tab1active: boolean = true;
  Tab2active: boolean = false;
  Tab3active: boolean = false;
  scheduleinterview_not_reload:boolean = false
  rolevalue: any = 1;

  mentorListNames: any;
  mentorListNamesor: any;
  submitStep1: boolean = false;
  submitStep2: boolean = false;
  submitStep3: boolean = false;
  resumePath: any = [];
  resume: any;
  meetingLink: any;
  fileName: any = '';
  resumeexists: boolean = false;
  resumemandatory: boolean = false;
  scheduleInterviewPaymodeDialogRef: any;
  //interviewLevels:any;            
  meetLink:any='';                          //priya
  createZoomMeetingresponse:any
  paymentId
//selectedLevel:any;
  interviewLevels:any= [
    {name: 'Basic',},
    { name: 'Intermediate'},
    { name: 'Excellent',}
];


  validation_messages = {
    'technologyIds': [
      { type: 'required', message: "Please enter Technologies" }
    ],
    'name': [
      { type: 'required', message: "Please enter name" }
    ],
    'emailId': [
      { type: 'required', message: "Please enter email" }
    ],
    'interviewDateTime': [
      { type: 'required', message: "Please select Date & Time" }
    ],
    'resume': [
      { type: 'required', message: 'Please upload Resume' }
    ],
    'meetingLink': [
      { type: 'pattern', message: 'Please Enter Valid Meeting Link' },
      { type: 'required', message: 'Please Enter Meeting Link'}
    ],
    'comment': [
      { type: 'maxlength', message: 'Comments should be only 1000 characters.' }
    ]
  };

  minDate: any;
  maxDate: any;
  interviewTime:any;
  IntDateandTime:any;
  isresumeexistsandsubmit:boolean = false
  datevalidation: boolean = false;
  datevalid: any = 0;
  userData: any;
  reskillingsch: boolean = true;
  disableschedule: boolean = false;
  feedbacchCheckbox:any=0;
  dialog1: MatDialogRef<any>;
  mentorListNameSearch:any;
  public todayDate:any = new Date();
  selectedLevel:any='Basic';
  meetingStatuses: any[];
  showMeetingLinksSection: boolean;
  showWhereByOption: boolean;
  showZoomOption: boolean;
  showAddYourLinkOption: boolean;
  showNoneOption: boolean;
  comment: any = [];
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public globalservice: GlobalscheduleService, private fb: FormBuilder,
   private _matSelectModule:MatSelectModule,public zoomServiceService:ZoomServiceService,
    public reskillingsrvc: ReskillingpageService, public dataStorage: DataStorageService, public homeService: HomeService,
    public sendReceiveServices:SendReceiveService,public router:Router,
    public myAppService: MyAppHttpService, private scheduleInterviewService: ScheduleMockinterviewService,
    private cdr: ChangeDetectorRef, private commonService: CommonfunctionService, private dialogService: DialogService,
    public dialog: MatDialog, public dialogRef: MatDialogRef<ReskillingScheduleInterviewComponent>,private zone: NgZone) {
    this.GetSkills();

    

    this.selectedLevel = this.commonService.selectedLevel
    console.log( this.commonService.selectedLevel, this.data, this.dataStorage)

    if (this.dataStorage.globalSkills.length > 0) {
      this.skills = this.dataStorage.globalSkills;
    }
    this.minDate = this.addDays(new Date(), 0);
    this.maxDate = this.addDays(new Date(), 90);
    if (this.dataStorage.expandedInterviewType == MyAppHttpService.expandedScheduledInterviewsType.RI) {
      this.resumemandatory = true;
      this.data.skillsIds = this.dataStorage.getTechnologyIdsFromDescription(this.data.skillDescription);
      if (this.data.resumeForFindProfile != null && this.data.resumeForFindProfile != '') {
       
        this.fileName = this.data.resumeForFindProfile.substring(this.data.resumeForFindProfile.lastIndexOf('/') + 1);
        this.resumePath = this.data.resumeForFindProfile;
     
      }
    }
    else {
      this.data.skillsIds = this.data.skillsIds;
      if (this.data.resumePath != '') {
        this.fileName = this.data.resumePath.substring(this.data.resumePath.lastIndexOf('/') + 1);
        this.resumePath = this.data.resumePath;
      }
    }
  
    this.userForm = new FormBuilder().group({
      'enterpriseUserId': [JSON.parse(localStorage.userData).enterpriseUserId],
      'userId': [this.data.userId],
      'name': [this.data.fullName, Validators.compose([Validators.required])],
      'email': [this.data.email, Validators.compose([Validators.required])],
      'mobileNumber': [this.data.mobileNumber, Validators.compose([Validators.required])],
      'technologyIds': [this.data.skillsIds, Validators.compose([Validators.required])],
      'interviewDateTime': ['', Validators.compose([Validators.required])],
      'resume': [this.resumePath,Validators.compose([Validators.required])],
      'interviewLevelId':[''],
      'comment': ['', Validators.compose([Validators.maxLength(1000)])],
      'interviewType': ["RESKILL"],
      'typeOfInterview': ["RESKILL"],
      'zoomUsage': [''],
      'subscriptionId': this.dataStorage.membershipCurrentPlan.subscriptionId,
      'interviewer': [''],
      'role': [1],
      'interviewerText': [],
      'consultantId': [null],
      'noName': ['Not Available'],
      'noEmail': ['Not Available'],
      'meetingHost':[''],
      // 'id':[''],
      'zoomId':[''],
      'meetingLink':[''],//[this.meetingLink, Validators.compose([Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.Metting_link)])],
      'shareMentorFeedbackStatus' :[true],
      // 'subject': ['', Validators.compose([Validators.required])]
      'googleCalender': [true]
    });
    this.userForm.controls['resume'].valueChanges.subscribe((value)=>{
      console.log(value)
    })

    if (this.data.fullName != '') {
      this.userForm.controls['name'].disable();
    } else {
      this.userForm.controls['noName'].disable();
    }
    if (this.data.email != '') {
      this.userForm.controls['email'].disable();
    } else {
      this.userForm.controls['noEmail'].disable();
    }
    this.userForm.controls['mobileNumber'].disable();
    if (this.fileName != '' && this.dataStorage.expandedInterviewType == MyAppHttpService.expandedScheduledInterviewsType.RI) {
      // this.userForm.controls['resume'].disable();
    }
  

    this.userForm.controls['interviewDateTime'].valueChanges.subscribe((value) => {

      let dateselected = new Date(value);
      this.interviewTime=value;
      if (dateselected < this.minDate) {
        this.datevalidation = true;
        this.datevalid = 1;
      } else if (dateselected > this.maxDate) {
        this.datevalidation = true;
        this.datevalid = 2;
      } else if(dateselected.getHours() < 6 || dateselected.getHours() > 22) {
        this.datevalidation = true;
        this.datevalid = 3
      } else {
        this.datevalidation = false;
        this.datevalid = 0;
      }
    })
    
    
    this.userForm.controls['role'].valueChanges.subscribe((valuechanged) => {
      
      this.rolevalue = valuechanged;
      let interviewerSel = this.userForm.controls['interviewer'].value;
      if (this.rolevalue == 1) {
        // console.log('interviewer value', interviewerSel);
        if (interviewerSel == ' ' || interviewerSel == undefined || interviewerSel == null) {
          this.userForm.controls['interviewer'].setValue('');
        }
      } else {
        for (let i = 0; i < this.mentorListNames.length; i++) {
          if (this.mentorListNames[i].selected == true) {
            this.userForm.controls['interviewer'].setValue(this.mentorListNames[i]);
          }
        }
      }
    })
  }

  closedialognew() {
    if (this.disableschedule == false) {
      this.dialogRef.close();
      this.commonService.selectedLevel = 'Basic';
    }
  }
  selectedskills:any=[]
  selectedids:any=[]
 

  ngOnInit() {
    this.scheduleInterviewService.showMeetingLinksSectionOrNot();
    let skill_count:number=0
    this.userForm.valueChanges.subscribe((res)=>{
      this.selectedids=res.technologyIds
      console.log(res,this.selectedids)
      for(let i=0;i<this.selectedids.length;i++)
      {
        console.log(res.technologyIds[i])
        this.skills.filter((r)=>{
          if(r.id == this.selectedids[i]){this.selectedskills[i] = r}
        })
        console.log(this.selectedskills)
      }
      return this.selectedskills
    })
    // this.homeService.getMasterSkillsDesignationTools().subscribe(success => {console.log(this.skills)})
    this.userData = JSON.parse(localStorage.getItem('userData'));

    
    setTimeout(() => {
      this.meetingStatuses = this.scheduleInterviewService.meetingStatuses;
      this.showMeetingLinksSection = this.scheduleInterviewService.showMeetingLinksSectionValue;
      this.showWhereByOption = this.scheduleInterviewService.showWhereByOption;
      this.showZoomOption = this.scheduleInterviewService.showZoomOption;
      this.showAddYourLinkOption = this.scheduleInterviewService.showAddYourLinkOption;
      this.showNoneOption = this.scheduleInterviewService.showNoneOption;
      console.log('Meeting status value: ', this.meetingStatuses, this.showMeetingLinksSection, this.scheduleInterviewService);
    }, 2000);
    
    // this.userForm.controls['resume'].reset()
  }

  // To add 90 days to present date
  addDays(date: any, days: number): Date {
    if (!days) return date;

    date.setDate(date.getDate() + days);

    return date;
  };
  // priya
  interviewLevel_Id

  getInterviewLevels(){

    this.interviewLevels = [];
    this.homeService.getInterviewLevels().subscribe((success)=>{
      if(success.statusCode == MyAppHttpService.RESPONSE_CODES.SUCCESS){
        this.interviewLevels = success.interviewLevels;
        this.userForm.controls['interviewLevelId'].setValue(!!this.commonService.selectedLevel.id  ?this.commonService.selectedLevel.id : this.interviewLevels[0].id)//(this.interviewLevels[0].id);
        console.log(this.interviewLevel_Id,this.selectedLevel,this.commonService.selectedLevel)
        this.selectedLevel = !!this.commonService.selectedLevel.name ? this.commonService.selectedLevel.name : 'Basic'
        console.log(this.interviewLevel_Id,this.selectedLevel,this.commonService.selectedLevel)

      }
    }, (error)=>{

    });
  }

  interviewLevelsTest(item){
   
    this.commonService.selectedLevel = item
    console.log( this.commonService.selectedLevel)
    
  }

  GetSkills() {
    if (this.dataStorage.globalSkills.length <= 0) {
      this.homeService.getMasterSkillsDesignationTools().subscribe(success => {
       
        this.skills = success.technologyList;
       console.log(this.skills)
      })
    }
  }

  closedialog() {
    this.reskillingsrvc.Scheduledialog.close();
  }

  scheduleInterview() {
        //work4
      console.log('scheduleInterview()',this.userForm.invalid)
    if(this.userForm.invalid) {
      this.submitted = true;
    } else {
      this.submitted = false;
      if (this.datevalidation == false) {
        this.ValidateAssessment();
      } else {
        if (this.datevalid == 1) {
          this.myAppService.showAlertMessage("Hi, You cannot schedule interview for previous date and time.");
        } else if (this.datevalid == 2) {
          this.myAppService.showAlertMessage("Hi, Not more than 90 days.");
        }
        else if (this.datevalid == 3) {
          this.myAppService.showAlertMessage("You cannot schedule interview between 10:00 PM and 06:00 AM.");
        }
      }
     
    }
    if(this.scheduleinterview_not_reload)
    {
      //  window.location.href = 'jobs'
      this.sendReceiveServices.scheduleinterview_not_reload = false
      this.scheduleinterview_not_reload = false
      //this.router.navigate(['jobs']);
    }
  }

  ValidateAssessment() {
    let fCode = this.dataStorage.expandedInterviewType == MyAppHttpService.expandedScheduledInterviewsType.RI ? this.dataStorage.globalFeatureCodes.INTERVIEW_RI : this.dataStorage.globalFeatureCodes.RESKILLING_ASSESSMENT;
    var requestData = {
      "subscriptionId": this.dataStorage.membershipCurrentPlan.subscriptionId,
      "featureId": this.dataStorage.membershipCurrentPlan.featureList.find((features) => {
        return features.featureCode == fCode;
      }).featureId,
      "resource": null,

    };
    console.log(requestData)
    this.commonService.getMemberFeatureEligibility(requestData).subscribe((success) => {
    
      if (success.statusCode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
        let userData = JSON.parse(localStorage.getItem('userData'));
        let dataforcall = JSON.parse(JSON.stringify(this.userForm.value));
        dataforcall.interviewDateTime = this.convertDateAndTimeToCustomFormat(dataforcall.interviewDateTime);
        this.IntDateandTime=dataforcall.interviewDateTime;
        let inputData = {
          enterpriseUserId: userData.enterpriseUserId,
          name: this.data.fullName,
          mobilenumber: this.data.mobileNumber,
          email: this.data.email,
          technologyIds: dataforcall.technologyIds,
          interviewTimeStamp: dataforcall.interviewDateTime,
          resumePath: this.resumePath, // "http://52.66.192.63:8080/rockinterviewadmin/UploadResumeServlet?fileName=8125532479/Sneha resume.docx"
          comment: dataforcall.comment,
          meetingLink : '',
          interviewLevelId:dataforcall.interviewLevelId,
          // subject: dataforcall.subject
        }
       
        if (this.dataStorage.expandedInterviewType == MyAppHttpService.expandedScheduledInterviewsType.RI) {
          // alert('this.scheduleInterviewForExistingUser();')
          this.scheduleInterviewForExistingUser();
        }
        else {
          // alert('validateScheduleInterviewForNonExistingUser')
          this.scheduleInterviewService.validateScheduleInterviewForNonExistingUser(inputData).subscribe(response => {
           
            if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SUCCESS ||
              response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_JOBSEEKER ||
              response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_EXISTING_RI_USER) 
              {
              var featureInfo = this.dataStorage.membershipCurrentPlan.featureList.find(x => x.featureCode == this.dataStorage.globalFeatureCodes.RESKILLING_ASSESSMENT);
              var finalReskillingPrice = featureInfo.featurePrice - ((!!featureInfo.credits ? parseInt(featureInfo.credits) : 0) * featureInfo.featurePrice) / 100;

            
              this.scheduleInterviewPaymodeDialogRef = this.dialogService.open(ScheduleinterviewPaymodeComponent);
              this.scheduleInterviewPaymodeDialogRef.afterClosed().subscribe(paymode => {
                if(paymode == false) {
                  this.disableschedule = false;
                }
                else {
                  this.disableschedule = true;
                }
              

                // if (paymode) {
                //   if (paymode == MyAppHttpService.PAYMENT_MODES.PAY_NOW) {
                //     this.payment(Math.floor(finalReskillingPrice));
                //   } else {
                //     this.ScheduleAssess('invoice');
                //   }
                // }
                if (paymode) {
                  if (paymode == MyAppHttpService.PAYMENT_MODES.PAY_NOW) {
                    console.log('paying now');
                    this.paymentForZoom=true;
                    this.payment(Math.floor(finalReskillingPrice));
                  } else {
                    console.log('paying later',this.select_zoom);
                    // this.ScheduleAssess('invoice');
                    //this.getMeeting1();
                    this.paymentForZoom=false;
                    if(this.select_zoom==1)//(this.checkBoxSelected==true)
                    {
                      console.log("rajesh")
                      this.getMeetingZoomLink();
                    }
                    else{
                      console.log("calling")
                      this.ScheduleAssess('invoice');
                    }
                  }
                }
              })

             
            }
            else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_INTERVIEWER) {
              this.myAppService.showAlertMessage("Can't Schedule Interview for existing interviewer.");
            }
            else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_PRESCREENED) {
              this.myAppService.showAlertMessage("Can't Schedule Interview for existing Pre-Screened User");
            }
            else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_INTERVIEW_ALREADY_ASSIGNED) {
              this.myAppService.showAlertMessage("You have already scheduled interview for this user");
            }
            else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_INTERVIEW_ALREADY_ASSIGNED) {
              this.myAppService.showAlertMessage("You have already scheduled interview for this user");
            }
            else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_DATE_IS_BEFORE_SCHEDULED_DATE) {
              this.myAppService.showAlertMessage("You can schedule interview only after completion of previous scheduled interview");
            }
            else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_GAP_2_HOURS) {
              this.myAppService.showAlertMessage("You can schedule interview only after 2 hours of previous interview completion");
            }
            else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_PREVIOUS_INTERVIEW_NOT_COMPLETED) {
              this.myAppService.showAlertMessage("You can schedule interview only after completion of previous scheduled interview");
            }
          });
        }


      } else {
        console.log('errorMethodForMemberSubscription')
        this.commonService.errorMethodForMemberSubscription('RESKILLING_ASSESSMENT', '', null, null, success);
      }
    })

  }

  payment(price) {
   
    var self = this;
    let options = {
      description: 'value',
      image: environment.payment.image,
      currency: 'INR',
      key: environment.payment.key,
      amount: price * 100,
      name: this.dataStorage.loggedInUserData.firstName,
      handler: (response) => {
        console.log('Payment response: ', response);
        self.successCallback(response.razorpay_payment_id);

      },
      prefill: {
        email: this.dataStorage.loggedInUserData.email,
        name: this.dataStorage.loggedInUserData.firstName
      },
      theme: {
        color: '#99226e'
      },
      modal: {
        ondismiss: () => {
          self.MethodAfterCancel();
        },
      }
    };
    var rzp1 = new Razorpay(options);
    rzp1.open();

  };

  // successCallback(payment_id) {
   
  //   this.ScheduleAssess(payment_id);
  // };


  successCallback(payment_id) {
    // this.fnScheduleInterviewForNonExistingUser(formValue, payment_id);
    this.paymentId=payment_id;
    console.log('payment id', payment_id);
    this.paymentIdForZoom=payment_id;
    console.log("this.paymentIdForZoom",this.paymentIdForZoom)
   
    //this.ScheduleAssess(payment_id);
    if(this.checkBoxSelected==true){
      console.log("chinni")
      
      this.getPayMeetingZoomLink();
    }
    else{
      console.log("calling")
      this.ScheduleAssess(payment_id);
    }
  };

  

  MethodAfterCancel() {
    this.disableschedule = false;
  }

  methodforSkill(item){                                        //new
    
    
    let items
    let techdisc=this.userForm.controls['technologyIds'].value
    if(techdisc.length >1){
      items=`${item},...`
    }
    else{
      items=item
    }
    localStorage.setItem("zoomtechnologydiscription",items)
  
   // localStorage.setItem("zoomtechnologydiscription",item)
    
  }
  checkBoxSelected:boolean=true
  interviewTimeNew
  zoomUsage:number=1
  
  getMeetingLink(){
    console.log('zoom select')
    this.userForm.get('meetingLink').clearValidators();
    this.userForm.get('meetingLink').updateValueAndValidity();
   this.zoomUsage=1;
    this.selectedItem=false;
    this.checkBoxSelected=true;
  }

  getPayMeetingZoomLink(){
    let selectedskill:any=[]
    console.log(this.selectedskills,this.selectedids)
    this.selectedskills.filter((r,i)=>{
      console.log(r,i)
      if(r.id == this.selectedids[i]){if(!selectedskill.includes(r)){selectedskill.push(r)} }
      console.log(selectedskill)
    })
    let inputData:any={},zoomTopic = this.data.fullName +" "+ localStorage.getItem("zoomtechnologydiscription") +" "+ [JSON.parse(localStorage.userData).company]+" "+ "- Rock Interview Schedule Zoom Meeting";
    // const event = new Date(this.interviewTimeNew);
    // const jsonDate = event.toJSON(); 
   
 
  console.log(this.userForm.value.interviewDateTime)
  inputData.commondate=this.userForm.value.interviewDateTime
   inputData.topic=this.data.fullName +" "+ localStorage.getItem("zoomtechnologydiscription") +" "+ [JSON.parse(localStorage.userData).company]+" "+ "- Rock Interview Schedule Zoom Meeting";
  //  inputData.commondate=jsonDate
   inputData.fullname = this.data.fullName
   inputData.skills = selectedskill//this.selectedskills
    console.log(inputData)
    // this.zoomServiceService.test(this.userForm.value.interviewDateTime)
    if(this.select_zoom ==1){
      this.zoomServiceService.createZoomMeeting(inputData).subscribe((response) => {
        // this.selectedskills=[]
         //response.id=668
        if(response.id==668 || response.id==999)
        {
          console.log(response.id)
          this.zone.run(() => {
            
           
          let dialogRef = this.dialogService.open(CommonConfirmModalComponent, {
            data: {
              // heading:'Something went Wrong',
              //message:"This interview has already scheduled with a Zoom meeting. Are you sure you want to switch to another way?",
               message:"Oh Oh! Sorry, our host is not available at your selected Date and Time.", 
               message1:"Don't worry, our Team will share you the meeting link shortly by clicking continue ",
              btn1:' ',
              btn2:'Continue',
            }
          });
        
      
          dialogRef.afterClosed().subscribe(value =>{
            console.log(value)
            if(value){
              console.log("haha");
              this.insideZoom(response,zoomTopic) }
          })
        });
        }
        else{ console.log("huuh");
          this.insideZoom(response,zoomTopic) }
        return response
      })
    }
    else{
      setTimeout(() => {
        this.ScheduleAssess(this.paymentId);
      }, 1000);
      
    }
  }
  //type = reskilling or findprofile
  getMeetingZoomLink(){
    let selectedskill:any=[]
    console.log(this.selectedskills,this.selectedids)
    this.selectedskills.filter((r,i)=>{
      console.log(r,i)
      if(r.id == this.selectedids[i]){if(!selectedskill.includes(r)){selectedskill.push(r)} }
      console.log(selectedskill)
    })
    let inputData:any={},zoomTopic = this.data.fullName +" "+ localStorage.getItem("zoomtechnologydiscription") +" "+ [JSON.parse(localStorage.userData).company]+" "+ "- Rock Interview Schedule Zoom Meeting";
    // const event = new Date(this.interviewTimeNew);
    // const jsonDate = event.toJSON(); 
   
 
  console.log(this.userForm.value.interviewDateTime)
  inputData.commondate=this.userForm.value.interviewDateTime
   inputData.topic=this.data.fullName +" "+ localStorage.getItem("zoomtechnologydiscription") +" "+ [JSON.parse(localStorage.userData).company]+" "+ "- Rock Interview Schedule Zoom Meeting";
  //  inputData.commondate=jsonDate
   inputData.fullname = this.data.fullName
   inputData.skills = selectedskill//this.selectedskills
    console.log(inputData)
    // this.zoomServiceService.test(this.userForm.value.interviewDateTime)
    if(this.select_zoom ==1){
      this.zoomServiceService.createZoomMeeting(inputData).subscribe((response) => {
        // this.selectedskills=[]
         //response.id=668
        if(response.id==668 || response.id==999)
        {
          console.log(response.id)
          this.zone.run(() => {
            
           
          let dialogRef = this.dialogService.open(CommonConfirmModalComponent, {
            data: {
              // heading:'Something went Wrong',
              //message:"This interview has already scheduled with a Zoom meeting. Are you sure you want to switch to another way?",
               message:"Oh Oh! Sorry, our host is not available at your selected Date and Time.", 
               message1:"Don't worry, our Team will share you the meeting link shortly by clicking continue or you can add your meeting link by going back.",
              btn1:'Cancel',
              btn2:'Continue',
            }
          });
        
      
          dialogRef.afterClosed().subscribe(value =>{
            console.log(value)
            if(value){
              console.log("haha");
              this.insideZoom(response,zoomTopic) }
          })
        });
        }
        else{ console.log("huuh");
          this.insideZoom(response,zoomTopic) }
        return response
      })
    }
    else{
      setTimeout(() => {
        this.ScheduleAssess(this.paymentId);
      }, 1000);
      
    }
  }
  
 
insideZoom(response,zoomTopic){
  if(response.statuscode==500){
   
    console.log("priya:",zoomTopic);
    this.myAppService.showAlertMessage('Exception Error Please Try Again');
  }
  else  {
  console.log(response.message);
  console.log("priya:",zoomTopic);
  }
   this.meetLink=response.message;
   this.userForm.controls.meetingLink.setValue(response.id==668?'':response.join_url)
   this.userForm.controls.meetingHost.setValue(response.id==668?'':response.host_email)
   this.userForm.controls.zoomId.setValue(response.id==668?668:response.id)
   this.userForm.controls.zoomUsage.setValue(response.id==668?1:this.select_zoom==1?1:5)//this.zoomUsage
   // 'ZoomUsage': [this.zoomUsage],
   console.log('ratandeep',response.id)
   this.createZoomMeetingresponse = response
  
    if(this.paymentForZoom==true){
      console.log('sairatandeep',this.paymentForZoom)
      this.ScheduleAssess(this.paymentIdForZoom)
    }
    else{
      console.log("deepu")
      this.ScheduleAssess('invoice');
    }
   

}


  ScheduleAssess(paymentid) {

    if(this.userForm.controls['shareMentorFeedbackStatus'].value == true){
      this.feedbacchCheckbox=2;
    }
    let dataforcall = JSON.parse(JSON.stringify(this.userForm.value));
    console.log(dataforcall)
    dataforcall.interviewDateTime = this.convertDateAndTimeToCustomFormat(dataforcall.interviewDateTime);
    dataforcall.featureId = 11;
    dataforcall.paymentId = paymentid;
    dataforcall.shareMentorFeedbackStatus=dataforcall.shareMentorFeedbackStatus? 2:0
    if (dataforcall.interviewer && dataforcall.interviewer.userId) {
      dataforcall.consultantId = dataforcall.interviewer.userId;
    }
    if (dataforcall.resume && this.resumePath) {
      dataforcall.resumePath = this.resumePath;
    }
    dataforcall.zoomUsage = this.select_zoom ? this.select_zoom : 5;
    dataforcall.meetingLink = this.select_zoom == 7 ? 'whereBy' : dataforcall.meetingLink;
    console.log('saichinnu' , dataforcall, this.select_zoom);
    if((this.select_zoom != 1) && (this.select_zoom != 7) && (this.select_zoom != 8) && (this.select_zoom != 9)){dataforcall.zoomUsage=5}
    console.log('Input request data for reskilling schedule interview: ', dataforcall);
    this.reskillingsrvc.ScheduleAssesment(dataforcall).subscribe((datareturned) => {
      
      if (datareturned.statusCode == 888891) {
        this.reskillingsrvc.Scheduledialog.close(true);
        this.myAppService.showAlertMessage('Interview Scheduled Successfully');
        this.resumePath = "";
      
      } else if (datareturned.statusCode == 888884) {
        this.reskillingsrvc.Scheduledialog.close(true);
        this.myAppService.showAlertMessage('Interview Scheduled Successfully');
        this.resumePath = "";
      
      } else if (datareturned.statusCode == 888889) {
        this.myAppService.showAlertMessage('Interview date is before scheduled date');
      } else if (datareturned.statusCode == 888890) {
        this.myAppService.showAlertMessage('Two interviews gap is less than 2 hrs');
      } else if (datareturned.statusCode == 200) {
        this.reskillingsrvc.Scheduledialog.close(true);
        this.dialogRef.close();
        this.myAppService.showAlertMessage('Interview Scheduled Successfully');
        this.resumePath = "";
        this.commonService.selectedLevel = 'Basic';
       
      }

    })
  }

  convertDateAndTimeToCustomFormat(d) {
    
    let date = new Date(d),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      hours = ("0" + date.getHours()).slice(-2),
      mins = ("0" + date.getMinutes()).slice(-2);
    return ([date.getFullYear(), month, day].join("-") + ' ' + [hours, mins].join(':') + ':00');
  }

  nextfrompage1() {
    let technologydata = this.userForm.controls['technologyIds'].value;
    let Namedata = this.userForm.controls['name'].value;
    let Emaildata = this.userForm.controls['email'].value;
   
    if (technologydata.length > 0) {
      this.submitStep1 = false;
      this.Tab1active = false;
      this.Tab2active = true;
      this.Tab3active = false;
      this.activeTab = 2;
      this.getAllMentorsAddedByEnterprise();
    } else {
      this.submitStep1 = true;
    }
  }

  previousfrompage2() {
    this.Tab1active = true;
    this.Tab2active = false;
    this.Tab3active = false;
    this.activeTab = 1;
  }

  nextfrompage2() {
     this.getInterviewLevels(); //priya
    if (this.rolevalue == 1) {
      this.Tab1active = false;
      this.Tab2active = false;
      this.Tab3active = true;
      this.activeTab = 3;
       this.emptyMeetLink = this.meetingLink
      console.log('meetingLink',this.meetingLink)
    } else {
      let interviewer = this.userForm.controls['interviewer'].value;
     
      if (interviewer) {
        if (this.userForm.value.mobileNumber != this.userForm.value.interviewer.mobileNumber) {
          this.Tab1active = false;
          this.Tab2active = false;
          this.Tab3active = true;
          this.activeTab = 3;
        } else {
          this.myAppService.showAlertMessage("Please assign different Mentor as you have selected same user for interview");
        }
      } else {
        if (this.mentorListNames.length > 0) {
          this.myAppService.showAlertMessage("Please Select Interviewer to continue");
        } else {
          this.myAppService.showAlertMessage("Please Add Interviewer to continue");
        }
      }
    }
  }

  previousfrompage3() {
    this.Tab1active = false;
    this.Tab2active = true;
    this.Tab3active = false;
    this.activeTab = 2;
  }

  allow:boolean = false
  select_zoom:number = 7
  scheduleInterviewnew() {
    // let allow:any = document.getElementById('meetingLink')
    console.log(this.select_zoom)
    if(true)
    {
      let dateAndTime = this.userForm.controls['interviewDateTime'].valid;
      let resume = this.userForm.controls['resume'].valid;
      let meetingLink = this.userForm.controls['meetingLink'].valid;
      let formValue = this.userForm.value;
     console.log('sai',dateAndTime,meetingLink,this.userForm.value,resume)

    //  if (dateAndTime == false || resume == false || meetingLink == false ){
    //   console.log('meet')
    //   this.submitStep3 = true;
    // } else {
    //   console.log('link')
    //   this.submitStep3 = false;
    //  }
    this.isresumeexistsandsubmit = true
    console.log('price',this.submitStep3, this.resumeexists,this.dataStorage.expandedInterviewType, MyAppHttpService.expandedScheduledInterviewsType.RI)
    if (this.dataStorage.expandedInterviewType == MyAppHttpService.expandedScheduledInterviewsType.RI) {
      console.log('price',this.submitStep3, this.resumeexists,dateAndTime)
      if (dateAndTime == false) {
        
        this.submitStep3 = true;
      } else {
       
        this.submitStep3 = false;
      }
    } else {
    
      if (dateAndTime == false || (this.resumePath == null || this.resumePath == "") || meetingLink ==false) {
        
        this.submitStep3 = true;
        this.resumeexists = true;
      } else {
        
        this.submitStep3 = false;
        this.resumeexists = false;
      }
    }
    console.log('price0',this.submitStep3, this.resumeexists)
    // if(this.userForm.get('meetingLink').invalid) {

    //   this.submitStep3 = true;
      
    // } else {
      
    //    this.submitStep3 = false;
    // }
    if (this.submitStep1 == false && this.submitStep2 == false && this.submitStep3 == false) {
      
      if (this.datevalidation == false) {
        
        if (this.data.skillsIds && this.data.skillsIds.length > 0) {
          console.log('price1')
          if (this.data.skillsIds.filter(element => formValue.technologyIds.includes(element)).length == 0) {
            console.log('price2')
            this.dialog1 = this.dialogService.open(ScheduleinterviewConfirmationComponent, {

            });
            this.dialog1.afterClosed().subscribe(response => {
              if (response) {
                
                console.log('price3')
                this.scheduleInterview();
              }
            });
          }
          else {
           
            if(this.sendReceiveServices.scheduleinterview_not_reload)
            {
              this.scheduleinterview_not_reload = true
            }
            // window.href = ''
            this.scheduleInterview();
          }
        } else {
         
          this.scheduleInterview();
        }

      } else {
        
        if (this.datevalid == 1) {
          
          this.myAppService.showAlertMessage("Hi, You cannot schedule interview for previous date and time.");
        } else if (this.datevalid == 2) {
          
          this.myAppService.showAlertMessage("Hi, Not more than 90 days.");
        } else if (this.datevalid == 3) {
          
          this.myAppService.showAlertMessage("You cannot schedule interview between 10:00 PM and 06:00 AM.");
        }
      }


    }
    }
  }


  getAllMentorsAddedByEnterprise() {
    var requestData = {
      enterpriseUserId: JSON.parse(localStorage.userData).enterpriseUserId,
      searchBy: ''
    }
    this.scheduleInterviewService.getAllMentorsAddedByEnterprise(requestData).subscribe(response => {
    
      if (response.statusCode == 200) {
        this.mentorListNamesor = JSON.parse(JSON.stringify(response.mentorList));
        this.mentorListNameSearch=JSON.parse(JSON.stringify(response.mentorList));
        this.mentorListNames = response.mentorList;
        if (this.mentorListNames.length > 0) {
          let interviewer = this.userForm.controls['interviewer'].value;
          for (let i = 0; i < this.mentorListNames.length; i++) {
            if (interviewer) {
              if (interviewer.userId == this.mentorListNames[i].userId) {
                this.mentorListNames[i].selected = true;
                this.mentorListNamesor[i].selected = true;
              } else {
                this.mentorListNames[i].selected = false;
                this.mentorListNamesor[i].selected = false;
              }
            } else {
              this.mentorListNames[i].selected = false;
              this.mentorListNamesor[i].selected = false;
            }
          }
        }
      

      }
      else if (response.statusCode === 409) {
        this.myAppService.showAlertMessage(response.statusMessage);
      }
    })
  }

  AddMentor() {
    this.globalservice.openedfrom = 2;
    this.globalservice.Schedulenewdialog = this.dialog.open(AddinterviewerComponent,
      {
        height: 'auto 700px',
        width: '780px',
        panelClass: 'custom-dialog-container',
        autoFocus: false,
        data: null,
        disableClose: true,
        id: 'schedulenewdialog'
      });

    this.globalservice.Schedulenewdialog.afterClosed().subscribe((afterclose) => {
     
      if (afterclose == true) {
        this.getAllMentorsAddedByEnterprise();
      }
    });
  }


  EditMentor(data) {
   
    this.globalservice.openedfrom = 2;
    this.globalservice.Schedulenewdialog = this.dialog.open(AddinterviewerComponent,
      {
        height: 'auto 700px',
        width: '780px',
        panelClass: 'custom-dialog-container',
        autoFocus: false,
        data: data,
        disableClose: true,
        id: 'schedulenewdialog'
      });

    this.globalservice.Schedulenewdialog.afterClosed().subscribe((afterclose) => {
      if (afterclose == true) {
        this.getAllMentorsAddedByEnterprise();
      }
    });
  }

  DeleteInterviewer(data) {
    let modalRef = this.dialogService.open(CommonConfirmModalComponent, {
      data: { message: 'Do you really want to delete this interviewer?' }
    });

    modalRef.afterClosed().subscribe(confirmed => {
      if (!!confirmed) {
        let requestdata = {
          userId: data.userId,
          enterpriseId: JSON.parse(localStorage.userData).enterpriseUserId
        }
        let interviewerSelected = this.userForm.controls['interviewer'].value;
        this.scheduleInterviewService.deleteInterviewer(requestdata).subscribe((datareturned) => {
          
          if (datareturned.statusCode == 200) {
            if (interviewerSelected != null) {
              if (interviewerSelected.userId == data.userId) {
                this.userForm.controls['interviewer'].setValue("");
              }
            }
            this.getAllMentorsAddedByEnterprise();
            this.myAppService.showAlertMessage('Interviewer Deleted Successfully');
          }
        })
      }
    })
  }

  interviewerSelect(int) {
    for (let i = 0; i < this.mentorListNames.length; i++) {
      if (int.userId == this.mentorListNames[i].userId) {
        if (this.mentorListNames[i].selected == false) {
          this.mentorListNames[i].selected = true;
          this.userForm.controls['interviewer'].setValue(this.mentorListNames[i]);
        } else {
          this.mentorListNames[i].selected = false;
          this.userForm.controls['interviewer'].setValue('');
        }
      } else {
        this.mentorListNames[i].selected = false;
      }
    }

  }

  getFile(event) {
   

    this.resume = event.target.files;
    let name = '';
    let fileExt = '';
    let size = 0;
    let mobile: any;
    if (this.resume[0] != undefined) {
      this.fileName = '';
    }
    if (!!this.resume && this.resume[0] != undefined) {
      name = this.resume[0].name;
      fileExt = name.split('.').pop();
      size = this.resume[0].size;

    }
    
    if (this.resume === undefined) {
      this.userForm.controls['resume'].reset();

    } else if (this.resume.length == 0) {
      this.userForm.controls['resume'].reset();
      this.myAppService.showAlertMessage("File not selected.");

    } else if (size == 0) {
      this.userForm.controls['resume'].reset();
      this.myAppService.showAlertMessage("File not supported, Please select another file.");

    } else if (fileExt.toLowerCase() != "pdf" && fileExt.toLowerCase() != "docx" && fileExt.toLowerCase() != "doc") {
      this.userForm.controls['resume'].reset();
      this.myAppService.showAlertMessage("Please select only pdf or word file.");

    } else if (size > 2097152) {
      this.userForm.controls['resume'].reset();
      this.myAppService.showAlertMessage("Please select file less than 2 MB.");

    }
   
    else {
      mobile = this.userForm.controls['mobileNumber'].value;

      let fileData = new FormData();
    
      let fileName: string = '';
   

      if (this.resume.length > 0) {
        fileName = mobile + '_' + this.resume[0].name;
     
        fileData.append("file", this.resume[0], fileName);
      }

      let xhr = new XMLHttpRequest();
      let apiUrl = environment.url + MyAppHttpService.REQUESTS.uploadResumeForApplyJob + '/' + JSON.parse(localStorage.userData).enterpriseUserId;
      

      var accessToken = localStorage.getItem('token');

      xhr.open('POST', apiUrl, true);
      xhr.setRequestHeader("token", accessToken);
      xhr.onload = () => {
        if (xhr.status == 200) {
          let response = JSON.parse(xhr.response);
          
          this.resumePath = response.uploadedURL;
          this.fileName = name;
        }
      }

      xhr.send(fileData);
    }
  }

 scheduleInterviewForExistingUser() {
  let selectedskill:any=[]
  console.log(this.selectedskills,this.selectedids)
  this.selectedskills.filter((r,i)=>{
    console.log(r,i)
    if(r.id == this.selectedids[i]){if(!selectedskill.includes(r)){selectedskill.push(r)} }
    console.log(selectedskill)
  })
    let formValue = JSON.parse(JSON.stringify(this.userForm.value));
    let consultantId1 = null;
    if (formValue.interviewer && formValue.interviewer.userId) {
      consultantId1 = formValue.interviewer.userId;
    }
    let userIdNew: any = 0;
    if (this.dataStorage.profileIdForScheduleInterview != undefined) {
      userIdNew = this.dataStorage.profileIdForScheduleInterview
    } else if (this.data.profileId != undefined) {
      userIdNew = this.data.profileId;
    }
    let resumepathsending = null;
    if (this.resumePath && this.resumePath.length > 0) {
    
      resumepathsending = this.resumePath;
    }
    
    let inputData:any = {
      enterpriseUserId: this.userData.enterpriseUserId,
      userId: userIdNew,
      interviewDateTime: this.convertDateAndTimeToCustomFormat(formValue.interviewDateTime),
      technologyIds: formValue.technologyIds,
      comment: formValue.comment,
      meetingLink : this.select_zoom == 7 ? 'whereBy' : formValue.meetingLink,
      consultantId: consultantId1,
      resumePath: resumepathsending,
      interviewLevelId:formValue.interviewLevelId, 
      zoomUsage: this.select_zoom ? this.select_zoom : 5,
      shareMentorFeedbackStatus:formValue.shareMentorFeedbackStatus? 2:0,
      // subject: formValue.subject
      googleCalender: formValue.googleCalender

    }
    /*
    host_email
    id
    join_url
    this.userForm.controls.meetingLink.setValue(response.join_url)
   this.userForm.controls.meetingHost.setValue(response.host_email)
   this.userForm.controls.zoomId.setValue(response.id)
   this.userForm.controls.ZoomUsage.setValue(this.zoomUsage)
    */
    let createzoom:any
    inputData.commondate = this.userForm.value.interviewDateTime
    // formValue.interviewDateTime
    console.log(this.select_zoom)
    console.log(inputData)
   if(this.select_zoom==1)
   {
   
    inputData.zoomUsage = 1
    inputData.fullname = this.data.fullName
    inputData.skills = selectedskill //this.data.technologies
    inputData.topic=this.data.fullName +" "+ localStorage.getItem("zoomtechnologydiscription") +" "+ [JSON.parse(localStorage.userData).company]+" "+ "- Rock Interview Schedule Zoom Meeting";
   console.log('thanos',inputData)
  //  this.zoomServiceService.test(inputData,1)
  //   if(false)
    this.zoomServiceService.createZoomMeeting(inputData).subscribe((response) => {
      // alert(response.join_url)
      inputData.meetingLink = response.id==668?'':response.join_url
      inputData.meetingHost = response.id==668?'':response.host_email
      inputData.zoomId = response.id==668?668:response.id
        // this.disableschedule = false;
        //response.id=668
        if(response.id==668 || response.id==999)
        {
          let dialogRef = this.dialogService.open(CommonConfirmModalComponent, {
            data: {
              // heading:'Something went Wrong',
              //message:"This interview has already scheduled with a Zoom meeting. Are you sure you want to switch to another way?",
               message:"Oh Oh! Sorry, our host is not available at your selected Date and Time.", 
               message1:"Don't worry, our Team will share you the meeting link shortly by clicking continue or you can add your meeting link by going back.",
              btn1:'Cancel',
              btn2:'Continue',
            }
          });
      
          dialogRef.afterClosed().subscribe(value =>{
            console.log(value)
            if(this.select_zoom != 1 && this.select_zoom != 7 && this.select_zoom != 8 && this.select_zoom != 9) { inputData.zoomUsage=5; }
            if(value){this.scheduleinterviewforexistinguser(inputData);this.cdr.detectChanges(); }
          })
        }
        else{if(this.select_zoom != 1 && this.select_zoom != 7 && this.select_zoom != 8 && this.select_zoom != 9) { inputData.zoomUsage=5; };
        this.scheduleinterviewforexistinguser(inputData);this.cdr.detectChanges(); }
        
    })
   }
   else{
        this.disableschedule = true;
        if(this.select_zoom != 1 && this.select_zoom != 7 && this.select_zoom != 8 && this.select_zoom != 9) { inputData.zoomUsage=5; }
        this.scheduleinterviewforexistinguser(inputData)
        this.cdr.detectChanges();
   }

  }


  scheduleinterviewforexistinguser(inputData)
  {
    console.log('Input request data for reskilling schedule interview: ', inputData);
    if(this.select_zoom != 1 && this.select_zoom != 7 && this.select_zoom != 8 && this.select_zoom != 9) { inputData.zoomUsage=5; }
    this.scheduleInterviewService.scheduleInterviewForExistingUser(inputData).subscribe((response) => {
      console.log(inputData)
      this.disableschedule = false;
      
      if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SUCCESS ||
        response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_JOBSEEKER ||
        response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_EXISTING_RI_USER) {
        if (this.dataStorage.subGetAllProfiles.observers.length > 1) {
          this.dataStorage.subGetAllProfiles.observers = [this.dataStorage.subGetAllProfiles.observers.pop()];
        }

        this.dataStorage.subGetAllProfiles.next({});


        this.myAppService.showAlertMessage('Interview Scheduled Successfully.');
        this.commonService.selectedLevel = 'Basic';
        this.disableschedule = false;
        this.dialogRef.close(); 
        this.cdr.detectChanges();
   
      }
      else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_INTERVIEWER) {
        this.myAppService.showAlertMessage("Can't Schedule Interview for existing interviewer.");
      }
      else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_PRESCREENED) {
        this.myAppService.showAlertMessage("Can't Schedule Interview for existing Pre-Screened User");
      }
      else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_INTERVIEW_ALREADY_ASSIGNED) {
        this.myAppService.showAlertMessage("You have already scheduled interview for this user");
      }
      else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_INTERVIEW_ALREADY_ASSIGNED) {
        this.myAppService.showAlertMessage("You have already scheduled interview for this user");
      }
      else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_DATE_IS_BEFORE_SCHEDULED_DATE) {
        this.myAppService.showAlertMessage("You can schedule interview only after completion of previous scheduled interview");
      }
      else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_GAP_2_HOURS) {
        this.myAppService.showAlertMessage("You can schedule interview only after 2 hours of previous interview completion");
      }
      else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_PREVIOUS_INTERVIEW_NOT_COMPLETED) {
        this.myAppService.showAlertMessage("You can schedule interview only after completion of previous scheduled interview");
      }
    });
  }

  selectedItem:boolean=false
  paymentForZoom:boolean=false
  paymentIdForZoom:any;
  emptyMeetLink:string;
  zoomselecttype='none'

  onSelectingGenerate(){
    console.log("yourlink")
    this.userForm.get('meetingLink').setValidators([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.Metting_link)]);
    this.zoomUsage=3;
    this.selectedItem=true
    
  }
  onSelectingNone(){
    console.log("none");
    this.userForm.get('meetingLink').clearValidators();
    this.userForm.get('meetingLink').updateValueAndValidity();
    this.zoomUsage=3;
    this.selectedItem=false;
    this.zoomselecttype = 'none'
    this.emptyMeetLink='';
   
  }

   mentorLists:any=[];
  getMentorsByName(value:any){

    if(value.length != 0) {
      this.mentorListNames = this.mentorListNameSearch.filter((x: any) => x.name.toString().toLowerCase().includes(value.toString().toLowerCase()))
     }
     else {
       this.mentorListNames = this.mentorListNameSearch;
     }
    }


     date: Date = new Date();  
// console.log("Date = " + date); //Date = Tue Feb 05 2019 12:05:22 GMT+0530 (IST)  

  scheduleUsingWhereBy() {
    this.select_zoom = 7;
    this.meetingLink = 'whereBy';
    this.userForm.controls['meetingLink'].setValue(this.meetingLink);
  }
}
