import { Injectable } from '@angular/core';
import { SendReceiveService } from './common/sendReceive.service';
import { MyAppHttpService } from './common/my-app-http.service';
import { MyProfileService } from '../components/views/myprofile/myprofile.service';
import { json } from '@angular-devkit/core';
@Injectable({
  providedIn: 'root'
})
export class ZoomServiceService {

  constructor(private sendReceiveService:SendReceiveService,public myProfileService:MyProfileService) { }
  confirm_popup_returns:boolean=false
  meetinglink_input_radio_checked
  zoomresponse:any
  fullname
  checkLogin :boolean
  companyName=JSON.parse(localStorage.getItem("userData")) ? JSON.parse(localStorage.getItem("userData")).company:'';
getCompanyName()
  {
    console.log(JSON.parse(localStorage.getItem('userData')).enterpriseUserId)
    this.myProfileService.getMyProfileServices(JSON.parse(localStorage.getItem('userData')).enterpriseUserId).subscribe(data => {
      localStorage.setItem("data.myProfilleList[0].company",data.myProfilleList[0].company)
    })
  }

  selectedskills:any=[]
  selectedids:any=[]
  skillbyid(form_res:any,skills:any)
 {
  if(form_res)
  {
      this.selectedids=form_res//.technologies//technologyIds
      console.log(form_res,this.selectedids)
      for(let i=0;i<this.selectedids.length;i++)
      {
        console.log(this.selectedids)
        skills.filter((r)=>{
          if(r.id == this.selectedids[i]){this.selectedskills[i] = r}
        })
        console.log(this.selectedskills)
      }
      return this.selectedskills
    }
 } 

  //https://reapiqa.rockinterview.in/updateInterview
  test(request,type){
    let topic = `${'$RI Enterprise_'}${this.companyName}${" - "}${!!request.fullname?request.fullname:'NA'}${" - "}${!!request.skills?!!request.skills[1]?request.skills[0].name+''+','+request.skills[1].name:request.skills[0].name:'NA'}`

    if(type==1){

    let c_payload = {
      "topic": topic,//!!request.topic?request.topic:topic,//"Rahul null RI - Rock Interview Schedule Zoom Meeting",
      "available_time": this.availabledate(this.start_time(request.commondate)),
      "type": 2,
      "start_time": this.start_time(request.commondate),
      "duration": "60",
      "zoomUsage": 1
  }
  console.log('create',c_payload)
}
else if(type==2)
{


 // let topic = `${'$RI Enterprise_'}${this.companyName}${" - "}${!!request.fullname?request.fullname:'NA'}${" - "}${!!request.skills?!!request.skills[1]?request.skills[0].name+''+','+request.skills[1].name:request.skills[0].name:'NA'}`
    console.log(topic)
    let u_payload = {
      "topic":topic,
      "id": request.zoomId,
      "host_email": request.meetingHost,
      'join_url':  request.meetingLink,
      "available_time": this.availabledate(this.start_time(request.commondate)),
      "type": 2,
      "start_time": this.start_time(request.commondate),
      "duration": "60",
      "interviewId" : request.interviewId,
      "zoomUsage": 2
  }
  console.log('Update sai',u_payload)
}
else if(type==3){
  let d_topic = `${'$Cancelled by Enterprise $RI Enterprise_'}${this.companyName}${" - "}${!!request.fullName?request.fullName:'NA'}${" - "}${!!request.skills?!!request.skills[1]?request.skills[0].name+''+','+request.skills[1].name:request.skills[0].name:'NA'}`
  //console.log(topic)
  console.log("deleete zoom ",request)
  let d_payload = {
    "topic":d_topic,
    "id": request.zoomId,
    "host_email": request.meetingHost,
    "zoomUsage" : 3
  }
  console.log('Deleteee',d_payload)
}

  
  }
  createZoomMeeting(request){
    console.log("update zoom ",request)
    // "$RI Enterprise_ASM Career Solutions - Sneha - Angular,Ionic"
    //"$canvcelled by enterprise $RI Enterprise_ASM Career Solutions - Sneha - Angular,Ionic"
    // let company = JSON.parse(localStorage.getItem("userData")).company//data.myProfilleList[0].company
    let topic = `${'$RI Enterprise_'}${this.companyName}${" - "}${!!request.fullname?request.fullname:'NA'}${" - "}${!!request.skills?!!request.skills[1]?request.skills[0].name+''+','+request.skills[1].name:request.skills[0].name:'NA'}`
    console.log(topic)
    let payload = {
      "topic": topic,//!!request.topic?request.topic:topic,//"Rahul null RI - Rock Interview Schedule Zoom Meeting",
      "available_time": this.availabledate(this.start_time(request.commondate)),
      "type": 2,
      "start_time": this.start_time(request.commondate),
      "duration": "60",
      "zoomUsage": 1
  }
  console.log('titus',payload)
    return this.sendReceiveService
    .send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.createZoom, payload);
  }

   updateZoomMeeting(request){
    console.log("update zoom ",request)
    // let company = JSON.parse(localStorage.getItem("userData")).company
    let topic = `${'$RI Enterprise_'}${this.companyName}${" - "}${!!request.fullname?request.fullname:'NA'}${" - "}${!!request.skills?!!request.skills[1]?request.skills[0].name+''+','+request.skills[1].name:request.skills[0].name:'NA'}`
    console.log(topic)
    let payload = {
      "topic":topic,
      "interviewId" : request.interviewId,
      "id": request.zoomId,
      "host_email": request.meetingHost,
      'join_url':  request.meetingLink,
      "available_time": this.availabledate(this.start_time(request.commondate)),
      "type": 2,
      "start_time": this.start_time(request.commondate),
      "duration": "60",
      "zoomUsage": 2
  }
  console.log("payload ",payload)
  return this.sendReceiveService
  .send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.updateZoom, payload);
 
  }
  deleteZoomMeeting(request){
//"$canvcelled by enterprise $RI Enterprise_ASM Career Solutions - Sneha - Angular,Ionic"
    // let company = JSON.parse(localStorage.getItem("userData")).company
    console.log(request)
    let topic = `${'$Cancelled by Enterprise $RI Enterprise_'}${this.companyName}${" - "}${!!request.fullName?request.fullName:'NA'}${" - "}${!!request.skills?!!request.skills[1]?request.skills[0].name+''+','+request.skills[1].name:request.skills[0].name:'NA'}`
    console.log(topic)
    console.log("deleete zoom ",request)
    let payload = {
      "topic":topic,
      "id": request.zoomId,
      "host_email": request.meetingHost,
      "zoomUsage" : 3
    }
    console.log(payload)
    return this.sendReceiveService
    .send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.deleteZoom, payload);
   }
  
   availabledate(date){let d = date.toString().split("T");return d[0]+" "+d[1].split(".")[0]}
   start_time(date){
    // console.log("start_time",date,new Date(date).toISOString());
    let a = new Date(date)
    let b = new Date(a.getTime() - a.getTimezoneOffset()*60000).toISOString()
    console.log(date,b)
    // return new Date(date).toISOString();
    return b
  }

   

}
