import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobPreviewComponent } from './job-preview.component';
import { AppMaterialModule } from 'src/app/app-material.module';
import { MatTabsModule } from '@angular/material';

@NgModule({
  declarations: [JobPreviewComponent],
  imports: [
    CommonModule,
    AppMaterialModule
  ],
  exports: [JobPreviewComponent],
  entryComponents: [JobPreviewComponent],
})
export class JobPreviewModule { }
