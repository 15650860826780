import { RouterModule } from "@angular/router";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RegisterModalComponent } from "./register-modal.component";
import { AppMaterialModule } from "src/app/app-material.module";
import { LoginModalModule } from "../../login/login-modal/login-modal.module";
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({
    imports: [
        // RouterModule.forChild(RegisterRoutes),
        TranslateModule,
        AppMaterialModule,
        FormsModule, 
        ReactiveFormsModule,
        CommonModule,
        NgSelectModule
        // LoginModalModule
    ],
    declarations: [RegisterModalComponent],
    exports: [RegisterModalComponent],
    entryComponents:[RegisterModalComponent],   
    
    schemas: [NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA],
    providers:[]
})
export class RegisterModalModule {}