import { Injectable } from "@angular/core";
import { SendReceiveService } from "src/app/services/common/sendReceive.service";
import { MyAppHttpService } from "src/app/services/common/my-app-http.service";


@Injectable({
    providedIn: 'root'
  })
export class LoginModalService{
    constructor(private sendReceiveService: SendReceiveService) { }

   

   
    login(requestData) {
        var loginData={
            emailId:requestData.emailId,
            currentPassword:requestData.password,
            randomGUId : requestData.randomId,
            typeGUID : requestData.randomType
        }
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.Login,loginData);  
    }

    saveFCMToken(requestData){
        console.log("Request data: ", requestData);
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.SaveEntUserFCMToken, requestData);
    }

}

