import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SendReceiveService } from '../../../services/common/sendReceive.service';
import { Router } from '@angular/router';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { OpportunitiesService } from '../../views/opportunities/opportunities.service';

@Component({
  selector: 'app-blue-topbar',
  templateUrl: './blue-topbar.component.html',
  styleUrls: ['./blue-topbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BlueTopbarComponent implements OnInit {

  post: boolean = true;
  short: boolean = true;
  favrt: boolean = true;
  myprfl: boolean = true;
  price: boolean = true;
  reskill: boolean = true;
  set: boolean = true;
  logt: boolean = true;
  myjob: boolean = true;
  findprfl : boolean = true;
  currentRoute: string = '';
  chat1 : boolean = true;
  rolledoutOffers: boolean = true;
  schInterviews: boolean = true;
  myresources:boolean = true
  findpartner: boolean = true;
  meetings:boolean = true
  myopportunite:boolean = true
  managefianc:boolean = true;
  resumebuilder:boolean = true;
  

  isLogin: boolean=false;
  element: any;
  constructor(private sendReceiveService: SendReceiveService ,public opportunitiesService:OpportunitiesService,
              private router: Router, public dataStorage: DataStorageService) {
    if (!!localStorage.getItem('token') && localStorage.getItem('token') != 'anonymous' && localStorage.getItem('token') != 'null'
    && !!localStorage.getItem('userData')) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
   }

  ngOnInit() {
    this.currentRoute = this.router.url;
    this.dataStorage.selectedMenu = this.router.url;
    console.log('Selected Menu: ', this.dataStorage.selectedMenu);
  }

  closeNav()
  {
    if(!!document.getElementById("mySidenav")) {
      this.element = document.getElementById("mySidenav").style.width = "0";
      this.element = document.getElementById("main").style.backgroundColor = "transparent";
    }
   

  }
  openNav()
  {
    if(!!document.getElementById("mySidenav")) {
      this.element =  document.getElementById("mySidenav").style.width = "220px";
    this.element =  document.getElementById("main").style.backgroundColor = "rgba(0,0,0,0.4)";
    }
    
  }

  logout(){
    this.sendReceiveService.logoutService();
  }
  selectedMenu = ''
  menuItems:any=[
    {sideheading:"Hire Talent",labelheading:'',mnmt:'-4px',mt:'',mis:'0px',image:'assets/images/menu/postajob.png',menuname:'Post a Job',routerLink:"/postajob",new:false,divideborder:false,fxFlex:'20%'},
    {sideheading:"",labelheading:'Rock Verified',mnmt:'',mt:'-20px',mis:'0px',image:'assets/images/menu/findprofiles.png',menuname:'Find Profile',routerLink:"/findprofile",new:false,divideborder:false,fxFlex:'20%'},
    {sideheading:"",labelheading:'Rock Verified',mnmt:'',mt:'-20px',mis:'0px',image:'assets/images/menu/findpartner.png',menuname:'Find Partner',routerLink:"/find-partners",new:false,divideborder:false,fxFlex:'20%'},
    {sideheading:"Build Talent",labelheading:'',mnmt:'-1px',mt:'',mis:'0px',image:'assets/images/menu/reskilling.png',menuname:'Reskill Workforce',routerLink:"/reskillingpage",new:false,divideborder:true,fxFlex:'16%'},
    {sideheading:"Manage Business",labelheading:'',mnmt:'',mt:'',mis:'8px',image:'assets/images/menu/myjobs.png',menuname:'My Jobs',routerLink:"/jobs?tab=myjobs",new:false,divideborder:true,fxFlex:'27%'},
    // {sideheading:"",labelheading:'',mt:'',mis:'0px',image:'assets/images/menu/opportunites.png',menuname:'Opportunites',routerLink:"/jobs",new:false,divideborder:false,fxFlex:'20%'},
    {sideheading:"",labelheading:'',mnmt:'-1px',mt:'',mis:'0px',image:'assets/images/menu/opportunites.png',menuname:'My Opportunities',routerLink:"/jobs?tab=opportunity",new:false,divideborder:false,fxFlex:'20%'},
    {sideheading:"",labelheading:'',mnmt:'-1px',mt:'',mis:'0px',image:'assets/images/menu/myresources.png',menuname:'My Resources',routerLink:"/jobs?tab=myresources",new:false,divideborder:false,fxFlex:'20%'},
    {sideheading:"",labelheading:'Coming Soon',mnmt:'',mt:'8px',mis:'0px',image:'assets/images/menu/managefinance.png',menuname:'Manage Finances',routerLink:"/managefinances",new:false,divideborder:true,fxFlex:'13%'},
    // {sideheading:"",labelheading:'Upload Resume',mnmt:'',mt:'',mis:'0px',image:'assets/images/menu/managefinance.png',menuname:'Test For Resume',routerLink:"/resume-builder",new:false,divideborder:false,fxFlex:'13%'},
    
  ]
  marginTop(style)
  {
    return style
  }

  selectmenu(menu)
  {
    try {
      if(this.dataStorage.myjobsCollapse){
        this.dataStorage.myjobsCollapse()
      }
    } catch (error) {
      console.log(error)
    }
    this.dataStorage.jobsStep = -1;
    this.opportunitiesService.collapse();
    menu.menuname == 'My Jobs'?this.dataStorage.selectedIndex=0:menu.menuname == 'Opportunites'?this.dataStorage.selectedIndex=1:menu.menuname == 'My Resources'? this.dataStorage.selectedIndex=2 :''
    this.dataStorage.selectedMenu=menu.routerLink
    // localStorage.setItem('setjobtabs',menu.routerLink)
    
    this.router.navigateByUrl(menu.routerLink)
   console.log("this.dataStorage.selectedMenu",this.dataStorage.selectedMenu)
  }

  postJob() {
    this.router.navigate(['postajob']);
    this.currentRoute = '/postajob';
    this.post = false;
    this.short = true;
    this.favrt = true;
    this.myprfl = true;
    this.price = true;
    this.reskill = true;
    this.set = true;
    this.myjob = true;
    this.findprfl = true;
    this.chat1 = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.myresources = true;
    this.findpartner = true;
    this.meetings = true
    this.myopportunite = true
    this.managefianc = true
  }

  shortlist() {
    this.currentRoute = '/dashboard';
    this.router.navigate(['dashboard']);
    this.short = false;
    this.post = true;
    this.favrt = true;
    this.myprfl = true;
    this.price = true;
    this.reskill = true;
    this.set = true;
    this.myjob = true;
    this.findprfl = true;
    this.chat1 = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.myresources = true;
    this.findpartner = true;
    this.meetings = true
    this.myopportunite = true
    this.managefianc = true
    // this.router.navigate(['dashboard']);
   // localStorage.setItem('nextPage','dashboard');

  }

  findProfile(){
    this.router.navigate(['findprofile']);
    this.currentRoute = '/findprofile';
    this.findprfl = false;
    this.short = true;
    this.post = true;
    this.favrt = true;
    this.myprfl = true;
    this.price = true;
    this.reskill = true;
    this.set = true;
    this.myjob = true;
    this.chat1 = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.myresources = true;
    this.findpartner = true;
    this.meetings = true
    this.myopportunite = true
    this.managefianc = true
  }

  favourite() {
    this.router.navigate(['favouriteprofile']);
    this.currentRoute = '/favouriteprofile';
    this.favrt = false;
    this.short = true;
    this.post = true;
    this.myprfl = true;
    this.price = true;
    this.reskill = true;
    this.set = true;
    this.myjob = true;
    this.findprfl = true;
    this.chat1 = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.myresources = true;
    this.findpartner = true
    this.meetings = true
    this.myopportunite = true
    this.managefianc = true
    // this.router.navigate(['favouriteprofile']);
    //localStorage.setItem('nextPage','favouriteprofile');
  }

  myProfile() {
    this.router.navigate(['myaccount']);
    this.currentRoute = '/myaccount';
    this.myprfl = false;
    this.favrt = true;
    this.short = true;
    this.post = true;
    this.price = true;
    this.reskill = true;
    this.set = true;
    this.myjob = true;
    this.findprfl = true;
    this.chat1 = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.myresources = true;
    this.findpartner = true;
    this.meetings = true
    this.myopportunite = true
    this.managefianc = true
    // this.router.navigate(['myprofile',JSON.parse(localStorage.getItem('userData')).enterpriseUserId]);
    // this.router.navigate(['myaccount', JSON.parse(localStorage.getItem('userData')).enterpriseUserId]);
  }

  myjobs(){
    this.router.navigate(['jobs']);
    this.currentRoute = '/jobs';
    this.myjob = false;
    this.myprfl = true;
    this.favrt = true;
    this.short = true;
    this.post = true;
    this.price = true;
    this.reskill = true;
    this.set = true;
    this.findprfl = true;
    this.chat1 = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.findpartner = true;
    this.myresources = true;
    this.myopportunite = true
    this.managefianc = true
    // this.router.navigate(['myjobs']);
   // localStorage.setItem('nextPage','myjobs');
  }
  
  myopportunites(){
    this.router.navigate(['jobs']);
    this.currentRoute = '/jobs';
    this.myopportunite = false
    this.managefianc = true
    this.myjob = true;
    this.myprfl = true;
    this.favrt = true;
    this.short = true;
    this.post = true;
    this.price = true;
    this.reskill = true;
    this.set = true;
    this.findprfl = true;
    this.chat1 = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.findpartner = true;
    this.myresources = true;
  }
  managefiancs(){
    this.router.navigate(['jobs']);
    this.currentRoute = '/jobs';
    this.myopportunite = true
    this.managefianc = false
    this.myjob = true;
    this.myprfl = true;
    this.favrt = true;
    this.short = true;
    this.post = true;
    this.price = true;
    this.reskill = true;
    this.set = true;
    this.findprfl = true;
    this.chat1 = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.findpartner = true;
    this.myresources = true;
  }

  offersReleased(){
    this.router.navigate(['rolledoutOffers']);
    this.currentRoute = '/rolledoutOffers';
    this.rolledoutOffers = false;
    this.set = true;
    this.price = true;
    this.reskill = true;
    this.myprfl = true;
    this.favrt = true;
    this.short = true;
    this.post = true;
    this.myjob = true;
    this.findprfl = true;
    this.chat1 = true;
    this.schInterviews = true;
    this.myresources = true;
    this.findpartner = true
    this.meetings = true
    this.myopportunite = true
    this.managefianc = true
  }

  pricing() {
    this.router.navigate(['pricing']);
    this.currentRoute = '/pricing';
    this.price = false;
    this.reskill = true;
    this.myprfl = true;
    this.favrt = true;
    this.short = true;
    this.post = true;
    this.set = true;
    this.myjob = true;
    this.findprfl = true;
    this.chat1 = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.myresources = true;
    this.findpartner = true
    this.meetings = true
    this.myopportunite = true
    this.managefianc = true
  }

  meeting()
  {
    this.router.navigate(['meetings']);
    this.currentRoute = '/meetings';
    this.meetings = false;
    this.post = true;
    this.short = true;
    this.favrt = true;
    this.myprfl = true;
    this.price = true;
    this.reskill = true;
    this.set = true;
    this.myjob = true;
    this.findprfl = true;
    this.chat1 = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.myresources = true;
    this.findpartner = true
    this.myopportunite = true
    this.managefianc = true
    
  }
  Reskilling() {
    this.router.navigate(['reskillingpage']);
    this.currentRoute = '/reskillingpage';
    this.reskill = false;
    this.price = true;
    this.myprfl = true;
    this.favrt = true;
    this.short = true;
    this.post = true;
    this.set = true;
    this.myjob = true;
    this.findprfl = true;
    this.chat1 = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.myresources = true;
    this.findpartner = true
    this.meetings = true
    this.myopportunite = true
    this.managefianc = true
  }

  settings() {
    this.set = false;
    this.price = true;
    this.reskill = true;
    this.myprfl = true;
    this.favrt = true;
    this.short = true;
    this.post = true;
    this.myjob = true;
    this.findprfl = true;
    this.chat1 = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.myresources = true;
    this.findpartner = true
    this.meetings = true
    this.myopportunite = true
    this.managefianc = true
  }

  scheduledInterviews(){
    this.router.navigate(['scheduledInterviews']);
    this.currentRoute = '/scheduledInterviews';
    this.schInterviews = false;
    this.set = true;
    this.price = true;
    this.reskill = true;
    this.myprfl = true;
    this.favrt = true;
    this.short = true;
    this.post = true;
    this.myjob = true;
    this.findprfl = true;
    this.chat1 = true;
    this.rolledoutOffers = true;
    this.myresources = true;
    this.findpartner = true
    this.meetings = true
    this.myopportunite = true
    this.managefianc = true
  }

  chat() {
    this.router.navigate(['chat']);
    this.currentRoute = '/chat';
    this.chat1 = false;
    this.dataStorage.globalChatMessageCount = 0;
    this.set = true;
    this.price = true;
    this.reskill = true;
    this.myprfl = true;
    this.favrt = true;
    this.short = true;
    this.post = true;
    this.myjob = true;
    this.findprfl = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.findpartner = true;
    this.myresources = true;
    this.myopportunite = true
    this.managefianc = true
  }

  findPartners() {
    this.router.navigate(['find-partners']);
    this.currentRoute = '/find-partners';
    this.chat1 = true;
    this.set = true;
    this.price = true;
    this.reskill = true;
    this.myprfl = true;
    this.favrt = true;
    this.short = true;
    this.post = true;
    this.myjob = true;
    this.findprfl = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.findpartner = false;
    this.myresources = true;
    this.myopportunite = true
    this.managefianc = true
  }
  myResource() {
    this.router.navigate(['myresources']);
    this.currentRoute = '/myresources';
    this.myresources = false;
    console.log(this.currentRoute,this.myresources)
    this.myprfl = true;
    this.favrt = true;
    this.short = true;
    this.post = true;
    this.price = true;
    this.reskill = true;
    this.set = true;
    this.myjob = true;
    this.findprfl = true;
    this.chat1 = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.findpartner = true;
    this.myopportunite = true
    this.managefianc = true
    // this.router.navigate(['myprofile',JSON.parse(localStorage.getItem('userData')).enterpriseUserId]);
    // this.router.navigate(['myaccount', JSON.parse(localStorage.getItem('userData')).enterpriseUserId]);
  }
  resumeBuilder() {
    this.router.navigate(['resume-builder']);
    this.currentRoute = '/resume-builder';
    this.chat1 = true;
    this.set = true;
    this.price = true;
    this.reskill = true;
    this.myprfl = true;
    this.favrt = true;
    this.short = true;
    this.post = true;
    this.myjob = true;
    this.findprfl = true;
    this.rolledoutOffers = true;
    this.schInterviews = true;
    this.findpartner = true;
    this.myresources = true;
    this.myopportunite = true
    this.managefianc = true;
    this.resumebuilder = false;
  }


}
