import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { ShareProfileService } from './share-profile.service';
import { PopoverContentComponent } from 'ngx-smart-popover';

@Component({
  selector: 'app-share-profile',
  templateUrl: './share-profile.component.html',
  styleUrls: ['./share-profile.component.scss']
})
export class ShareProfileComponent implements OnInit {

  shareProfileForm: FormGroup;
  submitted: boolean = false;
  isResumeChecked: boolean = false;
  isAudioChecked: boolean = false;
  isReportChecked: boolean = false;

  filesBoolean: boolean = false;

  @ViewChild('reportPopover') reportPopover: PopoverContentComponent;
  @ViewChild('audioPopover') audioPopover: PopoverContentComponent;

  validation_messages = {
    'toEmail': [
      { type: 'required', message: 'PAGES.SCHEDULE_INTERVIEW.ERRORS.EMAIL.REQUIRED' },
      { type: 'email', message: 'Please enter valid email id' },
      { type: 'pattern', message: 'Please enter valid email id' },
      { type: 'minlength', message: 'PAGES.REGISTRATION.ERRORS.EMAIL.MINLENGTH.LABEL' },
      { type: 'maxlength', message: 'PAGES.REGISTRATION.ERRORS.EMAIL.MAXLENGTH.LABEL' }
    ]
  };

  constructor(public dialogRef: MatDialogRef<ShareProfileComponent>, public dataStorage: DataStorageService, public shareService: ShareProfileService, private myAppService: MyAppHttpService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    // console.log("Profile data : ", this.dataStorage.shareProfileData);
    this.isResumeChecked = this.dataStorage.shareProfileData.resumeExist;
    this.isAudioChecked = this.dataStorage.shareProfileData.audioExist;
    this.isReportChecked = this.dataStorage.shareProfileData.reportExist;
  }

  ngOnInit() {
    this.shareProfileForm = new FormBuilder().group({
      'toEmail': ['', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.EMAIL)])],
      'isResume': [{ value: (this.isResumeChecked && this.data.shareResume), disabled: (!this.isResumeChecked || !this.data.shareResume) }],
      'isReport': [{ value: (this.isReportChecked && this.data.shareInterviewReport), disabled: (!this.isReportChecked || !this.data.shareInterviewReport) }],
      'isAudio': [{ value: (this.isAudioChecked && this.data.shareInterviewAudio), disabled: (!this.isAudioChecked || !this.data.shareInterviewAudio) }]
    });


    this.shareProfileForm.controls['isResume'].valueChanges.subscribe(value => {
      if (value == true) {
        this.filesBoolean = false;
      }
      else {
        if (this.shareProfileForm.controls['isReport'].value || this.shareProfileForm.controls['isAudio'].value) {
          this.filesBoolean = false;
        }
        else {
          this.filesBoolean = true;
        }
      }
    });

    this.shareProfileForm.controls['isReport'].valueChanges.subscribe(value => {
      if (value == true) {
        this.filesBoolean = false;
      }
      else {
        if (this.shareProfileForm.controls['isResume'].value || this.shareProfileForm.controls['isAudio'].value) {
          this.filesBoolean = false;
        }
        else {
          this.filesBoolean = true;
        }
      }
    });

    this.shareProfileForm.controls['isAudio'].valueChanges.subscribe(value => {
      if (value == true) {
        this.filesBoolean = false;
      }
      else {
        if (this.shareProfileForm.controls['isReport'].value || this.shareProfileForm.controls['isResume'].value) {
          this.filesBoolean = false;
        }
        else {
          this.filesBoolean = true;
        }
      }
    });
  }


  shareProfile() {
   
    let formValue = this.shareProfileForm.getRawValue();
    if (this.shareProfileForm.invalid) {
      this.submitted = true;
      if (!(formValue.isResume || formValue.isReport || formValue.isAudio)) {
        this.filesBoolean = true;
      }
    }
    else {
      if (formValue.isResume || formValue.isReport || formValue.isAudio) {
        this.submitted = false;
        this.filesBoolean = false;
       
        let inputData = {
          "mobileNumber":  this.dataStorage.shareProfileData.mobileNumber,
          "resourceId": this.data.latestInterviewId,
          "downloadCheckedFlag": formValue.isResume? 1 : 0,
          "reportCheckedFlag":  formValue.isReport? 1 : 0,
          "audioCheckedFlag": formValue.isAudio? 1 : 0,
          "enterpriseUserEmailId": JSON.parse(localStorage.userData).email,
          "toEmailId": formValue.toEmail,
          "profileId": this.dataStorage.shareProfileData.profileId,
          "enterpriseUserId": this.dataStorage.shareProfileData.enterpriseUserId
        };
      

       

        this.shareService.shareProfile(inputData).subscribe((response) => {
          if(!!response){
            if(response.statusCode == MyAppHttpService.RESPONSE_CODES.SUCCESS){
              this.myAppService.showAlertMessage(response.statusMessage);
              this.dialogRef.close();
            }
          }
          else{
             this.myAppService.showAlertMessage('Profile Shared Successfully.');
          this.dialogRef.close();
          }

         
        }, (error)=>{});

      }
      else {
        this.submitted = true;
        this.filesBoolean = true;
      }

    }
  }


}
