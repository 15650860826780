import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReskillingScheduleInterviewComponent } from './reskilling-schedule-interview.component';
import { AppMaterialModule } from 'src/app/app-material.module';
import { CommonPipesModule } from 'src/app/pipes/common-pipes/common-pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { TypeaheadModule } from 'ngx-type-ahead';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverModule } from "ngx-smart-popover";


@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    CommonPipesModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TypeaheadModule,
    NgSelectModule,
    TranslateModule,
    PopoverModule
  ],
  declarations: [ReskillingScheduleInterviewComponent],
  exports: [ReskillingScheduleInterviewComponent],
  entryComponents: [ReskillingScheduleInterviewComponent],
  schemas : [CUSTOM_ELEMENTS_SCHEMA]
})
export class ReskillingScheduleInterviewModule { }
