import { Injectable } from "@angular/core";
import {SendReceiveService} from '../../../services/common/sendReceive.service';
import {MyAppHttpService} from '../../../services/common/my-app-http.service';

@Injectable()
export class FindProfileService{
    constructor(private sendReceiveService: SendReceiveService) { }

       
    getMasterServices() {
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.MasterServices);  
    }

    searchProfiles(requestData){
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post,MyAppHttpService.REQUESTS.SearchProfiles,requestData);
    }
    
    addToFavourites(requestData){
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post,MyAppHttpService.REQUESTS.AddToFavourites,requestData);
    }
    
    deleteFromFavourites(requestData){
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post,MyAppHttpService.REQUESTS.DeleteFromFavourites,requestData);
    }

    getAllFavourites(requestData){
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post,MyAppHttpService.REQUESTS.GetAllFavourite,requestData);
    }

    downloadFile(requestData){
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post,MyAppHttpService.REQUESTS.DownloadFile,requestData);
    }

   

   
    getMasterLocation() {
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllLocations);  
    }

    getMasterSkillsDesignationTools() {
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllSkillsToolsDesignations);  
    }

    clickAndHireFromFindProfiles(requestData){
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.ClickAndHireFromSearchProfiles, requestData);
    }
}

