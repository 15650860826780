import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MyAppHttpService } from './common/my-app-http.service';
import { DialogService } from '../components/common/modal/login-dialog.service';
import { MatDialogRef } from '@angular/material';
//import { PaymentComponent } from '../components/common/modal/payment/payment/payment.component';
import { getMetadata, getThumbnails, Video } from 'video-metadata-thumbnails';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonConfirmModalComponent } from '../components/common/modal/common-confirm-modal/common-confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {

  public dataFromPostAJob: any = null;
  public isPaymentDone: boolean = true;
  public isInTourPage: boolean = true;
  public isTourCompleted: boolean = false;
  buttonhelight = 0
  selectedMenu = ""//"dashboard"
  selectedIndex = 0
  leftsideMenuWidth:any
  displaycarsourItem:any
  jobsStep:number 
  selectoptionname:String = 'Name'
  convertToNum:boolean = false
    
  public loggedInUserData: any = {};
  public loggedInUserName: string = '';
  public isUserLoggedIn: boolean = false;
  public singleDataforAddorRemoveFavorites: any;
  public flagforAddorRemoveFavorites: number;
  public favoritesRequest: boolean = false;
  public isMultipleProfilesSelectedForFavorites: boolean = false;
  //sai
  public globalLoggedInUserData: any = { mobileNumber: '', userType: 0, userName: '', userId: 0 };
  public clickedGroupId: any = null;


  public globalLocations: any[] = [];
  public globalSkills: any[] = [];
  public globalJobTitles: any[] = [];
  public globalSkillsAndJobTitles: any[] = [];
  public previousSearchCriteria = {};

  public profileIdForScheduleInterview: any;
  public ScheduleInterviewForExistingOrNonExistingUsers: any;

  public globalProfiles = [];
  public globalExpandedData = [];
  public selectedProfileIndex = 0;
  public globalScheduledInterviews = [];

  public subGetAllProfiles = new Subject<any>();
  public subReloadScheduledInterviews = new Subject<any>();
  public getPayments = new Subject<any>();
  public subPostAJob = new Subject<any>();
  public subMyPlan = new Subject<any>();

  public tempFindProfileSearchData = { resourceId: 0, skills_Technology: [], experiences: [], jobLocation: [], designation: [], pageFrom: 0, pageSize: 200 };

  public isInGuestOperation: boolean = false;

  public paginationPageSize: number = 10;

  public globalOfferDetails: any = {};

  public shareProfileData: any = {};

  public membershipFeatures: any = [];
  public membershipPlans: any = [];
  public membershipCurrentPlan: any = {};

  public countryName: any = [];
  public countryId: any = [];
  public stateName: any = [];
  public stateId: any = [];
  public cityName: any = [];
  public cityId: any = []; 

  public globalAvailableFeatureInfo: any = JSON.parse(JSON.stringify(MyAppHttpService.AVAILABLE_FEATURE_INFO));

  public globalFeatureCodes = MyAppHttpService.FEATURE_CODES;

  public fcmToken: any;
  public isNotificationsBlocked: boolean = false;
  //public isToPerformLazyLoading: boolean = true;
  public paginationIndex: number = 1;

  // public profileIdForChat : any;
  public paymentErrorForChat: boolean = false;
  public chatClicked: boolean = false;
  public chatMinimised: boolean = false;
  public chatClosed: boolean = true;
  public userNameFromChatForm: any;
  public newAdminChatId: any;
  //public adminChatData: any = {};
  public adminUserId: number = 1;
  public globalChatUserInfo: any = {};
  public globalUnread_ChatIdInfo: any = { adminChatId: '', userChatId: '', adminUnreadCount: 0, RIUnreadCount: 0, REUnreadCount: 0 };
  public globalUserChatConversationList: any = [];
  public globalAdminChatConversationList: any = [];
  public globalChatDialogUnreadCount = 0;
  public globalRIREConversation: any[] = [];
  public globalChatUserList: any = [];
  public globalSelectedChatUser: any = {};
  public globalChatMessageCount: number = 0;
  public globalGuestUserProfileData: { userId: number, userType: string, userName: string };
  public globalAdminchatUserInfo: any = {};
  public globalChatSelectedUserType = '';
  // public myProfileData = [];
  public myProfileData = { website: '', video: '', whyJoinUs: '', company: '', mobileNo: '' };
  public jobShareRoles = [];
  public scheduleInterviewPopupFor = { applyJob: 'applyJob' };
  public globalIsQuotaAvailable: boolean = true;
  public unreadNotificationsCountBoolean: boolean = false;
  public otherFeatures = { SEND_EMAIL: 'SEND_EMAIL', SHARE: 'SHARE', CHAT: 'CHAT', APPLY_JOB: 'APPLY_JOB' }
  public globalTechnologyAreas = MyAppHttpService.TECHNOLOGY_AREAS;
  // public havePlan: boolean = false;

  public isTourRendered: boolean = false;

  public expandedInterviewType = '';

  public jobShareData: any;

  public updateInterviewDialog: MatDialogRef<any>;

  public InterviewType: any = null;

  public masterData: any;

  public AfterLogin = new Subject<any>();

  public Adminchat: boolean = false;
  public dataforconversation: any = null;
  public thumbnailPath: any = '';
  meetings='nomeetings'
  meetingIndex:number=0
  MeetingLinks:any=[]
  Tabs:any=[
    // {label:"Meeting 1",src:"https://whereby.com/wherbytestingbysilas"},
    ];
  public globalDesignations: any[] = [];
  public myjobsCollapse: any;
  public activeUrl: string = '';
  public activeUrlJobData: any = {path: "", queryParams: {tab: "", jobId: ""}};
  public isInJobDetailsPage: boolean = false;
  
  
    meetingManipulation(link,count)
    {
      this.Tabs[this.Tabs.length]={label:"Meeting"+" "+count,
          src:link}
          this.meetingIndex = this.Tabs.length-1
          this.router.navigate(['meetings']);
    }
    openMeetings(link)
    { 
      console.log('Meeting link: ', link);
      let count:number = this.Tabs.length
      count++
      console.log("this.dataStorage.Tabs.length+1",this.Tabs.length+1)
      this.meetings = 'multiplemeetings'
      console.log("this.Tabs.includes(link)",!this.Tabs.includes(link))
      if(!this.MeetingLinks.includes(link))
      {
        this.MeetingLinks.push(link)
        this.meetingManipulation(link,count)
      }
      else{
        this.myAppService.showAlertMessage('You already used this meeting link');
      }
      
      
    }
  constructor(public router:Router,private myAppService: MyAppHttpService, private dialogService: DialogService, private sanitizer: DomSanitizer) { }

  public resetData() {
  }

  public setPaymentFlag(isPaymentDone: boolean) {
    this.isPaymentDone = isPaymentDone;
  }

  public havePlan() {
    if (!!localStorage.userData) {
      if (this.membershipCurrentPlan.planDurationNum && this.membershipCurrentPlan.planDurationNum < 0) {
        return false;
      }
      else {
        return true;
      }
    }
  } 
  async getFormattedMessage(element) {
    let message = element.message;
    console.log('Message in format: ', message);

    let regex = new RegExp(/<([^\s]+).*?id="([^"]*?)".*?>(.+?)<\/\1>/gi);
    let regex1 = new RegExp(/<([^\s]+).*?name="([^"]*?)".*?>(.+?)<\/\1>/gi);
    let imgRegex = new RegExp(/<img.*?src='(.*?)'.*?id='(.*?)'[^\>]+>/gi);
    let imgMatches, matches, matches1;
    if(!!message) {
      imgMatches = message.match(imgRegex);
      matches = message.match(regex);
      matches1 = message.match(regex1);
    }
    
    let i: any;
    let results = null;
    let results1 = null;
    let imgResults = null;
    let filename = '';
    console.log('Matches: ', matches, matches1, imgMatches);
    for(i in imgMatches) {
      let parts = imgRegex.exec(imgMatches[0]);
      console.log('Parts at results: ', parts);
      results = parts[2];
      let a = results.split('/');
      filename = a[a.length - 1];
    }
    
    for (i in matches) {
      let parts = regex.exec(matches[i]);
      results = parts[2];
      filename = parts[3];
    }
    for (i in matches1) {
      let parts = regex1.exec(matches1[i]);
      // console.log('pp', parts[2]);
      results1 = parts[2];
    }
    console.log('message formats', results, results1, filename);
    let filetype = filename.split('.')[filename.split('.').length - 1];
    let fileExtension = !!filetype ? this.GettingContentType(filetype) : '';
    let ft = !!fileExtension ? fileExtension.split('/')[0] : '';
    console.log('filetype: ', filetype, ft, filename);

    // debugger
    let thumbnailPath;
    let formattedMessage = message;

    if (!!ft && ft == 'image') {
      message = "<img src='" + results + "' class='chat-img' name='filetype' id='" + results + "' />";
      // formattedMessage =  message;
      element.message = message;
      element.msgType = 'image';
      // if(typeof thumbnailPath == 'object') {
      //   element.thumbnail = this.sanitizer.bypassSecurityTrustUrl(thumbnailPath.changingThisBreaksApplicationSecurity);
      // }
      // else {
      //   element.thumbnail = this.sanitizer.bypassSecurityTrustUrl(thumbnailPath);
      // }
      element.thumbnail = results;
    }
    else if (ft == 'video') {
      // console.log('Video Details: ', results, filetype);
      // setTimeout(() => {    
      await this.getThumbnailFromVideoURL(results).then(re => {
        console.log('res thumb: ', re);
        thumbnailPath = re;
        console.log('Thumbnail path: ', thumbnailPath.changingThisBreaksApplicationSecurity);
        if(!!thumbnailPath) {
          message = "<img src='" + thumbnailPath.changingThisBreaksApplicationSecurity + "' class='chat-img' name='filetype' id='" + results + "' />";
          console.log('Video Message: ', message);
          // formattedMessage = message; 
          element.message = message; 
          element.src = results
          // element.thumbnail = thumbnailPath;
          if(typeof thumbnailPath == 'object') {
            element.thumbnail = this.sanitizer.bypassSecurityTrustUrl(thumbnailPath.changingThisBreaksApplicationSecurity);
            console.log(element.thumbnail)
          }
          else {
            element.thumbnail = this.sanitizer.bypassSecurityTrustUrl(thumbnailPath);
            console.log(element.thumbnail)
          }
          element.msgType = 'video';
        }
        
      });

      // console.log('Thumbnail path: ', thumbnailPath);  
      // message = "<img src='" + thumbnailPath + "' class='chat-img' name='filetype' id='" + results + "' /> <img  src='/assets/imgs/ri/white_play_button.png' alt='ri' style='height:70px; width:70px; position: absolute; display: block; top: 38%; bottom: 40%; left:40%;' id='btnPlayVideo'>" ;
      // console.log('Message: ', message);
      // }, 100);

    }
    else if(ft == 'application') {
      if(filetype == 'pdf') {
        element.message = message;
        element.msgType = 'pdf';
      }
      else if(filetype == 'doc' || filetype == 'docx') {
        element.message = message;
        element.msgType = 'doc';
      }
      else if(filetype == 'zip') {
        element.message = message;
        element.msgType = 'zip';
      }
     
    }
    else if(ft == 'text' && filetype == 'txt') {
      element.message = message;
      element.msgType = 'doc';
    }
    else {
      element.msgType = 'text';
    }
    console.log('Formatted Message: ', formattedMessage, element);
    return element;
    // else {
    //   return message;
    // }
    // console.log('Message: ', message);
    // return message;
}

GettingContentType(fileExt) {
  console.log('file exension', fileExt);
  if (fileExt == 'html' || fileExt == 'htm' || fileExt == 'shtml') {
      return 'text/html';
  } else if (fileExt == 'css') {
      return 'text/css';
  } else if (fileExt == 'xml') {
      return 'text/xml';
  } else if (fileExt == 'gif') {
      return 'image/gif';
  } else if (fileExt == 'jpeg' || fileExt == 'jpg') {
      return 'image/jpeg';
  } else if (fileExt == 'js') {
      return 'application/x-javascript';
  } else if (fileExt == 'atom') {
      return 'application/atom+xml';
  } else if (fileExt == 'rss') {
      return 'application/rss+xml';
  } else if (fileExt == 'mml') {
      return 'text/mathml';
  } else if (fileExt == 'txt') {
      return 'text/plain';
  } else if (fileExt == 'jad') {
      return 'text/vnd.sun.j2me.app-descriptor';
  } else if (fileExt == 'wml') {
      return 'text/vnd.wap.wml';
  } else if (fileExt == 'htc') {
      return 'text/x-component';
  } else if (fileExt == 'png') {
      return 'image/png';
  } else if (fileExt == 'tif' || fileExt == 'tiff') {
      return 'image/tiff';
  } else if (fileExt == 'wbmp') {
      return 'image/vnd.wap.wbmp';
  } else if (fileExt == 'ico') {
      return 'image/x-icon';
  } else if (fileExt == 'jng') {
      return 'image/x-jng';
  } else if (fileExt == 'bmp') {
      return 'image/x-ms-bmp';
  } else if (fileExt == 'svg') {
      return 'image/svg+xml';
  } else if (fileExt == 'webp') {
      return 'image/webp';
  } else if (fileExt == 'jar' || fileExt == 'war' || fileExt == 'ear') {
      return 'application/java-archive';
  } else if (fileExt == 'hqx') {
      return 'application/mac-binhex40';
  }
  //  else if (fileExt == 'doc') {
  //     return 'application/msword';
  // } 
  else if (fileExt == 'pdf') {
      return 'application/pdf';
  } else if (fileExt == 'ps' || fileExt == 'eps' || fileExt == 'ai') {
      return 'application/postscript';
  } else if (fileExt == 'rtf') {
      return 'application/rtf';
  } else if (fileExt == 'xls') {
      return 'application/vnd.ms-excel';
  } else if (fileExt == 'wmlc') {
      return 'application/vnd.wap.wmlc';
  } else if (fileExt == 'kml') {
      return 'application/vnd.google-earth.kml+xml';
  } else if (fileExt == 'kmz') {
      return 'application/vnd.google-earth.kmz';
  } else if (fileExt == '7z') {
      return 'application/x-7z-compressed';
  } else if (fileExt == 'cco') {
      return 'application/x-cocoa';
  } else if (fileExt == 'jardiff') {
      return 'application/x-java-archive-dif';
  } else if (fileExt == 'jnlp') {
      return 'application/x-java-jnlp-file';
  } else if (fileExt == 'run') {
      return 'application/x-makeself';
  } else if (fileExt == 'pl' || fileExt == 'pm') {
      return 'application/x-perl';
  } else if (fileExt == 'prc' || fileExt == 'pdb') {
      return 'application/x-pilot';
  } else if (fileExt == 'rar') {
      return 'application/x-rar-compressed';
  } else if (fileExt == 'rpm') {
      return 'application/x-redhat-package-manager';
  } else if (fileExt == 'sea') {
      return 'application/x-sea';
  } else if (fileExt == 'swf') {
      return 'application/x-shockwave-flash';
  } else if (fileExt == 'sit') {
      return 'application/x-stuffit';
  } else if (fileExt == 'tcl' || fileExt == 'tk') {
      return 'application/x-tcl';
  } else if (fileExt == 'der' || fileExt == 'pem' || fileExt == 'crt') {
      return 'application/x-x509-ca-cert';
  } else if (fileExt == 'xpi') {
      return 'application/x-xpinstall';
  } else if (fileExt == 'xhtml') {
      return 'application/xhtml+xml';
  } else if (fileExt == 'zip') {
      return 'application/zip';
  } else if (fileExt == 'bin' || fileExt == 'xlsx' || fileExt == 'ppt' || fileExt == 'pptx' || fileExt == 'exe' || fileExt == 'dll' || fileExt == 'deb' || fileExt == 'dmg' || fileExt == 'eot' || fileExt == 'iso' || fileExt == 'img' || fileExt == 'msi' || fileExt == 'msp' || fileExt == 'msm' || fileExt == 'docx' || fileExt == 'doc') {
      return 'application/octet-stream';
  } else if (fileExt == 'mid' || fileExt == 'midi' || fileExt == 'kar') {
      return 'audio/midi';
  } else if (fileExt == 'mp3') {
      return 'audio/mpeg';
  } else if (fileExt == 'ogg') {
      return 'audio/ogg';
  } else if (fileExt == 'ra') {
      return 'audio/x-realaudio';
  } else if (fileExt == '3gpp' || fileExt == '3gp') {
      return 'video/3gpp';
  } else if (fileExt == 'mpeg' || fileExt == 'mpg') {
      return 'video/mpeg';
  } else if (fileExt == 'mov') {
      return 'video/quicktime';
  } else if (fileExt == 'flv') {
      return 'video/x-flv';
  } else if (fileExt == 'mng') {
      return 'video/x-mng';
  } else if (fileExt == 'asx' || fileExt == 'asf') {
      return 'video/x-ms-asf';
  } else if (fileExt == 'wmv') {
      return 'video/x-ms-wmv';
  } else if (fileExt == 'avi') {
      return 'video/x-msvideo';
  } else if (fileExt == 'm4v' || fileExt == 'mp4') {
      return 'video/mp4';
  } else if (fileExt == 'csv') {
      return 'text/csv';
  } else if (fileExt == 'apk') {
      return 'application/vnd.android.package-archive';
  } else if (fileExt == 'eml') {
      return 'message/rfc822';
  } else if (fileExt == 'vcf') {
      return 'application/vcard';
  } else if (fileExt == 'xlsx') {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  } else {
      return null;
  }
}

  public getTechnologyIdsFromDescription(techArray) {
    let techIds = [];
    if (!!techArray) {
      if (techArray.length != 0) {
        for (let i = 0; i < techArray.length; i++) {
          this.globalSkills.forEach(x => {
            if (x.name == techArray[i]) {
              techIds.push(x.id);
            }
          })
        }
        return techIds;
      }
    }
  }

  
  // Method to get thumbnail from video url
  getThumbnailFromVideoURL(videoPath) {
    return new Promise(async (resolve, reject) => {
      console.log('Video path from chat: ', videoPath);
      const metadata =  await getMetadata(videoPath);
      const thumbnails = await getThumbnails(videoPath, {
        quality: 0.6,
        start: 2
      });

      console.log('Video path Metadata and thumbnails: ', metadata, thumbnails);

      const url2 = URL.createObjectURL(thumbnails[0].blob);
      console.log('URLs: ', url2);

      let thumbnailPath1 = this.sanitizer.bypassSecurityTrustUrl(url2);
      console.log('my-src: ', thumbnailPath1);
      this.thumbnailPath = thumbnailPath1;
      resolve(thumbnailPath1);
    });
  }

}
