import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { MyAppHttpService } from '../../../services/common/my-app-http.service';
import { ChangepasswordService } from "./changepassword.service";
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';


@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {

  public changePasswordForm: FormGroup;
  public emailId;
  public submitted: boolean = false;


  validation_messages = {
    'password': [
      { type: 'required', message: 'PAGES.RESET_PASSWORD.ERRORS.PASSWORD.REQUIRED.LABEL' },
      { type: 'pattern', message: 'PAGES.RESET_PASSWORD.ERRORS.PASSWORD.PATTERN.LABEL' }
    ],
    'confirmpassword': [
      { type: 'required', message: 'PAGES.RESET_PASSWORD.ERRORS.CONFIRM_PASSWORD.REQUIRED.LABEL' },
      { type: 'pattern', message: 'PAGES.RESET_PASSWORD.ERRORS.PASSWORD.PATTERN.LABEL' }
    ]
  };

  constructor(public changePasswordService: ChangepasswordService, public route: ActivatedRoute, public router: Router, private snackBar: MatSnackBar, private translate: TranslateService,
     private myAppService: MyAppHttpService, private sendReceiveService: SendReceiveService) { 
    this.changePasswordForm = new FormBuilder().group({
      "password": ["", Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.PASSWORD)])],
      "confirmpassword": ["", Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.PASSWORD)])],
    });
  }

  ngOnInit() {
    if(localStorage.userData){
      this.emailId = JSON.parse(localStorage.getItem('userData')).email;
    }
  }

  changePassword() {
    this.submitted = true;
    if(this.changePasswordForm.valid){
      if(this.changePasswordForm.value.password != this.changePasswordForm.value.confirmpassword){
        this.myAppService.showAlertMessage(this.translate.instant('PAGES.RESET_PASSWORD.ERRORS.PASSWORD_MISMATCH.LABEL'));
        return false;
      }

      if(this.emailId){
        let changePasswordData = {
          'emailId' : this.emailId,
          'currentPassword' : this.changePasswordForm.value.password
        };


        this.changePasswordService.changePassword(changePasswordData).subscribe((response) => {
          if (response.statuscode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
            this.myAppService.showAlertMessage(this.translate.instant('PAGES.CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS.LABEL'));
            this.sendReceiveService.logoutService();
          }
          else if (response.statuscode === 999999) {
            this.myAppService.showAlertMessage(this.translate.instant('PAGES.CHANGE_PASSWORD.ERRORS.SAME_PASSWORD.LABEL'));
          }
        }, error => {
          this.myAppService.showAlertMessage(error.message);
        });
      }
    }
  }
}
