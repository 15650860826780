import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AutoTestSearchFilterPipe } from 'src/app/pipes/autotest.search.filter';
import { PostajobService } from '../../../views/postajob/postajob.service';
import { Router, NavigationStart } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TypeaheadSettings, TypeaheadSuggestions } from 'ngx-type-ahead';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { HomeService } from '../../home/home.service';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { DropDownListComponent } from '@syncfusion/ej2-ng-dropdowns';
import { DialogService } from '../../../common/modal/login-dialog.service';
import { LoginModalComponent } from '../../../common/modal/login/login-modal/login-modal.component';
import { CommonfunctionService } from '../../../../services/common/commonfunction.service';
import { ProfileInfoService } from '../../../common/profile-info/profile-info.service';


@Component({
  selector: 'app-tour-postajob',
  templateUrl: './tour-postajob.component.html',
  styleUrls: ['./tour-postajob.component.scss'],
  providers: [AutoTestSearchFilterPipe],
  encapsulation: ViewEncapsulation.None
})
export class TourPostajobComponent implements OnInit {

  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;

 // public postAJobForm: FormGroup;
  public experiences: any = [];
  public technologiesArray1: any = [];
  public submitted = false;

  showJobDescriptionEditor: boolean = false;

  userData: any;
  jobDescTemplates: any;
  jobTemplatesCount: number = 0;
  skills: any;
  showSelectedTemplate: any = null;

  isTourPostajob: boolean;

  editorConfiguration: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    placeholder: 'Enter job description',
    translate: 'no',
  }

  // experiences1: TypeaheadSuggestions = [];
  // experiences1: any[];

  technologies: any[];
  locations: TypeaheadSuggestions = [];
  jobTitles: any[] = [];
  jobTitleNames: any[];
  displayDates: boolean = false;

  titlesArray: any = [];

  minFromDate: Date;
  minToDate: Date;
  fromToMinValueBool: boolean = false;
  fromToNotAvailBool: boolean = false;

  customSettings: Partial<TypeaheadSettings> = {
    suggestionsLimit: Infinity
  };

  emptyJobDescription: boolean;
  emptyJobDescriptionErrorMsg: string;
  timerId: any;
  //skillsTechDesignation2: any[];


  selectedSkillsTechnologyIdList: number[] = [];
  selectedSkillsTechnologyNameList: string[] = [];
  selectedJobTitleIdList: number[] = [];
  selectedJobTitleNameList: string[] = [];
  selectedExperience: number = 0;
  selectedLocations: any = [];
  selectedJobTitle: any = '';
  isTooltipSkills;
  postAJobForm: FormGroup;
  currentRoute: string = '';
  

  @ViewChild('ddlLocations')
  public dropDownLocationObject: DropDownListComponent;
  @ViewChild('ddlExperience')
  public dropDownExperienceObject: DropDownListComponent;
  showJDEditor: boolean = false;


  constructor(public fb: FormBuilder, public postajobService: PostajobService, public dataStorage: DataStorageService,
    private router: Router,
    private dialogService: DialogService,
    private _sanitizer: DomSanitizer, public myAppService: MyAppHttpService, public homeService: HomeService,
    private commonService: CommonfunctionService,
    private profileInfoService: ProfileInfoService,
    private commonFunction: CommonfunctionService) {

  }

  ngOnInit() {

    this.currentRoute = '/tour-postajob';

    this.isTourPostajob = true;

    this.userData = JSON.parse(localStorage.getItem('userData'));

    this.minFromDate = new Date();
    this.minToDate = new Date();
    this.minToDate.setDate(this.minFromDate.getDate() + 1);
    
    this.postAJobForm = this.fb.group({
      'skillsTechnologies': ['', Validators.compose([Validators.required])],
      'location': [''],
      'experiences': [, Validators.compose([Validators.required])],
      // 'jobDescription': ['', Validators.compose([Validators.required])],
      'jobDescriptionData': ['', Validators.compose([Validators.required])],
      'jobTitles': [, Validators.compose([Validators.required])],
      'typeOfJob': ['1'],
      'fromDate': [''],
      'toDate': [''],
      'salaryRange': [],
      'hours': [],
      'rate': [],
      'website': [!!this.dataStorage.loggedInUserData ? (this.dataStorage.loggedInUserData.website ? this.dataStorage.loggedInUserData.website : '') : '', Validators.compose([Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.WEBSITE_PATTERN)])],
      'video': [!!this.dataStorage.loggedInUserData ? (this.dataStorage.loggedInUserData.video ? this.dataStorage.loggedInUserData.video : '') : '', Validators.compose([Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.YOUTUBE_PATTERN)])],
      'whyJoinUs': [!!this.dataStorage.loggedInUserData ? (this.dataStorage.loggedInUserData.whyJoinUs ? this.dataStorage.loggedInUserData.whyJoinUs : '') : ''],
      'company': [!!this.dataStorage.loggedInUserData ? (this.dataStorage.loggedInUserData.company ? this.dataStorage.loggedInUserData.company : '') : '', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.alphanumericWithOneSpace), Validators.maxLength(50)])],
      'workRemote': []
    });


  }


  next(){
    this.dataStorage.isInTourPage = false;
    this.dataStorage.isTourCompleted = true;
    this.router.navigate(['']);
  }

  openDownloadBrochure(){
    this.commonFunction.openDownloadBrochure();
  }


}

