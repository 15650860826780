import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { AvatarFilter } from 'src/app/pipes/avtar';

@Component({
  selector: 'app-show-userprofile',
  templateUrl: './show-userprofile.component.html',
  styleUrls: ['./show-userprofile.component.scss'],
  providers: [AvatarFilter],
  encapsulation: ViewEncapsulation.None
})
export class ShowUserprofileComponent implements OnInit {

  details1 = {
    userName : 'Sneha',
    experience : 30,
    emailId : 'sneha.j@rockinterview.in',
    mobileNumber : '1234567890',
    interviewDateAndTime : new Date(),
    profileSummary : "Overall 5 Years of experience in the areas of Software Automation Testing using QTP/UFT. Hands on experience in Manual Testing & Automation Testing and various testing techniques like User Interface Testing, Functionality Testing, and Regression Testing and System Integration Testing. Proficiency in designing and developing of Automation Framework, Business Scripts and Generic Functions.",
    educationalDetails : 'B.Tech',
    skillDescription : ['C','Angular','HTML'],
    currentEmployer: '',
    jobTitle: '',
    currentCTCValue: '0.0',
    expectedCTCValue: '',
    preferredLocation: '',
    noticePeriod: '',
    resumeExist: '',
    shareResume: '',
    scheduleInterviewInfo: '',
  }
  

  constructor(public dialogRef: MatDialogRef<ShowUserprofileComponent>, public dataStorage: DataStorageService, private myAppService: MyAppHttpService,
    @Inject(MAT_DIALOG_DATA) public details: any) { }

  ngOnInit() {
  }

  onValidatePricingAnonymousUserClick(flag, details, featureCode){

  }
}
