import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingConfirmationComponent } from './pricing-confirmation.component';
import { AppMaterialModule } from 'src/app/app-material.module';

@NgModule({
  declarations: [PricingConfirmationComponent],
  imports: [
    CommonModule,
    AppMaterialModule
  ],
  exports: [PricingConfirmationComponent],
  entryComponents: [PricingConfirmationComponent]
})

export class PricingConfirmationModule { }
