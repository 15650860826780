
import { Component, OnInit, ViewEncapsulation,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { DialogService } from '../../login-dialog.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { RegisterModalService } from './register-modal.service';
import { LoginModalComponent } from '../../login/login-modal/login-modal.component';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { HomeService } from 'src/app/components/views/home/home.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterModalComponent implements OnInit {
  mattabids:any={0:'',1:''}
  public registerForm: FormGroup;
  public submitted: boolean = false;
  public emailExists: boolean = false;

  public userRoles: any = [];
  public focusOnPassword: boolean = false;
  public focusOnEmail: boolean = false;

  inputValue: string;

  validation_messages = {
    'firstname': [
      { type: 'required', message: 'PAGES.REGISTRATION.ERRORS.FIRST_NAME.REQUIRED.LABEL' },
      { type: 'pattern', message: 'PAGES.REGISTRATION.ERRORS.FIRST_NAME.PATTERN.LABEL' },
     
      { type: 'maxlength', message: 'PAGES.REGISTRATION.ERRORS.FIRST_NAME.MAXLENGTH.LABEL' }
    ],
    'lastname': [
      { type: 'required', message: 'PAGES.REGISTRATION.ERRORS.LAST_NAME.REQUIRED.LABEL' },
      { type: 'pattern', message: 'PAGES.REGISTRATION.ERRORS.LAST_NAME.PATTERN.LABEL' },
    
      { type: 'maxlength', message: 'PAGES.REGISTRATION.ERRORS.LAST_NAME.MAXLENGTH.LABEL' }
    ],
    'email': [
      { type: 'required', message: 'PAGES.REGISTRATION.ERRORS.EMAIL.REQUIRED.LABEL' },
      { type: 'email', message: 'PAGES.REGISTRATION.ERRORS.EMAIL.PATTERN.LABEL' },
      { type: 'pattern', message: 'PAGES.REGISTRATION.ERRORS.EMAIL.PATTERN.LABEL' },
      { type: 'minlength', message: 'PAGES.REGISTRATION.ERRORS.EMAIL.MINLENGTH.LABEL' },
      { type: 'maxlength', message: 'PAGES.REGISTRATION.ERRORS.EMAIL.MAXLENGTH.LABEL' }
    ],
    'mobileNumber': [
 
      { type: 'pattern', message: 'PAGES.REGISTRATION.ERRORS.MOBILENUMBER.PATTERN.LABEL' },
   
    ],
    'company': [
  
      { type: 'maxlength', message: 'EDITPROFILE.ERRORS.CURRENT_COMPANY.MAXLENGTH.MESSAGE' },
      { type: 'required', message: 'PAGES.REGISTRATION.ERRORS.COMPANY.REQUIRED.LABEL' },
      // { type: 'pattern', message: 'PAGES.REGISTRATION.ERRORS.COMPANY.PATTERN.LABEL' }
    ],
    'password': [
      { type: 'required', message: 'PAGES.REGISTRATION.ERRORS.PASSWORD.REQUIRED.LABEL' },
      { type: 'pattern', message: 'PAGES.REGISTRATION.ERRORS.PASSWORD.PATTERN.LABEL' }
    ],
    'numOfOpenJobs': [
      { type: 'pattern', message: 'PAGES.REGISTRATION.ERRORS.NUMBER_OF_OPENJOBS.PATTERN.LABEL' }
    ],
    'jobtitle': [

      { type: 'minlength', message: 'PAGES.REGISTRATION.ERRORS.JOB_TITLE.MINLENGTH.LABEL' },
      { type: 'pattern', message: 'PAGES.REGISTRATION.ERRORS.JOB_TITLE.PATTERN.LABEL' }
    ],
    'userRole': [
      { type: 'required', message: 'PAGES.REGISTRATION.ERRORS.USER_ROLE.REQUIRED.LABEL' }
    ],
    'skills':[ { type: 'required', message: 'Please select Skills' }],
    'jobType':[ { type: 'required', message: 'Please select Job type' }],
    'areasOfOperations':[ { type: 'required', message: 'Please select Company location' }],
    'noOfResources':
    [ 
      { type: 'required', message: 'Please enter No.of resources' },
      {type: 'pattern', message: 'Please enter No.of resources'}
    ],
    'consultationFee':[ { type: 'required', message: '' }],
    'typeOfFee':[ { type: 'required', message: '' }],
    'websiteUrl':[
      { type: 'pattern', message: 'Please enter Valid Website URL'}
    ],
    'showOnPartner':[true]
  };
  mobileError: boolean = false;
  skillsTechDesignation:any
  location:any
  jobTypes:any = [{id:1,name:'Fulltime'},{id:2,name:'Contract to Hire'},{id:3,name:'Contract'},{id:4,name:'All'}]
  Resources:any =[{id:1,name:1},{id:2,name:2},{id:3,name:3},{id:4,name:4},{id:5,name:5},{id:6,name:6},{id:7,name:7},{id:8,name:8},{id:9,name:9},{id:10,name:10}]
  selectedTabIndex: any;

  constructor(
    private dialogService: DialogService,public dataStorage:DataStorageService,
    public homeService:HomeService,
    public dialogRef: MatDialogRef<RegisterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public registerService: RegisterModalService, public myAppService: MyAppHttpService,
    private translate: TranslateService, public router: Router) {

      this.selectedTabIndex = 0;
      if (this.dataStorage.globalSkillsAndJobTitles.length == 0 || true) {
        this.homeService.getMasterSkillsDesignationTools().subscribe(success => {
          if (!!success) {
    
            this.dataStorage.globalSkills = JSON.parse(JSON.stringify(success.technologyList));
           
            this.skillsTechDesignation = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));
    
            this.dataStorage.globalSkillsAndJobTitles = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));// this.skillsTechDesignation1;
          console.log("skills",this.dataStorage.globalSkills,this.skillsTechDesignation,this.dataStorage.globalSkillsAndJobTitles)
          }
        }, error => {
          this.myAppService.showAlertMessage(error.message);
        });
      }
      else {
        // this.skillsTechDesignation = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));
      }
      if (this.dataStorage.globalLocations.length == 0 || true) {
        this.homeService.getMasterLocation().subscribe(success => {
          if (!!success) {
            this.location = success.locationsList;
            console.log("loc",this.location)
          }
          this.dataStorage.globalLocations = this.location;
        }, error => {
          this.myAppService.showAlertMessage(error.message);
        });
      }

    this.registerForm = new FormBuilder().group({
      'firstname': ["", Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.NAME)])],
      'lastname': ["", Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.NAME)])],
      'emailId': ["", Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.EMAIL)])],
      'company': ["", Validators.compose([Validators.required, Validators.maxLength(50)])],
      'jobtitle': ["", Validators.compose([Validators.minLength(2), Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.alphanumericWithOneSpace)])],
      "currentPassword": ["", Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.PASSWORD)])],
      "noOfOpenJobs": ["", Validators.compose([Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.NUM_OF_OPENJOBS)])],
      'acceptTerms': [false, Validators.compose([Validators.required])],
      'roleId': ['', Validators.compose([Validators.required])],
      'mobileno': ['', Validators.compose([Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.MOBILE_NUMBER)])],
      'technologyIds':[,Validators.compose([Validators.required])],
      'areasOfOperations':[, Validators.compose([Validators.required])],
      'jobType':[, Validators.compose([Validators.required])],
      'noOfResources': ["", Validators.compose([Validators.required])],
      'consultationFee':[''],
      'typeOfFee':[''],
      'websiteUrl':['',Validators.compose([Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.WEBSITE_PATTERN)])],
      'showOnPartner':[true]

    });
    this.registerForm.controls['typeOfFee'].setValue('%')
  }

  ngOnInit() {
   
    // setTimeout(() => {
    //   let elements:any = document.getElementsByClassName('mat-tab-label')
    //   for (let i = 0; i < elements.length; i++) {
    //     this.mattabids[i] = elements[i].getAttribute('id');
    //     console.log(this.mattabids);
    //   }
    //   let left = document.getElementById(this.mattabids[0])//('mat-tab-label-0-0')
    //   let right = document.getElementById(this.mattabids[1])//('mat-tab-label-0-1')
   
    //    left.style.opacity='1'
    //    right.style.opacity='1'
    //    left.style.width = '215px'
    //    left.style.borderBottom = '3px solid blue'
    //    right.style.borderBottom = '3px solid white'
    //    left.style.marginLeft = '-60px'
    //  }, 100);

    this.getRoles();

    this.registerForm.controls['mobileno'].valueChanges.subscribe((value) => {
      if (value) {
        if (value.toString().charAt(0) <= 4) {
          this.mobileError = true;
        }
        else {
          this.mobileError = false;
        }
      }
    });

  }

 
 
TabEvent(event: MatTabChangeEvent) {
    let elements:any = document.getElementsByClassName('mat-tab-label')
    for (let i = 0; i < elements.length; i++) {
      this.mattabids[i] = elements[i].getAttribute('id');
      console.log(this.mattabids);
    }
    this.borderChange();
  }
 

  
  left_border:boolean=true
 borderChange()
{
  //   let left = document.getElementById(this.mattabids[0])//('mat-tab-label-0-0')
  //  let right = document.getElementById(this.mattabids[1])//('mat-tab-label-0-1')

  //   left.style.opacity='1'
  //   right.style.opacity='1'
  //    if(this.left_border)
  //    {
  //    this.left_border=false
  //    left.style.marginLeft = '30px'
  //    right.style.width = '220px'
  //    left.style.borderBottom = '3px solid white'
  //      right.style.borderBottom = '3px solid blue'
  //    }
  //     else{
  //      this.left_border=true
  //      left.style.marginLeft = '-30px'
  //      left.style.width = '215px'
  //       left.style.borderBottom = '3px solid blue'
  //      right.style.borderBottom = '3px solid white'
  //   }
   }
  
 
  registerUser() {
    this.submitted = true;
    if (this.registerForm.valid && !this.mobileError ) {
      if (this.registerForm.controls.acceptTerms.value == false) {
        this.myAppService.showAlertMessage(this.translate.instant('PAGES.REGISTRATION.ERRORS.ACCEPT_TERMS.REQUIRED.LABEL'));
        return false;
      }
      var registerData = this.registerForm.value;
      registerData.userId = registerData.emailId;
      if (!!registerData.noOfOpenJobs) {
        registerData.noOfOpenJobs = parseInt(registerData.noOfOpenJobs);
      } else if (registerData.noOfOpenJobs === "") {
        registerData.noOfOpenJobs = 0;
      }
   registerData.showOnPartner = !!registerData.showOnPartner ? 1 : 0;
      this.registerService.registerUser(registerData).subscribe((success => {
        if (success.statuscode === MyAppHttpService.RESPONSE_CODES.SUCCESS) {
          this.myAppService.showAlertMessage(this.translate.instant('PAGES.REGISTRATION.SUCCESS.USER_REGISTERED.LABEL'));
          this.dialogService.clear();
          this.dialogService.open(LoginModalComponent, {
            data : 'Login'
          });
        
        }
     
        else{
          this.myAppService.showAlertMessage(success.message);
        }

      }), error => {
        this.myAppService.showAlertMessage(error.message);
      });

    }
    else
    {
      this.myAppService.showAlertMessage('Please fill all the required details with valid data.');
    }
  }

  clkPrivacyPolicy() {

    this.registerForm.controls['acceptTerms'].setValue(!this.registerForm.controls.acceptTerms.value);
  
  }

  getRoles() {
    this.registerService.getRoles().subscribe(response => {
      if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
        this.userRoles = response.rolesList;
      }
    });
  }

  showLogin() {
    this.dialogService.clear();
    this.dialogService.open(LoginModalComponent, {
    })
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if ( charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  onSkillsTypeAheadChange(event) {

  }

}
