import { Injectable } from '@angular/core';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsInboxlistService {

  constructor(public sendReceiveService: SendReceiveService) { }

  getAllNotifications(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetAllNotifications, requestData);
  }

  readNotification(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.ReadNotification, requestData);
  }

  getUnreadCountForNotifications(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetUnreadCountForNotification + '?enterpriseUserId=' + requestData.enterpriseUserId);
  }
}
