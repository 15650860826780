import { NgModule } from "@angular/core";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AnonymousLayoutComponent } from "./layouts/anonymous-layout/anonymous-layout.component";
import { UserLayoutComponent } from "./layouts/user-layout/user-layout.component";
import { RouterModule } from "@angular/router";
import { AppMaterialModule } from "../../app-material.module";

import { FlexLayoutModule } from '@angular/flex-layout';
import { AppTranslateModule } from '../../app-translate.module';
import { AnonymousAnotherLayoutComponent } from "./layouts/anonymous-another-layout/anonymous-another-layout.component";
import { BlueTopbarComponent } from "./blue-topbar/blue-topbar.component";
import { HomeModule } from "../views/home/home.module";
import { AutoSearchFilterPipe } from "../../pipes/auto.search.filter";
import { FindProfileModule } from "../views/find-profile/find-profile.module";
import { CommonModule } from "@angular/common";

import { TourHomeModule } from '../views/tour/tour-home/tour-home.module';
import { TourFindprofilesModule } from "../views/tour/tour-findprofiles/tour-findprofiles.module";
import { TourPostajobModule } from "../views/tour/tour-postajob/tour-postajob.module";
import { StaffingAgenciesModule } from "../views/staffing-agencies/staffing-agencies.module";
import { ReskillingScheduleInterviewModule } from "../views/reskilling-page/reskilling-schedule-interview/reskilling-schedule-interview.module";
import { ResubscribeComponent } from './modal/resubscribe/resubscribe.component';
import { UnsubscribeModule } from "../views/unsubscribe/unsubscribe.module";





@NgModule({
    imports: [
        RouterModule,
        AppMaterialModule,
        FlexLayoutModule,
        AppTranslateModule,
        HomeModule,
        TourHomeModule,
        CommonModule,
        TourFindprofilesModule,
        TourPostajobModule,
        StaffingAgenciesModule,
        ReskillingScheduleInterviewModule,
        UnsubscribeModule
    ],
    declarations: [
        AdminLayoutComponent,
        AnonymousLayoutComponent,
        UserLayoutComponent,
     
        AnonymousAnotherLayoutComponent,
        BlueTopbarComponent,
     
    
    ]
})


export class AppCommonModule {

}