import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subStringByLength'
})
export class SubStringByLength implements PipeTransform {

  transform(data: any,length: number): any {
    if(!!data && !!length){
        return data.substr(0,length);
    }
    else{
     return '';  
    }
  }

}