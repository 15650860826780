import { Injectable } from '@angular/core';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeService {

  constructor(private sendReceiveService: SendReceiveService) { }

  subscribeToEmails(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.subscribeToEmails,requestData);  
  }

  unsubscribeToEmails(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.unsubscribeToEmails,requestData);  
  }
}
