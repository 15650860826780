import { Component, OnInit, ViewEncapsulation, EventEmitter, ViewChild, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatSelect } from '@angular/material';
import { AutoSearchFilterPipe } from '../../../pipes/auto.search.filter';
import { MyAppHttpService } from '../../../services/common/my-app-http.service';
import { HomeService } from './home.service';
import { Router, NavigationExtras } from '@angular/router';
import { SendReceiveService } from '../../../services/common/sendReceive.service';
import { TypeaheadSuggestions, TypeaheadSettings } from 'ngx-type-ahead';
import { DropDownListComponent } from '@syncfusion/ej2-ng-dropdowns';
import { DialogService } from '../../common/modal/login-dialog.service';
import { LoginModalComponent } from '../../common/modal/login/login-modal/login-modal.component';
import { RegisterModalComponent } from '../../common/modal/register/register-modal/register-modal.component';
import { PaymentComponent } from '../../common/modal/payment/payment/payment.component';
import { DataStorageService } from '../../../services/data-storage.service';
import { TourModalComponent } from '../../common/modal/tour-modal/tour-modal.component';
import { ChatModalComponent } from '../../common/modal/chat-modal/chat-modal.component';
import { DownloadBrochureComponent } from '../../common/modal/download-brochure/download-brochure.component';
import { WebsocketService } from 'src/app/ws/websocket.service';
import { NotificationsInboxlistService } from '../notifications-inboxlist/notifications-inboxlist.service';
import { ScheduleMockinterviewComponent } from '../../common/modal/schedule-mockinterview/schedule-mockinterview.component';
import { environment } from '../../../../environments/environment';
import { BuildTeamsRemotelyComponent } from './build-teams-remotely/build-teams-remotely.component';
import { ReskillingComponent } from './reskilling/reskilling.component';
import { SearchFiltersComponent } from './search-filters/search-filters.component';
import { CommonfunctionService } from 'src/app/services/common/commonfunction.service';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [AutoSearchFilterPipe],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  experiences: any[];
  // skillsTechDesignation: TypeaheadSuggestions = [];
  booleanArray = [{ value: true, display: 'Yes' }, { value: false, display: 'No' }, { value: '', display: 'All' }];
  searchTypeArray = [{ value: true, display: 'Exact' }, { value: false, display: 'Broad' }];
  location = [];
  matchedData: any;
  preScreened: any;
  exactMatch: any;
  profileHired: any;
  noticePeriods: any[];
  rockRatings: any[];
  jobTypes: any[]
  expectedCTCs: any[];
  probabilityofJoinings: any[];
  lastUpdates: any[];
  searchForm: FormGroup;
  isLogin: boolean = false;
  advFltr: boolean = false;
  advFilter: boolean = false;
  name;
  isTopSectionChanged = false;
  selectedTalentArea;
  slideshowImageList = ['assets/images/bg-image-2-1239x548_1.png', 'assets/images/image_3_1.png'];


  skillsTechDesignation: any = [];
  skillsTechDesignation1: any = [];
  skillsTechDesignation2: any = [];

  selectedSkillsandDesignations: any = [];
  selectedLocations: any = [];
  locationsArray: any = [];
  txtExperience = '';
  txtNoticePeriod = '';
  txtRockRatings = '';
  isTooltipSkills;
  messageCount: boolean = false;
  txtUpdates: any;
  txtExpectedCTC: any;
  notificationsList = [
  
  ];


  @ViewChild('ddlLocations')
  public dropDownLocationObject: DropDownListComponent;

  customSettings: Partial<TypeaheadSettings> = {
    suggestionsLimit: Infinity
  };
  EnterpriseId: any;

  @ViewChild('skillsAndTitles') skillsAndTitles: TypeaheadSuggestions;
  element: any;
  isMenuClicked: boolean;

  selectedSkills: any = [];
  subSkills = [
    { id: 118, type: "Skill", name: "ABAP" },
    { id: 34, type: "Skill", name: "Advance Java" }
  ];

  constructor(private dialogService: DialogService, public myAppService: MyAppHttpService, public homeService: HomeService,
    public router: Router, private sendReceiveService: SendReceiveService, public dataStorage: DataStorageService,
    private cdr: ChangeDetectorRef, public wsService: WebsocketService, public notificationsService: NotificationsInboxlistService,public dialog: MatDialog,
    public myAppHttp: MyAppHttpService,public commonfunction: CommonfunctionService) {
  

    if (!!localStorage.getItem('token') && localStorage.getItem('token') != 'anonymous' && localStorage.getItem('token') != 'null'
    && !!localStorage.getItem('userData')) {
      this.isLogin = true;
      this.commonfunction.userLogedin = true;
        this.name = JSON.parse(localStorage.getItem('userData')).firstName;
        this.EnterpriseId = JSON.parse(localStorage.getItem('userData')).enterpriseUserId;

    } else {
      this.isLogin = false;
      this.commonfunction.userLogedin = false;
    }
    this.searchForm = new FormBuilder().group({
      'skillsTechDesignation': [""],
      'location': [""],
      'preScreened': [],
      'experiences': [""],
      'noticePeriods': [""],
      'rockRatings': [""],
      'jobTypes': [""],
      'expectedCTS': [""],
      'probabilityofJoining': [""],
      'trainedProfiles': [""],
      'exactSearch': [""],
      'brandSearch': [""],
      'lastUpdates': [""],
      'freelancer': [false]
    });
  }

  ngOnInit() {

    this.searchForm.controls['skillsTechDesignation'].valueChanges.subscribe((value) => {
      this.selectedSkillsandDesignations = value;



    });

    this.searchForm.controls['location'].valueChanges.subscribe((value) => {
      this.selectedLocations = value;
    });


   

    $('#carouselProfiles').carousel({
      interval: 5000
    });

    // Master data
    this.homeService.getMasterServices().subscribe(success => {
      if (!!success) {
        this.experiences = success.experienceListDetails.experienceList;
        this.noticePeriods = success.noticePeriodListDetails.noticePeriodList;
        this.rockRatings = success.rockRatingListDetails.rockRatingList.sort((a, b) => (a.rockRatingId < b.rockRatingId) ? 1 : -1);
        this.jobTypes = success.jobTypeListDetails.jobTypeList;
        this.expectedCTCs = success.expectedCTCListDetails.expectedCTCList;
        this.probabilityofJoinings = success.pojListDetails.pojList;
        this.lastUpdates = success.lastUpdatedListDetails.lastUpdatedList;

        this.dataStorage.masterData = {
          experiences : this.experiences,
          noticePeriods: this.noticePeriods,
          rockRatings: this.rockRatings,
          jobTypes: this.jobTypes,
          expectedCTCs: this.expectedCTCs,
          pojs: this.probabilityofJoinings,
          lastUpdates: this.lastUpdates
        } 
      }
    }, error => {
      this.myAppService.showAlertMessage(error.message);
    });

    // location

    if (this.dataStorage.globalLocations.length == 0) {
      this.homeService.getMasterLocation().subscribe(success => {
        if (!!success) {
          this.location = success.locationsList;
        }
        this.dataStorage.globalLocations = this.location;
      }, error => {
        this.myAppService.showAlertMessage(error.message);
      });
    }
    else {
      this.location = this.dataStorage.globalLocations;
    }

    // skills


    if (this.dataStorage.globalSkillsAndJobTitles.length == 0) {
      this.homeService.getMasterSkillsDesignationTools().subscribe(success => {
        if (!!success) {

          this.dataStorage.globalSkills = JSON.parse(JSON.stringify(success.technologyList));
         
          this.skillsTechDesignation = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));

          this.dataStorage.globalSkillsAndJobTitles = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));// this.skillsTechDesignation1;
        }
      }, error => {
        this.myAppService.showAlertMessage(error.message);
      });
    }
    else {
      this.skillsTechDesignation = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));
    }
    // Matched Profile

    this.homeService.getMasterMatchedData().subscribe(success => {
      if (!!success) {
        this.matchedData = success;
        this.preScreened = this.matchedData.preScreenedProfileCount;
        this.exactMatch = this.matchedData.totalAvailableProfiles;
        this.profileHired = this.matchedData.hiredProfilesCount;
      }
    }, error => {
      this.myAppService.showAlertMessage(error.message);
    });


  }

  ngAfterViewInit() {
    // Tour code 125 to 165

    if (!this.dataStorage.isTourRendered) {
      
        setTimeout(() => {
          this.dialogService.open(DownloadBrochureComponent, {

          });
        }, 5000);

    }

 

  }

  Homepage() {
    this.isTopSectionChanged = false;
    this.router.navigate(['/']);
  }

  searchProfiles() {
    if (this.searchForm.valid) {
      this.searchForm.value.skillsTechDesignation = this.selectedSkillsandDesignations;
      this.searchForm.value.location = this.selectedLocations;
      this.searchForm.value.selectedSkills = this.selectedSkills;

      this.dataStorage.previousSearchCriteria = this.searchForm.value;
      this.router.navigate(['/findprofile']);
    } else if (!this.searchForm.valid) {
      this.myAppService.showAlertMessage('Please Enter Skills,Expected CTC,Notice Period and Experience');
    }
  }

  advanceFilter() {
    if (this.advFltr === false) {
      this.advFilter = true;
      this.advFltr = true;
      return this.advFltr;
    }
    else {
      this.advFilter = false;
      this.advFltr = false;
      return this.advFltr;
    }

  }
  login() {
    if (this.isLogin) {
      this.router.navigate(['/pricing']);
    }
    else {
      this.router.navigate(['/login']);
      localStorage.setItem('nextPage', 'home');
    }
  }
  closeNav() {
    this.isMenuClicked = false;
    this.element = document.getElementById("mySidenav").style.width = "0";
  }
  openNav() {
    this.element = document.getElementById("mySidenav").style.width = "260px";

  }

  public dashboard() {
    this.router.navigate(['dashboard']);
  }

  public ReskillingNew(){
    if(this.isLogin) {
      this.router.navigate(['reskillingpage']);
    }
    else {
      this.dialogService.open(LoginModalComponent, {
        
      });
    }
  }

  public myjobs() {
    this.router.navigateByUrl('jobs?tab=myjobs');
  }

  public favourite() {
    this.router.navigate(['favouriteprofile']);
  }

  public myprofile() {

    
    this.router.navigate(['myaccount']);
  }

  public offersReleased() {
    this.router.navigate(['rolledoutOffers']);
  }

  public chat() {
    this.router.navigate(['chat']);
  }

  logout() {
    this.sendReceiveService.logoutService();
  }

  clearDropdownLocationValue() {
    this.dropDownLocationObject.value = null;
  }

  help() {
    this.router.navigate([]).then(result => { window.open('/help', '_blank'); });
  }

  public postajob() {
    this.router.navigate(['/postajob']);
  }

  public findprofiles() {
    this.router.navigate(['/findprofile']);
  }
  // globalinterviews

  public globalInterviews() {
    this.router.navigate(['/globalscheduleinterview']);
  }

  public findpartners() {
    this.router.navigate(['/find-partners']);
  }

  public resumebuilder() {
    this.router.navigate(['/resume-builder']);
  }
  
  

  screenedProfiles() {
    var skills = this.dataStorage.globalTechnologyAreas.SCREENED.skill_set;
    var skillQueryParams = this.dataStorage.globalSkills.filter(x => skills.indexOf(x.name) > -1).map(x => x.id).join();
    this.onSkillsTypeAheadChange(this.skillsTechDesignation.filter(x => skills.indexOf(x.name) > -1));

    this.searchForm.value.selectedSkills = this.skillsTechDesignation.filter(x => skills.indexOf(x.name) > -1);
    this.dataStorage.previousSearchCriteria = this.searchForm.value;

    if (skillQueryParams) {
      this.router.navigate(['findprofile'], {
        queryParams: {
          'skillIds': skillQueryParams,
          'selectedFilter': 2
        }
      });
    }
    else {
      this.router.navigate(['findprofile']);
    }
  }

  AIUtilities() {
    var skills = this.dataStorage.globalTechnologyAreas.SCREENED.skill_set;
    var skillQueryParams = this.dataStorage.globalSkills.filter(x => skills.indexOf(x.name) > -1).map(x => x.id).join();
    this.onSkillsTypeAheadChange(this.skillsTechDesignation.filter(x => skills.indexOf(x.name) > -1));

    this.searchForm.value.selectedSkills = this.skillsTechDesignation.filter(x => skills.indexOf(x.name) > -1);
    this.dataStorage.previousSearchCriteria = this.searchForm.value;


    if (skillQueryParams) {
      this.router.navigate(['findprofile'], {
        queryParams: {
          'skillIds': skillQueryParams
        }
      });
    }
    else {
      this.router.navigate(['findprofile']);
    }
  }

  public pricing() {
    this.router.navigate(['/pricing']);
  }

  public staffingAgencies() {
 
    this.router.navigate([]).then(result => { window.open('/staffingAgencies', '_blank'); });
  }

  public fields: object = { text: 'name', value: 'id', type: 'type' };
  // set the placeholder to MultiSelect input element
  public waterMark: string = 'Skills/Technology/Designation';
  public waterMark1: string = 'Location';
  // set the type of mode for how to visualized the selected items in input element.
  public box: string = 'marketBox';

  public Loc_fields: object = { text: 'locationName', value: 'locationId' };

  // [mode]='marketBox
  // formatBox

  showLogin() {
    this.dialogService.open(LoginModalComponent, {
    })
  }

  showRegister() {
    this.dialogService.open(RegisterModalComponent, {
    })
  }

  showPayment() {
    this.dialogService.open(PaymentComponent, {
    })
  }

  onSkillsTypeAheadChange(values) {
    this.selectedSkills = values;
    this.selectedSkills.forEach(x=> {
      if(x.keywords && !x.allKeywords){
        x.allKeywords = [];
        for(var i=0;i<x.keywords.length;i++){
          var temp ={id: 0, name: '', checked: false};
          temp.id = i+1;
          temp.name = x.keywords[i];
          temp.checked = false;
          x.allKeywords.push(temp);
        }
      }
      else{
        //x.allKeywords = null;
      }
    });

  }

  onLocationTypeAheadChanges(values) {
  }

  changePassword() {
    this.router.navigate(['/changepassword']);
  }

  scheduledInterviews() {
    this.router.navigate(['/scheduledInterviews']);
  }

  postJobs() {
    this.router.navigate(['/postajob']);
  }

  globalinterviews(){
    // if(this.isLogin) {
    //   this.router.navigate(['/globalscheduleinterview']);
    // }
    // else {
    //   this.dialogService.open(LoginModalComponent, {

    //   });
    // }
     this.router.navigate(['/globalscheduleinterview']);
    
  }

  openTourModal() {
    this.dialogService.open(TourModalComponent, {

    });
  }

  openChatModal() {
    if (JSON.parse(localStorage.getItem('userData'))) {
      let userChatInfo = {
        userId: JSON.parse(localStorage.getItem('userData')).enterpriseUserId,
        userName: JSON.parse(localStorage.getItem('userData')).firstName,
        userType: MyAppHttpService.chatUserTypes.RE,
        sourceUnreadCount: 0,
        adminChatId: '',
        userChatId: '',
        toUserType: MyAppHttpService.chatUserTypes.READMIN,
        toUserName: 'Ricruit Support',
        toUserId: this.dataStorage.adminUserId,
        inviteStatus: this.dataStorage.globalAdminchatUserInfo.inviteStatus
      };
      this.dataStorage.globalChatUserInfo = userChatInfo;
    }

    this.messageCount = false;
    this.dataStorage.chatClicked = true;
    this.cdr.detectChanges();

    if (this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount > 0) {
      var message = {
        data: {
          userId: localStorage.getItem('userData') ? (JSON.parse(localStorage.getItem('userData')).enterpriseUserId) : this.dataStorage.globalChatUserInfo.userId,
          userType: MyAppHttpService.chatUserTypes.RE,
          userName: localStorage.getItem('userData') ? (JSON.parse(localStorage.getItem('userData')).firstName) : this.dataStorage.globalChatUserInfo.userName,
          chatId: this.dataStorage.globalUnread_ChatIdInfo.userChatId,
          toUserId: this.dataStorage.adminUserId,
          toUserType: MyAppHttpService.chatUserTypes.READMIN,
          toUserName: MyAppHttpService.chatUserTypes.READMIN
        },
        message: MyAppHttpService.chatEventNames.UPDATE_READ_COUNT
      }

      this.wsService.messages.next(message);
    }
   
  }

  goToContractorsPage(enterpriseRole) {
    this.router.navigate(['/contractors'], {
      queryParams: {
        'enterpriseRole': enterpriseRole
      }
    });
  }

  ngOnDestroy() {
    this.dataStorage.chatClicked = false;
  }

  getAllNotifications(menuTrigger) {
    if (this.isLogin) {
      this.dataStorage.unreadNotificationsCountBoolean = false;
      let requestData = {
        enterpriseUserId: this.EnterpriseId,
        limit: 4
      };

      this.notificationsService.getAllNotifications(requestData).subscribe(response => {
        if (response.statusCode == 200) {
          this.notificationsList = response.notifications;
          if (this.notificationsList.length == 0) {
            this.router.navigate(['/notifications'], {
              queryParams: {
                'noticationId': 0
              }
            });
          }
          else {
            menuTrigger.openMenu();
          }
        }
      });
    }
  }

  showMoreNotifications(notf) {
    if (notf != null) {
      let inputData = {
        id: notf.id,
        read: true
      };
      this.notificationsService.readNotification(inputData).subscribe(response => {
      });
    }
    this.router.navigate(['/notifications'], {
      queryParams: {
        'noticationId': notf ? notf.id : 0
      }
    });
  }

  convertToPlainString(content) {
    content = content ? content.replace(/(<([^>]+)>)/ig, '') : '';

    return content ? content.replace(/&nbsp;/g, ' ') : '';
  }

  scheduleInterview() {
    if(this.isLogin){
      if (!!this.dataStorage.membershipCurrentPlan.planId && this.dataStorage.membershipCurrentPlan.planDurationNum > -1) {
        this.fnOpenScheduleMockInterviewDialog();
      }
      else {
        this.dialogService.open(PaymentComponent, {
          data: { actionName: this.dataStorage.globalFeatureCodes.INTERVIEW_OWN, popupFlag: true, details: null, flag: null, eventType: null }
        });
      }
      
    }
    else{
      console.log("navigate")
       this.fnOpenScheduleMockInterviewDialog();

    }
  }

  fnOpenScheduleMockInterviewDialog() {
    console.log('navigate to schedule')
    //debugger
    // this.router.navigate(['/globalscheduleinterview']).then((r)=>{
    //   console.log('sucessfully navigate',r)
    // },(err)=>{
    //   console.log('error',err)
    // })
    this.router.navigate(['/globalscheduleinterview']) 
    
  

  }

  onFeatureClick(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  isMenuOpened() {
    this.isMenuClicked = true;
  }
  isMenuClosed() {
    this.isMenuClicked = false;
  }

  goToWebsite() {
    window.open(environment.websiteURL, '_blank');
  }


  teams(){


    const dialogRef: MatDialogRef<any> = this.dialog.open(BuildTeamsRemotelyComponent,
      {
        height: 'auto 850px',
        width: '900px',
        panelClass: 'custom-dialog-container',
        autoFocus: false,
        data: { EnterpriseId: this.EnterpriseId },
        disableClose: true
      });


  }

  Reskilling(selectedOption)
  {
    this.Openreskilling(selectedOption);
  }

  Openreskilling(selectedOption) {
    const dialogRef: MatDialogRef<any> = this.dialog.open(ReskillingComponent,
      {
        height: 'auto',
        width: '880px',
        panelClass: 'custom-dialog-container',
        autoFocus: false,
        data: { EnterpriseId: this.EnterpriseId, selectedOption: selectedOption },
        disableClose: true
      });

  }

  openSearchFilters() {
   let modalRef =  this.dialogService.open(SearchFiltersComponent, {
      position: {top: 'auto', bottom: '0px'}, disableClose: false, width: '100%', maxWidth: '95vw'
    
    }, false
    );

    modalRef.afterClosed().subscribe(results => {
      if(!!results) {
        this.searchForm.value.experiences = results.experiences;
        this.searchForm.value.noticePeriods = results.noticePeriods;
        this.searchForm.value.rockRatings = results.rockRatings;
        this.searchForm.value.lastUpdates = results.lastUpdated;
        this.searchForm.value.freelancer = results.freelancer;
        this.searchForm.value.expectedCTS = results.expectedCTCs;
      }
    })

  }



}

