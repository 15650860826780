import { Injectable } from '@angular/core';
import { GlobaldataService } from './globaldata.service';
import { DialogService } from 'src/app/components/common/modal/login-dialog.service';
import { LoginModalComponent } from 'src/app/components/common/modal/login/login-modal/login-modal.component';
import { FindProfileService } from 'src/app/components/views/find-profile/find-profile.service';
import { MyAppHttpService } from './my-app-http.service';
import { PricingService } from '../../components/views/pricing/pricing.service';
import { DataStorageService } from '../data-storage.service';
import { LoginService } from '../../components/views/login/login.service';
import { PaymentComponent } from '../../components/common/modal/payment/payment/payment.component';
import { Router } from '@angular/router';
import { ProfileInfoService } from 'src/app/components/common/profile-info/profile-info.service';
import { SendReceiveService } from './sendReceive.service';
import { NotificationsInboxlistService } from 'src/app/components/views/notifications-inboxlist/notifications-inboxlist.service';
import { PricingConfirmationComponent } from 'src/app/components/common/modal/pricing-confirmation/pricing-confirmation.component';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material';
import { DownloadBrochureComponent } from '../../components/common/modal/download-brochure/download-brochure.component';
// import { ViewRefFBComponent } from '../../common/profile-info/view-ref-fb/view-ref-fb.component';
import { ViewRefFBComponent } from 'src/app/components/common/profile-info/view-ref-fb/view-ref-fb.component';
import { Subject } from 'rxjs/internal/Subject';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonfunctionService {

  profileIdsArray: any[];
  // expandedData: any[];
  // selectedProfiles: any[];
  dialogRef: MatDialogRef<any>;
  userLogedin: boolean;
  // logedInUserData:any;
  logedInUserData = {
    istoken: null,
    isanonymoustoken: null,
    isnulltoken: null,
    isuserdata: false,
    userData: {}
  };
  isLogedUserData$: Subject<any> = new Subject<any>();


  constructor(private globaldataService: GlobaldataService,
    private dialogService: DialogService,
    private findProfileServie: FindProfileService,
    private myAppService: MyAppHttpService,
    private pricingService: PricingService,
    private dataStorage: DataStorageService,
    private loginService: LoginService,
    private router: Router,
    private profileInfoService: ProfileInfoService,
    private sendReceiveService: SendReceiveService,
    private notificationsService: NotificationsInboxlistService,
    public dialog: MatDialog,public http: HttpClient,) {
    this.isLogedUserData$.subscribe((data) => {
      this.logedInUserData = {
        istoken: !!data.token,
        isanonymoustoken: data.token != 'anonymous',
        isnulltoken: data.token != 'null',
        isuserdata: !!data.userData,
        userData: data.userData
      };
    })
  }

  selectedLevel:any='Basic';
//   {
//     "id": 18,
//     "name": "Basic",
//     "price": 9
// }
  


  getMemberFeatureEligibility(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetMemberFeatureEligibility, requestData);
  }

  addorDeleteFavouritesService(profileId, flag, isMultipleSelected = false) {
    this.globaldataService.setData(profileId, flag);
    if (!localStorage.userData) {
      // this.myAppService.showAlertMessage(this.translate.instant('PAGES.COMMON.ERRORS.LOGIN_REQUIRED'));
      // return false;
      localStorage.setItem('addedFav', 'FindFav');
      // this.router.navigate(['/login']);
      this.dialogService.open(LoginModalComponent, {
        data: 'login'
        // width: '250px', data: 'login', hasBackdrop: false
      })
    }


    if (isMultipleSelected) {
      this.profileIdsArray = profileId;
    }
    else {
      this.profileIdsArray = [profileId];
    }

    //this.favouriteStatus = !this.favouriteStatus;

    let inputData = {
      enterpriseUserId: JSON.parse(localStorage.userData).enterpriseUserId,
      profileId: this.profileIdsArray
    }
    if (flag == 1) {
      this.findProfileServie.addToFavourites(inputData).subscribe((response) => {
        // this.expandedData[0].isInFavourites = !this.expandedData[0].isInFavourites;
        if (isMultipleSelected) {
          // this.selectedProfiles = [];
          // this.searchProfiles();
        }
        this.myAppService.showAlertMessage('Added to Favorites');
      });
    }
    else {
      this.findProfileServie.deleteFromFavourites(inputData).subscribe((response) => {
        // this.expandedData[0].isInFavourites = !this.expandedData[0].isInFavourites;
        if (isMultipleSelected) {
          // this.selectedProfiles = [];
          // this.searchProfiles();
        }
        this.myAppService.showAlertMessage('Removed from Favorites');
      });
    }

  }

  getAllCountriesList() {
    return this.http.get('https://restcountries.com/v3.1/all') 
  }

  getMemberPlanInfo() {
    this.pricingService.getAllPlans().subscribe((success) => {
      if (success != undefined) {
        this.dataStorage.membershipPlans = success.planList;
        this.getCurrentPlanDetails();
      }
    }, (error) => {

    });

    this.pricingService.getAllFeatures().subscribe((success) => {
      if (success != undefined)
        this.dataStorage.membershipFeatures = success;
    });
  }

  getCurrentPlanDetails() {
    if (this.dataStorage.membershipPlans.length > 0) {
      this.pricingService.getMembershipCurrentPlan().subscribe(currentPlanResonse => {
        this.dataStorage.membershipCurrentPlan = !!currentPlanResonse.planId ? this.dataStorage.membershipPlans.find(x => x.planId == currentPlanResonse.planId) : {};
        if (!!currentPlanResonse.planId) {
          // this.dataStorage.isUserLoggedIn = true;
          this.dataStorage.membershipCurrentPlan.planDurationNum = currentPlanResonse.planDuration;
          this.dataStorage.membershipCurrentPlan.subscriptionId = currentPlanResonse.subscriptionId;
          this.dataStorage.membershipCurrentPlan.paymentType = currentPlanResonse.paymentType;
          this.dataStorage.membershipCurrentPlan.priority = this.dataStorage.membershipPlans.find(x => x.planId == currentPlanResonse.planId).priority;
        }
        if (!!currentPlanResonse.planId) {
          if ((this.dataStorage.membershipCurrentPlan.planDurationNum) > 0 || true) {
            this.dataStorage.membershipCurrentPlan.featureList.forEach(cf => {
              this.dataStorage.globalAvailableFeatureInfo.forEach(f =>
                (f.featureCode == cf.featureCode) ? f.isAvailable = true : false);
            });
          }
        }

        // if(this.router.url == '/postajob'){
        //     if (this.validateMemberForPlanSubscription(this.dataStorage.globalFeatureCodes.POST_A_JOB, null, null, null)) {
        //     }
        // }
      });
    }
  }

  addUpdateUserFCMToken() {
    if (!!localStorage.userData) {
      let inputData = {
        enterpriseUserId: JSON.parse(localStorage.userData).enterpriseUserId,
        fcmToken: this.dataStorage.fcmToken
      }
      this.loginService.saveFCMToken(inputData).subscribe(success => {
      });
    }
  }


  errorMethodForMemberSubscription(eventType, actionName, details, flag, response) {
    console.log('price service')
    this.dataStorage.globalIsQuotaAvailable = true;
    console.log('inside dialog')
    if (response.statusCode == MyAppHttpService.RESPONSE_CODES.MEMBER_NOT_A_MEMBER) {
      this.myAppService.showAlertMessage('Subscribe/Update plan to avail feature');
      console.log('dialog')
      this.dialogService.open(PaymentComponent, {
        data: { actionName: actionName, popupFlag: true, details: details, flag: flag, eventType: eventType }
      });
      console.log('inside dialog')
    } else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.MEMBER_PLAN_EXPIRED) {
      console.log('inside dialog1')
      setTimeout(() => {
        this.myAppService.showAlertMessage('Your plan has expired');
      }, 1);
      console.log('inside dialog2')
      this.dialogService.open(PaymentComponent, {
        data: { actionName: actionName, popupFlag: true, details: details, flag: flag, eventType: eventType }
      });
      console.log('inside dialog3')
    } else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.MEMBER_FEATURE_NOT_AVAILABLE) {
      this.myAppService.showAlertMessage('Feature is not available for your plan');
    } else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.MEMBER_QUOTA_COMPLETE) {
      this.dataStorage.globalIsQuotaAvailable = false;
      this.dialogService.open(PaymentComponent, {
        data: { actionName: actionName, popupFlag: true, details: details, flag: flag, eventType: eventType }
      });
      this.myAppService.showAlertMessage('Your Quota has completed');
    } else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.MEMBER_UNKNOWN) {
      this.myAppService.showAlertMessage('Unknown member');
    }
  }

  public validateFeatureAvailability(eventType, actionName, details, flag) {
    this.dataStorage.globalIsQuotaAvailable = true;
    if (!!this.dataStorage.membershipCurrentPlan.planId) {
      if (this.dataStorage.globalAvailableFeatureInfo.filter(x => (x.featureCode && x.isAvailable)).map(x => x.featureCode).includes(eventType)) {
        return true;
      } else {
        if (eventType == this.dataStorage.globalFeatureCodes.POJ || eventType == this.dataStorage.globalFeatureCodes.JOB_OF_THE_DAY_TEMPLATE || (eventType == this.dataStorage.globalFeatureCodes.COMMUNICATION && flag == 3) || eventType == this.dataStorage.globalFeatureCodes.OPPORTUNITIES) {

        }
        else {
          this.myAppService.showAlertMessage('This feature is not available');
        }
        return false;
      }
    }
    else {
      if (eventType == this.dataStorage.globalFeatureCodes.POJ || eventType == this.dataStorage.globalFeatureCodes.JOB_OF_THE_DAY_TEMPLATE || (eventType == this.dataStorage.globalFeatureCodes.COMMUNICATION && flag == 3) || eventType == this.dataStorage.globalFeatureCodes.OPPORTUNITIES) {
      }
      else {
        this.dialogService.open(PaymentComponent, {
          data: { actionName: actionName, popupFlag: true, details: details, flag: flag, eventType: eventType }
        });
      }
      return false;
    }
  }

  getUnreadNotifications() {
    if (!!localStorage.userData) {
      let inputData = {
        enterpriseUserId: JSON.parse(localStorage.userData).enterpriseUserId,
      };
      this.notificationsService.getUnreadCountForNotifications(inputData).subscribe(response => {
        if (response != undefined) {
          if (response.statusCode == 200 && response.count > 0) {
            this.dataStorage.unreadNotificationsCountBoolean = true;
          }
        }
      });
    }
  }

  showPricingConfirmationDialog(feature, data, event) {
    this.dialogRef = this.dialogService.open(PricingConfirmationComponent, {
      data: {
        message: 'Hey, you need to have a plan to use this! Do you want to Upgrade?'
      }
    });

    this.dialogRef.afterClosed().subscribe(response => {
      if (response == true) {
        this.dialogService.open(PaymentComponent, {
          data: { actionName: feature, details: data, eventType: event }
        });

        this.dataStorage.getPayments.subscribe((data) => {
          if (data.actionName == feature) {

            // this.dataStorage.havePlan = true;
            return this.dataStorage.havePlan();
          }

        });
      }
    });
  }

  openDownloadBrochure() {
    setTimeout(() => {
      var windowOuterWidth = window.outerWidth;
      if (windowOuterWidth > 767) {
        this.dialogService.open(DownloadBrochureComponent, {

        });
      }

    }, 5000);
  }

  //priya

  getReferenceDetail(referenceId: number) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "850px";
    dialogConfig.height = "450px";
    dialogConfig.panelClass = "custom-dialog";

    let inputData = {
      "referenceId": referenceId
    }

    this.profileInfoService.getReferenceDetail(inputData).subscribe((response) => {

      if (response) {
        dialogConfig.data = {
          id: 1,
          title: response
        };
        let addRef = this.dialog.open(ViewRefFBComponent, dialogConfig);
      }



    })//close
    //open popup

  }//
}
