import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleMockinterviewComponent } from './schedule-mockinterview.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppMaterialModule } from 'src/app/app-material.module';
import { TypeaheadModule } from 'ngx-type-ahead';
import { CommonPipesModule } from 'src/app/pipes/common-pipes/common-pipes.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TypeaheadModule,
    CommonPipesModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgSelectModule,
    AngularEditorModule
  ],
  declarations: [ScheduleMockinterviewComponent],
  exports: [ScheduleMockinterviewComponent],
  entryComponents: [ScheduleMockinterviewComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: []
})
export class ScheduleMockinterviewModule { }
