import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppCommonModule } from './components/common/app-common.module';
import { AuthGuardService } from './services/common/auth-guard.service';
import { AuthService } from './services/common/auth.service';
import { AppTranslateModule } from './app-translate.module';
import { HttpModule } from '@angular/http';
import { MyAppHttpService } from './services/common/my-app-http.service';
import { SendReceiveService } from './services/common/sendReceive.service';
// import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TypeaheadModule } from 'ngx-type-ahead';
import { AvatarFilter } from './pipes/avtar';
import { FooterComponent } from 'src/app/components/common/footer/footer.component';
import { GlobaldataService } from './services/common/globaldata.service';
import { LoginService } from './components/views/login/login.service';
import { AppMaterialModule } from './app-material.module';
import { FindProfileService } from './components/views/find-profile/find-profile.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChatDialogComponent } from './components/common/chat/chat-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ViewRefFBComponent } from './components/common/profile-info/view-ref-fb/view-ref-fb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UpSingleProfileComponent } from './components/views/my-resources/up-single-profile/up-single-profile.component';
import { StarRatingModule } from 'angular-star-rating';
import { CommonPipesModule } from './pipes/common-pipes/common-pipes.module';



@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    ChatDialogComponent,
    ViewRefFBComponent,
    UpSingleProfileComponent
    
    
    // AvatarFilter
  ],
  imports: [
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppCommonModule,
    AppTranslateModule,
    HttpModule,
    AppMaterialModule,
    // FroalaEditorModule.forRoot(), FroalaViewModule.forRoot()
    //AngularFireModule.initializeApp(environment.firebase),
    AngularEditorModule,
    TypeaheadModule,
    //AngularFireDatabaseModule,
    //AngularFireAuthModule,
    //AngularFireMessagingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    StarRatingModule.forRoot(),
    CommonPipesModule
 
  ],
  providers: [AuthGuardService, AuthService,MyAppHttpService,SendReceiveService,AvatarFilter,GlobaldataService, LoginService,
    //MessagingService, AngularFireDatabase,
    FindProfileService,],
    entryComponents: [ViewRefFBComponent, UpSingleProfileComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
