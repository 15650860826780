import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { GlobalscheduleService } from 'src/app/components/views/globalscheduleinterview/globalscheduleinterview.service';
import { HomeService } from 'src/app/components/views/home/home.service';
import { ScheduledInterviewsService } from 'src/app/components/views/scheduledinterviews/scheduledinterviews.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { environment } from 'src/environments/environment';
import { ScheduleMockinterviewService } from '../schedule-mockinterview/schedule-mockinterview.service';
import { UpdateInterviewService } from './updateinterview.service';
import { ClipboardService } from 'ngx-clipboard';
import * as internal from 'assert';
import { DatePipe } from '@angular/common'; 
import { ZoomServiceService } from 'src/app/services/zoom-service.service';
// import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { CommonConfirmModalComponent } from '../common-confirm-modal/common-confirm-modal.component';
import { DialogService } from '../login-dialog.service';
// import {
//   DialogService,
//   DialogRef,
//   DialogCloseResult,
// } from "@progress/kendo-angular-dialog";

// DialogRef
// import { setInterval } from 'timers';
// import { Console } from 'console';
//ConfirmModalComponent//dialogService
@Component({
  selector: 'app-updateinterview',
  templateUrl: './updateinterview.component.html',
  styleUrls: ['./updateinterview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class UpdateInterviewComponent implements OnInit {
  owl_date_time
  dialogRef
  popup_firing:boolean = false
  // meetinglink_input_radio_checked
  interviewForm: FormGroup;
  validation_messages = {

    'interviewer': [
      { type: 'required', message: 'Please select Interviewer' }
    ],
    'month': [
      { type: 'required', message: 'Please select Months' }
    ],
    'technologies': [
      { type: 'required', message: "Please select Technologies" }

    ],
    'intDateAndTime': [
      { type: 'required', message: "Please select Date & Time" }
    ],
    // 'resume': [
    //   { type: 'required', message: 'Please upload Resume' }
    // ],
    'comment': [
      { type: 'maxlength', message: 'Comments should be only 500 characters.' }
    ],
    'meetingLink': [
      { type: 'pattern', message: 'Please Enter Valid Meeting Link'  },
      {type: 'required', message: 'please Enter meetinLink'}
    ],
    
    'upload resume':[
      { type:'pattern', message: 'Please select pdf or word file less than 2 MB' }
    ],
  }

  zoom_select: number;

  skills: any;
  submitted: boolean = false;
  userData: any;
  enterpriseUserId: any;
  mobileError;

  resumePath: any = "";
  resume: any;
  fileName: any = '';
  uploadresume: boolean =false;

  editdata: boolean = false;

  mentorslist: any = [];

  interviewtimedataforedit: any;

  minDate: Date;
  maxDate: Date;

  dtError: boolean = false;
  dtErrortype: any = 0;

  matcheditem: boolean = false;
  resumeshortcut: any = "";
  meetingLink: any = "";
  public todayDate:any = new Date();
  pastDate:boolean=false
  meetLink:string="";
  isFormValueChanged: boolean =false;
  inp_meetlink: boolean;
  selectedRadio: number ;
  zoomUsage:number;
  zoomUsageField:number
  paymentForZoom:boolean=false;
  isRequired:boolean=true
  meetinglinkfieldValue:string
  select_zoom_usage:number = 0
  create_update:number=0

  meetingStatuses: any[];
  showMeetingLinksSection: boolean;

  showWhereByOption: boolean;
  showZoomOption: boolean;
  showAddYourLinkOption: boolean;
  showNoneOption: boolean;
 

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public globalservice: GlobalscheduleService, private fb: FormBuilder,
    public dataStorage: DataStorageService, private homeService: HomeService, private myAppService: MyAppHttpService,private datePipe: DatePipe,
    private scheduleInterviewService: ScheduleMockinterviewService, private _clipboardService: ClipboardService,
     private updateintservice: UpdateInterviewService,private zoomserviceService:ZoomServiceService,public dialogService:DialogService, 
    private router: Router) {
    this.GetInterviewersAddedByEnterprise();
    let resumegetback = "";
    this.skills = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));
    console.log(this.data.meetingLink)

    console.log('Data from profile component: ', this.data);
    if(this.data.meetingLink == 'Not Availabe'){
      this.meetLink="";
    }
    else{
      this.meetLink=this.data.meetingLink
    }
    if (this.data != null) {
      this.editdata = true;
      console.log("selected raido button ", this.selectedRadio)
      if(true)//(data.zoomUsage == 0)
      {
        if(data.zoomId == 668){
          //data.zoomId = null;
          this.create_update=1
          this.zoomserviceService.meetinglink_input_radio_checked=1}
        if(!!data.meetingLink && !!data.meetingHost && !!data.zoomId && data.zoomUsage != 7)
        {
          this.zoomUsageField =1;
          this.select_zoom_usage=1;
          this.isRequired=false; 
          this.selectedRadio=1;
          this.zoomserviceService.meetinglink_input_radio_checked=1;
          this.popup_firing=true; 
          this.create_update=1 
        }
        else if(!!data.meetingLink && data.meetingLink!="Not Availabe" && (data.meetingHost=='' || data.meetingHost=="NA") && data.zoomId=='' )
        {
          this.zoomUsageField =2;
          this.isRequired=true; 
          this.selectedRadio=2;
          this.zoomserviceService.meetinglink_input_radio_checked=2;
          this.popup_firing=false;
          this.meetinglinkfieldValue=data.meetingLink
        }
        else if(data.meetingLink =='' && (data.meetingHost =='' || data.meetingHost =="NA") && data.zoomId =='')
        {
          this.zoomUsageField =3;
          this.isRequired=false; 
          this.selectedRadio=3;
          this.zoomserviceService.meetinglink_input_radio_checked=3;
          this.popup_firing=false 
        }
        else if(!!data.meetingLink && !!data.meetingHost && !!data.zoomId && data.zoomUsage == 7) {
          this.zoomUsageField =7;
          this.select_zoom_usage=7;
          this.isRequired=false; 
          this.selectedRadio=7;
          this.zoomserviceService.meetinglink_input_radio_checked=7;
          this.popup_firing=false; 
        }

      }
      else if(data.zoomUsage == 1){this.zoomserviceService.meetinglink_input_radio_checked==1}
      else if(data.zoomUsage == 2){this.zoomserviceService.meetinglink_input_radio_checked==2}
      else if(data.zoomUsage == 3){this.zoomserviceService.meetinglink_input_radio_checked==3}
      console.log(this.zoomserviceService.meetinglink_input_radio_checked)
      if (this.dataStorage.InterviewType == 'RESKILL') {
        
        // this.interviewtimedataforedit = new Date(this.data.interviewTimeStamp);
        this.interviewtimedataforedit = new Date(this.data.interviewTimeStamp)//"2023-01-26T04:59:06.000Z"
  
        console.log("this.interviewtimedataforedit",this.interviewtimedataforedit)
        if (this.data.skills.length > 0) {
          this.data.technologies = this.GetSkillIdfromSkills();
        }
      } else {
        this.interviewtimedataforedit = new Date(this.data.interviewDateAndTime);
        this.data.technologies = this.data.interTechnIds.map(i => Number(i));
      }
console.log('Data: ', this.data, this.dataStorage.InterviewType, this);
      if (this.dataStorage.InterviewType != 'LOCAL') {
        if (this.data.resumePath != null) {
          this.fileName = this.data.resumePath.substring(this.data.resumePath.lastIndexOf('/') + 1);
          resumegetback = this.data.resumePath;
          if(this.router.url.includes('hiring-id')) {
            let resume = !!this.data.resumePath ? this.data.resumePath : this.data.resumeForFindProfile;
            this.fileName = resume.substring(resume.lastIndexOf('/') + 1);
            resumegetback = resume;
          }
        }
      } else {
        if (this.data.resumeForFindProfile != null && this.data.resumeForFindProfile != "") {
          this.fileName = this.data.resumeForFindProfile.substring(this.data.resumeForFindProfile.lastIndexOf('/') + 1);
          resumegetback = this.data.resumeForFindProfile;
        }
      }


  
    }

    let mentorId = 0;
    if (this.data.mentorId != 0) {
      mentorId = this.data.mentorId
    }
  
    this.meetingLink=this.meetLink
    // if(!!data.meetingLink && data.meetingHost=='' && data.zoomId=='' ){
    //   let meetLink:any = document.getElementById('meetingLink')
    //  meetLink.value=this.data.meetinglink }

    this.minDate = this.addDays(new Date(), 0);
    this.maxDate = this.addDays(new Date(), 90);
    // zoom_id
    console.log("this.data deepu",this.data)
    this.interviewForm = this.fb.group({
      'enterpriseUserId': [JSON.parse(localStorage.userData).enterpriseUserId],
      'interviewId': [this.data.interviewId],
      'newMentorId': [mentorId],
      'interviewNewDateTime': [this.interviewtimedataforedit],
      'resume': [''],
      'inlineRadioOptions': [''],
      'resumePath': [resumegetback],
      'skills': [this.data.technologies, Validators.required],
      'interviewType': [this.dataStorage.InterviewType],
      // 'meetingLink':[this.meetingLink,[Validators.required,Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.Metting_link)]],
      'meetingLink':[this.data.meetingLink, Validators.compose([Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.Metting_link)])], 
      'zoomId':[this.data.zoomId],
      'meetingHost':[this.data.meetingHost],
      'zoomUsage':[this.data.zoomUsage]
    });
    this.zoom_select = this.data.zoomUsage;
    /*
   
    this.interviewForm.controls['zoomId']
    this.interviewForm.controls['meetingHost']
    this.interviewForm.controls['zoomUsage']
    payload['zoomId']=this.zoomdata.id
    payload['meetingHost']=this.zoomdata.host_email
    payload['meetingLink']=this.zoomdata.join_url
    */
    this.interviewForm.controls['interviewNewDateTime'].valueChanges.subscribe((value) => {
      this.getDateAndTime();
    });

    this.interviewForm.controls['newMentorId'].valueChanges.subscribe((value) => {
      this.getDateAndTime();
    });

    if (this.fileName != '' && this.dataStorage.InterviewType == 'LOCAL') {
      this.interviewForm.controls['resume'].disable();
    }


    
  }
  default_date = 'null'
  fixed_date
  ngOnInit() {
    this.scheduleInterviewService.showMeetingLinksSectionOrNot(); 
    
   this.zoomserviceService.getCompanyName()
    this.owl_date_time = document.getElementById('owldatetime')
    console.log(this.interviewtimedataforedit)
    this.interviewForm.controls['interviewNewDateTime'].valueChanges.subscribe((value) => {
        console.log(value)
        this.default_date = value
        // this.interviewformupdatedate.interviewNewDateTime
      });
    // this.interviewtimedataforedit = "1/17/2023, 10:24 AM"
    this.interviewForm.valueChanges.subscribe((data)=>{
      this.interviewformupdatedate = data
      // this.default_date = this.interviewformupdatedate.interviewNewDateTime
      console.log(this.interviewformupdatedate,'data');
      this.isFormValueChanged = true;
    } , (error)=>{
      this.isFormValueChanged = false;
    })
  //priya
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.enterpriseUserId = this.userData.enterpriseUserId;

    if (this.dataStorage.globalSkillsAndJobTitles.length == 0) {
      this.homeService.getMasterSkillsDesignationTools().subscribe(success => {
        if (!!success) {
          this.dataStorage.globalSkills = JSON.parse(JSON.stringify(success.technologyList));
          this.skills = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));
          this.dataStorage.globalSkillsAndJobTitles = JSON.parse(JSON.stringify(this.dataStorage.globalSkills))
        }
      }, error => {
        this.myAppService.showAlertMessage(error.message);
      });
    }
    else {
      this.skills = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));
  

    }
    // this.owl_date_time.style.backgroundColor = 'red'
    // .date.value = "2022-01-01"
  }

  inlineRadio1_m()
  {
    let e:any = document.getElementById('inlineRadio1')
    e.checked = true
    this.zoomserviceService.meetinglink_input_radio_checked = 1
    console.log(this.zoomserviceService.meetinglink_input_radio_checked,e.value)
  }

 convertDateFormat(date) {
    let [day, month, dateNum, year, startTime, endTime, timezone] = date.split(" ");
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    month = months.indexOf(month) + 1;
    let time = startTime;
    let [startHour, startMinute, startPeriod] = startTime.split(":");
    let [endHour, endMinute, endPeriod] = endTime.split(":");
    if(startPeriod === "PM") startHour = (parseInt(startHour) + 12).toString();
    if(endPeriod === "PM") endHour = (parseInt(endHour) + 12).toString();
    if(month < 10) month = "0" + month;
    if(dateNum < 10) dateNum = "0" + dateNum;
    if(startHour < 10) startHour = "0" + startHour;
    if(startMinute < 10) startMinute = "0" + startMinute;
    if(endHour < 10) endHour = "0" + endHour;
    if(endMinute < 10) endMinute = "0" + endMinute;
    // let newDate = year + "-" + month + "-" + dateNum + "T" + startHour + ":" + startMinute + ":" + endMinute + ".000Z";
    let newDate = year + "-" + month + "-" + dateNum + "T" + startHour + ":" + startMinute + ":" + endMinute + ".000Z";
    let op = new Date(newDate)//.toString();
    return op.toISOString();
}


  addDays(date: any, days: number): Date {
    if (!days) return date;

    date.setDate(date.getDate() + days);

    return date;
  };


  getDateAndTime() {
    let intDate = new Date(this.interviewForm.controls['interviewNewDateTime'].value);
    console.log('Selected dates: ', intDate, this.minDate, this.maxDate);
    if (this.interviewForm.controls['interviewNewDateTime'].value) {
      if (intDate < this.minDate) {
        this.dtError = true;
        this.dtErrortype = 1;
      } else if (intDate > this.maxDate) {
        this.dtError = true;
        this.dtErrortype = 2;
      } else if(intDate.getHours() < 6 || intDate.getHours() > 22) {
        this.dtError = true;
        this.dtErrortype = 3
      } else {
        this.dtError = false;
        this.dtErrortype = 0;
      }
     
    }
  }
  CopyNum(value){
    let linkCopy =value ;
    this._clipboardService.copyFromContent(linkCopy);
    this.myAppService.showAlertMessage('Mobile Number Copied');
    
  }

  GetInterviewersAddedByEnterprise() {
    var requestData = {
      enterpriseUserId: JSON.parse(localStorage.userData).enterpriseUserId,
      searchBy: ''
    }
    this.scheduleInterviewService.getAllMentorsAddedByEnterprise(requestData).subscribe(response => {
      if (response) {
        if (response.mentorList && response.mentorList.length > 0) {
          this.mentorslist = response.mentorList;
          if (this.data && this.data.mentorId && this.data.mentorId != 0) {
           
            for (let i = 0; i < this.mentorslist.length; i++) {
              if (this.mentorslist[i].userId == this.data.mentorId) {
                this.matcheditem = true;
                break;
              } else {
                this.matcheditem = false;
              }
            }
            // this.pushData();
          }
        }
        this.pushData();
      }
    })
  }

  pushData() {
    if (this.matcheditem == false) {
      let name1 = "";
      if (this.data.interviewerName == "") {
        name1 = "NA"
      } else {
        name1 = this.data.interviewerName;
      }
      let objnew = {
        userId: this.data.mentorId,
        name: name1
      }

      this.mentorslist.push(objnew);
    }
  }



  GetSkillIdfromSkills() {
  
    let skillIds = [];
   
    for (let i = 0; i < this.data.skills.length; i++) {
      skillIds.push(this.data.skills[i].id);
    }
    return skillIds;
  }

  interviewformupdatedate:any
 


 

  closedialog(data,type) {
    console.log(data,type)
    let responsesending = {
      success: false,
      responsedata: null
    }
    // this.delete_zoom(data,{})
    this.dataStorage.updateInterviewDialog.close(responsesending);
  }

  getFile(event) {
 
    this.fileName = '';
    this.resume = event.target.files;
    let name = '';
    let fileExt = '';
    let size = 0;
    let mobile: any;
    if (!!this.resume) {
      name = this.resume[0].name;
      fileExt = name.split('.').pop();
      size = this.resume[0].size;
      console.log(size)

    }
    if (this.resume === undefined) {
      this.uploadresume = true;
      this.interviewForm.controls['resume'].reset();

    } else if (this.resume.length == 0) {
      this.uploadresume = true;
      this.interviewForm.controls['resume'].reset();
      this.myAppService.showAlertMessage("File not selected.");

    } else if (size == 0) {
      this.uploadresume = true;
      this.interviewForm.controls['resume'].reset();
      this.myAppService.showAlertMessage("File not supported, Please select another file.");

    } else if (fileExt.toLowerCase() != "pdf" && fileExt.toLowerCase() != "docx" && fileExt.toLowerCase() != "doc") {
      this.uploadresume = true;
      this.interviewForm.controls['resume'].reset();
      this.myAppService.showAlertMessage("Please select only pdf or word file.");

    } else if (size > 2097152) {
      this.uploadresume = true;
      this.interviewForm.controls['resume'].reset();
     // this.myAppService.showAlertMessage("Please select file less than 2 MB.");

    }
   
    
    else {
      this.uploadresume = false;
      this.fileName = name;
      let fileData = new FormData();
      let fileName: string = '';

      if (this.resume.length > 0) {
        fileName = mobile + '_' + this.resume[0].name;
        fileData.append("file", this.resume[0], fileName);
      }

      let xhr = new XMLHttpRequest();
      let apiUrl = environment.url + MyAppHttpService.REQUESTS.uploadResumeForApplyJob + '/' + this.enterpriseUserId;
      

      var accessToken = localStorage.getItem('token');

      xhr.open('POST', apiUrl, true);
      xhr.setRequestHeader("token", accessToken);
      xhr.onload = () => {
        if (xhr.status == 200) {
          let response = JSON.parse(xhr.response);
       
          this.resumePath = response.uploadedURL;
        }
      }

      xhr.send(fileData);
    }
  }

  convertDateAndTimeToCustomFormat(d) {
    let date = new Date(d),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      hours = ("0" + date.getHours()).slice(-2),
      mins = ("0" + date.getMinutes()).slice(-2);
    return ([date.getFullYear(), month, day].join("-") + ' ' + [hours, mins].join(':') + ':00');
  }

  test(data,p)
  {
    console.log("present date ", p) 
    console.log("previous date ", data.interviewTimeStamp)
    if(p!=data.interviewTimeStamp)
    return false
  }
  zoomdata:any
  changing_dates="previousdate"
  index:number =0
  // default_date = 'dd-MMM-yyyy'//'medium'
 
  date_now_(myDate)
  {
      return new DatePipe('en-US').transform(myDate, 'medium');//'dd-MMM-yyyy'
  }

  allow:boolean = false
  allow_meeting_link:any

  UpdateInterview(data)  {
    console.log('Data in update interview method: ', this.zoomserviceService.meetinglink_input_radio_checked, this.zoom_select, this.selectedRadio, data)
    this.zoomserviceService.meetinglink_input_radio_checked == 3? this.onSelectingNone(this.data):  this.selectedRadio
    console.log( this.selectedRadio)
    data.zoomUsage = this.zoomserviceService.meetinglink_input_radio_checked
    //  this.allow_meeting_link = this.interviewformupdatedate.meetingLink
    // //  document.getElementById('meetingLink')
    // //  this.interviewForm.controls['meetingLink'].valueChanges.subscribe((value) => {allow=value;alert(allow)})
    //  if(this.selectedRadio == 1){this.allow=false}
    //  else if(this.selectedRadio == 2){this.allow = this.allow_meeting_link == ''}
    // //  {this.allow = allow.value.length }
    //  else if(this.selectedRadio == 3){this.allow=false}
     console.log(this.allow)
    //  if(this.zoomserviceService.meetinglink_input_radio_checked==2 && this.interviewformupdatedate.meetingLink=='')
    //  { 
    //   this.myAppService.showAlertMessage("Please enter meetinglink"); this.allow=false}
    //  else
    //  {
      console.log(this.uploadresume == true)
      if(this.uploadresume == true)
      {
        console.log('if')
        console.log('Please Select less than 2mb')
      }
      else{
        let requestInfo = this.interviewForm.value;
        console.log('else')
        if(this.isFormValueChanged){
          console.log(this.isFormValueChanged)
          // this.create_Update_delete_Interview(data,requestInfo)
        }
        else {
          //this.myAppService.showAlertMessage("please select interview.");
        }
        console.log(this.dtError == false)
        if (this.dtError == false) {
          console.log(this.interviewForm.value)
          console.log((requestInfo.skills.length == 0),(this.interviewForm.invalid))
          if ((requestInfo.skills.length == 0) || (this.interviewForm.invalid)) {
            this.submitted = true;
            if(this.interviewForm.controls['interviewNewDateTime'].value < this.todayDate){
                  this.pastDate=true
            }
          } else {
            this.submitted = false;
            this.pastDate=false
            let skills:any = []
            let length = this.interviewForm.value.skills
            for(let i=0; i<length.length;i++)
            {
              if(!skills.includes(length[i]))
              {
                skills.push(length[i])
              }
            }
            requestInfo.skills = skills
            setTimeout(() => {                             //trail
              // this.UpdateInterviewNew(requestInfo);
              if(this.isFormValueChanged){
                console.log('Request Data for update zoom: ', data, requestInfo, this.zoom_select);
                this.create_Update_delete_Interview(data,requestInfo)

              }
                
              }, 1000);
          }
        } else {
          if (this.dtErrortype == 1) {
            this.myAppService.showAlertMessage("Hi, You cannot schedule interview for previous date and time.");
          } else if (this.dtErrortype == 2) {
            this.myAppService.showAlertMessage("Hi, Not more than 90 days.");
          }
          else if (this.dtErrortype == 3) {
            this.myAppService.showAlertMessage("You cannot reschedule interview between 10:00 PM and 06:00 AM.");
          }
        }
      }
    //  }
      
      
  
  }
  input_radio_checked(val,type)
  {
    return type==1?val:false
  }  
    date_changes(){return this.default_date=='null'?false:true}

  makewait = 'wait' 
  makewaitpromise = 'null' 
  co:number=0
  payload:any
async create_Update_delete_Interview(data,requestInfo)
  {
    
    console.log("selected raido button ", this.selectedRadio)
    // if(!!data.meetingLink && !!data.meetingHost && !!data.zoomId){ this.interviewForm.controls['zoomUsage'].setValue(1)}
    // else if(!!data.meetingLink && data.meetingHost=='' && data.zoomId=='' ){ this.interviewForm.controls['zoomUsage'].setValue(2)}
    // else if(data.meetingLink =='' && data.meetingHost =='' && data.zoomId ==''){ this.interviewForm.controls['zoomUsage'].setValue(3)}
    this.interviewForm.controls['zoomUsage'].setValue(this.zoomserviceService.meetinglink_input_radio_checked)
    if(this.default_date != 'null')
    {
      data['commondate']=this.interviewformupdatedate.interviewNewDateTime
    }
    else{
      data['commondate'] = new Date(this.interviewtimedataforedit)
    }
    data.fullname = data.fullName//this.zoomserviceService.fullname
    // data.skills=data.
    // alert(this.selectedRadio)
   if(this.zoomserviceService.meetinglink_input_radio_checked==1)
   {
    // this.payload = data 
    //data.zoomId = 668
    if(data.zoomId == 669){
        this.dialogRef = this.dialogService.open(CommonConfirmModalComponent, {
          data: {
            // heading:'Something went Wrong',
            message:"This interview has already scheduled with a Zoom meeting. Are you sure you want to switch to another way?",
            // message:"Oh Oh! Sorry, our host is not available at your selected Date and Time.", 
            // message1:"Don't worry, our Team will share you the meeting link shortly by clicking continue or you can add your meeting link by going back.",
            btn1:'Cancel',
            btn2:'Continue',
          }
        });
    
        this.dialogRef.afterClosed().subscribe(value =>{
          console.log(value)
          if(value){
            this.UpdateInterviewNew(requestInfo)
          }
         
        })
      
    }
    else if(data.zoomId != null && !!data.zoomId && data.zoomId != 668)
    {
      // this.interviewForm.controls['interviewNewDateTime'].valueChanges.subscribe((value) => {
      //   console.log(value)
      // });
      console.log("this.date_changes()",this.date_changes())
      if(this.date_changes())
      {
        console.log('**** updateZoomMeeting(-) :: start ****')
       //this.zoomserviceService.test(data,2)
        this.update_zoom(data,requestInfo)
      }
      else{this.UpdateInterviewNew(requestInfo)}
      // this.updateInterviewAll()
      
     
    }
    else if(data.zoomId == 999)
    {
      console.log('**** createZoomMeeting(-) :: start ****')
      //this.zoomserviceService.test(data,1)
      this.create_zoom(data,requestInfo)
      
    }
    else{
      // if(this.date_changes())
      // {
        console.log('**** createZoomMeeting(-) :: start ****')
        //this.zoomserviceService.test(data,1)
        this.create_zoom(data,requestInfo)
        
      // }else{this.UpdateInterviewNew(requestInfo)}
    }
   } 
   else //this.selectedRadio == 2 || this.selectedRadio == 3
   {
    if(data.zoomId != null && !!data.zoomId && data.zoomId != 668 && data.zoomUsage != 7)
    {
      console.log('**** deleteZoomMeeting(-) :: start ****')
      if(this.zoomserviceService.confirm_popup_returns)
      {
        //this.zoomserviceService.test(data,3)
        this.delete_zoom(data,requestInfo)
      }
      // else{
      //   // this.meetinglink_input_radio_checked==1
      // }
    }
    else{
      this.UpdateInterviewNew(requestInfo)
    }
    // this.updateInterviewAll()
    // this.UpdateInterviewNew(requestInfo)
   }
  

  }

  async keepwaiting()
  {
      for(let i=0;i<=10;i++)
     {
      await  new Promise(resolve =>
        setTimeout(() => {
          resolve(i)
          console.log(i)
        }, 1000)
      );
     }
  }

  nohost:number=0
  create_zoom(data,requestInfo)
  {
    this.zoomUsageField =1;
    this.zoomserviceService.createZoomMeeting(data)
    .subscribe((r) =>{
      this.create_update=1
      this.nohost=r.id
      console.log("this.zoomdata",r,this.nohost,this.nohost==668)
      if(!!r && r!=null && r != undefined)
      {
        requestInfo.zoomId = r.id==668?668:r.id
        requestInfo.meetingHost = r.id==668?'':r.host_email
        requestInfo.zoomUsage = 1
        this.zoomserviceService.meetinglink_input_radio_checked=1
        requestInfo.meetingLink = r.id==668?'':r.join_url
        //r.id=668
        if(r.id==668)
      {
        this.dialogRef = this.dialogService.open(CommonConfirmModalComponent, {
          data: {
            // heading:'Something went Wrong',
           // message:"This interview has already scheduled with a Zoom meeting. Are you sure you want to switch to another way?",
             message:"Oh Oh! Sorry, our host is not available at your selected Date and Time.", 
             message1:"Don't worry, our Team will share you the meeting link shortly by clicking continue or you can add your meeting link by going back.",
            btn1:'Cancel',
            btn2:'Continue',
          }
        });
    
        this.dialogRef.afterClosed().subscribe(value =>{
          console.log(value)
          if(value)
          {
            // this.meetingLink = r.join_url
      console.log("this.zoomdata ",this.zoomdata)
      // this.updateInterviewAll()
      this.makewaitpromise = 'promise'
      
      this.UpdateInterviewNew(requestInfo)
          }
         
        })
        }else{this.UpdateInterviewNew(requestInfo)}
      }
    })
  }
  update_zoom(data,requestInfo)
  {
    this.zoomUsageField =2;
    console.log('Update zoom payload: ', this.payload,data,this.data)
    data = this.data
    this.zoomserviceService.updateZoomMeeting(data).subscribe((r) =>{
      console.log('r:', r );
      this.create_update=2
      this.nohost=r.id
      console.log(r,this.nohost)
      if(!!r && r!=null && r!=undefined)
      {
        this.zoomdata = r
        console.log("this.zoomdata ",this.zoomdata,r.id==668)
        // this.interviewForm.controls['zoomId'].setValue(r.id)
        // this.interviewForm.controls['meetingHost'].setValue(r.host_email)
        //   this.interviewForm.controls['zoomUsage'].setValue(2)
        //   this.interviewForm.controls['meetingLink'].setValue(r.join_url)
        requestInfo.zoomId = r.id==668?668:r.id
        requestInfo.meetingHost = r.id==668?'':r.host_email
        requestInfo.zoomUsage = 2
        // this.zoomserviceService.meetinglink_input_radio_checked=2
        requestInfo.meetingLink = r.id==668?'':r.join_url
        //r.id = 668
        if(r.id==668)
        {
          this.dialogRef = this.dialogService.open(CommonConfirmModalComponent, {
            data: {
              // heading:'Something went Wrong',
             // message:"This interview has already scheduled with a Zoom meeting. Are you sure you want to switch to another way?",
              message:"Oh Oh! Sorry, our host is not available at your selected Date and Time.", 
              message1:"Don't worry, our Team will share you the meeting link shortly by clicking continue or you can add your meeting link by going back.",
              btn1:'Cancel',
              btn2:'Continue',
            }
          });
      
          this.dialogRef.afterClosed().subscribe(value =>{
            console.log(value)
            if(value)
            {
             // this.meetingLink = r.join_url
          console.log("this.zoomdata ",this.zoomdata)
          // clearTimeout(this.makewait)
          this.UpdateInterviewNew(requestInfo)
          // this.makewaitpromise = 'null'
          // this.makewait='stop'
            }
           
          })
        }else{
          this.UpdateInterviewNew(requestInfo)
        }
      }
    }) 
  }

  delete_zoom(data,requestInfo)
  {
    this.zoomUsageField =3;
    this.zoomserviceService.deleteZoomMeeting(data).subscribe((r) =>{
      this.zoomdata = r
      // this.interviewForm.controls['zoomId'].setValue('')
      // this.interviewForm.controls['meetingHost'].setValue('')
      // this.interviewForm.controls['zoomUsage'].setValue(3)
        requestInfo.zoomId = ''//r.id==668?'':r.id
        requestInfo.meetingHost = ''//r.id==668?'':r.host_email
        
       // this.zoomserviceService.meetinglink_input_radio_checked=3
        if(this.zoomserviceService.meetinglink_input_radio_checked==2){requestInfo.zoomUsage = 2;requestInfo.meetingLink = this.meetinglinkfieldValue}
        else if(this.zoomserviceService.meetinglink_input_radio_checked==3){requestInfo.zoomUsage = 3;requestInfo.meetingLink = ''}
        //r.id==668?'':r.join_url
      console.log("this.zoomdata ",this.zoomdata)
      this.UpdateInterviewNew(requestInfo)
      this.zoomserviceService.confirm_popup_returns = false
    })
  }
  // updateInterviewAll()
  // {
  //   console.log(this.interviewformupdatedate)
  //   let payload = this.interviewformupdatedate
  //   this.scheduleInterviewService.updateInterview(payload).subscribe((r)=>{
  //     console.log(r)
  //   })
  // }

 

  UpdateInterviewNew(requestInfo) {
   // let requestInfo = this.interviewForm.value;
    if (this.dataStorage.InterviewType == 'LOCAL') {
      delete requestInfo.interviewType;
    }
    delete requestInfo.resume;

    requestInfo.interviewNewDateTime = this.convertDateAndTimeToCustomFormat(requestInfo.interviewNewDateTime);


    if (this.resumePath != null && !!this.resumePath) {
      requestInfo.resumePath = this.resumePath;
    }
    //this.interviewForm.get('meetingLink').setValue(data.meetingLink)
    
    
    // requestInfo = this.interviewformupdatedate
    console.log("formupdate",this.interviewformupdatedate)
    
    // if(this.zoomserviceService.meetinglink_input_radio_checked==1 && this.nohost!=668){requestInfo.meetingLink = this.data.meetingLink}
    // this.interviewForm.controls['meetingHost'].setValue('')
    // delete requestInfo.inlineRadioOptions
    console.log(requestInfo.interviewNewDateTime)
    if(this.default_date != 'null')
    {
      requestInfo.interviewNewDateTime = this.zoomserviceService.availabledate(this.zoomserviceService.start_time(requestInfo.interviewNewDateTime))
    }
    else{
      //this.interviewtimedataforedit
      requestInfo.interviewNewDateTime = this.zoomserviceService.availabledate(this.zoomserviceService.start_time(new Date(this.interviewtimedataforedit)))
      
      //"2023-01-27 04:59:06"
      // this.zoomserviceService.availabledate(this.zoomserviceService.start_time(this.interviewtimedataforedit))
    }
    console.log(requestInfo.interviewNewDateTime)
    requestInfo.meetingLink = this.zoomserviceService.meetinglink_input_radio_checked==3?'':requestInfo.meetingLink
    // requestInfo.zoomUsage = this.zoomserviceService.meetinglink_input_radio_checked
    // this.data.zoomId = requestInfo.zoomId
    // this.data.meetingHost = requestInfo.meetingHost
    // this.data.zoomUsage = this.zoomUsageField
    // requestInfo.zoomUsage = this.zoomUsageField ==1? this.create_update:3

    if(this.zoom_select == 7 || this.zoom_select == 8 || this.zoom_select == 9) {
      requestInfo.zoomUsage = this.zoom_select;
    }
    else {
      requestInfo.zoomUsage = this.zoomUsageField ==1 ? this.create_update : 3;
    }
    console.log("requestInfo ",requestInfo, this.zoom_select)
    
    this.updateintservice.UpdateInterview(requestInfo).subscribe((response) => {
      let responsesending = {
        success: true,
        responsedata: response
      }
      if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
        this.data.zoomId = requestInfo.zoomId
        this.data.meetingHost = requestInfo.meetingHost
        this.data.zoomUsage = this.zoomUsageField ==1?  this.create_update:3
        this.data.meetingLink =  requestInfo.meetingLink
       // this.data.meetinglink =  requestInfo.meetingLink
        this.myAppService.showAlertMessage("Interview details updated successfully.");
        this.dataStorage.updateInterviewDialog.close(responsesending);
      }

    })
  }

 
/*
 {{!!data.meetingLink && !!data.meetingHost && !!data.zoomId}}@@
  {{data.meetingLink}}@:@{{data.meetingHost}}@:@{{data.zoomId}}
*/

confirm_popup(msg)
{
  this.dialogRef = this.dialogService.open(CommonConfirmModalComponent, {
    data: {
      message:msg
    }
  });

  this.dialogRef.afterClosed().subscribe(value =>{
    console.log(value)
    this.zoomserviceService.confirm_popup_returns = value
  return value
  })
}

create_update_MeetingLink(data){
  this.popup_firing = true
  this.inp_meetlink = false;
  this.selectedRadio = 1;
  this.isRequired=false;
  this.zoomserviceService.meetinglink_input_radio_checked=1
  console.log(this.selectedRadio)
  this.interviewForm.get('meetingLink').clearValidators();
    this.interviewForm.get('meetingLink').updateValueAndValidity();
    // this.interviewForm.patchValue({meetingLink:'newValue'});
    // Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.Metting_link)

}
  onSelectingGenerate(data){
    this.zoomserviceService.meetinglink_input_radio_checked=2
    this.selectedRadio =2;
    this.isRequired=true;
    console.log(this.selectedRadio)
    this.interviewForm.controls['meetingLink'].setValidators([Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.Metting_link)])
    this.interviewForm.controls['meetingLink'].updateValueAndValidity();
    // this.inp_meetlink = true;try{console.log(this.interviewForm.get('meetingLink').setValue(''))}catch(e){console.log(e)}
   
      if(this.popup_firing && !!data.meetingLink && !!data.meetingHost && !!data.zoomId)//(!!data.meetingLink && !!data.meetingHost && !!data.zoomId)
      {
        this.dialogRef = this.dialogService.open(CommonConfirmModalComponent, {
          data: {
            //message:'Already RI Zoom meeting is available are you sure want to delete RI meetinglink'
            message:'This interview has already scheduled with a Zoom meeting. Are you sure you want to switch to another way?'
          }
        });
    
        this.dialogRef.afterClosed().subscribe(value =>{
          console.log(value)
          this.zoomserviceService.confirm_popup_returns = value
        if(!value&&this.zoomserviceService.meetinglink_input_radio_checked!=1){this.zoomserviceService.meetinglink_input_radio_checked=1;this.isRequired=false;
          this.interviewForm.get('meetingLink').clearValidators();
          this.interviewForm.get('meetingLink').updateValueAndValidity();}
        else{this.inp_meetlink = true;this.popup_firing=false;try{console.log(this.interviewForm.get('meetingLink').setValue(''))}catch(e){console.log(e)}this.isRequired=true;}
        })
      }

  }
    

  onSelectingNone(data){

    this.isRequired=false;
    console.log(JSON.parse(localStorage.getItem('userData')).enterpriseUserId)
    this.zoomserviceService.getCompanyName()
    console.log(data)
    this.zoomserviceService.meetinglink_input_radio_checked=3
    this.selectedRadio = 3;
    console.log(this.selectedRadio)
    this.inp_meetlink = false
    this.interviewForm.get('meetingLink').clearValidators();
    this.interviewForm.get('meetingLink').updateValueAndValidity();
    // if(!!data.meetingLink && !!data.meetingHost && !!data.zoomId){
    //   let val:any = this.confirm_popup('Already RI Zoom meeting is available are you sure want to delete RI meetinglink')
    //   if(!val&&this.zoomserviceService.meetinglink_input_radio_checked!=1){this.zoomserviceService.meetinglink_input_radio_checked=1}
    // }
    if(this.popup_firing && !!data.meetingLink && !!data.meetingHost && !!data.zoomId)//(!!data.meetingLink && !!data.meetingHost && !!data.zoomId)
    {
      this.dialogRef = this.dialogService.open(CommonConfirmModalComponent, {
        data: {
          //message:'Already RI Zoom meeting is available are you sure want to delete RI meetinglink'
          message:'This interview has already scheduled with a Zoom meeting. Are you sure you want to switch to another way?'
        }
      });
  
      this.dialogRef.afterClosed().subscribe(value =>{
        console.log(value)
        this.zoomserviceService.confirm_popup_returns = value
      if(!value&&this.zoomserviceService.meetinglink_input_radio_checked!=1){this.zoomserviceService.meetinglink_input_radio_checked=1;}
      else{this.popup_firing=false;this.interviewForm.controls['meetingLink'].setValue('')}
      
      })
    }
    
  }
  interviewTimeNew

  scheduleUsingWhereBy() {
    this.zoom_select = 7;
    this.meetingLink = 'whereBy'
    this.interviewForm.controls['meetingLink'].setValue(this.meetingLink);
  }

  setMeetingOptions() {
    setTimeout(() => {
      this.meetingStatuses = this.scheduleInterviewService.meetingStatuses;
      this.showMeetingLinksSection = this.scheduleInterviewService.showMeetingLinksSectionValue;
      this.showWhereByOption = this.scheduleInterviewService.showWhereByOption;
      this.showZoomOption = this.scheduleInterviewService.showZoomOption;
      this.showAddYourLinkOption = this.scheduleInterviewService.showAddYourLinkOption;
      this.showNoneOption = this.scheduleInterviewService.showNoneOption;
      console.log('Meeting status value: ', this.meetingStatuses, this.showMeetingLinksSection);
      // this.Tab3active = true;
      // this.Tab1active = false;
    }, 2000);
  }
  

}
