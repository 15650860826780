import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { DataStorageService } from 'src/app/services/data-storage.service';

@Component({
  selector: 'app-jobshare-confirmation',
  templateUrl: './jobshare-confirmation.component.html',
  styleUrls: ['./jobshare-confirmation.component.scss']
})
export class JobshareConfirmationComponent implements OnInit {

  companies: FormControl;
  agencies: FormControl;
  modalClosedFromCode: boolean;

  constructor( public dialogRef: MatDialogRef<JobshareConfirmationComponent>, public dataStorage: DataStorageService) {
    this.companies = new FormControl(true);
    this.agencies = new FormControl(true);
   }

  ngOnInit() {
    this.dataStorage.jobShareRoles = [];
  }

  closeModal(option){
    // console.log('Form values : ', this.companies.value, this.agencies.value);
    if(!!option){
      if(option == 'continue'){
        if(this.companies.value){
          this.dataStorage.jobShareRoles.push(2);
        }
        if(this.agencies.value){
          this.dataStorage.jobShareRoles.push(1);
        }
    }
    else if (option == 'skip'){
      this.dataStorage.jobShareRoles = [];
    }
    this.modalClosedFromCode = true;
    }
   
    // console.log('New value : ', this.dataStorage.jobShareRoles);
    this.dialogRef.close(this.modalClosedFromCode);

  }
}
