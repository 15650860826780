import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { WebsocketService } from 'src/app/ws/websocket.service';
import { Router } from '@angular/router';
import { DataStorageService } from 'src/app/services/data-storage.service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
   private wsService: WebsocketService, public router: Router, public dataStorage: DataStorageService ) { 
    //  console.log('user Data : ', data);
   }

  ngOnInit() {
  }

  submit(value){
    let message: any, status : any;
    if(value){
      let userId, userName;

      if (JSON.parse(localStorage.getItem('userData'))) {
         userId = JSON.parse(localStorage.getItem('userData')).enterpriseUserId;
         userName = this.data.userName
      }
      else{
        if(!JSON.parse(localStorage.getItem('userData'))) {
          userId = this.dataStorage.globalGuestUserProfileData.userId;
          userName = this.data.userName;
        }
      }

      if(this.data.condition == 'block'){
        status = 3;
        message = {
          data: {
            userId: userId, 
            userType: MyAppHttpService.chatUserTypes.RE, 
            userName: userName, 
            chatId: this.data.chatId,
            toUserId: this.data.toUserId, 
            toUserType: this.data.toUserType,
            toUserName: this.data.toUserName,
            inviteStatus: status,
          },
          message: MyAppHttpService.chatEventNames.INVITE_STATUS_UPDATE
        }
      }
      else{
        status = 5;
        message = {
          data: {
            userId: userId, 
            userType: MyAppHttpService.chatUserTypes.RE, 
            userName: userName,
            chatId: this.data.chatId,
            toUserId: this.data.toUserId, 
            toUserType: this.data.toUserType,
            toUserName: this.data.toUserName,
            inviteStatus: status,
          },
          message: MyAppHttpService.chatEventNames.INVITE_STATUS_UPDATE
        }        
      }

      console.log('message sending through socket for invite update', message);
  
      this.wsService.messages.next(message);

      this.dialogRef.close();
    }
    else{
      this.dialogRef.close();
    }
  }

}
