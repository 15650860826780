import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ForgotpasswordmodalComponent } from './forgotpasswordmodal.component';
import { AppMaterialModule } from "src/app/app-material.module";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  imports: [
      // RouterModule.forChild(RegisterRoutes),
      TranslateModule,
      AppMaterialModule,
      FormsModule, 
      ReactiveFormsModule,
      CommonModule,
      // LoginModalModule
  ],
  entryComponents:[ForgotpasswordmodalComponent],
  declarations: [ForgotpasswordmodalComponent],
  exports: [ForgotpasswordmodalComponent],
   
  schemas: [],
  providers:[]
})
export class ForgotpasswordmodalModule { }

