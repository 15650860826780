
import { Component, OnInit, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, NgForm } from '@angular/forms';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { FindProfileService } from './find-profile.service';
import { MyAppHttpService } from '../../../services/common/my-app-http.service';
import { AutoTestSearchFilterPipe } from '../../../pipes/autotest.search.filter';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../common/modal/login-dialog.service';
import { GlobaldataService } from 'src/app/services/common/globaldata.service';
import { DataStorageService } from '../../../services/data-storage.service';
import { TypeaheadSettings } from 'ngx-type-ahead';
import { ProfileInfoService } from '../../common/profile-info/profile-info.service';
import { HomeService } from '../home/home.service';
import { CommonfunctionService } from '../../../services/common/commonfunction.service';
import { element } from '@angular/core/src/render3';
import { PopoverContentComponent } from 'ngx-smart-popover';
import {Location} from '@angular/common';
import { ProfileInfoComponent } from '../../common/profile-info/profile-info.component';

@Component({
  selector: 'app-find-profile',
  templateUrl: './find-profile.component.html',
  styleUrls: ['./find-profile.component.scss'],
  providers: [AutoTestSearchFilterPipe],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FindProfileComponent implements OnInit {
  search:string = 'find-profiles'
  advFltr: boolean = false;
  booleanArray = [{ value: true, display: 'Yes' }, { value: false, display: 'No' }, { value: '', display: 'All' }];
  searchTypeArray = [{ value: true, display: 'Exact' }, { value: false, display: 'Broad' }];
  experiences: any[];
  noticePeriods: any[];
  rockRatings: any[];
  jobTypes: any[]
  expectedCTCs: any[];
  probabilityofJoinings: any[];
  lastUpdates: any[];
  freelancer: any;
  searchForm: FormGroup;
  searchData: any = {};
  profiles: ProfileElement[] = [];
  profilesCount: any;
  profileId;
  expandedData: ProfileElement[] = new Array();
  status;
  skillsTechDesignation1: any[];
  skillsTechDesignation2: any[];
  skillsTechDesignation: any[];
  mail = 'manish.k@rockinterview.in';
  isDefaultSearch: boolean = false;

  isTooltipSkills;

  selectedSkills: any = [];
  selectedTitles: any = [];
  selectedLocations: any = [];
  isToPerformDefaultSearch: boolean = false;

  locations: any = [];
  skills: any[];
  isDataLoaded: boolean = false;

  txtExperience: any;
  txtNoticePeriod: any;
  txtRockRatings: any;
  profileFilterCounts: any ={};

  customSettings: Partial<TypeaheadSettings> = {
    suggestionsLimit: Infinity
  };

  showHintBoxes: boolean = false;
  @ViewChild('skillsPopover') skillsPopover: PopoverContentComponent;
  @ViewChild('skills') ngSelectInput;
  @ViewChild('referenceViewing') profileinfo:any=ProfileInfoComponent;
  isFilterSearch: boolean = false;
  exactMatchRecords: any;
  isSearchEmpty: boolean = false;
  noProfilesFound: boolean = false;
  txtUpdates: any;
  txtExpectedCTC: any;

  fpFilters = [
    { id: 1, name: 'All Profiles', count: 2354, countColor: '#083aac', key: EnumResultCategory.ALL, countKey: 'all'},
    { id: 2, name: 'Exact Match', count: 2354, countColor: 'green', key: EnumResultCategory.EXACT, countKey: 'exact'},
    { id: 3, name: 'Rock Screened Profiles', count: 2354, countColor: '#99226e', key: EnumResultCategory.SCR, countKey: 'screened'},
    { id: 4, name: 'Non Screened Profiles', count: 2354, countColor: '#083aac', key: EnumResultCategory.NSCR, countKey: 'nonScreened'}
  ];
  selectedFilter: any = this.fpFilters[0];

  selectedSkills1: any = [];
  subSkills= [
    {id: 118, type: "Skill", name: "ABAP"},
    {id: 34, type: "Skill", name: "Advance Java"}
  ];


  constructor(private router: Router,
    private route: ActivatedRoute,
    private findProfileServie: FindProfileService,
    private myAppService: MyAppHttpService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private globaldataService: GlobaldataService,
    public dataStorage: DataStorageService,
    private profileInfoService: ProfileInfoService,
    private homeService: HomeService,
    private commonService: CommonfunctionService,
    private cdr: ChangeDetectorRef,
    private location: Location) {
    this.searchData = this.dataStorage.previousSearchCriteria;

    var tempMinDate, tempMaxDate;
    tempMinDate = new Date();
    tempMaxDate = new Date();
    tempMinDate.setDate(tempMinDate.getDate());
    tempMaxDate.setDate(tempMaxDate.getDate() + 90);

    this.locations = this.dataStorage.globalLocations;

  }

  validation_messages = {
    'jobTitle': [
      { type: 'required', message: 'PAGES.FIND_PROFILES.ERRORS.JOB_TITLE.REQUIRED.LABEL' },
    ],
    'proposedSalary': [
      { type: 'required', message: 'PAGES.FIND_PROFILES.ERRORS.PROPOSED_SALARY.REQUIRED.LABEL' },
    ],
    'joiningDate': [
      { type: 'required', message: 'PAGES.FIND_PROFILES.ERRORS.JOINING_DATE.REQUIRED.LABEL' }
    ]
  };

  ngOnInit() {

    this.searchForm = new FormBuilder().group({
      'skillsTechDesignation': '',//[this.skillsFromPreviousPage],
      'location': '',//[this.getLocationsDataForTypeAhead()],//[[{locationId: 1, locationName: "Bangalore"},{locationId: 2, locationName: "Chennai"}]], // this.getLocationsDataForTypeAhead(),//[this.locationsFromPreviousPage], //'',// [this.searchData.location],
      'preScreened': [(!!this.searchData ? this.searchData.preScreened : "")],
      'experiences': [(!!this.searchData ? (!!this.searchData.experiences ? this.searchData.experiences : "") : "")],
      'noticePeriods': [(!!this.searchData ? (!!this.searchData.noticePeriods ? this.searchData.noticePeriods : "") : "")],
      'rockRatings': [(!!this.searchData ? (!!this.searchData.rockRatings ? this.searchData.rockRatings : "") : "")],
      'jobTypes': [(!!this.searchData ? (!!this.searchData.jobTypes ? this.searchData.jobTypes : "") : "")],
      'expectedCTS': [(!!this.searchData ? (!!this.searchData.expectedCTS ? this.searchData.expectedCTS : "") : "")],
      'probabilityofJoining': [(!!this.searchData ? (!!this.searchData.probabilityofJoining ? this.searchData.probabilityofJoining : "") : "")],
      'trainedProfiles': [(!!this.searchData ? (!!this.searchData.trainedProfiles ? this.searchData.trainedProfiles : "") : "")],
      'exactSearch': [(!!this.searchData ? this.searchData.exactSearch : "")],
      'brandSearch': [(!!this.searchData ? (!!this.searchData.brandSearch ? this.searchData.brandSearch : "") : "")],
      'lastUpdates': [(!!this.searchData ? (!!this.searchData.lastUpdates ? this.searchData.lastUpdates : "") : "")],
      'freelancer': [(!!this.searchData ? (!!this.searchData.freelancer ? this.searchData.freelancer : false) : false)]
    });

    this.searchForm.controls['skillsTechDesignation'].valueChanges.subscribe((value) => {

    });

    this.searchForm.controls['location'].valueChanges.subscribe((value) => {
      this.selectedLocations = !!value ? value: [];
    });


    // this.findProfileServie.getMasterServices().subscribe(success => {
    this.homeService.getMasterServices().subscribe(success => {
      if (!!success) {
        this.experiences = success.experienceListDetails.experienceList;
        this.noticePeriods = success.noticePeriodListDetails.noticePeriodList;
        this.rockRatings = success.rockRatingListDetails.rockRatingList.sort((a, b) => (a.rockRatingId < b.rockRatingId) ? 1 : -1);
        this.jobTypes = success.jobTypeListDetails.jobTypeList;
        this.expectedCTCs = success.expectedCTCListDetails.expectedCTCList;
        this.probabilityofJoinings = success.pojListDetails.pojList;
        this.lastUpdates = success.lastUpdatedListDetails.lastUpdatedList;
      }
    }, error => {
      this.myAppService.showAlertMessage(error.message);
    });

    this.isToPerformDefaultSearch = true;




    if (this.dataStorage.globalLocations.length == 0) {
      this.findProfileServie.getMasterLocation().subscribe(success => {
        if (!!success) {
          this.locations = success.locationsList;
          this.dataStorage.globalLocations = this.locations;
          this.validateSkillAndLocationData();
        }
      }, error => {
        this.myAppService.showAlertMessage(error.message);
      });

    }

    if (this.dataStorage.globalSkillsAndJobTitles.length == 0) {
      this.homeService.getMasterSkillsDesignationTools().subscribe(success => {
        if (!!success) {
          this.dataStorage.globalSkills = JSON.parse(JSON.stringify(success.technologyList));
          this.skillsTechDesignation =  JSON.parse(JSON.stringify(this.dataStorage.globalSkills));
          this.dataStorage.globalSkillsAndJobTitles = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));


          this.validateSkillAndLocationData();

        }
      }, error => {
        this.myAppService.showAlertMessage(error.message);
      });
    }
    else {
      this.skillsTechDesignation = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));
   
      if (Object.keys(this.searchData).length > 0) {
        this.setSkillsData(this.searchData.skillsTechDesignation);
        this.setLocationsData();
      }
      this.validateSkillAndLocationData();
    }


    if(Object.keys(this.searchData).length > 0){
      var tempFilteredArray = [];
      tempFilteredArray = Object.values(this.searchData).filter(x=>x);



      if( tempFilteredArray.map(x=>x.length).filter(x=>x).length == 0){
        setTimeout(() => {
          document.getElementById('skills').focus();
          this.ngSelectInput.filterInput.nativeElement.focus();
          document.getElementById('btnSearch').click();
        }, 1000);

      }
      else{
        this.searchProfiles(true, EnumResultCategory.ALL, false);
      }
    }
    else{
      this.skillsPopover.show();
      this.isSearchEmpty = true;

          setTimeout(() => {
            document.getElementById('skills').focus();
            this.ngSelectInput.filterInput.nativeElement.focus();
            document.getElementById('btnSearch').click();
          }, 1000);

    }



  }

  setSkillsData(searchedSkills) {

    var skillsFromPreviousPage = this.skillsTechDesignation.filter(x => searchedSkills.indexOf(x.id) > -1).map(x => x.id);

    if(this.searchData && this.searchData.selectedSkills && this.searchData.selectedSkills.length > 0){
      this.selectedSkills = this.searchData.selectedSkills;

    }
    else{
      this.selectedSkills =  this.skillsTechDesignation.filter(x=> this.searchData.skillsTechDesignation.indexOf(x.id) > -1 );

      this.selectedSkills.forEach(x=> {
        if(x.keywords && !x.allKeywords){
          x.allKeywords = [];
          for(var i=0;i<x.keywords.length;i++){
            var temp ={id: 0, name: '', checked: false};
            temp.id = i+1;
            temp.name = x.keywords[i];
            temp.checked = false;
            x.allKeywords.push(temp);
          }
        }
        else{
        }
      });
    }

    this.searchForm.controls['skillsTechDesignation'].setValue(skillsFromPreviousPage);
  }
  setLocationsData() {

    if (!!this.locations && !!this.searchData) {
      if (this.searchData.location)
    

        var previouslySelectedLocations = (this.locations.filter(x => this.searchData.location.indexOf(x.locationId) > -1)).length > 0 ?
          (this.locations.filter(x => this.searchData.location.indexOf(x.locationId) > -1).map(x => x.locationId)) : '';
      this.searchForm.controls['location'].setValue(previouslySelectedLocations);
    }
    else {

    }

  }


  searchProfiles(isFilterSearch, resultCategory, isFilterClicked) {
    this.showHintBoxes = false;
    this.isDataLoaded = false;



    if (this.searchForm.valid) {
      this.searchForm.value.selectedSkills = JSON.parse(JSON.stringify( this.selectedSkills));
      this.dataStorage.previousSearchCriteria = this.searchForm.value;

      if (this.isToPerformDefaultSearch && Object.keys(this.searchData).length > 0) {
        this.selectedLocations = this.searchData.location;

      }
      this.isToPerformDefaultSearch = false;

   

     this.skillsPopover.hideFromPopover();
     this.isSearchEmpty = false;
      if(this.selectedSkills.length == 0){
       this.skillsPopover.show();
       this.isSearchEmpty = true;
      
       document.getElementById('skills').focus();
       this.ngSelectInput.filterInput.nativeElement.focus();

     }
     else {
      var requestData;
      if(!isFilterClicked){

        var skillsWithKeywords = JSON.parse(JSON.stringify(this.selectedSkills));

        for(var i=0;i<skillsWithKeywords.length; i++){
          skillsWithKeywords[i].keywords = skillsWithKeywords[i].allKeywords ?
          (skillsWithKeywords[i].allKeywords.filter(x=>x.checked).length > 0 ? skillsWithKeywords[i].allKeywords.filter(x=>x.checked).map(x=>x.name) : null)
          : null;
        }

        requestData = {
        "techSelectionList": skillsWithKeywords,
        "skills_Technology": this.selectedSkills.map(x=>x.id),
        "designation": this.selectedTitles,
        "jobLocation": this.selectedLocations ? this.selectedLocations: undefined,//this.location1,
        "isPreScreened": this.searchForm.controls.preScreened.value,
        "experiences": !!this.searchForm.controls.experiences.value ? this.searchForm.controls.experiences.value : [],
        "noticePeriod": !!this.searchForm.controls.noticePeriods.value ? this.searchForm.controls.noticePeriods.value : [],
        "rockRating": !!this.searchForm.controls.rockRatings.value ? this.searchForm.controls.rockRatings.value : [],
        "Categories": [],
        "probabilityOfJoining": !!this.searchForm.controls.probabilityofJoining.value ? this.searchForm.controls.probabilityofJoining.value : [],
        "jobTypes": !!this.searchForm.controls.jobTypes.value ? this.searchForm.controls.jobTypes.value : [],
        "expectedCTC": !!this.searchForm.controls.expectedCTS.value ? this.searchForm.controls.expectedCTS.value : [],
        "isTrainedProfile": this.searchForm.controls.trainedProfiles.value,
        "isExactSearch": this.searchForm.controls.exactSearch.value,
        "isBroadSearch": this.searchForm.controls.brandSearch.value,
        "lastUpdated": !!this.searchForm.controls.lastUpdates.value ? this.searchForm.controls.lastUpdates.value : [],
        "matchType": [],
        "resourceId": !!localStorage.userData ? JSON.parse(localStorage.userData).enterpriseUserId : 0,
        "pageFrom":0, "pageSize": this.dataStorage.paginationPageSize,
        "isFilterSearch": isFilterSearch,
        "searchTypeEnum": resultCategory,
        'freelancer': !!this.searchForm.controls.freelancer.value ? this.searchForm.controls.freelancer.value : null
      };
    }
    else{
      requestData = this.dataStorage.tempFindProfileSearchData;
      requestData.searchTypeEnum = resultCategory;
      requestData.isFilterSearch = false;
      requestData.pageFrom = 0;
      requestData.pageSize = this.dataStorage.paginationPageSize;
    }
      this.dataStorage.tempFindProfileSearchData = requestData;

      this.findProfileServie.searchProfiles(requestData).subscribe((success => {
        this.isDataLoaded = true;
        this.dataStorage.paginationIndex = 1;

        if (success.statuscode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
          if(success.profileInfo.length > 0) {
            success.profileInfo[0].profileDetails[0].resumeExist = !!success.profileInfo[0].profileDetails[0].resumeForFindProfile ? true : false;
            success.profileInfo[0].profileDetails[0].primarySkills = success.profileInfo[0].profileDetails[0].skillsExperienceAndRating.length != 0 ? success.profileInfo[0].profileDetails[0].skillsExperienceAndRating.filter((x: any) => x.type == '1') : [];
            success.profileInfo[0].profileDetails[0].secondarySkills = success.profileInfo[0].profileDetails[0].skillsExperienceAndRating.length != 0 ? success.profileInfo[0].profileDetails[0].skillsExperienceAndRating.filter((x: any) => x.type == '2' || x.type == '0') : [];
          }
          
          this.profiles = success.profileInfo;
          this.dataStorage.globalProfiles = this.profiles;

          document.getElementsByClassName('profile-left') &&
          document.getElementsByClassName('profile-left').length > 0 &&
          (document.getElementsByClassName('profile-left')[0].scrollTop = 0);

          if (this.profiles.length > 0) {
            this.noProfilesFound = false;
            this.isDefaultSearch = success.isDefaultSearch;
            this.noProfilesFound = false;
            this.profileId = this.profiles[0].profileId;

            if (this.profileId) {
              this.expandedData = [];
              this.dataStorage.globalExpandedData = [];

              var enterpriseUserId = 0;
              if (!!localStorage.userData) {
                enterpriseUserId = JSON.parse(localStorage.userData).enterpriseUserId;
              }
              var requestData = {
                "esId": [this.profiles[0].esId],
                "enterpriseUserId": enterpriseUserId,
                "jobId": 0
              };

              this.expandedData = (this.profiles[0].profileDetails);
              this.dataStorage.globalExpandedData = (this.profiles[0].profileDetails);

              if (this.commonService.validateFeatureAvailability(this.dataStorage.globalFeatureCodes.POJ, null, null, null)) {
                this.getProbabilityOfJoining(this.profiles[0].profileId);
              }

             
              this.status = this.profileId;
              this.mail = 'manish.k@rockinterview.in'
            }
            this.profilesCount = success.profileCount;
            this.exactMatchRecords = success.exactMatchRecords;

            if(isFilterSearch){
              this.profileFilterCounts =  success.countByCategory;
              if(this.selectedFilter.id != 1){
                this.onResultCategoryClick(this.selectedFilter);
              }
            }
          }
          else {
            this.profilesCount = 0;
            this.exactMatchRecords = 0;
            this.profileId = 0;
            this.expandedData = [];
            this.noProfilesFound = true;
            if(isFilterSearch){
              this.profileFilterCounts =  success.countByCategory;
              if(this.selectedFilter.id != 1){
                this.onResultCategoryClick(this.selectedFilter);
              }
            }
            this.exactMatchRecords.detectChanges();
          }


        }
        else if (success.statuscode == MyAppHttpService.RESPONSE_CODES.NOT_FOUND) {
          this.profiles = [];
          this.profilesCount = 0;
          this.exactMatchRecords = 0;
        }
        else{
          this.profiles = [];
          this.profilesCount = 0;
          this.expandedData = [];
          this.dataStorage.globalExpandedData = [];
          this.dataStorage.globalProfiles  = [];
          this.exactMatchRecords = 0;
        }
      }), error => {
        this.myAppService.showAlertMessage(error.message);
      });
    }
    } else if (!this.searchForm.valid) {
      this.myAppService.showAlertMessage('Please Enter Skills,Expected CTC,Notice Period and Experience');
    }
  }



  getProbabilityOfJoining(profileId) {

    if (true) {
      var tempProfileData = this.dataStorage.globalProfiles.find((profile) => { return profile.profileId == profileId });
      if (!!tempProfileData.probabiltyOfJoining) {
        return false;
      }
    }

    var loggedInUserId = !!localStorage.userData ? JSON.parse(localStorage.userData).enterpriseUserId : 0;
    var requestData = {
      "id": profileId,
      "searchKey": this.dataStorage.tempFindProfileSearchData.skills_Technology,
      "searchBy": loggedInUserId,
      "searchExp": this.dataStorage.tempFindProfileSearchData.experiences,
      "searchLocation": this.dataStorage.tempFindProfileSearchData.jobLocation,
      "searchDesignation": this.dataStorage.tempFindProfileSearchData.designation
    };
  



    this.profileInfoService.getProbabilityOfJoining(requestData).subscribe((response) => {
      if (response) {

        this.profileinfo.ReadMore=true;
        this.profileinfo.visible=false;
        this.dataStorage.globalProfiles.filter((profile) => {
          if (profile.profileId == profileId) {
            profile.probabiltyOfJoining = response.probabilityOfJoining;
            this.expandedData[0].probabiltyOfJoining = response.probabilityOfJoining;
            this.dataStorage.globalExpandedData[0].probabiltyOfJoining = response.probabilityOfJoining;
          }
        });
      }
    }, (error) => {

    });
  }

  advanceFilter() {
    if (this.advFltr === false) {
      this.advFltr = true;
      return this.advFltr;
    }
    else {
      this.advFltr = false;
      return this.advFltr;
    }
  }

  onlocationChange(selectedItems) {
    this.selectedLocations = selectedItems.map(x => x.locationId);
  }

  onSkillsTitleChange(values) {
    this.selectedSkills = values;
    this.selectedSkills.forEach(x=> {
      if(x.keywords && !x.allKeywords){
        x.allKeywords = [];
        for(var i=0;i<x.keywords.length;i++){
          var temp ={id: 0, name: '', checked: false};
          temp.id = i+1;
          temp.name = x.keywords[i];
          temp.checked = false;
          x.allKeywords.push(temp);
        }
      }
      else{
      }
    });
  }

  onResultCategoryClick(filter) {
    this.selectedFilter = filter;

    let filterKey = Object.keys(this.profileFilterCounts).filter(x => (x == this.selectedFilter.countKey));
    if(this.dataStorage.chatClicked){
      this.closeChatDialog();
    }
    if(this.profileFilterCounts[filterKey[0]] > 0){
    this.searchProfiles(false, filter.key, true);
    }
    else if(this.profileFilterCounts[filterKey[0]] == 0) {
      this.profiles = [];
      this.noProfilesFound = true;
    }

  }

  get enumResultCategory(){
    return EnumResultCategory;
  }

  closeChatDialog(){
    this.dataStorage.chatClicked = false;
    this.dataStorage.globalUserChatConversationList =[];
    this.cdr.detectChanges();
  }

  validateSkillAndLocationData(){
    if(
      (this.skillsTechDesignation && this.skillsTechDesignation.length > 0) &&
      (this.locations && this.locations.length > 0)
    ){
      this.route.queryParams.subscribe(params => {
        let s,l;
        if (params.skillIds) {
          s = params.skillIds.split(',').map(Number);
          this.searchData.skillsTechDesignation = s;
          this.setSkillsData(s);
        }
        if (params.locationIds) {
          l = params.locationIds.split(',').map(Number);
          this.searchData.location = l;
          this.setLocationsData();
        }

        if(params.selectedFilter){
          this.selectedFilter = this.fpFilters[params.selectedFilter];
        }
        setTimeout(() => {
          this.searchProfiles(true, EnumResultCategory.ALL, false);
          this.location.replaceState("/findprofile");
        }, 100);

      });
    }
  }
}


export interface ProfileElement {
  currentEmployer: any
  educationalDetails: any
  emailId: any
  experience: any
  isExactMatch: any
  isInFavourites: any
  noticePeriod: any
  probabiltyOfJoining: any,
  profileDetails: any,
  profileId: any
  profileName: any
  rating: any
  role: any
  salary: any
  userName: any
  show: boolean
  isProfileChecked: boolean,
  esId: any
}

 export enum EnumResultCategory{
  ALL = 'ALL', EXACT = 'EXACT', SCR = 'SCREENED', NSCR = 'NON_SCREENED'
}
