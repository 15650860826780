import { Component, OnInit,Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import {ThemePalette} from '@angular/material/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl, EmailValidator } from '@angular/forms';
import { HomeService } from '../home.service';
import { data } from 'jquery';
import { MyAppHttpService } from '../../../../services/common/my-app-http.service';
import { EMAIL_VALIDATOR } from '@angular/forms/src/directives/validators';

@Component({
  selector: 'app-reskilling',
  templateUrl: './reskilling.component.html',
  styleUrls: ['./reskilling.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReskillingComponent implements OnInit {

  name: string;
  completed: boolean;
  color: ThemePalette;

  submitError: boolean = false;
  isEmployeeAssessmentChecked:boolean=false;
  isUpSkillChecked:boolean=false;
  isMentoringChecked:boolean=false;
  enterpriseUserId:any;


  reskillForm: FormGroup;

  validation_messages = {

    'firstName': [
      { type: 'required', message: 'Please enter your First Name' },
      { type: 'pattern', message: 'Please enter a valid Name' },
      { type: 'minLength', message: 'Name minimum length should be greate than 0' },
      { type: 'maxLength', message: 'Name maximum length should not be less than 50 characters' }
    ],
    'lastName': [
      { type: 'required', message: 'Please enter your Last Name' },
      { type: 'pattern', message: 'Please enter a valid Last Name' },
      { type: 'minLength', message: 'Name minimum length should be greate than 0' },
      { type: 'maxLength', message: 'Name maximum length should not be less than 50 characters' }
    ],
    'email': [
      { type: 'required', message: 'Please enter your Email Address' },
      { type: 'pattern', message: 'Please enter a valid Email' },
      { type: 'minLength', message: 'Email minimum length should be greate than 0' },
      { type: 'maxLength', message: 'Email maximum length should not be less than 500 characters' }
    ],


  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>,
  private formBuilder: FormBuilder,public homeService: HomeService,public myAppHttp: MyAppHttpService,) {
    this.enterpriseUserId = this.data ? this.data.EnterpriseId : null;

    var userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')): null;
    this.reskillForm = this.formBuilder.group({
    
      firstName: [userData ? userData.firstName : '', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.NAME)])],
      lastName: [userData ? userData.lastName : '', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.NAME)])],
      email: [userData ? userData.email : '', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.EMAIL)])],
      isEmployeeAssessmentChecked: [data.selectedOption == 1 ? true : false],
      isUpSkillChecked: [data.selectedOption == 2 ? true : false],
      isMentoringChecked: [data.selectedOption == 3 ? true : false],
   });

    }

  ngOnInit() {

  }

    BookDemo(){
      if(this.reskillForm.controls['isEmployeeAssessmentChecked'].value == false &&
      this.reskillForm.controls['isUpSkillChecked'].value == false &&
      this.reskillForm.controls['isMentoringChecked'].value == false){
          this.myAppHttp.showAlertMessage("Please Select Atleast One Feature");
      }
      else{

      if(this.reskillForm.invalid)
      {
        this.submitError = true;
      }else{
      let datasending = this.reskillForm.value;
      this.submitError = false;
       let inputData = {
        firstName:datasending.firstName,
        lastName:datasending.lastName,
        email:datasending.email,
        isEmployeeAssessmentChecked:datasending.isEmployeeAssessmentChecked,
        isUpSkillChecked:datasending.isUpSkillChecked,
        isMentoringChecked:datasending.isMentoringChecked,
        enterpriseUserId:this.enterpriseUserId,
      }
     

      this.homeService.saveReskillingDetails(inputData).subscribe(response => {
        if(response.statusCode==200)
        {
          this.dialogRef.close();
          this.myAppHttp.showAlertMessage("Thanks for your interest. We will get back to you shortly.");
        }else{
          this.dialogRef.close();
          this.myAppHttp.showAlertMessage("Something went wrong, please try again.");
        }

      })
    }

  }

    }

}
