import { Component, OnInit, Inject, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { Router } from '@angular/router';
import { ScheduleMockinterviewComponent } from '../schedule-mockinterview/schedule-mockinterview.component';
import { environment } from 'src/environments/environment';
import { MyAppHttpService } from '../../../../services/common/my-app-http.service';
import { ScheduleMockinterviewService } from '../schedule-mockinterview/schedule-mockinterview.service';
declare var Razorpay: any;

@Component({
  selector: 'app-scheduleinterview-paymode',
  templateUrl: './scheduleinterview-paymode.component.html',
  styleUrls: ['./scheduleinterview-paymode.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScheduleinterviewPaymodeComponent implements OnInit {

  paymentModes;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dataStorage: DataStorageService,
  public scheduleInterviewService: ScheduleMockinterviewService,
  public myAppService: MyAppHttpService,
  public dialogRef: MatDialogRef<ScheduleinterviewPaymodeComponent>,
  public cdr: ChangeDetectorRef,
  public router: Router) {
  }

  ngOnInit() {
    this.paymentModes = MyAppHttpService.PAYMENT_MODES;
  }
  onCloseDialog(){
    this.dialogRef.close(false);
  }
  onPaymodeClick(paymode){
    this.dialogRef.close(paymode);
  }


}
