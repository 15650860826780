import { RouterModule } from "@angular/router";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HomeComponent } from "./home.component";
import { HomeRoutes } from "./home.route";
import { TranslateModule } from "@ngx-translate/core";
import { AppMaterialModule } from "../../../app-material.module";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AutoSearchFilterPipe } from "../../../pipes/auto.search.filter";
import { HomeService } from "./home.service";
import { CommonPipesModule } from "src/app/pipes/common-pipes/common-pipes.module";
import { TypeaheadModule } from "ngx-type-ahead";
import { MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DropDownListModule } from '@syncfusion/ej2-ng-dropdowns';
import { LoginModalModule } from "../../common/modal/login/login-modal/login-modal.module";
import { RegisterModalModule } from "../../common/modal/register/register-modal/register-modal.module";
import { PaymentModule } from "../../common/modal/payment/payment/payment.module";
import { ScheduleMockinterviewModule } from "../../common/modal/schedule-mockinterview/schedule-mockinterview.module";
import { ShareProfileModule } from "../../common/modal/share-profile/share-profile.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TourModalModule } from '../../common/modal/tour-modal/tour-modal.module';
import { TourModalComponent } from '../../common/modal/tour-modal/tour-modal.component';
import { ChatModalModule } from "../../common/modal/chat-modal/chat-modal.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { DownloadBrochureModule } from "../../common/modal/download-brochure/download-brochure.module";
import { ShowUserprofileModule } from "../../common/modal/show-userprofile/show-userprofile.module";
import { ConfirmModalModule } from "../../common/modal/confirm-modal/confirm-modal.module";
import { JobshareConfirmationModule } from "../../common/modal/jobshare-confirmation/jobshare-confirmation.module";
import { ScheduleinterviewConfirmationModule } from "../../common/modal/scheduleinterview-confirmation/scheduleinterview-confirmation.module";
import { VideoModalModule } from "../../common/modal/video-modal/video-modal.module";
import { JobinfoModalModule } from "../../common/modal/jobinfo-modal/jobinfo-modal.module";
import { JobPreviewModule } from "../../common/modal/job-preview/job-preview.module";
import { EmployerDetailsModule } from "../../common/modal/employer-details/employer-details.module";
import { ShareOpportunityModule } from "../../common/modal/share-opportunity/share-opportunity.module";
import { PricingConfirmationModule } from "../../common/modal/pricing-confirmation/pricing-confirmation.module";
import { ScheduleinterviewPaymodeModule } from "../../common/modal/scheduleinterview-paymode/scheduleinterview-paymode.module";
import { HomeTopSectionComponent } from "./home-top-section/home-top-section.component";
import { BuildTeamsRemotelyComponent } from './build-teams-remotely/build-teams-remotely.component';
import { ReskillingComponent } from './reskilling/reskilling.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { CommonConfirmModalModule } from "../../common/modal/common-confirm-modal/common-confirm-modal.module";
import { TrainingDetailsModule } from "../../common/modal/training-details/training-details.module";
import { AddinterviewerComponentModule } from "../../common/modal/addinterviewer/addinterviewer.module";
import { SearchFiltersComponent } from './search-filters/search-filters.component';
import { ResubscribeModule } from "../../common/modal/resubscribe/resubscribe.module";




@NgModule({
    imports: [
        RouterModule.forChild(HomeRoutes),
        TranslateModule,
        AppMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgxMatSelectSearchModule,
        CommonPipesModule,
        TypeaheadModule,
        MultiSelectAllModule,
        DropDownListModule,
        LoginModalModule,
        RegisterModalModule,
        PaymentModule,
        ScheduleMockinterviewModule,
        ShareProfileModule,
        NgbModule,
        TourModalModule,
        ChatModalModule,
        NgSelectModule,
        DownloadBrochureModule,
        ShowUserprofileModule,
        ConfirmModalModule,
        JobshareConfirmationModule,
        ScheduleinterviewConfirmationModule,
        ScheduleinterviewPaymodeModule,
        VideoModalModule,
        JobinfoModalModule,
        AddinterviewerComponentModule,
        JobPreviewModule,
        EmployerDetailsModule,
        ShareOpportunityModule,
        PricingConfirmationModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        CommonConfirmModalModule,
        TrainingDetailsModule,
        ResubscribeModule
    ],
    declarations: [HomeComponent, HomeTopSectionComponent, BuildTeamsRemotelyComponent, ReskillingComponent, SearchFiltersComponent ],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers:[HomeService],
    entryComponents: [TourModalComponent,BuildTeamsRemotelyComponent,ReskillingComponent, SearchFiltersComponent
    ]
})
export class HomeModule {

}
