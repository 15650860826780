import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatModalComponent } from './chat-modal.component';
import { AppMaterialModule } from 'src/app/app-material.module';
import { ChatModalService } from './chat-modal.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  declarations: [ChatModalComponent],
  exports: [ChatModalComponent],
  entryComponents: [ChatModalComponent],
  providers: [ChatModalService]
})
export class ChatModalModule { }
