import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { DataStorageService } from "../data-storage.service";


@Injectable()
export class AuthGuardService  implements CanActivate, CanActivateChild  {
    constructor(private router: Router, private dataStorageService:  DataStorageService){}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('Router url: ', this.router.url, route, state);
        //this.router.navigate(['/user']);
        // return true;
        let bool: boolean;
        let routeUrl = state.url;
        let isUserloggedIn = JSON.parse(localStorage.getItem('userData'));
        let token = localStorage.getItem('token');
        console.log('Is User logged in: ', this.dataStorageService.isUserLoggedIn, isUserloggedIn, this.router.url, token)
        if(!isUserloggedIn || token == 'anonymous') {
            bool = false;
            if(((routeUrl.indexOf('jobs') > -1) && (routeUrl.indexOf('jobId') > -1))) {
                bool = false;
                this.router.navigate(['jobdetails'], {
                    queryParams: {
                        jobId: route.queryParams.jobId
                    }
                })
            
                this.dataStorageService.isInJobDetailsPage = true;
                this.dataStorageService.activeUrl = state.url;
                this.dataStorageService.activeUrlJobData = {
                    path: route.routeConfig.path,
                    queryParams: {
                        tab: route.queryParams.tab,
                        jobId: route.queryParams.jobId
                    }
                }

            }
            else {
                this.router.navigate(['login']);
            }
        }
        else {
            bool = true;
        }
        return bool;
    }

    canActivateChild() {
        return true;
    }
} 