import { Component, OnInit, Inject, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DataStorageService } from 'src/app/services/data-storage.service';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoModalComponent implements OnInit {
  @ViewChild('videoPlayer') videoplayer: ElementRef<any>;
  title = 'playvideo';
  videoPath: any = 'http://techslides.com/demos/sample-videos/small.mp4';

  constructor(public dialogRef: MatDialogRef<VideoModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) 
  { }

  ngOnInit() {
  
    this.videoPath = this.data.videoPath;
    this.videoplayer.nativeElement.play();
  }

  
toggleVideo(event: any) {

  this.videoplayer.nativeElement.play();
}

}
