import { Injectable } from "@angular/core";
import { SendReceiveService } from "src/app/services/common/sendReceive.service";
import { MyAppHttpService } from "src/app/services/common/my-app-http.service";


@Injectable({
    providedIn: 'root'
  })
export class RegisterModalService{
    //HMACKey: string;
    constructor(private sendReceiveService: SendReceiveService) { }

    // public setHMACKey(input) {
    //     this.HMACKey = input;
    // }

   
    registerUser(requestData) {
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.Registration,requestData);  
    }

    getRoles(){
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllRoles);
    }
}

