import { ChangeDetectorRef, Injectable } from '@angular/core';
import * as Rx from 'rxjs';
import { CommonfunctionService } from '../services/common/commonfunction.service';
import { environment } from '../../environments/environment';
import { map, take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { DataStorageService } from '../services/data-storage.service';
import { MyAppHttpService } from '../services/common/my-app-http.service';
import { generateGUID } from '../services/common/common.function';
import { ChatService } from '../components/views/chat/chat.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

export interface Message {
  data: Object,
  message: string
}

@Injectable({
  providedIn: 'root'
})

export class WebsocketService {

  constructor(private commonService: CommonfunctionService, private dataStorage: DataStorageService,
    private chatService: ChatService, private sanitizer: DomSanitizer, 
    // private cdr: ChangeDetectorRef,
     private router: Router) {
    this.messages = <Subject<Message>>this
      .connect(environment.WS_CHAT_URL)
      .pipe(map((response: MessageEvent): Message => {
        //debugger;

        var responseData = JSON.parse(response.data);
        this.dataStorage.getFormattedMessage(responseData);
        if(responseData.toUserType == MyAppHttpService.chatUserTypes.READMIN || MyAppHttpService.chatUserTypes.RI ||MyAppHttpService.chatUserTypes.RE){
          this.dataStorage.newAdminChatId = responseData.chatId;
        }
        if (responseData.eventName == MyAppHttpService.chatEventNames.SEND_MESSAGE) {
          var tempUserInfo = responseData.data;

          if (tempUserInfo.toUserType == MyAppHttpService.chatUserTypes.RI || tempUserInfo.userType == MyAppHttpService.chatUserTypes.RI) {
            this.dataStorage.globalUnread_ChatIdInfo.userChatId = tempUserInfo.chatId;
          }
          if (this.router.url == '/' || this.router.url == '/staffingAgencies') {
            if (
              tempUserInfo.toUserType == MyAppHttpService.chatUserTypes.READMIN
              || tempUserInfo.userType == MyAppHttpService.chatUserTypes.READMIN) {
                this.dataStorage.getFormattedMessage(responseData.data);
              this.dataStorage.globalAdminChatConversationList.push((responseData.data));
              // this.dataStorage.globalAdminChatConversationList.inviteStatus = responseData.data.inviteStatus;
            }

            if (!dataStorage.chatClicked && this.dataStorage.globalUnread_ChatIdInfo.adminChatId
              && tempUserInfo.userType == MyAppHttpService.chatUserTypes.READMIN) {
              this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount = this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount + 1;
              this.dataStorage.globalChatMessageCount = this.dataStorage.globalChatMessageCount + 1;

            }
            this.dataStorage.globalChatUserInfo.inviteStatus = responseData.data.inviteStatus;
            this.dataStorage.globalSelectedChatUser.inviteStatus = responseData.data.inviteStatus;
            this.dataStorage.globalAdminchatUserInfo.inviteStatus = responseData.data.inviteStatus;
          }
          else if (this.router.url == '/chat') {
            // this.dataStorage.globalRIREConversation.push(responseData.data);
            console.log(responseData.data)

            if (this.dataStorage.globalSelectedChatUser.toUserId == responseData.data.userId
              || this.dataStorage.globalSelectedChatUser.toUserId == responseData.data.toUserId
              || responseData.data.toUserType == MyAppHttpService.chatUserTypes.READMIN
              || (responseData.data.userType == MyAppHttpService.chatUserTypes.READMIN && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE && this.dataStorage.globalChatSelectedUserType == MyAppHttpService.chatUserTypes.READMIN)) {
              this.dataStorage.globalSelectedChatUser.inviteStatus = responseData.data.inviteStatus;
              // if(!!responseData.data.thumbnail) {
              //   // let thumbnail = JSON.parse(responseData.data.thumbnail);
              //   let thumbnail = responseData.data.thumbnail;
              //   console.log('thumbnail: ', thumbnail);
              //   if(typeof thumbnail == 'object') {
              //     if(thumbnail.changingThisBreaksApplicationSecurity.indexOf('blob') != -1) {
              //       responseData.data.thumbnail = this.sanitizer.bypassSecurityTrustUrl(thumbnail.changingThisBreaksApplicationSecurity);
              //     }
              //   } 
              //   else if(thumbnail.indexOf('blob') != -1) {
              //     responseData.data.thumbnail = this.sanitizer.bypassSecurityTrustUrl(thumbnail);
              //   }
              //   else {
              //     responseData.data.thumbnail = this.sanitizer.bypassSecurityTrustUrl(thumbnail);
              //   }
              // }
              this.dataStorage.getFormattedMessage(responseData.data);
              this.dataStorage.globalRIREConversation.push((responseData.data));
            }
            else {
              if (responseData.data.userType == MyAppHttpService.chatUserTypes.RE && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE) {
                this.dataStorage.globalUnread_ChatIdInfo.REUnreadCount = this.dataStorage.globalUnread_ChatIdInfo.REUnreadCount + 1;
              } else if (responseData.data.userType == MyAppHttpService.chatUserTypes.RI && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE) {
                this.dataStorage.globalUnread_ChatIdInfo.RIUnreadCount = this.dataStorage.globalUnread_ChatIdInfo.RIUnreadCount + 1;
              } else if (responseData.data.userType == MyAppHttpService.chatUserTypes.READMIN && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE) {
                this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount = this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount + 1;
              }
            }
            this.dataStorage.globalChatUserInfo.inviteStatus = responseData.data.inviteStatus;
            this.dataStorage.globalAdminchatUserInfo.inviteStatus = responseData.data.inviteStatus;

            if (true) {

              if (responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE
                && responseData.data.userType == MyAppHttpService.chatUserTypes.RE
                && this.dataStorage.globalChatSelectedUserType == MyAppHttpService.chatUserTypes.RE
                && responseData.data.toUserId == this.dataStorage.loggedInUserData.enterpriseUserId) {
                  debugger;
                if (this.dataStorage.globalChatUserList.findIndex(x => x.toUserId == responseData.data.userId) == -1) {

                  var newUserData = {
                    recentMessage: responseData.data.message,
                    sourceUnreadCount: 0,
                    companyName: responseData.data.companyName,
                    destinationName: responseData.data.userName,
                    chatId: responseData.data.chatId,
                    destinationId: `${responseData.data.userId}_${MyAppHttpService.chatUserTypes.RE}`,
                    destinationEmailId: "NULL",
                    inviteStatus: "1",
                    destinationMobileNumber: "NULL",
                    eventTime: responseData.data.eventTime,
                    RecentMessageTime: responseData.data.RecentMessageTime,
                    toUserId: responseData.data.userId,
                    toUserType: MyAppHttpService.chatUserTypes.RE
                  }
                  this.dataStorage.getFormattedMessage(newUserData);
                  this.dataStorage.globalChatUserList.push(newUserData);
                  console.log("masuie", this.dataStorage.globalChatUserList)
                }
              }


              this.dataStorage.globalChatUserList.filter((item) => {
                if (
                  (item.toUserId == responseData.data.userId
                    && responseData.data.userType == this.dataStorage.globalChatSelectedUserType)
                  ||
                  (item.toUserId == responseData.data.toUserId
                    && responseData.data.toUserType == this.dataStorage.globalChatSelectedUserType)
                ) {
                  item.eventTime = new Date(responseData.data.eventTime);
                  item.msgType = responseData.data.msgType;
                  item.recentMessage = responseData.data.message;
                  item.RecentMessageTime = responseData.data.RecentMessageTime;
                }

                if (item.toUserId == responseData.data.userId && this.dataStorage.globalSelectedChatUser.toUserId != responseData.data.userId) {
                  item.sourceUnreadCount = item.sourceUnreadCount + 1;
                }

              }
              );


              this.dataStorage.globalChatUserList.sort((a, b) => (b.eventTime > a.eventTime) ? 1 : -1);
            }



            // if(responseData.toUserType == MyAppHttp.chatUserTypes.RE){
            this.dataStorage.newAdminChatId = responseData.data.chatId;
            // }
          }
          else {
            if(this.dataStorage.chatMinimised && !this.dataStorage.chatClosed){
              if(this.dataStorage.globalChatUserInfo.toUserId == responseData.data.userId
                && this.dataStorage.globalChatUserInfo.toUserType == responseData.data.userType){

                  //alert('');
                  //this.dataStorage.globalChatMessageCount = this.dataStorage.globalChatMessageCount + 1;
                  dataStorage.globalChatDialogUnreadCount
                  =dataStorage.globalChatDialogUnreadCount + 1;

                  if(responseData.data.userType == MyAppHttpService.chatUserTypes.RE
                    && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE){
                      dataStorage.globalUnread_ChatIdInfo.REUnreadCount
                      = dataStorage.globalUnread_ChatIdInfo.REUnreadCount + 1;
                    }
                }
            }
            if (
              (
                (tempUserInfo.userId == this.dataStorage.globalChatUserInfo.toUserId && tempUserInfo.userType == this.dataStorage.globalChatUserInfo.toUserType)
                || (tempUserInfo.toUserId == this.dataStorage.globalChatUserInfo.toUserId && tempUserInfo.toUserType == this.dataStorage.globalChatUserInfo.toUserType)

              ) && this.dataStorage.chatClicked
            ) {
              this.dataStorage.globalUnread_ChatIdInfo.userChatId = responseData.data.chatId;
              this.dataStorage.getFormattedMessage(responseData.data);
              this.dataStorage.globalUserChatConversationList.push((responseData.data));
              this.dataStorage.globalChatUserInfo.inviteStatus = responseData.data.inviteStatus;
            }
            // else if( tempUserInfo.toUserType == MyAppHttpService.chatUserTypes.READMIN
            //   || tempUserInfo.userType == MyAppHttpService.chatUserTypes.READMIN ){

            // }
            else {
              this.dataStorage.globalChatMessageCount = this.dataStorage.globalChatMessageCount + 1;
              if (responseData.data.userType == MyAppHttpService.chatUserTypes.RE && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE) {
                this.dataStorage.globalUnread_ChatIdInfo.REUnreadCount = this.dataStorage.globalUnread_ChatIdInfo.REUnreadCount + 1;
              } else if (responseData.data.userType == MyAppHttpService.chatUserTypes.RI && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE) {
                this.dataStorage.globalUnread_ChatIdInfo.RIUnreadCount = this.dataStorage.globalUnread_ChatIdInfo.RIUnreadCount + 1;
              } else if (responseData.data.userType == MyAppHttpService.chatUserTypes.READMIN && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE) {
                this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount = this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount + 1;
              }
            }
          }




          setTimeout(() => {
            if (!!document.getElementById('chatbody')) {
              document.getElementById('chatbody').scrollTop = document.getElementById('chatbody').scrollHeight;
            }
          }, 100);

          if (responseData.toUserType == MyAppHttpService.chatUserTypes.READMIN) {
            this.dataStorage.newAdminChatId = responseData.data.chatId;
          }
        }
        else if (responseData.eventName == MyAppHttpService.chatEventNames.UPDATE) {
          var tempUserData = JSON.parse(responseData.data);
          if(responseData.toUserType == MyAppHttpService.chatUserTypes.READMIN || MyAppHttpService.chatUserTypes.RI ){
            this.dataStorage.newAdminChatId = responseData.chatId;
          }
          if (tempUserData.userType == MyAppHttpService.chatUserTypes.RE) {
            var data = {};
            if (localStorage.getItem('userData')) {
              data = {
                userId: parseInt(this.dataStorage.loggedInUserData.enterpriseUserId), userType: MyAppHttpService.chatUserTypes.RE, userName: this.dataStorage.loggedInUserData.fullName,
                companyName: this.dataStorage.loggedInUserData.company
              };
              this.invokeWebSocketFunction(data, MyAppHttpService.chatEventNames.LOAD_ADMIN_CONVERSATION);
            }
            else {
              data = { userId: tempUserData.userId, userType: MyAppHttpService.chatUserTypes.RE, userName: tempUserData.userName, isAnonymous: true, companyName: 'Guest user' };
              this.invokeWebSocketFunction(data, MyAppHttpService.chatEventNames.LOAD_ADMIN_CONVERSATION);
            }
          }
        }
        else if (responseData.eventName == MyAppHttpService.chatEventNames.LOAD_ADMIN_CONVERSATION) {
          var tempResponseData = responseData.data;
          this.dataStorage.globalAdminchatUserInfo = tempResponseData;
          this.dataStorage.globalChatUserInfo = tempResponseData;
          this.dataStorage.globalChatUserInfo.toUserType = MyAppHttpService.chatUserTypes.READMIN;
          this.dataStorage.globalUnread_ChatIdInfo.adminChatId = tempResponseData.chatId;

          this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount = tempResponseData.sourceUnreadCount;
          this.dataStorage.globalAdminchatUserInfo.inviteStatus = tempResponseData.inviteStatus;

          if (tempResponseData.userType == MyAppHttpService.chatUserTypes.RE && tempResponseData.isAnonymous) {
            var inputData = {
              chatId: this.dataStorage.globalUnread_ChatIdInfo.adminChatId,
              userId: this.dataStorage.globalChatUserInfo.userId,
              toUserId: 1,
              toUserType: MyAppHttpService.chatUserTypes.READMIN
            }
            this.chatService.getChatDetails(inputData).subscribe((response) => {
              if (response && response.Items) {
                this.dataStorage.globalAdminChatConversationList = response.Items;

                setTimeout(() => {
                  if (!!document.getElementById('chatbody')) {
                    document.getElementById('chatbody').scrollTop = document.getElementById('chatbody').scrollHeight;
                  }
                }, 100);

              }
            });
          }
        }
        else if (responseData.eventName == MyAppHttpService.chatEventNames.UPDATE_READ_COUNT) {
          if (responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE) {
            this.dataStorage.globalUnread_ChatIdInfo.REUnreadCount = 0;
          } else if (responseData.data.toUserType == MyAppHttpService.chatUserTypes.RI) {
            this.dataStorage.globalUnread_ChatIdInfo.RIUnreadCount = 0;
          } else if (responseData.data.toUserType == MyAppHttpService.chatUserTypes.READMIN) {
            this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount = 0;
          }

        }
        else if (responseData.eventName == MyAppHttpService.chatEventNames.CHAT_ID_BY_USER) {
          this.dataStorage.globalUserChatConversationList = [];
          this.dataStorage.globalChatUserInfo = responseData.data;
          this.dataStorage.globalUnread_ChatIdInfo.userChatId = responseData.data.chatId;
          if (responseData.data.chatId) {
            var inputData = {
              chatId: this.dataStorage.globalUnread_ChatIdInfo.userChatId,
              userId: this.dataStorage.globalChatUserInfo.userId,
              toUserId: 1,
              toUserType: MyAppHttpService.chatUserTypes.RI
            }
            this.chatService.getChatDetails(inputData).subscribe((response) => {
              if (response && response.Items) {
                console.log('Chat dialog data: ', response);
                response.Items.forEach((element: any) => {
                  element = this.dataStorage.getFormattedMessage(element);
                })
                this.dataStorage.globalUserChatConversationList = response.Items;

                setTimeout(() => {
                  if (!!document.getElementById('chatbody')) {
                    document.getElementById('chatbody').scrollTop = document.getElementById('chatbody').scrollHeight;
                  }
                }, 100);

              }
            });
          }
          else {
            // alert('')
          }
        }
        // else if(responseData.eventName == MyAppHttpService.chatEventNames.INVITE_STATUS_UPDATE){

        // }
        return {
          data: response.data,
          message: MyAppHttpService.chatEventNames.UPDATE,
        }
      }));
  }

  private subject: Rx.Subject<MessageEvent>;
  public messages: Subject<Message>;

  public connect(url): Rx.Subject<MessageEvent> {
    // alert(!this.subject);
    if (!this.subject) {
      this.subject = this.create(url);
      if (localStorage.getItem('userData')) {
        setTimeout(() => {
          var data = { userId: JSON.parse(localStorage.getItem('userData')).enterpriseUserId, userType: MyAppHttpService.chatUserTypes.RE };
          this.invokeWebSocketFunction(data, MyAppHttpService.chatEventNames.UPDATE);

        }, 2000);
      }
      else {
     
      }



    }
    return this.subject;
  }

  /*
  private create(url): Rx.Subject<MessageEvent> {
    let ws = new WebSocket(url);

    let observable = Rx.Observable.create(
      (obs: Rx.Observer<MessageEvent>) => {
        ws.onmessage = obs.next.bind(obs);
        ws.onerror = obs.error.bind(obs);
        ws.onclose = obs.complete.bind(obs);
        return ws.close.bind(ws);
      }
    )

    let observer = {
      next: (data: Object) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      }
    }

    return Rx.Subject.create(observer, observable);
  }
  */

  public wsocket;
  private create(url): Rx.Subject<MessageEvent> {
    this.wsocket = new WebSocket(url);

    this.wsocket.onclose = (response) => {
      console.log('praveen closed connection')
      this.wsocket.close();
    };

    this.wsocket.onopen = (response) => {
      console.log('praveen open connection');
      if (!this.wsocket)
        this.wsocket = new WebSocket(url);
    };
    this.wsocket.onmessage = (response) => {
      console.log('praveen message connection');

      var responseData = JSON.parse(response.data);
      this.dataStorage.getFormattedMessage(responseData);
      if (responseData.eventName == MyAppHttpService.chatEventNames.SEND_MESSAGE) {
        var tempUserInfo = responseData.data;

        if (tempUserInfo.toUserType == MyAppHttpService.chatUserTypes.RI || tempUserInfo.userType == MyAppHttpService.chatUserTypes.RI) {
          this.dataStorage.globalUnread_ChatIdInfo.userChatId = tempUserInfo.chatId;
        }
        if (this.router.url == '/' || this.router.url == '/staffingAgencies') {
          if (
            tempUserInfo.toUserType == MyAppHttpService.chatUserTypes.READMIN
            || tempUserInfo.userType == MyAppHttpService.chatUserTypes.READMIN) {
              this.dataStorage.getFormattedMessage(responseData.data);
            this.dataStorage.globalAdminChatConversationList.push((responseData.data));
            // this.dataStorage.globalAdminChatConversationList.inviteStatus = responseData.data.inviteStatus;
          }

          if (!this.dataStorage.chatClicked && this.dataStorage.globalUnread_ChatIdInfo.adminChatId
            && tempUserInfo.userType == MyAppHttpService.chatUserTypes.READMIN) {
            this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount = this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount + 1;
            this.dataStorage.globalChatMessageCount = this.dataStorage.globalChatMessageCount + 1;

          }
          this.dataStorage.globalChatUserInfo.inviteStatus = responseData.data.inviteStatus;
          this.dataStorage.globalSelectedChatUser.inviteStatus = responseData.data.inviteStatus;
          this.dataStorage.globalAdminchatUserInfo.inviteStatus = responseData.data.inviteStatus;
        }
        else if (this.router.url == '/chat') {
          // this.dataStorage.globalRIREConversation.push(responseData.data);
          console.log(responseData.data)

          if (this.dataStorage.globalSelectedChatUser.toUserId == responseData.data.userId
            || this.dataStorage.globalSelectedChatUser.toUserId == responseData.data.toUserId
            || responseData.data.toUserType == MyAppHttpService.chatUserTypes.READMIN
            || (responseData.data.userType == MyAppHttpService.chatUserTypes.READMIN && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE && this.dataStorage.globalChatSelectedUserType == MyAppHttpService.chatUserTypes.READMIN)) {
            this.dataStorage.globalSelectedChatUser.inviteStatus = responseData.data.inviteStatus;
            // if(!!responseData.data.thumbnail) {
            //   // let thumbnail = JSON.parse(responseData.data.thumbnail);
            //   let thumbnail = responseData.data.thumbnail;
            //   console.log('thumbnail: ', thumbnail);
            //   if(typeof thumbnail == 'object') {
            //     if(thumbnail.changingThisBreaksApplicationSecurity.indexOf('blob') != -1) {
            //       responseData.data.thumbnail = this.sanitizer.bypassSecurityTrustUrl(thumbnail.changingThisBreaksApplicationSecurity);
            //     }
            //   } 
            //   else if(thumbnail.indexOf('blob') != -1) {
            //     responseData.data.thumbnail = this.sanitizer.bypassSecurityTrustUrl(thumbnail);
            //   }
            //   else {
            //     responseData.data.thumbnail = this.sanitizer.bypassSecurityTrustUrl(thumbnail);
            //   }
            // }
            this.dataStorage.getFormattedMessage(responseData.data);
            this.dataStorage.globalRIREConversation.push((responseData.data));
          }
          else {
            if (responseData.data.userType == MyAppHttpService.chatUserTypes.RE && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE) {
              this.dataStorage.globalUnread_ChatIdInfo.REUnreadCount = this.dataStorage.globalUnread_ChatIdInfo.REUnreadCount + 1;
            } else if (responseData.data.userType == MyAppHttpService.chatUserTypes.RI && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE) {
              this.dataStorage.globalUnread_ChatIdInfo.RIUnreadCount = this.dataStorage.globalUnread_ChatIdInfo.RIUnreadCount + 1;
            } else if (responseData.data.userType == MyAppHttpService.chatUserTypes.READMIN && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE) {
              this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount = this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount + 1;
            }
          }
          this.dataStorage.globalChatUserInfo.inviteStatus = responseData.data.inviteStatus;
          this.dataStorage.globalAdminchatUserInfo.inviteStatus = responseData.data.inviteStatus;

          if (true) {

            if (responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE
              && responseData.data.userType == MyAppHttpService.chatUserTypes.RE
              && this.dataStorage.globalChatSelectedUserType == MyAppHttpService.chatUserTypes.RE
              && responseData.data.toUserId == this.dataStorage.loggedInUserData.enterpriseUserId) {
                debugger;
              if (this.dataStorage.globalChatUserList.findIndex(x => x.toUserId == responseData.data.userId) == -1) {

                var newUserData = {
                  recentMessage: responseData.data.message,
                  sourceUnreadCount: 0,
                  companyName: responseData.data.companyName,
                  destinationName: responseData.data.userName,
                  chatId: responseData.data.chatId,
                  destinationId: `${responseData.data.userId}_${MyAppHttpService.chatUserTypes.RE}`,
                  destinationEmailId: "NULL",
                  inviteStatus: "1",
                  destinationMobileNumber: "NULL",
                  eventTime: responseData.data.eventTime,
                  RecentMessageTime: responseData.data.RecentMessageTime,
                  toUserId: responseData.data.userId,
                  toUserType: MyAppHttpService.chatUserTypes.RE
                }
                this.dataStorage.getFormattedMessage(newUserData);
                this.dataStorage.globalChatUserList.push(newUserData);
                console.log("hasui", this.dataStorage.globalChatUserList)
              }
            }


            this.dataStorage.globalChatUserList.filter((item) => {
              if (
                (item.toUserId == responseData.data.userId
                  && responseData.data.userType == this.dataStorage.globalChatSelectedUserType)
                ||
                (item.toUserId == responseData.data.toUserId
                  && responseData.data.toUserType == this.dataStorage.globalChatSelectedUserType)
              ) {
                item.eventTime = new Date(responseData.data.eventTime);
                item.msgType = responseData.data.msgType;
                item.recentMessage = responseData.data.message;
                item.RecentMessageTime = responseData.data.RecentMessageTime;
              }

              if (item.toUserId == responseData.data.userId && this.dataStorage.globalSelectedChatUser.toUserId != responseData.data.userId) {
                item.sourceUnreadCount = item.sourceUnreadCount + 1;
              }

            }
            );


            this.dataStorage.globalChatUserList.sort((a, b) => (b.eventTime > a.eventTime) ? 1 : -1);
          }



          // if(responseData.toUserType == MyAppHttp.chatUserTypes.RE){
          this.dataStorage.newAdminChatId = responseData.data.chatId;
          // }
        }
        else {
          if(this.dataStorage.chatMinimised && !this.dataStorage.chatClosed){
            if(this.dataStorage.globalChatUserInfo.toUserId == responseData.data.userId
              && this.dataStorage.globalChatUserInfo.toUserType == responseData.data.userType){

                //alert('');
                //this.dataStorage.globalChatMessageCount = this.dataStorage.globalChatMessageCount + 1;
                this.dataStorage.globalChatDialogUnreadCount
                =this.dataStorage.globalChatDialogUnreadCount + 1;

                if(responseData.data.userType == MyAppHttpService.chatUserTypes.RE
                  && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE){
                    this.dataStorage.globalUnread_ChatIdInfo.REUnreadCount
                    = this.dataStorage.globalUnread_ChatIdInfo.REUnreadCount + 1;
                  }
              }
          }
          if (
            (
              (tempUserInfo.userId == this.dataStorage.globalChatUserInfo.toUserId && tempUserInfo.userType == this.dataStorage.globalChatUserInfo.toUserType)
              || (tempUserInfo.toUserId == this.dataStorage.globalChatUserInfo.toUserId && tempUserInfo.toUserType == this.dataStorage.globalChatUserInfo.toUserType)

            ) && this.dataStorage.chatClicked
          ) {
            this.dataStorage.globalUnread_ChatIdInfo.userChatId = responseData.data.chatId;
            this.dataStorage.getFormattedMessage(responseData.data);
            this.dataStorage.globalUserChatConversationList.push((responseData.data));
            this.dataStorage.globalChatUserInfo.inviteStatus = responseData.data.inviteStatus;
          }
          // else if( tempUserInfo.toUserType == MyAppHttpService.chatUserTypes.READMIN
          //   || tempUserInfo.userType == MyAppHttpService.chatUserTypes.READMIN ){

          // }
          else {
            this.dataStorage.globalChatMessageCount = this.dataStorage.globalChatMessageCount + 1;
            if (responseData.data.userType == MyAppHttpService.chatUserTypes.RE && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE) {
              this.dataStorage.globalUnread_ChatIdInfo.REUnreadCount = this.dataStorage.globalUnread_ChatIdInfo.REUnreadCount + 1;
            } else if (responseData.data.userType == MyAppHttpService.chatUserTypes.RI && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE) {
              this.dataStorage.globalUnread_ChatIdInfo.RIUnreadCount = this.dataStorage.globalUnread_ChatIdInfo.RIUnreadCount + 1;
            } else if (responseData.data.userType == MyAppHttpService.chatUserTypes.READMIN && responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE) {
              this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount = this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount + 1;
            }
          }
        }




        setTimeout(() => {
          if (!!document.getElementById('chatbody')) {
            document.getElementById('chatbody').scrollTop = document.getElementById('chatbody').scrollHeight;
          }
        }, 100);

        if (responseData.toUserType == MyAppHttpService.chatUserTypes.READMIN) {
          this.dataStorage.newAdminChatId = responseData.data.chatId;
          //   console.log("Admin chat id : ", this.dataStorage.newAdminChatId)
        }
      }
      else if (responseData.eventName == MyAppHttpService.chatEventNames.UPDATE) {
        var tempUserData = JSON.parse(responseData.data);
        if (tempUserData.userType == MyAppHttpService.chatUserTypes.RE) {
          var data = {};
          if (localStorage.getItem('userData')) {
            data = {
              userId: parseInt(this.dataStorage.loggedInUserData.enterpriseUserId), userType: MyAppHttpService.chatUserTypes.RE, userName: this.dataStorage.loggedInUserData.fullName,
              companyName: this.dataStorage.loggedInUserData.company
            };
            this.invokeWebSocketFunction(data, MyAppHttpService.chatEventNames.LOAD_ADMIN_CONVERSATION);
          }
          else {
            data = { userId: tempUserData.userId, userType: MyAppHttpService.chatUserTypes.RE, userName: tempUserData.userName, isAnonymous: true, companyName: 'Guest user' };
            this.invokeWebSocketFunction(data, MyAppHttpService.chatEventNames.LOAD_ADMIN_CONVERSATION);
          }
        }
      }
      else if (responseData.eventName == MyAppHttpService.chatEventNames.LOAD_ADMIN_CONVERSATION) {
        var tempResponseData = responseData.data;
        this.dataStorage.globalAdminchatUserInfo = tempResponseData;
        this.dataStorage.globalChatUserInfo = tempResponseData;
        this.dataStorage.globalChatUserInfo.toUserType = MyAppHttpService.chatUserTypes.READMIN;
        this.dataStorage.globalUnread_ChatIdInfo.adminChatId = tempResponseData.chatId;

        this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount = tempResponseData.sourceUnreadCount;
        this.dataStorage.globalAdminchatUserInfo.inviteStatus = tempResponseData.inviteStatus;

        if (tempResponseData.userType == MyAppHttpService.chatUserTypes.RE && tempResponseData.isAnonymous) {
          //call chatconversations with chatId
          var inputData = {
            chatId: this.dataStorage.globalUnread_ChatIdInfo.adminChatId,
            userId: this.dataStorage.globalChatUserInfo.userId,
            toUserId: 1,
            toUserType: MyAppHttpService.chatUserTypes.READMIN
          }
          this.chatService.getChatDetails(inputData).subscribe((response) => {
            if (response && response.Items) {
              this.dataStorage.globalAdminChatConversationList = response.Items;

              setTimeout(() => {
                if (!!document.getElementById('chatbody')) {
                  document.getElementById('chatbody').scrollTop = document.getElementById('chatbody').scrollHeight;
                }
              }, 100);

            }
          });
        }
      }
      else if (responseData.eventName == MyAppHttpService.chatEventNames.UPDATE_READ_COUNT) {
        if (responseData.data.toUserType == MyAppHttpService.chatUserTypes.RE) {
          this.dataStorage.globalUnread_ChatIdInfo.REUnreadCount = 0;
        } else if (responseData.data.toUserType == MyAppHttpService.chatUserTypes.RI) {
          this.dataStorage.globalUnread_ChatIdInfo.RIUnreadCount = 0;
        } else if (responseData.data.toUserType == MyAppHttpService.chatUserTypes.READMIN) {
          this.dataStorage.globalUnread_ChatIdInfo.adminUnreadCount = 0;
        }

      }
      else if (responseData.eventName == MyAppHttpService.chatEventNames.CHAT_ID_BY_USER) {
        this.dataStorage.globalUserChatConversationList = [];
        this.dataStorage.globalChatUserInfo = responseData.data;
        this.dataStorage.globalUnread_ChatIdInfo.userChatId = responseData.data.chatId;
        if (responseData.data.chatId) {
          var inputData = {
            chatId: this.dataStorage.globalUnread_ChatIdInfo.userChatId,
            userId: this.dataStorage.globalChatUserInfo.userId,
            toUserId: 1,
            toUserType: MyAppHttpService.chatUserTypes.RI
          }
          this.chatService.getChatDetails(inputData).subscribe((response) => {
            if (response && response.Items) {
              console.log('Chat dialog data: ', response);
              response.Items.forEach((element: any) => {
                element = this.dataStorage.getFormattedMessage(element);
              })
              console.log('Chat dialog data: ', response.Items);
              this.dataStorage.globalUserChatConversationList = response.Items;
              // this.cdr.detectChanges()
              setTimeout(() => {
                if (!!document.getElementById('chatbody')) {
                  document.getElementById('chatbody').scrollTop = document.getElementById('chatbody').scrollHeight;
                }
              }, 100);

            }
          });
        }
        else {
          // alert('')
        }
      }
      // else if(responseData.eventName == MyAppHttpService.chatEventNames.INVITE_STATUS_UPDATE){

      // }
      return {
        data: response.data,
        message: MyAppHttpService.chatEventNames.UPDATE,
      }
    };


    let observable = Rx.Observable.create(
      (obs: Rx.Observer<MessageEvent>) => {
      }
    )

    let observer = {
      next: (data: Object) => {
        if (this.wsocket.readyState === WebSocket.OPEN) {
          this.wsocket.send(JSON.stringify(data));
        }
        else {
          this.subject = undefined;
          // ws.readyState = websock;
          this.connect(environment.WS_CHAT_URL);
        }
      }
    }

    return Rx.Subject.create(observer, observable);
  }


  public invokeWebSocketFunction(data, functionName) {
    var message = {
      data: data,
      message: functionName
    };
    this.messages.next(message);
  }



}
