import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { Router } from '@angular/router';
import { MatSnackBar, MatDialogModule } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { LoginModalService } from './login-modal.service';
import { DialogService } from '../../login-dialog.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { RegisterModalComponent } from '../../register/register-modal/register-modal.component';
import { ForgotpasswordmodalComponent } from '../../forgotpasswordmodal/forgotpasswordmodal.component';
import { GlobaldataService } from 'src/app/services/common/globaldata.service';
import { FindProfileComponent } from 'src/app/components/views/find-profile/find-profile.component';
import { FindProfileService } from 'src/app/components/views/find-profile/find-profile.service';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { Subject } from 'rxjs';
import { CommonfunctionService } from '../../../../../services/common/commonfunction.service';
import { ZoomServiceService } from 'src/app/services/zoom-service.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [GlobaldataService, FindProfileComponent, FindProfileService]
})
export class LoginModalComponent implements OnInit {

  public loginForm: FormGroup;
  public submitted: boolean = false;
  public invalidCredentials: boolean = false;
  public emailNotVerified: boolean = false;
  public userFCMToken: any;

  validation_messages = {
    'email': [
      { type: 'required', message: 'PAGES.LOGIN.ERRORS.EMAIL.REQUIRED.LABEL' },
      { type: 'email', message: 'PAGES.LOGIN.ERRORS.EMAIL.PATTERN.LABEL' },
      { type: 'pattern', message: 'PAGES.LOGIN.ERRORS.EMAIL.PATTERN.LABEL' },
      { type: 'minLength', message: 'PAGES.LOGIN.ERRORS.EMAIL.MINLENGTH.LABEL' },
      { type: 'maxLength', message: 'PAGES.LOGIN.ERRORS.EMAIL.MAXLENGTH.LABEL' }
    ],
    'password': [
      { type: 'required', message: 'PAGES.LOGIN.ERRORS.PASSWORD.REQUIRED.LABEL' },
      { type: 'pattern', message: 'PAGES.LOGIN.ERRORS.PASSWORD.PATTERN.LABEL' }
    ]
  };

  constructor(private dialogService: DialogService,
    private globaldataService: GlobaldataService,
    public findProfileComponent: FindProfileComponent,
    public dialogRef: MatDialogRef<LoginModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loginService: LoginModalService,
    public router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private myAppService: MyAppHttpService,
    private dataStorage: DataStorageService,
    private zoomServiceService:ZoomServiceService,
    private commonServices: CommonfunctionService) {
    this.loginForm = new FormBuilder().group({
      'emailId': ["", Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.EMAIL)])],
      "password": ["", Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.PASSWORD)])],
    });

  }

  ngOnInit() {
    //console.log(this.data);
    this.userFCMToken = this.dataStorage.fcmToken;
  }

  login() {
    this.submitted = true;
    let randomDataForGuestUser = JSON.parse(localStorage.getItem('randomDataForGuestUser'));
    let randomId, randomType;

    if (randomDataForGuestUser) {
      randomId = randomDataForGuestUser.randomId;
      randomType = randomDataForGuestUser.randomType;
    }
    if (this.loginForm.valid) {
      var loginData = this.loginForm.value;
      if (randomDataForGuestUser) {
        loginData.randomId = randomDataForGuestUser.randomId;
        loginData.randomType = randomDataForGuestUser.randomType;
      }
      this.loginService.login(loginData).subscribe(success => {
        if (success.statuscode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
          if (!!success.token) {
            success.fullName = `${success.firstName} ${success.lastName}`;
           this.dataStorage.isUserLoggedIn = true;
           this.dataStorage.loggedInUserName = success.fullName;

           this.dataStorage.loggedInUserData = success;
           this.zoomServiceService.checkLogin= false
            this.dialogService.clear();

            localStorage.setItem('token', success.token);
            localStorage.setItem('userData', JSON.stringify(success));
            this.dataStorage.loggedInUserData = JSON.parse(localStorage.getItem('userData'));
            this.commonServices.isLogedUserData$.next({
              token :localStorage.getItem('token'), 
              userData : JSON.parse(localStorage.getItem('userData'))
            }) 

            console.log('login',this.dataStorage.loggedInUserData)


            this.commonServices.getMemberPlanInfo();
            this.commonServices.addUpdateUserFCMToken();
            this.commonServices.getUnreadNotifications();



            if (success.typeOfActivity == 2) {
              if (this.dataStorage.subGetAllProfiles.observers.length > 1) {
                //this.dataStorage.subGetAllProfiles.observers = this.dataStorage.subGetAllProfiles.observers.splice(1);
                this.dataStorage.subGetAllProfiles.observers = [this.dataStorage.subGetAllProfiles.observers.pop()];
              }

              if (this.dataStorage.subPostAJob.observers.length > 1) {
                this.dataStorage.subPostAJob.observers = [this.dataStorage.subPostAJob.observers.pop()];
              }

              if (this.data.actionName == this.dataStorage.globalFeatureCodes.POST_A_JOB) {
                this.dataStorage.subPostAJob.next({ actionName: this.data.actionName, details: null, flag: null });
              }
              else {
                if (!!this.data) {
                  this.dataStorage.subGetAllProfiles.next({ actionName: this.data.actionName, details: this.data.details, flag: this.data.flag });
                }
                else {
                  this.dataStorage.subGetAllProfiles.next({ actionName: null, details: {}, flag: 0 });
                }
              }

            }

            if (this.dataStorage.isInGuestOperation) {

              setTimeout(() => {
                if (this.dataStorage.subGetAllProfiles.observers.length > 1) {
                  //this.dataStorage.subGetAllProfiles.observers = this.dataStorage.subGetAllProfiles.observers.splice(1);
                  this.dataStorage.subGetAllProfiles.observers = [this.dataStorage.subGetAllProfiles.observers.pop()];
                }

                if (this.dataStorage.subPostAJob.observers.length > 1) {
                  this.dataStorage.subPostAJob.observers = [this.dataStorage.subPostAJob.observers.pop()];
                }
                if (!!this.data) {
                  if (this.data.actionName == this.dataStorage.globalFeatureCodes.POST_A_JOB) {
                    this.dataStorage.subPostAJob.next({ actionName: this.data.actionName, details: null, flag: null });
                  }
                  else {
                    if (!!this.data) {
                      this.dataStorage.subGetAllProfiles.next({ actionName: this.data.actionName, details: this.data.details, flag: this.data.flag });
                    }
                    else {
                      this.dataStorage.subGetAllProfiles.next({ actionName: null, details: {}, flag: 0 });
                    }
                  }
                }

              }, 1000);
            }

            if (localStorage.postJobData) {
              //  Need to go to payment page if not done payment
              this.router.navigate(['/findprofile']);
             
            }
            else {
            
              if (this.dataStorage.favoritesRequest) {
                console.log("favorites request");
               
              }
              else {

              }

            }
            let inputData = {
              enterpriseUserId: success.enterpriseUserId,
              fcmToken: this.userFCMToken
            }
            this.loginService.saveFCMToken(inputData).subscribe(success => {
            
              this.dataStorage.AfterLogin.next(true);
            });

            if(this.dataStorage.isInJobDetailsPage) {
              this.router.navigate([this.dataStorage.activeUrlJobData.path], {queryParams: this.dataStorage.activeUrlJobData.queryParams});;
            }
          }
        }
 
        else {
          this.myAppService.showAlertMessage(success.message);
        }
        if (success.clickAndHireStatus == 99999) {
          this.myAppService.showAlertMessage("Offer sent already.");
        }
      }, error => {
        // console.log(error);
        this.myAppService.showAlertMessage(error.message);
      });
    }
  }

  showSignUp() {
    this.dialogService.clear();
    this.dialogService.open(RegisterModalComponent, {
    })
  }

  showForgotPassword() {
    this.dialogService.clear();
    this.dialogService.open(ForgotpasswordmodalComponent, {
    })
  }

}

