import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';

@Component({
  selector: 'app-training-details',
  templateUrl: './training-details.component.html',
  styleUrls: ['./training-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrainingDetailsComponent implements OnInit {

  trainingDetails: any = [];
  userId: any;
  trainingData: any;
  trainerDetails: any;
  math = Math;

  constructor(public dialogRef: MatDialogRef<TrainingDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private myAppService: MyAppHttpService) { }

  ngOnInit() {
    // console.log('Incoming data: ', this.data);
    this.trainingDetails = this.data.trainingDetails;
    this.userId = this.data.userId;
    this.trainingData = this.data.trainingData;
    this.trainerDetails = this.data.trainerDetails;
    this.trainingDetails.forEach(element => {
      element.toExpand = false;
    });
  }

  returnSkillsArray(skills) {
    return skills ? skills.split(',') : [];
  }

  formatUrl(url) {
 
    if (url) {
      if (!url.match(/^https?:\/\//i)) {
        url = 'http://' + url;
      }
      return url;
    }
    else {
      return '';
    }
  }

  registerTraining() {
    this.dialogRef.close(true);
  }

  showTopicDesc(details) {
    if (!!details) {
      this.trainingDetails.filter(x => x.topicId == details.topicId).map(y => y.toExpand = true);
      this.trainingDetails.filter(x => x.topicId != details.topicId).map(y => y.toExpand = false);
    }
  }

  hideTopicDesc() {
    this.trainingDetails.map(y => y.toExpand = false);
  }

  OpenAttachment(data) {
    if (data.multimediaPath != null) {
      this.downloadFile(data.multimediaPath, data.multimediaPath.substr(data.multimediaPath.lastIndexOf('/') + 1));
      this.myAppService.showAlertMessage('Attachment Downloaded Successfully');
    }
  }

  downloadFile(url, fileName) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", '' + url + '', true);
    xhr.responseType = "blob";
    xhr.onload = function () {
    
      var urlCreator = window.URL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    }
    xhr.send();
  }

}
