import { Injectable } from "@angular/core";
import {SendReceiveService} from '../../../services/common/sendReceive.service';
import {MyAppHttpService} from '../../../services/common/my-app-http.service';

@Injectable()
export class LoginService{
    //HMACKey: string;
    constructor(private sendReceiveService: SendReceiveService) { }

   

   
    login(requestData) {
        var loginData={
            emailId:requestData.emailId,
            currentPassword:requestData.password,
            randomGUId : requestData.randomId,
            typeGUID : requestData.randomType

        }
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.Login,loginData);  
    }

    isPaidUser(enterpriseUserId){
        var requestObj = {
                "enterpriseUserId" : enterpriseUserId
        };
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.paidUserCheck, requestObj)
    }

    updatePaymentStatus(enterpriseUserId, paymentId){
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, `${MyAppHttpService.REQUESTS.updatePaymentId}/${enterpriseUserId}/${paymentId}`, {});
    }

    saveFCMToken(requestData){
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.SaveEntUserFCMToken, requestData);
    }
}

