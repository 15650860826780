import { Injectable } from '@angular/core';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { DataStorageService } from '../../../services/data-storage.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PostajobService {

  constructor(private sendReceiveService: SendReceiveService, private dataStorage: DataStorageService, public http: HttpClient) { }

  getMasterServices() {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.MasterServices);
  }

  getExperiences() {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllExperienceDetails);
  }

  getTechnologies() {
    var enterpriseUserId = 0;
    if(!!localStorage.userData){
        enterpriseUserId = JSON.parse(localStorage.userData).enterpriseUserId;
    }

    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllTechnologies + '?enterpriseUserId='+enterpriseUserId+'');
  }

  getJobDescTemplates(){
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllJobDescriptionTemplatesForGuestUser);
  }

  getJobDescTemplatesByEnterpriseUser(requestData){
    var enterpriseUserId = 0;
    if(!!localStorage.userData){
        enterpriseUserId = JSON.parse(localStorage.userData).enterpriseUserId;
    }
    requestData.enterpriseUserId = enterpriseUserId;

    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetAllJobDescriptionTemplatesForEnterpriseUser,requestData);
  }

  getJobDescTemplatesWithFilter(requestData){
    var enterpriseUserId = 0;
    if(!!localStorage.userData){
        enterpriseUserId = JSON.parse(localStorage.userData).enterpriseUserId;
    }
    requestData.enterpriseUserId = enterpriseUserId;
    
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetAllJobDescriptionTemplatesWithFilter,requestData);
  }

  getLocations(){
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllLocations);
  }

  getjobTitles(){
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllJobTitles);
  }

  addJobTemplate(requestData){
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.addJobTemplate, requestData);
  }

  postJob(requestData){
    requestData.featureId = this.dataStorage.membershipCurrentPlan.featureList.find((features) => {        
      return features.featureCode == this.dataStorage.globalFeatureCodes.POST_A_JOB;
    }).featureId
    requestData.subscriptionId = this.dataStorage.membershipCurrentPlan.subscriptionId;
    
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.postJob, requestData);
  }

  getJobTitlesBySkills(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetJobTitlesBySkills, requestData)
  }

  getAllCountriesList(){
    return this.http.get('https://restcountries.com/v3.1/all') 
  }

  getJobDescTemplatesFromChatGPT(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.getJobDescFromChatGPT, requestData);
  }

  // private baseUrl = 'http://localhost:8080/api/v2/shared';

  getAllCountries() {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetCountries);
  }

  getAllCities(payload: any) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetCities+`${payload}`);
  }

  getAllStatesAndCities(payload: any) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetStatesAndCities+`${payload}`);
  }  

  getAllTheCountries() {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllCountries)
  }

  getAllStates() {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllStates)
  }

  getAllTheCities() {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllCities)
  }
}
