import { Component, OnInit, ViewEncapsulation, EventEmitter, ViewChild, ChangeDetectorRef, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { DataStorageService } from '../../../../services/data-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-top-section',
  templateUrl: './home-top-section.component.html', 
  styleUrls: ['./home-top-section.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class HomeTopSectionComponent implements OnInit{

  @Input('talentArea') talentArea: any;

  constructor(public dataStorage: DataStorageService, private router: Router) {
  }

  ngOnInit() {

  }

  onGetStartedClick(skills){
  
    this.dataStorage.previousSearchCriteria ={};
    var skillQueryParams = this.dataStorage.globalSkills.filter(x=> skills.indexOf(x.name) > -1).map(x=>x.id).join();

   if(skillQueryParams){
    this.router.navigate(['findprofile'], {
      queryParams : {
        'skillIds' : skillQueryParams
      }
    });
   } 
   else{
    this.router.navigate(['findprofile']);
   }
  }

}

