import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-resubscribe',
  templateUrl: './resubscribe.component.html',
  styleUrls: ['./resubscribe.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResubscribeComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ResubscribeComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
