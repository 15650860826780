import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobshareConfirmationComponent } from './jobshare-confirmation.component';
import { AppMaterialModule } from 'src/app/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [JobshareConfirmationComponent],
  imports: [
    CommonModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [JobshareConfirmationComponent],
  entryComponents: [JobshareConfirmationComponent],
})
export class JobshareConfirmationModule { }
