import { ViewFeedBackComponent } from './../viewfeedback/viewfeedback.component';
import { ViewRefFBComponent } from './view-ref-fb/view-ref-fb.component';
import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MyAppHttpService } from '../../../services/common/my-app-http.service';
import { TranslateService } from '@ngx-translate/core';
import { ProfileInfoService } from './profile-info.service';
import { saveByteArray, b64toBlob } from 'src/app/services/common/common.function';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DialogService } from '../../common/modal/login-dialog.service';
import { LoginModalComponent } from '../../common/modal/login/login-modal/login-modal.component';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { ScheduleMockinterviewComponent } from '../modal/schedule-mockinterview/schedule-mockinterview.component';
import { Observable } from 'rxjs';
import { FindProfileService } from '../../views/find-profile/find-profile.service';
import { ShareProfileComponent } from '../modal/share-profile/share-profile.component';
import { PaymentComponent } from '../modal/payment/payment/payment.component';
import { CommonfunctionService } from '../../../services/common/commonfunction.service';
import { PopoverContentComponent } from 'ngx-smart-popover';
import { ChatModalComponent } from '../modal/chat-modal/chat-modal.component';
import { WebsocketService } from 'src/app/ws/websocket.service';
import { VideoModalComponent } from '../modal/video-modal/video-modal.component';
import { CommonConfirmModalComponent } from '../modal/common-confirm-modal/common-confirm-modal.component';
import { ReskillingpageService } from '../../views/reskilling-page/reskilling-page.service';
import { ReskillingScheduleInterviewComponent } from '../../views/reskilling-page/reskilling-schedule-interview/reskilling-schedule-interview.component';
import { UpdateInterviewComponent } from '../modal/updateinterview/updateinterview.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { ClipboardService } from 'ngx-clipboard';
import { MyProfileService } from '../../views/myprofile/myprofile.service';
import { checkDate } from '@amcharts/amcharts4/.internal/core/utils/Type';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';
// @import "~@ng-select/ng-select/themes/material.theme.css";
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { asWindowsPath } from '@angular-devkit/core';

// interface DOMEvent<T extends EventTarget> extends Event {
//   readonly target: T
// }

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileInfoComponent implements OnInit, OnDestroy {
  showMore = false;
  text = `Rock Interview is an interview preparation solution. The platform allows users to gain interview
              skills by attending mock interviews and receive feedback and areas of improvement and receive
              customized training recommendations. It allows professionals to share expertise and skills. It also
              acts as a job board. The product is available in the Google Play Store and the Apple App Store.`;
  name = 'profile Completed: 70%';
  @ViewChild('scrollMe') private scrollProfileDetailsRightContent: ElementRef;
  @Input('profiles') profiles: any[];
  @Input('profilesCount') profilesCount: any;
  @Input('fromPage') fromPage: any;
  @Input('postedJobId') postedJobId: any;
  @Output() getAllFavorites = new EventEmitter();
  @Input() expandedData: any[] = [];

  @Input() isDefaultSearch: boolean = false;
  @Input('exactMatchRecords') exactMatchRecords: any;

  @ViewChild('emailPopover') emailPopover: PopoverContentComponent;
  @ViewChild('emailPricingPopover') emailPricingPopover: PopoverContentComponent;
  @ViewChild('chatPopover') chatPopover: PopoverContentComponent;
  @ViewChild('pojPopover') pojPopover: PopoverContentComponent;
  @ViewChild('sharePopover') sharePopover: PopoverContentComponent;
  @ViewChild('clickAndHirePopover') clickAndHirePopover: PopoverContentComponent;
  @ViewChild('scheduleInterviewPopover') scheduleInterviewPopover: PopoverContentComponent;
  @ViewChild('videoPopover') videoPopover: PopoverContentComponent;

  @ViewChild('skillsPopover') skillsPopover: PopoverContentComponent;
  @ViewChild('feedbackPopover') feedbackPopover: PopoverContentComponent;

  @Input() searchview

  offersDropdown: FormControl;

  offersList: any = [
    { id: 1, name: "All" },
    { id: 2, name: "Screened Profiles" },
    { id: 3, name: "Non Screened Profiles" }
  ];

  cities = [
    { id: 1, name: 'Email' },
    { id: 2, name: 'Name' },
    { id: 3, name: 'Mobile' },//, disabled: true
  ];

  //start dropdown 
  convertToNum: boolean = false
  toggle //= document.getElementById("myDropdown").style.display = 'none';
  inputtype = 'name'
  dropdownbtnwidth
  inputvalue = ''
  showhidesearchimplement: string = 'block'
  selectedtype: number = 2
  inputwidth: string = '25%'
  inputmarginleft: string = '10px'
  hideshowdropdown: boolean = false
  dropdownshow: boolean = false
  count = 0
  c = 0
  selectoptionname: string = 'Name'
  selectoptionsymbol: string = '▼'
  displaysearchdropdowndata: any = [{ id: 2, select: 'Name', allow: false }, { id: 1, select: 'Email', allow: false }, { id: 3, select: 'Mobile', allow: false }]
  //end drop down  
  finprofileselectstyling: string = '1px solid white'
  selectsearch
  IsClickandHire: boolean = false;
  status: any;
  mail: any;
  profileIdsArray: any = [];
  selectedProfiles: any = [];
  clickAndHireFormGroup: FormGroup;
  submitted: boolean = false;
  proposeOfferButton: boolean = false;
  minDateForInterview: string;
  maxDateForInterview: string;
  profileId: any;
  myJobsProfiles: any = [];
  liked: boolean = true;
  disliked: boolean = true;
  liked1: boolean = true;
  disliked1: boolean = true;
  fromPageNameChild: any = { 'FINDPROFILES': 'findProfiles', 'FAVORITES': 'favourites', 'MYJOBS': 'myJobs', 'ROLLEDOUTOFFERS': 'rolledOutOffers', 'SCHEDULEDINTERVIEWS': 'scheduledInterviews' }
  actionName: any = { ROLL_OUT_OFFER: 'ROLL_OUT_OFFER', SEND_MESSAGE_OUTLOOK: 'SEND_MESSAGE_OUTLOOK', SEND_MESSAGE_GMAIL: 'SEND_MESSAGE_GMAIL', SCHEDULE_INTERVIEW: 'SCHEDULE_INTERVIEW', FAVORITES: 'FAVORITES', SHARE_PROFILE: 'SHARE_PROFILE', DOWNLOAD_RESUME: 'DOWNLOAD_PROFILE', CHAT: 'CHAT', VIDEO_PROFILE: 'VIDEO_PROFILE' };
  noticePeriodData: any = [];
  scheduleInterviewBtn: boolean = false;
  math = Math;
  chkAll: boolean = false;
  callSearchService: boolean = true;
  pageStartIndex: number = 0;
  pageEndIndex: number = 10;
  itemsPerPage: number = this.dataStorage.paginationPageSize;

  isClickNHire: boolean = false;

  enterpriseUserIdData = !!localStorage.userData ? JSON.parse(localStorage.userData).enterpriseUserId : 0

  validation_messages = {
    'jobTitle': [
      { type: 'required', message: 'PAGES.FIND_PROFILES.ERRORS.JOB_TITLE.REQUIRED.LABEL' },

    ],
    'proposedSalary': [
      { type: 'required', message: 'PAGES.FIND_PROFILES.ERRORS.PROPOSED_SALARY.REQUIRED.LABEL' },
    ],
    'joiningDate': [
      { type: 'required', message: 'PAGES.FIND_PROFILES.ERRORS.JOINING_DATE.REQUIRED.LABEL' }
    ]
  };
  featureUnavailable: boolean = false;
  noDetailsAvailable: boolean = false;

  filteredProfiles: any = [];
  employerDetails: any;
  referenceDetails: any;
  offerDetails: any = [];
  yearMonthDayDisplay: string

  expandedSchType = MyAppHttpService.expandedScheduledInterviewsType;

  constructor(public myAppService: MyAppHttpService,
    private myProfileService: MyProfileService,
    private sendreceiveservice: SendReceiveService,
    private translate: TranslateService,
    private profileInfoService: ProfileInfoService,
    private router: Router,
    private dialogService: DialogService,
    public dataStorage: DataStorageService,
    private findProfileServie: FindProfileService,
    private commonService: CommonfunctionService,
    private cdr: ChangeDetectorRef,
    public reskillingsrvc: ReskillingpageService,
    public dialog: MatDialog,
    private wsService: WebsocketService,
    private _clipboardService: ClipboardService,
  ) {

    var tempMinDate, tempMaxDate;
    tempMinDate = new Date();
    tempMaxDate = new Date();
    tempMinDate.setDate(tempMinDate.getDate());
    tempMaxDate.setDate(tempMaxDate.getDate() + 90);
    this.minDateForInterview = this.formatDate(tempMinDate);
    this.maxDateForInterview = this.formatDate(tempMaxDate);



  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    if (!!event.currentTarget.className)
      if ((event.target.clientHeight > (event.target.scrollHeight - event.target.scrollTop - 60))) {

      }
  }
  checkboxChecked: boolean = false;
  checkboxDisable: boolean = false;
  shareFeedback: any = 0;
  usernameNotAvailable: number
  ngOnInit() {
    // this.sendreceiveservice.scheduleinterview_not_reload = true
    // console.log(this.sendreceiveservice.scheduleinterview_not_reload)
    // console.log(screen.width)
    // console.log(this.dataStorage.globalScheduledInterviews[1].interviewType)

    // document.getElementById("myDropdown").style.display = 'none';
    this.myProfileService.getMyPlanData().subscribe(response => {
      this.sendreceiveservice.searchoptionsforfinprofile = response
    })
    this.selectsearch = document.getElementById('selectoptionwithid')
    let today = new Date();
    let tomorrow = new Date(today.setDate(today.getDate() + 1));
    let yesturday = new Date(today.setDate(today.getDate() - 1));
    let filterbydate: any = [], filterresults: any = []
    if (true) {
      //scheduleinterviewscompoment
      // console.log(this.searchview)
      if (this.searchview == 'find-profiles') {
        // this.showhidesearchimplement = 'none'
        // document.getElementById("iconinput").style.marginLeft = "80px";
        // this.inputwidth = '30%'
        // this.hideshowdropdown = false
        // this.finprofileselectstyling = '1px solid grey'
      }
      else if (this.searchview == 'scheduleinterviewscompoment') {
        // for(let i=0; i<this.sendreceiveservice.searching_implementation_for_profiles.length;i++)
        // {
        //   if(this.sendreceiveservice.searching_implementation_for_profiles[i].userName == ''){
        //     this.usernameNotAvailable = i
        //     console.log(i)
        //   }

        // }

        this.dropdownbtnwidth = document.getElementById("dropdownBasic1")
        let searchbardropdown = document.getElementById("c1")
        setTimeout(() => {

          try {//1366X678
            // if(this.dataStorage.globalScheduledInterviews[1].interviewType == 'Rock Int Profiles')
            // {
            //   // searchbardropdown.className = "col-9"
            // }
            if (screen.width > 1300 && screen.width < 1450) {
              // searchbardropdown.className = "col-8"
            }
            else if (screen.width > 1450 && screen.width < 1900) {
              // searchbardropdown.className = "col-8"
            }
            else if (screen.width > 1900) {
              // searchbardropdown.className = "col-9"
            }
          }
          catch (e) {
            console.log(e)
          }

          // try{this.dropdownbtnwidth.style.width = '98px'}catch(e){console.log(e)}
        }, 100);
        this.showhidesearchimplement = 'block'
        // document.getElementById("iconinput").style.marginLeft = "10px";
        this.inputwidth = '25%'
        this.hideshowdropdown = true
        filterbydate = this.sendreceiveservice.globalSearch('', this.profiles, 6)
        try {
          // this.toggle = document.getElementById("myDropdown").style.display = 'none';
          this.inputtype = 'name'//= document.getElementById("inpsearch1")//inpsearch1

        }
        catch (e) {
          console.log(e)
        }
        for (let i = 0; i < filterbydate.length; i++) {
          if (!filterresults.includes(i)) {
            filterresults.push(filterbydate[i])
          }
          else {
          }
          this.sendreceiveservice.searchDateList = filterbydate
        }

      }
      else {
        this.searchview = 'all=profiles'
        // console.log(this.searchview)
      }
    }

    this.offersDropdown = new FormControl(1);
    this.dataStorage.subGetAllProfiles.subscribe((data) => {
      // console.log(data)
      // let searchbardropdown = document.getElementById("c1")

      if (this.router.url == "/findprofile") {
        this.dataStorage.tempFindProfileSearchData.resourceId = !!localStorage.userData ? JSON.parse(localStorage.userData).enterpriseUserId : 0;
        this.findProfileServie.searchProfiles(this.dataStorage.tempFindProfileSearchData).subscribe(success => {
          // console.log(success)

          if (success.statuscode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
            if (success.profileInfo.length > 0) {
              success.profileInfo[0].profileDetails[0].resumeExist = !!success.profileInfo[0].profileDetails[0].resumeForFindProfile ? true : false;
              success.profileInfo[0].profileDetails[0].primarySkills = success.profileInfo[0].profileDetails[0].skillsExperienceAndRating.length != 0 ? success.profileInfo[0].profileDetails[0].skillsExperienceAndRating.filter((x: any) => x.type == '1') : [];
              success.profileInfo[0].profileDetails[0].secondarySkills = success.profileInfo[0].profileDetails[0].skillsExperienceAndRating.length != 0 ? success.profileInfo[0].profileDetails[0].skillsExperienceAndRating.filter((x: any) => x.type == '2' || x.type == '0') : [];
            }

            this.profiles = success.profileInfo;
            this.dataStorage.globalProfiles = this.profiles;
            // console.log(this.profiles)
            //   if(this.dataStorage.globalScheduledInterviews[1].interviewType == 'Rock Int Profiles')
            // {
            // searchbardropdown.className = "col-9"
            // }
            // console.log(this.dataStorage.globalProfiles)
            this.onClickExtData(this.dataStorage.globalProfiles[this.dataStorage.selectedProfileIndex], this.dataStorage.selectedProfileIndex);

            if (data.actionName == this.actionName.ROLL_OUT_OFFER) {

              this.onValidatePricingAnonymousUserClick(null, null, this.dataStorage.globalFeatureCodes.PROPOSE_OFFER)

            }
            else if (data.actionName == this.actionName.SEND_MESSAGE_OUTLOOK) {

              this.onValidatePricingAnonymousUserClick(0, data.details, this.dataStorage.globalFeatureCodes.COMMUNICATION)
            }
            else if (data.actionName == this.actionName.SEND_MESSAGE_GMAIL) {

              this.onValidatePricingAnonymousUserClick(1, data.details, this.dataStorage.globalFeatureCodes.COMMUNICATION)
            }
            else if (data.actionName == this.actionName.FAVORITES) {
              this.addorDeleteFavourites(data.details.profileId, data.details.flag, data.details.isMultipleSelected);
            }
            else if (data.actionName == this.actionName.SHARE_PROFILE) {

              this.onValidatePricingAnonymousUserClick(2, data.details, this.dataStorage.globalFeatureCodes.COMMUNICATION)
            }
            else if (data.actionName == this.actionName.CHAT) {

              this.onValidatePricingAnonymousUserClick(3, data.details, this.dataStorage.globalFeatureCodes.COMMUNICATION)
            }
            else if (data.actionName == this.actionName.DOWNLOAD_RESUME) {
              this.onValidatePricingAnonymousUserClick(data.flag, data.details, this.dataStorage.globalFeatureCodes.DOWNLOAD_PROFILE)
            }
            else if (data.actionName == this.actionName.SCHEDULE_INTERVIEW) {
              this.onValidatePricingAnonymousUserClick(null, data.details, this.dataStorage.globalFeatureCodes.INTERVIEW_RI);
            }

            else if (data.actionName == this.actionName.VIDEO_PROFILE) {
              this.showVideo(data.details);
            }

            this.cdr.detectChanges();
          }
        });
      }
      else {
        this.onClickExtData(this.dataStorage.globalProfiles[this.dataStorage.selectedProfileIndex], this.dataStorage.selectedProfileIndex);
      }
    });

    this.dataStorage.getPayments.subscribe((data) => {


      if (data.actionName == this.actionName.DOWNLOAD_RESUME) {
        this.onValidatePricingAnonymousUserClick(data.flag, data.details, this.dataStorage.globalFeatureCodes.DOWNLOAD_PROFILE);

      } else if (data.actionName == this.actionName.ROLL_OUT_OFFER) {
        this.onValidatePricingAnonymousUserClick(null, null, this.dataStorage.globalFeatureCodes.PROPOSE_OFFER);

      } else if (data.actionName == this.actionName.SCHEDULE_INTERVIEW) {
        this.onValidatePricingAnonymousUserClick(null, data.details, this.dataStorage.globalFeatureCodes.INTERVIEW_RI);

      } else if (data.eventType == this.dataStorage.globalFeatureCodes.COMMUNICATION) {
        this.onValidatePricingAnonymousUserClick(data.flag, data.details, this.dataStorage.globalFeatureCodes.COMMUNICATION);
      }
    });




    this.clickAndHireFormGroup = new FormBuilder().group({
      'jobTitle': new FormControl("", Validators.compose([Validators.required])),
      'proposedSalary': new FormControl("", Validators.compose([Validators.required])),
      'joiningDate': new FormControl("", Validators.compose([Validators.required])),
    });

    if (this.profiles.length > 0) {
      this.profilesList(0, '')
      this.profileId = this.profiles[0].profileId;
      this.dataStorage.globalExpandedData = [];

      if (!this.profiles[0].profileDetails || this.profiles[0].profileDetails.length == 0) {
        if (this.profiles[0].isProfileDisabled) {
          this.expandedData.push(this.profiles[0]);
          this.dataStorage.globalExpandedData.push(this.profiles[0]);
        }
        else {
          this.fnGetProfileAdditionalDetails(this.profiles[0]);
        }
      }
      else {
        if (this.profiles[0].isProfileDisabled) {
          this.expandedData.push(this.profiles[0]);
          this.dataStorage.globalExpandedData.push(this.profiles[0]);
        }
        else {
          this.dataStorage.selectedProfileIndex = 0;
          this.profiles[0].profileDetails[0].resumeExist = !!this.profiles[0].profileDetails[0].resumeForFindProfile ? true : false;
          this.profiles[0].profileDetails[0].primarySkills = this.profiles[0].profileDetails[0].skillsExperienceAndRating.length != 0 ? this.profiles[0].profileDetails[0].skillsExperienceAndRating.filter((x: any) => x.type == '1') : [];
          this.profiles[0].profileDetails[0].secondarySkills = this.profiles[0].profileDetails[0].skillsExperienceAndRating.length != 0 ? this.profiles[0].profileDetails[0].skillsExperienceAndRating.filter((x: any) => x.type == '2' || x.type == '0') : [];
          this.expandedData = (this.profiles[0].profileDetails);
          this.dataStorage.globalExpandedData = (this.profiles[0].profileDetails);

          if (this.commonService.validateFeatureAvailability(this.dataStorage.globalFeatureCodes.POJ, null, null, null) && this.dataStorage.membershipCurrentPlan.planDurationNum > -1) {
            this.getProbabilityOfJoining(this.profiles[0].profileId);
          }
        }


      }

      this.status = this.profileId;
    }
    else {
      this.profileId = 0;
      this.expandedData = [];
      this.dataStorage.globalExpandedData = [];
    }



    this.profileInfoService.getAllNoticePeriodDetails().subscribe(success => {
      if (!!success) {
        this.noticePeriodData = success.noticePeriodList;
      }
    }, error => {
      this.myAppService.showAlertMessage(error.message);
    });


    this.itemsPerPage = this.dataStorage.paginationPageSize;
    if (this.fromPage != this.fromPageNameChild.FINDPROFILES) {
      this.itemsPerPage = this.dataStorage.globalProfiles.length;
    }

    this.offersDropdown.valueChanges.subscribe(value => {

      if (value == 1) {
        this.filteredProfiles = this.dataStorage.globalProfiles;
      }
      else if (value == 2) {
        this.filteredProfiles = this.dataStorage.globalProfiles.filter(x => !x.isProfileDisabled && x.ratingValue != 0);
      }
      else if (value == 3) {
        this.filteredProfiles = this.dataStorage.globalProfiles.filter(x => x.ratingValue == 0);
      }

      if (this.filteredProfiles.length == 0) {
        this.dataStorage.globalExpandedData = [];
      }
      else {
        if (this.filteredProfiles.profileDetails == null) {
          if (this.filteredProfiles[0].isProfileDisabled) {
            this.expandedData.push(this.filteredProfiles[0]);
            this.dataStorage.globalExpandedData.push(this.filteredProfiles[0]);
          }
          else {
            this.fnGetProfileAdditionalDetails(this.filteredProfiles[0]);
          }
        }
        else {

        }
      }

    });
    setTimeout(() => {
      let mainsearchresultslr: any = document.getElementById("mainsearchresults-lr"),
        mainsearchresultsl: any = document.getElementById("mainsearchresults-l"),
        mainsearchresultsr: any = document.getElementById("mainsearchresults-r")
      // mainsearchresultslr.style.border=`5px solid blue`
      console.log("mainsearchresultslr.offsetHeight", mainsearchresultslr.offsetHeight)
      mainsearchresultsr.style.maxHeight = `${mainsearchresultslr.offsetHeight - 90}px`
      // mainsearchresultsr.style.border=`5px solid red`
      console.log("b1 =>", mainsearchresultslr.style.border, " br =>", mainsearchresultsr.style.border, " h =>", mainsearchresultsr.style.maxHeight)
    }, 500);
  }

  updateddatesearch(results: any) {
    let updateddates: any = []
    // let results:any = []
    results.map((r) => {
      try {
        // if(r.scheduleInterviewInfo[0] != undefined)
        // {
        //   console.log(r.scheduleInterviewInfo[0])
        //   if(!!r.scheduleInterviewInfo[0])
        // {
          if(!!r.scheduleInterviewInfo) {
            if (!updateddates.includes(r.scheduleInterviewInfo[0].interviewCreatedDate.split("T")[0])) {
              //this.sendreceiveservice.searchDateList
              updateddates.push(r.scheduleInterviewInfo[0].interviewCreatedDate.split("T")[0])
              // console.log(updateddates)
              this.sendreceiveservice.searchDateList = updateddates
            }
          }
        
        // }
        // }
      }
      catch (e) {
        console.log(e)
      }
    })
  }

  noProfileFound: boolean = false;
  searchScheduleInterviews(inputSearch: any, searchTerm: string) {
    if (!searchTerm) {
      // If search term is empty, reset to the original list
      this.filteredProfiles = JSON.parse(JSON.stringify(this.profiles));
      this.dataStorage.globalProfiles = this.filteredProfiles;
    } else {
      // Perform filtering based on search term
      this.filteredProfiles = this.profiles.filter(profile => {
        this.dataStorage.InterviewType = "GLOBAL";
        // Perform your filtering logic here, for example:
        // return profile.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
      this.dataStorage.globalProfiles = this.filteredProfiles;
    }

    let length = this.sendreceiveservice.searching_implementation_for_profiles.length
    // console.log(length)
    for (let i = 0; i < 2; i++) {
      if (this.sendreceiveservice.searching_implementation_for_profiles[length - i] != undefined) {

        if (this.sendreceiveservice.searching_implementation_for_profiles[length - i].isProfileDisabled == true) {
          this.sendreceiveservice.searching_implementation_for_profiles[length - i] = this.sendreceiveservice.searching_implementation_for_profiles[length - i - 1]
        }
      }

    }
    this.inputvalue = inputSearch.value
    // if(this.selectedtype == 3){if(inputSearch.value == ''){inputSearch.value = ''}}  
    let op: any = []
    let results: any = [];
    if (inputSearch.length != 0) {


      // console.log(this.usernameNotAvailable)
      // console.log( this.sendreceiveservice.searching_implementation_for_profiles[this.usernameNotAvailable])
      // this.sendreceiveservice.searching_implementation_for_profiles[this.usernameNotAvailable].userName == 'Not Available'
      // console.log( this.sendreceiveservice.searching_implementation_for_profiles[this.usernameNotAvailable])

      results = this.sendreceiveservice.globalSearch(inputSearch.value, this.sendreceiveservice.searching_implementation_for_profiles, this.selectedtype)//this.selectsearch.value
      // console.log(results)  
      if (results.length == 1 && !!results[0].isProfileDisabled && !!results[0].profileId) {
        results = []
      }
      if (this.selectedtype == 1) {
        // emailId
        results.filter((e, ei) => {
          if (!e.emailId) {
            results.splice(ei, 1)
            if (results.length == 1 && !!results[0].isProfileDisabled && !!results[0].profileId) {
              results = []
            }
          }
        })
      }
      this.profilesList(1, results)
      this.updateddatesearch(results)
    }
    else {

      // results.map((r) => {
      //   if(!updateddates.includes(r.scheduleInterviewInfo[0].interviewCreatedDate.split("T")[0]))
      //     {
      //                     //this.sendreceiveservice.searchDateList
      //       updateddates.push(r.scheduleInterviewInfo[0].interviewCreatedDate.split("T")[0])
      //         // console.log(updateddates)
      //         this.sendreceiveservice.searchDateList = updateddates
      //     }
      // })
      this.updateddatesearch(results)
      this.profilesList(1, this.profiles)
    }
    // this.updateddatesearch(inputSearch)
    // console.log(this.sendreceiveservice.searchDateList)
  }

  searchScheduleInterviews1(inputSearch) {
    let results: any = [];
    results = this.sendreceiveservice.globalSearch(inputSearch.value, this.sendreceiveservice.searching_implementation_for_profiles, this.selectedtype)//this.selectsearch.value
    console.log('Results: ', results, this.profiles, inputSearch, inputSearch.value, inputSearch.length);
    if(!inputSearch.value || inputSearch.length == 0 ) {
      this.updateddatesearch(results)
      this.profilesList(1, this.profiles)
    }
    this.cdr.detectChanges();
  }

  // profilesList(v,searchResults:any) {
  //   if(v == 0)
  //   {
  //     this.dataStorage.globalProfiles = JSON.parse(JSON.stringify(this.profiles));
  //     this.filteredProfiles = this.dataStorage.globalProfiles;
  //   }
  //   else if(v == 1)
  //   {
  //     this.filteredProfiles = searchResults
  //     this.dataStorage.globalProfiles = searchResults
  //   }
  // }

  profilesList(v, searchResults: any) {
    if (v == 0) {
      this.dataStorage.globalProfiles = JSON.parse(JSON.stringify(this.profiles));
      this.filteredProfiles = this.dataStorage.globalProfiles;
      console.log("sudherr");
    }
    else if (v == 1) {
      this.filteredProfiles = searchResults
      this.dataStorage.globalProfiles = searchResults
      console.log("sudh");
      this.dataStorage.InterviewType = "GLOBAL";
      setTimeout(() => {
        this.fnGetProfileAdditionalDetails(this.dataStorage.globalProfiles[0]);
      }, 100);
    }
  }

  cancelInterview(data) {
    let modalRef = this.dialogService.open(CommonConfirmModalComponent, {
      data: { message: 'Do you really want to cancel interview?' }
    });

    modalRef.afterClosed().subscribe(confirmed => {

      if (!!confirmed) {
        let requestData = {};
        if (this.dataStorage.expandedInterviewType == 'RI Profiles') {
          requestData = {
            enterpriseUserId: JSON.parse(localStorage.getItem("userData")) ? JSON.parse(localStorage.getItem("userData")).enterpriseUserId : 0,
            interviewId: data.interviewId,

          }
        } else {
          requestData = {
            enterpriseUserId: JSON.parse(localStorage.getItem("userData")) ? JSON.parse(localStorage.getItem("userData")).enterpriseUserId : 0,
            interviewId: data.interviewId,
            interviewType: "GLOBAL"
          }
        }

        this.reskillingsrvc.cancelInterview(requestData).subscribe((response) => {

          data.interviewStatus = 9;
          for (let i = 0; i < this.dataStorage.globalProfiles.length; i++) {
            if (this.expandedData[0].profileId == this.dataStorage.globalProfiles[i].profileId) {
              if (this.dataStorage.globalProfiles[i].scheduleInterviewInfo.length == 1) {
                if (data.interviewId == this.dataStorage.globalProfiles[i].scheduleInterviewInfo[0].interviewId) {
                  this.dataStorage.globalProfiles[i].scheduleInterviewInfo[0].interviewStatus = 9;
                }
              }
            }
          }
          this.cdr.detectChanges();
          this.myAppService.showAlertMessage('Interview Cancelled Successfully.');
        })
      }
    })
  }


  editInterview(data, fullName) {
    let skills: any = []
    data.fullName = !!fullName.profileName ? fullName.profileName : ''
    data.fullname = !!fullName.profileName ? fullName.profileName : ''
    if (!!data.interviewtechnologies)
      for (let i = 0; i < data.interviewtechnologies.length; i++) { skills.push({ name: data.interviewtechnologies[i] }) }
    data.skills = skills//data.interviewtechnologies
    document.getElementById('preloader-div').style.visibility = 'hidden'; //trail
    if (this.dataStorage.expandedInterviewType == 'RI Profiles') {

      this.dataStorage.InterviewType = "LOCAL";
    } else {

      this.dataStorage.InterviewType = "GLOBAL";
    }
    this.dataStorage.updateInterviewDialog = this.dialog.open(UpdateInterviewComponent,
      {
        height: 'auto 400px',
        width: '800px',
        panelClass: 'custom-dialog-container',
        autoFocus: false,
        data: data,
        disableClose: true,
        id: 'updatedialog'
      });

    this.dataStorage.updateInterviewDialog.afterClosed().subscribe((datareturned) => {

      if (datareturned.success == true) {
        let datareturnedback = datareturned.responsedata;

        if (datareturnedback.siinfo) {

          for (let i = 0; i < this.dataStorage.globalProfiles.length; i++) {
            if (this.expandedData[0].profileId == this.dataStorage.globalProfiles[i].profileId) {
              if (this.dataStorage.globalProfiles[i].scheduleInterviewInfo.length == 1) {
                if (data.interviewId == this.dataStorage.globalProfiles[i].scheduleInterviewInfo[0].interviewId) {
                  this.dataStorage.globalProfiles[i].scheduleInterviewInfo[0].interviewDateAndTime = datareturnedback.siinfo.interviewDateAndTime;
                }
              }
            }
          }
          this.dataStorage.globalExpandedData[0].interviewDateAndTime = datareturnedback.siinfo.interviewDateAndTime;
          data.interviewDateAndTime = datareturnedback.siinfo.interviewDateAndTime;
          data.interTechnIds = datareturnedback.siinfo.interTechnIds;
          data.interviewTechIds = datareturnedback.siinfo.interviewTechIds;
          data.interview_technologies = datareturnedback.siinfo.interview_technologies;
          data.interviewerName = datareturnedback.siinfo.interviewerName;
          data.interviewer_technologies = datareturnedback.siinfo.interviewer_technologies;
          data.interviews_taken = datareturnedback.siinfo.interviews_taken;
          data.interviewtechnologies = datareturnedback.siinfo.interviewtechnologies;
          data.interviewer_ratings = datareturnedback.siinfo.interviewer_ratings;
          data.interviwerExperience = datareturnedback.siinfo.interviwerExperience;
          data.mentorId = datareturnedback.siinfo.mentorId;
          data.resumePath = datareturnedback.siinfo.resumePath;
          console.log(datareturnedback.siinfo.resumePath)
          data.meetingLink = datareturnedback.siinfo.meetingLink;

          if (data.mentorId) {
            if (datareturnedback.siinfo.interviewStatus == 1) {
              data.interviewStatus = 2;
            } else {
              data.interviewStatus = datareturnedback.siinfo.interviewStatus;
            }
          }

          this.cdr.detectChanges();
        }
      } else {

      }
    }, error => {
    })
  }


  // Method to expand selected profile
  onClickExtData(details, profileIndex = 0) {
    this.visible = false      //priya
    this.ReadMore = true
    this.closeChatDialog();

    this.dataStorage.selectedProfileIndex = profileIndex;
    this.liked = true;
    this.disliked = true;
    this.liked1 = true;
    this.disliked1 = true;
    this.IsClickandHire = false;
    this.status = details.profileId;
    this.expandedData = [];
    this.dataStorage.globalExpandedData = [];
    this.proposeOfferButton = false;
    this.clickAndHireFormGroup.reset();
    this.isClickNHire = false;

    if (details.isProfileDisabled) {
      this.expandedData.push(details);
      this.dataStorage.globalExpandedData.push(details);
    }
    else {

      var tempProfileData = (this.fromPage != this.fromPageNameChild.MYJOBS) ? this.dataStorage.globalProfiles.find((profile) => { return profile.profileId == details.profileId }) : this.filteredProfiles.find((profile) => { return profile.profileId == details.profileId });
      console.log('Temp profile data: ', tempProfileData);
      // if (!tempProfileData || !tempProfileData.profileDetails || tempProfileData.profileDetails.length == 0) {
      this.fnGetProfileAdditionalDetails(details);

      // }
      // else {
      //   this.expandedData = tempProfileData.profileDetails;
      //   this.dataStorage.globalExpandedData = tempProfileData.profileDetails;

      //   if (this.dataStorage.globalExpandedData.length > 0)
      //     this.dataStorage.globalExpandedData[0].isChecked = false;
      //   this.dataStorage.globalExpandedData[0].isOfferChecked = false;

      //   if (this.commonService.validateFeatureAvailability(this.dataStorage.globalFeatureCodes.POJ, null, null, null)) {
      //     this.getProbabilityOfJoining(details.profileId);
      //   }

      // }
    }




  }

  fnGetProfileAdditionalDetails(details) {
    var enterpriseUserId = 0;
    if (!!localStorage.userData) {
      enterpriseUserId = JSON.parse(localStorage.userData).enterpriseUserId;
    }
    var requestData = {
      esId: [details.esId],
      enterpriseUserId: enterpriseUserId,
      jobId: this.fromPage == this.fromPageNameChild.MYJOBS ? this.postedJobId : 0,

      interviewType: (this.fromPage == this.fromPageNameChild.SCHEDULEDINTERVIEWS && this.dataStorage.expandedInterviewType == MyAppHttpService.expandedScheduledInterviewsType.COMPANY) ?
        MyAppHttpService.expandedScheduledInterviewsType.COMPANY : null
    };

    if (this.fromPage == this.fromPageNameChild.FINDPROFILES) {
      var searchCriteria = JSON.parse(JSON.stringify(this.dataStorage.previousSearchCriteria));
      var skillsWithKeywords = searchCriteria.selectedSkills;

      for (var i = 0; i < skillsWithKeywords.length; i++) {
        skillsWithKeywords[i].keywords = skillsWithKeywords[i].allKeywords ?
          (skillsWithKeywords[i].allKeywords.filter(x => x.checked).length > 0 ? skillsWithKeywords[i].allKeywords.filter(x => x.checked).map(x => x.name) : null)
          : null;
      }
      requestData["techSelectionList"] = skillsWithKeywords;
    }

    this.profileInfoService.getProfileInformation(requestData).subscribe((success) => {
      console.log("sriram calling ", success)
      if (success.statuscode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
        // if (success.profileInfo[0].skillDescription.length > 0 && success.profileInfo[0].skillsExperienceAndRating.length == 0) {

        //   let psList = []
        //   success.profileInfo[0].skillDescription.forEach((e, i) => {
        //     let ps = {
        //       "techId": 0,
        //       "skill": "",
        //       "experience": 0,
        //       "rating": 0,
        //       "type": i < 5 ? 1 : 2
        //     }
        //     // success.profileInfo[0]?.skillDescription.length < 5 ? 1 : success.profileInfo[0]?.skillDescription.length < 21 ? 2 : 1
        //     ps['skill'] = e;
        //     psList.push(ps)

        //   });
        //   success.profileInfo[0].skillDescription = []
        //   success.profileInfo[0].skillsExperienceAndRating = psList
        //   console.log("sriram calling psList", psList)
        // }
        success.profileInfo[0].resumeExist = !!success.profileInfo[0].resumeForFindProfile ? true : false;
        success.profileInfo[0].primarySkills = success.profileInfo[0].skillsExperienceAndRating.length != 0 ? success.profileInfo[0].skillsExperienceAndRating.filter((x: any) => x.type == '1') : [];
        success.profileInfo[0].secondarySkills = success.profileInfo[0].skillsExperienceAndRating.length != 0 ? success.profileInfo[0].skillsExperienceAndRating.filter((x: any) => x.type == '2' || x.type == '0') : [];
        this.expandedData = (success.profileInfo);
        this.dataStorage.globalExpandedData = (success.profileInfo);
        // console.log(this.dataStorage.globalExpandedData)
        if (this.commonService.validateFeatureAvailability(this.dataStorage.globalFeatureCodes.POJ, null, null, null)) {
          this.getProbabilityOfJoining(details.profileId);
        }

        this.dataStorage.globalProfiles.forEach((profile) => {
          if (profile.profileId == details.profileId) {
            profile.profileDetails = success.profileInfo;
            console.log(success.profileInfo)
            // profile.profileDetails.resumeExist = !!profile.profileDetails.resumePath ? true : false;
          }
        });

        // console.log(this.dataStorage.globalProfiles)
      }
    }, (error) => {

    });
  }

  // Event raised when any action performed on check box
  onCheckboxChange(option, event) {
    if (event.target.checked) {
      this.selectedProfiles.push(option);
    } else {
      this.chkAll = false;
      for (var i = 0; i < this.selectedProfiles.length; i++) {
        if (this.selectedProfiles[i] == option) {
          this.selectedProfiles.splice(i, 1);
        }
      }

      this.dataStorage.globalProfiles.forEach(x => { if (x.profileId == option) { x.isProfileChecked = false } });
    }
  }

  // Method to add or delete favourites(single profile)
  addorDeleteFavourites(profileId, flag, isMultipleSelected = false) {
    if (!localStorage.userData) {

      this.dataStorage.singleDataforAddorRemoveFavorites = profileId;
      this.dataStorage.flagforAddorRemoveFavorites = flag;
      this.dataStorage.favoritesRequest = true;
      this.dataStorage.isMultipleProfilesSelectedForFavorites = isMultipleSelected;
      this.dataStorage.isInGuestOperation = true;

      // let modalRef = this.dialogService.open(CommonConfirmModalComponent, {
      //   panelClass:'msg-dialog-container',
      //   data: { message: "Are you sure you want to remove profile from favourites" }
      // });
      this.dialogService.open(LoginModalComponent, {
        data: { actionName: this.actionName.FAVORITES, details: { profileId: profileId, flag: flag, isMultipleSelected: isMultipleSelected } }
      });
      return false;
    }

    // console.log(isMultipleSelected)
    if (isMultipleSelected) {
      // console.log(profileId)
      this.profileIdsArray = profileId;
    }
    else {
      // console.log([profileId])
      this.profileIdsArray = [profileId];
    }


    let inputData = {
      enterpriseUserId: JSON.parse(localStorage.userData).enterpriseUserId,
      profileId: this.profileIdsArray
    }


    if (flag == 1) {
      this.profileInfoService.addToFavourites(inputData).subscribe((response) => {
        this.dataStorage.globalExpandedData[0].isInFavourites = true;
        if (isMultipleSelected) {
          this.selectedProfiles = [];
        }
        this.myAppService.showAlertMessage('Added to Favourites');
        this.chkAll = false;
        this.dataStorage.globalProfiles.map(x => { x.isProfileChecked = false });
      });
    }
    else {
      //  console.log(profileId.length > 1)
      let message: string
      if (profileId.length > 1) {
        message = 'Are you sure you want to Remove All Profiles from Favourite ?'
      }
      else {
        message = 'Are you sure you want to Remove Profile from Favourite ?'//favourite
      }
      if (true) {
        let dialog = this.dialogService.open(CommonConfirmModalComponent, {
          panelClass: 'msg-dialog-container',
          data: { message: message }
        });
        dialog.afterClosed().subscribe(confirmed => {
          // console.log(confirmed)
          if (!!confirmed) {
            this.profiledelete(inputData, isMultipleSelected, profileId)
          }
          else {

          }
        })

      }
      else {
        this.profiledelete(inputData, isMultipleSelected, profileId)
      }
      //  this.profiledelete(inputData,isMultipleSelected,profileId)

    }

  }

  profiledelete(inputData: any, isMultipleSelected, profileId) {
    this.profileInfoService.deleteFromFavourites(inputData).subscribe((response) => {
      this.dataStorage.globalExpandedData[0].isInFavourites = false;
      if (isMultipleSelected) {
        this.selectedProfiles = [];
      }
      if (this.fromPage == 'favourites') {
        this.status = 0;
        this.expandedData = [];
        this.dataStorage.globalExpandedData = [];

        if (isMultipleSelected) {
          this.profiles = this.profiles.filter(x => profileId.indexOf(x.profileId) == -1);
          this.dataStorage.globalProfiles = this.dataStorage.globalProfiles.filter(x => profileId.indexOf(x.profileId) == -1);
        }
        else {
          this.profiles = this.profiles.filter(x => x.profileId != profileId);
          this.dataStorage.globalProfiles = this.dataStorage.globalProfiles.filter(x => x.profileId != profileId);
        }


        // if (this.dataStorage.globalProfiles.length > 0)
        //   this.expandedData.push(this.dataStorage.globalProfiles[0]);
        // this.dataStorage.globalExpandedData.push(this.dataStorage.globalProfiles[0]);

        if (this.dataStorage.globalProfiles.length > 0) {
          this.fnGetProfileAdditionalDetails(this.dataStorage.globalProfiles[0]);
          // this.expandedData.push(this.dataStorage.globalProfiles[0]);
          // this.dataStorage.globalExpandedData.push(this.dataStorage.globalProfiles[0]);
        }


        this.profilesCount = this.dataStorage.globalProfiles.length;
      }

      this.myAppService.showAlertMessage('Removed from Favourites');
      this.chkAll = false;
      this.dataStorage.globalProfiles.map(x => { x.isProfileChecked = false });
      console.log('Global profiles: ', this.dataStorage.globalProfiles, this.dataStorage.globalExpandedData, this.expandedData);
    });
  }

  // Method to add or delete favourites(Multiple profiles)
  addorDeleteFavouritesMultiple(flag) {
    if (this.selectedProfiles.length == 0) {
      this.myAppService.showAlertMessage('Select atleast one profile to process');
      return false;
    }
    else {

      this.addorDeleteFavourites(this.selectedProfiles, flag, true);

    }
  }

  // Method to select all check boxes
  selectAllCheckboxes(event) {
    if (event.target.checked) {
      this.dataStorage.globalProfiles.map(x => { x.isProfileChecked = true });
      this.selectedProfiles = this.dataStorage.globalProfiles.map(({ profileId }) => profileId);
    }
    else {
      this.dataStorage.globalProfiles.map(x => { x.isProfileChecked = false });
      this.selectedProfiles = [];
    }
  }


  downloadFileClick(flag, details) {
    if (!localStorage.userData) {

      this.dataStorage.isInGuestOperation = true;
      this.dialogService.open(LoginModalComponent, {
        data: 'login'
      });
      return false;
    }

    var resource = null;
    if (flag == 1) {
      resource = details.mobileNumber + '_RESUME';
    }
    else if (flag == 2) {
      resource = details.interviewId + '_AUDIO';
    }
    else if (flag == 3) {
      resource = details.interviewId + '_REPORT';
    }

    var requestPayload = {
      "mobileNumber": flag == 1 ? details.mobileNumber : details.mobilenumber_JS,
      "flag": flag,
      "resourceId": flag == 1 ? details.resourceId : details.interviewId,
      "resource": resource,
      "enterpriseUserId": JSON.parse(localStorage.getItem("userData")) ? JSON.parse(localStorage.getItem("userData")).enterpriseUserId : 0,
      "profileId": details.profileId,
      "jobId": this.fromPage == this.fromPageNameChild.MYJOBS ? this.postedJobId : undefined,
      "featureId": this.dataStorage.membershipCurrentPlan.featureList.find((features) => {
        return features.featureCode == this.dataStorage.globalFeatureCodes.DOWNLOAD_PROFILE;
      }).featureId,
      "subscriptionId": this.dataStorage.membershipCurrentPlan.subscriptionId,

      interviewType: (this.fromPage == this.fromPageNameChild.SCHEDULEDINTERVIEWS && this.dataStorage.expandedInterviewType == MyAppHttpService.expandedScheduledInterviewsType.COMPANY) ?
        MyAppHttpService.expandedScheduledInterviewsType.COMPANY : null
    }


    this.profileInfoService.downloadFile(requestPayload).subscribe((response) => {
      if (!!response) {
        saveByteArray(b64toBlob(response.fileData, '', ''), response.fileName);
      }
      else {
        if (flag == 1) {
          this.myAppService.showAlertMessage("Resume not found");
        }
        else if (flag == 2) {
          // this.myAppService.showAlertMessage("File not found");
        }
        else if (flag == 3) {
          this.myAppService.showAlertMessage("Report not found");
        }
      }


    },
      (error) => {
      });
  }

  // Event raised when click and hire button clicked
  clickHire() {
    if (!this.isClickNHire) {
      this.IsClickandHire = true;
    }
    this.clickAndHireFormGroup.reset();
    this.isClickNHire = true;
    this.submitted = false;
    this.dataStorage.globalOfferDetails = {};

    this.cdr.detectChanges();

    setTimeout(() => {
      this.scrollProfileDetailsRightContent.nativeElement.scrollTop = this.scrollProfileDetailsRightContent.nativeElement.scrollHeight;
    }, 1);

  }


  // Method to
  proposeOffer(details) {
    var profileId = details.profileId;


    let resourceId: any;

    if (this.clickAndHireFormGroup.invalid) {
      this.submitted = true;

      return false;
    }
    else {
      if (!this.clickAndHireFormGroup.value.proposedSalary.trim()) {
        this.clickAndHireFormGroup.controls['proposedSalary'].setValue('');
      }

      if (!this.clickAndHireFormGroup.value.jobTitle.trim()) {
        this.clickAndHireFormGroup.controls['jobTitle'].setValue('');
      }
    }

    let userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      this.dataStorage.globalOfferDetails =
      {
        "profileId": profileId,
        "proposingSalary": this.clickAndHireFormGroup.value.proposedSalary,
        "joiningDate": this.convertDateToCustomFormat(this.clickAndHireFormGroup.value.joiningDate),
        "jobTitle": this.clickAndHireFormGroup.value.jobTitle,
        "resourceId": resourceId,
        "jobId": this.fromPage == this.fromPageNameChild.MYJOBS ? this.postedJobId : undefined
      };

      this.dataStorage.isInGuestOperation = true;
      this.dialogService.open(LoginModalComponent, {
        data: { actionName: this.actionName.ROLL_OUT_OFFER, details: {} }
      });
      return false;
    }


    else {
      this.submitted = false;

      let resourceId: any;
      let userData = JSON.parse(localStorage.getItem('userData'));

      if (userData) {
        resourceId = userData.enterpriseUserId;
      }
      else {
        resourceId = 0;
      }

      let inputData = {
        profileId: profileId,
        proposingSalary: this.clickAndHireFormGroup.value.proposedSalary,
        joiningDate: this.convertDateToCustomFormat(this.clickAndHireFormGroup.value.joiningDate),
        jobTitle: this.clickAndHireFormGroup.value.jobTitle,
        resourceId: resourceId,
        jobId: this.fromPage == this.fromPageNameChild.MYJOBS ? this.postedJobId : undefined
      }

      this.proposeOfferCommonFunction(inputData, details, 0);
    }
  }

  proposeOfferCommonFunction(inputData, details, flag) {





    this.profileInfoService.clickAndHire(inputData).subscribe((response) => {


      if (response.statuscode == 200) {
        this.proposeOfferButton = true;
        details.isOfferReleased = true;

        if (flag == 0) {
          details.offerJobTitle = this.clickAndHireFormGroup.value.jobTitle
          details.proposedSalary = this.clickAndHireFormGroup.value.proposedSalary
          details.joiningDate = this.clickAndHireFormGroup.value.joiningDate
        }
        else if (flag == 1) {
          details.offerJobTitle = this.dataStorage.globalOfferDetails.jobTitle
          details.proposedSalary = this.dataStorage.globalOfferDetails.proposingSalary
          details.joiningDate = this.dataStorage.globalOfferDetails.joiningDate
        }
        this.myAppService.showAlertMessage("Propose offer sent Successfully");
        this.IsClickandHire = false;
        this.isClickNHire = true;
        details.isOfferChecked = true;
        details.toEnableClickNHire = false;
        this.getOfferDetails(details);
      }
    });

  }

  // Method to convert date to custom format
  convertDateToCustomFormat(d) {
    let date = new Date(d),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  public likedProfile(data, profileId) {
    let inputData = {
      interested: 3,
      jobProfileMappingId: data,
      profileId: profileId,
      enterpriseUserId: JSON.parse(localStorage.userData).enterpriseUserId
    }
    this.profileInfoService.updateprofileInteresed(inputData).subscribe((response) => {
      if (response) {
        this.dataStorage.globalProfiles.forEach(x => {
          if (x.profileId == profileId) {
            x.isInterested = true;
            x.isNotInterested = false;
          }
        });

        this.expandedData[0].isInterested = true;
        this.expandedData[0].isNotInterested = false;

        this.dataStorage.globalExpandedData[0].isInterested = true;
        this.dataStorage.globalExpandedData[0].isNotInterested = false;

        this.dataStorage.globalProfiles = this.dataStorage.globalProfiles.sort((a, b) => (a.isInterested < b.isInterested || a.isNotInterested > b.isNotInterested) ? 1 : -1);
        this.filteredProfiles = this.filteredProfiles.sort((a, b) => (a.isInterested < b.isInterested || a.isNotInterested > b.isNotInterested) ? 1 : -1);
      }
    });
  }


  public dislikedProfile(data, profileId) {
    let inputData = {
      interested: 4,
      jobProfileMappingId: data,
      profileId: profileId,
      enterpriseUserId: JSON.parse(localStorage.userData).enterpriseUserId
    }
    this.profileInfoService.updateprofileInteresed(inputData).subscribe((response) => {
      if (response) {
        this.dataStorage.globalProfiles.forEach(x => {
          if (x.profileId == profileId) {
            x.isInterested = false;
            x.isNotInterested = true;
          }
        });

        this.expandedData[0].isInterested = false;
        this.expandedData[0].isNotInterested = true;

        this.dataStorage.globalExpandedData[0].isInterested = false;
        this.dataStorage.globalExpandedData[0].isNotInterested = true;

        this.dataStorage.globalProfiles = this.dataStorage.globalProfiles.sort((a, b) => (a.isInterested < b.isInterested || a.isNotInterested > b.isNotInterested) ? 1 : -1);
        this.filteredProfiles = this.filteredProfiles.sort((a, b) => (a.isInterested < b.isInterested || a.isNotInterested > b.isNotInterested) ? 1 : -1);
      }
    });
  }

  public dislikedProfile1(data, profileId) {
    let inputData = {
      interested: 2,
      jobProfileMappingId: data,
      profileId: profileId,
      enterpriseUserId: JSON.parse(localStorage.userData).enterpriseUserId
    }
    this.profileInfoService.updateprofileInteresed(inputData).subscribe((response) => {
      if (response) {
        this.dataStorage.globalProfiles.forEach(x => {
          if (x.profileId == profileId) {
            x.isInterested = false;
            x.isNotInterested = false;
          }
        });

        this.expandedData[0].isInterested = false;
        this.expandedData[0].isNotInterested = false;

        this.dataStorage.globalExpandedData[0].isInterested = false;
        this.dataStorage.globalExpandedData[0].isNotInterested = false;
      }
    });
  }

  public dislikedProfile2(data, profileId) {
    let inputData = {
      interested: 2,
      jobProfileMappingId: data,
      profileId: profileId,
      enterpriseUserId: JSON.parse(localStorage.userData).enterpriseUserId
    }
    this.profileInfoService.updateprofileInteresed(inputData).subscribe((response) => {

      if (response) {
        this.dataStorage.globalProfiles.forEach(x => {
          if (x.profileId == profileId) {
            x.isInterested = false;
            x.isNotInterested = false;
          }
        });

        this.expandedData[0].isInterested = false;
        this.expandedData[0].isNotInterested = false;

        this.dataStorage.globalExpandedData[0].isInterested = false;
        this.dataStorage.globalExpandedData[0].isNotInterested = false;
      }
    });
  }

  sendMessage(flag, details) {

    if (localStorage.userData) {
      if (!!details.emailId) {


        if (flag == 0) {
          this.openOutlook(details.emailId);
        }
        else if (flag == 1) {
          this.openGmail(details.emailId);
        }
      }

      else {
        this.myAppService.showAlertMessage("Profile EmailId doesn't exist");


      }
    }
    else {
      this.dataStorage.isInGuestOperation = true;
      this.dialogService.open(LoginModalComponent, {
        data: { actionName: this.actionName.SEND_MESSAGE, details: details }

      });
    }
  }


  scheduleMockInterview(details) {

    var profileId = details.profileId;
    this.dataStorage.ScheduleInterviewForExistingOrNonExistingUsers = "existing";
    if (localStorage.userData) {
      if (this.profileId) {
        this.dataStorage.profileIdForScheduleInterview = profileId;
      }
      // this.scheduleInterviewBtn = true;

      // this.dialogService.open(ScheduleMockinterviewComponent, {
      //   data: { dialogFor: this.dataStorage.globalFeatureCodes.INTERVIEW_RI, jobSkills: details.skillDescription, jobSkillIds: [] },
      // });

      details.fullName = details.userName;
      details.email = details.emailId;
      this.dataStorage.expandedInterviewType = MyAppHttpService.expandedScheduledInterviewsType.RI;
      this.reskillingsrvc.Scheduledialog = this.dialog.open(ReskillingScheduleInterviewComponent,
        {
          height: 'auto 400px',
          width: '720px',
          panelClass: 'custom-dialog-container',
          autoFocus: false,
          data: details,
          disableClose: true,
          id: 'scheduledialog'
        });
      // this.dialogService.open(ReskillingScheduleInterviewComponent, {
      //   data: details
      // });

    }
    else {
      this.dataStorage.isInGuestOperation = true;
      this.dialogService.open(LoginModalComponent, {
        data: 'login'
      });
    }

  }
  customInterviewDateAndTimeFormat(intDateAndTime) {

    let date = new Date(intDateAndTime),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      hours = ("0" + date.getHours()).slice(-2),
      mins = ("0" + date.getMinutes()).slice(-2);
    return ([date.getFullYear(), month, day].join(".") + ', ' + [hours, mins].join(':') + ((date.getHours() < 12) ? ' AM' : ' PM'));
  }


  getProbabilityOfJoining(profileId) {

    var tempProfileData = this.dataStorage.globalProfiles.find((profile) => { return profile.profileId == profileId });

    if (!!tempProfileData.probabiltyOfJoining) {

    }
    else {
      var loggedInUserId = !!localStorage.userData ? JSON.parse(localStorage.userData).enterpriseUserId : 0;
      var requestData = {
        "id": profileId,
        "searchKey": this.dataStorage.tempFindProfileSearchData.skills_Technology,
        "searchBy": loggedInUserId,
        "searchExp": this.dataStorage.tempFindProfileSearchData.experiences,
        "searchLocation": this.dataStorage.tempFindProfileSearchData.jobLocation,
        "searchDesignation": this.dataStorage.tempFindProfileSearchData.designation
      };



      this.profileInfoService.getProbabilityOfJoining(requestData).subscribe((response) => {
        if (response) {

          this.dataStorage.globalProfiles.filter((profile) => {
            if (profile.profileId == profileId) {
              profile.probabiltyOfJoining = response.probabilityOfJoining;
              profile.profileDetails[0].probabiltyOfJoining = response.probabilityOfJoining;
              this.expandedData[0].probabiltyOfJoining = response.probabilityOfJoining;
              this.dataStorage.globalExpandedData[0].probabiltyOfJoining = response.probabilityOfJoining;
            }
          });
        }
      }, (error) => {

      });
    }



  }

  getInterviewDetailsByEntUserIdAndProfileId(profileId) {
    if (true) {
      var tempProfileData = this.dataStorage.globalProfiles.find((profile) => { return profile.profileId == profileId });
      if (!tempProfileData.isJobSeeker || !!tempProfileData.isPrescreend) {
        return false;
      }
      if (!!tempProfileData.scheduleInterviewInfo) {
        return false;
      }
    }

    var loggedInUserId = !!localStorage.userData ? JSON.parse(localStorage.userData).enterpriseUserId : 0;
    if (loggedInUserId == 0) {
      return false;
    }
    var requestData = {
      "userId": profileId,
      "enterpriseUserId": loggedInUserId
    };

    this.profileInfoService.getInterviewDetailsByEntUserIdAndProfileId(requestData).subscribe((response) => {
      if (response) {

        if (response.profileCount > 0) {
          this.dataStorage.globalProfiles.filter((profile) => {
            if (profile.profileId == profileId) {
              profile.scheduleInterviewInfo = response.interviewInfo[0];
            }
          });
        }
        else {
          this.dataStorage.globalProfiles.filter((profile) => {
            if (profile.profileId == profileId) {
              profile.scheduleInterviewInfo = undefined;
            }
          });
        }

      }
    }, (error) => {

    });
  }

  shareProfile(details) {
    if (!!details.scheduleInterviewInfo && details.scheduleInterviewInfo.length > 0) {
      var requestObj = {
        interviewId: details.scheduleInterviewInfo[0].interviewId
      };
      details.latestInterviewId = details.scheduleInterviewInfo[0].interviewId;
      this.profileInfoService.getAllInterviewReportsForShare(requestObj).subscribe(response => {
        if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
          details.audioExist = response.audioExist;
          details.reportExist = response.reportExist;

          this.shareProfileCommonFunction(details);

        }
      }, error => {

      });

    }
    else {
      this.shareProfileCommonFunction(details);

    }
  }

  shareProfileCommonFunction(details) {
    //this.emailPopover.hideFromPopover();
    this.dataStorage.shareProfileData = {
      'profileId': details.profileId,
      'resumeExist': details.resumeExist,
      'audioExist': details.audioExist,
      'reportExist': details.reportExist,
      'mobileNumber': details.mobileNumber,
      'resourceId': details.resourceId,
      'enterpriseUserId': JSON.parse(localStorage.getItem("userData")).enterpriseUserId
    };

    if (localStorage.userData) {
      if (details.resumeExist || details.audioExist || details.reportExist) {
        this.dialogService.open(ShareProfileComponent, {
          data: details
        });
      }
      else {
        this.noDetailsAvailable = true;
        this.sharePopover.show();
        setTimeout(() => {
          this.sharePopover.hideFromPopover();
        }, 2000);
      }

    }
    else {
      this.dataStorage.isInGuestOperation = true;
      this.dialogService.open(LoginModalComponent, {
        data: { actionName: this.actionName.SHARE_PROFILE, details: details }
      });
    }
  }

  onValidatePricingAnonymousUserClick(flag, details, eventType) {
    // debugger;
    //details.isEnterpriseScheduled
    //deepu
    // alert('clicked sch int')
    if (eventType == this.dataStorage.globalFeatureCodes.COMMUNICATION) {
      if (flag == 3) {
        this.chatPopover.hide();
      }
      else if (flag == 2) {
        this.sharePopover.hide();
        //this.sharePopover.hideFromPopover();
      }
    }
    var actionName = '';
    if (eventType == this.dataStorage.globalFeatureCodes.DOWNLOAD_PROFILE) {
      actionName = this.actionName.DOWNLOAD_RESUME;
    }
    else if (eventType == this.dataStorage.globalFeatureCodes.PROPOSE_OFFER) {
      actionName = this.actionName.ROLL_OUT_OFFER;
    }
    else if (eventType == this.dataStorage.globalFeatureCodes.INTERVIEW_RI) {
      actionName = this.actionName.SCHEDULE_INTERVIEW;
    }
    else if (eventType == this.dataStorage.globalFeatureCodes.COMMUNICATION) {
      if (flag == 0) {
        actionName = this.actionName.SEND_MESSAGE_OUTLOOK;
      }
      if (flag == 1) {
        actionName = this.actionName.SEND_MESSAGE_GMAIL;
      }
      else if (flag == 2) {
        actionName = this.actionName.SHARE_PROFILE;
      }
      else if (flag == 3) {
        actionName = this.actionName.CHAT;
      }
    }
    else {
      actionName = '';
    }

    if (!localStorage.userData) {
      this.dataStorage.isInGuestOperation = true;
      this.dialogService.open(LoginModalComponent, {
        data: { actionName: actionName, details: details, flag: flag }
      });

      return false;
    }

    if (this.commonService.validateFeatureAvailability(eventType, actionName, details, flag)) {
      var requestData = {
        "subscriptionId": this.dataStorage.membershipCurrentPlan.subscriptionId,
        "featureId": this.dataStorage.membershipCurrentPlan.featureList.find((features) => {
          return features.featureCode == eventType;
        }).featureId,
        "resource": null
      };

      if (eventType == this.dataStorage.globalFeatureCodes.DOWNLOAD_PROFILE) {
        if (flag == 1) {
          requestData.resource = details.mobileNumber + '_RESUME';
        }
        else if (flag == 2) {
          requestData.resource = details.interviewId + '_AUDIO';
        }
        else if (flag == 3) {
          requestData.resource = details.interviewId + '_REPORT';
        }
      }

      this.commonService.getMemberFeatureEligibility(requestData).subscribe((success) => {
        if (success.statusCode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
          if (eventType == this.dataStorage.globalFeatureCodes.DOWNLOAD_PROFILE) {
            this.downloadFileClick(flag, details);
          }
          else if (eventType == this.dataStorage.globalFeatureCodes.PROPOSE_OFFER) {
            this.clickHire();
          }
          else if (eventType == this.dataStorage.globalFeatureCodes.INTERVIEW_RI) {
            this.scheduleMockInterview(details);
          }
          else if (eventType == this.dataStorage.globalFeatureCodes.COMMUNICATION) {
            if (flag == 0) {
              this.sendMessage(flag, details);
            }
            if (flag == 1) {
              this.sendMessage(flag, details);
            }
            else if (flag == 2) {
              this.shareProfile(details);
            }
            else if (flag == 3) {
              this.dataStorage.paymentErrorForChat = false;
              this.openChatDialog(details);
            }

          }
          else {
            this.myAppService.showAlertMessage('Invalid Selection');
          }
        }
        else {
          if (actionName != this.actionName.CHAT) {
            this.commonService.errorMethodForMemberSubscription(eventType, actionName, details, flag, success);
          }
          else {
            this.dataStorage.paymentErrorForChat = true;
            this.openChatDialog(details);
          }

        }
      }, (error) => {

      });
    }
    else {
      if (eventType == this.dataStorage.globalFeatureCodes.COMMUNICATION && flag == 3) {
        this.chatPopover.show();
      }
    }


  }

  openOutlook(emailId) {
    window.open(`mailto:${emailId}?body=`, "_self");

  }

  openGmail(emailId) {
    window.open('https://mail.google.com/mail/u/0/?view=cm&fs=1&to=' + emailId);

  }

  // pro:any = []
  // pageNumbers:any = [1]
  // prodata:any = []
  onPageChanges(event) {

    this.dataStorage.tempFindProfileSearchData.pageFrom = (event - 1) * this.dataStorage.paginationPageSize;
    this.dataStorage.tempFindProfileSearchData.pageSize = this.dataStorage.paginationPageSize;

    // console.log(this.dataStorage.tempFindProfileSearchData.pageSize)
    // this.dataStorage.tempFindProfileSearchData.pageSize = this.dataStorage.tempFindProfileSearchData.pageFrom
    // console.log(this.dataStorage.tempFindProfileSearchData)

    this.findProfileServie.searchProfiles(this.dataStorage.tempFindProfileSearchData).subscribe(success => {
      if (success.statuscode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
        if (success.profileInfo.length > 0) //success.profileInfo.length > 0
        {
          // console.log(success)
          success.profileInfo[0].profileDetails[0].resumeExist = !!success.profileInfo[0].profileDetails[0].resumeForFindProfile ? true : false;
          success.profileInfo[0].profileDetails[0].primarySkills = success.profileInfo[0].profileDetails[0].skillsExperienceAndRating.length != 0 ? success.profileInfo[0].profileDetails[0].skillsExperienceAndRating.filter((x: any) => x.type == '1') : [];
          success.profileInfo[0].profileDetails[0].secondarySkills = success.profileInfo[0].profileDetails[0].skillsExperienceAndRating.length != 0 ? success.profileInfo[0].profileDetails[0].skillsExperienceAndRating.filter((x: any) => x.type == '2' || x.type == '0') : [];
          this.dataStorage.globalProfiles = success.profileInfo;
          // console.log(this.dataStorage.globalProfiles)
          this.dataStorage.globalExpandedData = (success.profileInfo[0].profileDetails);
          // console.log(this.dataStorage.globalExpandedData)
          // success.profileDetails.resumeExist = !!success.profileDetails.resumePath ? true : false;

          if (this.commonService.validateFeatureAvailability(this.dataStorage.globalFeatureCodes.POJ, null, null, null)) {
            this.getProbabilityOfJoining(this.dataStorage.globalExpandedData[0].profileId);
          }

          document.getElementsByClassName('profile-left').length > 0 && (document.getElementsByClassName('profile-left')[0].scrollTop = 0);
        }
        else {
        }

      }
    });
  }


  openChatDialog(details) {
    this.chatPopover.hideFromPopover();

    if (this.dataStorage.paymentErrorForChat) {
      // this.showPopover = true;
      setTimeout(() => {
        this.chatPopover.show();
      }, 1);
      // console.log("Chat popover: ",this.chatPopover.closeOnClickOutside);

      this.cdr.detectChanges();
    }
    else {
      if (details) {
        window.scrollTo(0, 500);

        var loggedInUserInfo = JSON.parse(localStorage.getItem('userData'));
        var userChatInfo = {
          userId: loggedInUserInfo.enterpriseUserId,
          userName: loggedInUserInfo.firstName,
          userType: MyAppHttpService.chatUserTypes.RE,
          sourceUnreadCount: 0,
          adminChatId: '',
          userChatId: '',
          toUserType: MyAppHttpService.chatUserTypes.RI,
          toUserName: details.userName,
          toUserId: details.profileId,
          toEmailId: (this.dataStorage.globalExpandedData ? this.dataStorage.globalExpandedData[0].emailId : ''),
          toMobileNumber: (this.dataStorage.globalExpandedData ? this.dataStorage.globalExpandedData[0].mobileNumber : ''),
        };
        this.dataStorage.chatClicked = true;
        this.dataStorage.globalChatUserInfo = userChatInfo;

        this.wsService.invokeWebSocketFunction(userChatInfo, MyAppHttpService.chatEventNames.CHAT_ID_BY_USER);


        this.cdr.detectChanges();
      }
    }
  }

  @ViewChild('mobilePopover') mobilePopover: PopoverContentComponent;

  upgradePlan(value, popover) {

    if (value == 'yes') {

      setTimeout(() => {
        this.chatPopover.hideFromPopover();
        this.pojPopover.hideFromPopover();
        this.sharePopover.hideFromPopover();
        this.emailPopover.hideFromPopover();
        this.emailPricingPopover.hideFromPopover();
        this.scheduleInterviewPopover.hideFromPopover();
        this.videoPopover.hideFromPopover();
        if (this.expandedData[0].isPrescreend && this.dataStorage.globalExpandedData[0].isJobSeeker) {
          this.clickAndHirePopover.hideFromPopover();
        }

      }, 1);

      if (this.dataStorage.isUserLoggedIn) {
        // console.log('From profile info component');
        this.dialogService.open(PaymentComponent, {

        });
      }
      else {
        this.dialogService.open(LoginModalComponent, {

        });
      }

    }
    else if(value == 'no') {
      console.log("SudheerKumar");
      popover.hide();
      // this.dialog.close(value);
    }
    else {
      // setTimeout(() => {
        this.chatPopover.hideFromPopover();
        this.pojPopover.hideFromPopover();
        this.sharePopover.hideFromPopover();
        this.emailPopover.hideFromPopover();
        this.emailPricingPopover.hideFromPopover();
        this.scheduleInterviewPopover.hideFromPopover();
        this.videoPopover.hideFromPopover();
        if (this.expandedData[0].isPrescreend && this.dataStorage.globalExpandedData[0].isJobSeeker) {
          this.clickAndHirePopover.hideFromPopover();
        }
      // }, 10);

    }
  }

  ngOnDestroy() {
    this.dataStorage.chatClicked = false;
  }

  openChat() {
    if (this.dataStorage.chatClicked) {
      if (this.dataStorage.isUserLoggedIn) {
        if (!this.dataStorage.chatMinimised) {
          this.dataStorage.chatClicked = false;
          // console.log("Global expanded data : ", this.dataStorage.globalExpandedData);
          this.cdr.detectChanges();
          setTimeout(() => {
            this.dataStorage.chatClicked = true;
            this.cdr.detectChanges();
          }, 500);
        }
      }
    }
  }
  closeChatDialog() {
    this.dataStorage.chatClicked = false;
    this.dataStorage.globalUserChatConversationList = [];
    this.cdr.detectChanges();
  }

  isFeatureExists(featureCode) {
    if (!this.dataStorage.membershipCurrentPlan.featureList) {
      return false;
    }
    else {
      return this.dataStorage.membershipCurrentPlan.featureList.findIndex(x => x.featureCode == featureCode) > -1 ? true : false;
    }
  }

  getEmployerDetails(profileId) {
    let jobId = this.fromPage == this.fromPageNameChild.MYJOBS ? this.postedJobId : 0;

    let inputData = {
      jobId: jobId,
      profileId: profileId
    }
    this.profileInfoService.getEmployerDetails(inputData).subscribe((response) => {

      if (response.statusCode == 200) {
        this.employerDetails = response.employerDetails;
      }
    })

  }

  showVideo(details) {

    if (this.commonService.validateFeatureAvailability(this.dataStorage.globalFeatureCodes.VIDEO_PROFILE, null, null, null)) {
      let requestData = {
        'subscriptionId': this.dataStorage.membershipCurrentPlan.subscriptionId,
        'featureId': this.dataStorage.membershipCurrentPlan.featureList.find((features) => {
          return features.featureCode === this.dataStorage.globalFeatureCodes.VIDEO_PROFILE;
        }).featureId,
        'resource': null
      };
      this.commonService.getMemberFeatureEligibility(requestData).subscribe((success) => {

        // return success.statusCode == 200 ? this.getAllOpportunities() : false;
        if (success.statusCode == 200) {
          // this.getAllOpportunities();
          if (localStorage.userData) {
            if (details.isProfileVideoExists) {
              let request = {
                mobileNumber: details.mobileNumber
              }
              this.profileInfoService.downloadProfileVideo(request).subscribe((response) => {
                let videoPath = response.profileVideoPath;
                if (!!videoPath) {
                  this.dialogService.open(VideoModalComponent, {
                    data: { videoPath: videoPath, username: details.userName }
                  });
                }
              });
            }
          }
          else {
            this.dataStorage.isInGuestOperation = true;
            this.dialogService.open(LoginModalComponent, {
              data: 'login'
            });
          }
        }
        else if (success.statusCode == MyAppHttpService.RESPONSE_CODES.MEMBER_PLAN_EXPIRED) {
          this.myAppService.showAlertMessage('Your plan has expired');
          this.dialogService.open(PaymentComponent, {
            data: { actionName: this.dataStorage.globalFeatureCodes.VIDEO_PROFILE, popupFlag: true, details: details, flag: false, eventType: this.dataStorage.globalFeatureCodes.VIDEO_PROFILE }
          });
        }
      });
    }


  }
  // sailu
  ReadMore: boolean = true;
  visible: boolean = false;

  onClick1() {
    this.ReadMore = !this.ReadMore;
    this.visible = !this.visible;
  }



  getReferenceDetail(referenceId: number) {
    this.commonService.getReferenceDetail(referenceId);
  }

  getOfferDetails(details) {
    console.log("isOfferChecked => ", details.isOfferChecked)
    // details.isOfferChecked=true
    !!details.isOfferChecked == true ? details.isOfferChecked = !details.isOfferChecked : details.isOfferChecked = true
    console.log("details.isOfferChecked => ", details.isOfferChecked)
    console.log("offerDetails => ", this.offerDetails, "localStorage.userData =>", localStorage.userData)
    this.offerDetails = [];
    if (localStorage.userData) {
      let inputData = {
        profileId: details.profileId,
        enterpriseUserId: JSON.parse(localStorage.userData).enterpriseUserId
      };

      this.profileInfoService.getOfferSummary(inputData).subscribe(response => {
        console.log("offerDetails => ", inputData, response)
        if (response && response.statusCode == 200) {
          this.offerDetails = response.offersSummary;
          console.log("offerDetails => ", this.offerDetails)
        }
      });
    }

  }

  // getOfferDetails(details) {
  //   details.isOfferChecked = !details.isOfferChecked
  //   this.offerDetails = [];
  //   if (localStorage.userData) {
  //     let inputData = {
  //       profileId: details.profileId,
  //       enterpriseUserId: JSON.parse(localStorage.userData).enterpriseUserId
  //     };

  //     this.profileInfoService.getOfferSummary(inputData).subscribe(response => {

  //       if (response && response.statusCode == 200) {
  //         this.offerDetails = response.offersSummary;
  //       }
  //     });
  //   }

  // }

  CopyNum() {      //priya
    let numberCopy = this.dataStorage.globalExpandedData[0].mobileNumber;
    this._clipboardService.copyFromContent(numberCopy);
    this.myAppService.showAlertMessage('Mobile Number Copied');

  }


  sharFeedback: any = 0;
  interviewId: any;
  onChange(ob: any, value: any) {
    this.checkboxChecked = ob;
    let modalRef = this.dialogService.open(CommonConfirmModalComponent, {
      panelClass: 'msg-dialog-container',
      data: { message: "Are you sure you want to share Mentor feedback to Jobseeker? \n Once it shared to Jobseeker, this action can't be undone." }
    });

    modalRef.afterClosed().subscribe(confirmed => {

      if (!!confirmed) {

        this.interviewId = value
        localStorage.setItem("interviewId", this.interviewId)
        let inputData = {
          'interviewId': this.interviewId
        }

        this.profileInfoService.shareFeedback(inputData).subscribe(success => {
          this.sharFeedback = 2
          this.checkboxChecked = true;
          this.checkboxDisable = true
          this.myAppService.showAlertMessage("Shared Feedback successfully");
        })
      }
      else {
        this.checkboxChecked = false;
      }
    })
  }

  unCheck() {

    this.myAppService.showAlertMessage("You can\'t perform this action on Cancelled interview ");
  }

  check(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  // convertToNum:boolean = false
  dropdowselect(val) {
    // let convertToNum:boolean = false
    // console.log(val,'deepu')
    if (this.searchview == 'scheduleinterviewscompoment') {
      // let searchvalue:any = document.getElementById("inpsearch1")
      // console.log(searchvalue.value)
      if (val == 1) {
        if (this.convertToNum) {
          this.convertToNum = false
          this.inputvalue = ''
        }
        this.c = 1
        // this.inputvalue = ''
        this.selectoptionname = 'Email'
        this.inputtype = 'email'
        this.selectedtype = 1
        this.dataStorage.globalProfiles = this.sendreceiveservice.globalSearch(this.inputvalue, this.sendreceiveservice.searching_implementation_for_profiles, this.selectedtype)
        this.updateddatesearch(this.dataStorage.globalProfiles)
      }
      if (val == 2) {
        if (this.convertToNum) {
          this.convertToNum = false
          this.inputvalue = ''
        }
        this.c = 1
        this.selectoptionname = 'Name'
        this.inputtype = 'name'
        this.selectedtype = 2

        this.dataStorage.globalProfiles = this.sendreceiveservice.globalSearch(this.inputvalue, this.sendreceiveservice.searching_implementation_for_profiles, this.selectedtype)
        this.updateddatesearch(this.dataStorage.globalProfiles)
      }
      if (val == 3) {

        this.convertToNum = true
        this.c = 1
        this.inputvalue = ''
        this.selectoptionname = 'Mobile'
        this.inputtype = 'number'
        this.selectedtype = 3
        if (this.inputvalue == '') {
          this.dataStorage.globalProfiles = this.sendreceiveservice.globalSearch(this.inputvalue, this.sendreceiveservice.searching_implementation_for_profiles, this.selectedtype)
          this.updateddatesearch(this.dataStorage.globalProfiles)
        }

      }
    }
    else if (this.searchview == 'find-profiles') {

      if (val == 1) {
        this.c = 1
        // this.inputvalue = ''
        this.selectoptionname = 'Email'
        this.selectedtype = 4
      }
      if (val == 2) {
        this.c = 1
        // this.inputvalue = ''
        this.selectoptionname = 'Name'
        this.selectedtype = 2
      }
      if (val == 3) {
        this.c = 1
        // this.inputvalue = ''
        this.selectoptionname = 'Mobile'
        this.selectedtype = 5
      }
    }

    // this.dropdownMethod()
  }

  dropdownMethod() {
    if (this.count == 0) {
      //  console.log('dropdown fired')
      this.c = 0
      try {
        // document.getElementById("myDropdown").classList.toggle("show");
        this.toggle = document.getElementById("myDropdown").style.display = 'block';
        //.classList.toggle("show");

      }
      catch (e) {
        console.log(e)
      }
    }
    else if (this.count == 1) {
      try {
        // document.getElementById("myDropdown").classList.toggle("show");
        if (this.toggle == 'block') {
          this.toggle = document.getElementById("myDropdown").style.display = 'none'
        }
        else if (this.toggle == 'none') {
          this.toggle = document.getElementById("myDropdown").style.display = 'block'
        }
      }
      catch (e) {
        console.log(e)
      }
      this.count = this.count - 1
      this.c = 1
    }
    if (!this.dropdownshow) {
      this.dropdownshow = true
      window.onclick = (event) => {
        if (this.c == 0)
          if (this.count == 0) {
            this.count = this.count + 1
          }
          else if (this.count == 1) {
            try {
              // document.getElementById("myDropdown").classList.toggle("show");
              this.toggle = document.getElementById("myDropdown").style.display = 'none'
            }
            catch (e) {
              console.log(e)
            }
            this.count = this.count - 1
            this.c = this.c + 1
          }
      }
    }
    else {
      // this.selectoptionsymbol = '▼'
      this.dropdownshow = false
    }
  }
  //(keypress)="searchInputAllow($event)"
  //(keypress)="searchInputAllow(inputSearch,inputtype)"

  allowonlydecimal(event, inputSearch) {

    if (this.inputtype == 'number') {
      let charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31
        && (charCode < 48 || charCode > 57) || inputSearch.value.length > 9)
        return false;
      return true;
    }
  }

  joinMeeting(int) {
    if (!!int) {
      window.open(int.meetingLink)
    }
  }

  primarySkills: any = [
    // { Primary_Skills: "Machine Learning", Experinece: "3 Years 6 Months", Self_Rating: "" },
    {
      "techId": 1,
      "skill": [''],
      "experience": "",
      "rating": "",
      "type": 1
    },
  ];
  secondarySkills: any = [
    // { Secondary_Skills: "React JS 1.4 Years" }
    {
      "techId": 4,
      "skill": "",
      "experience": "",
      "rating": "",
      "type": 0
    },
  ]
  ovpauc: any
  Click_chat_icon(details: any) {
    if (this.dataStorage.isUserLoggedIn) {
      let ife: any = this.isFeatureExists(this.dataStorage.globalFeatureCodes.COMMUNICATION)
      let priceVal: any = this.onValidatePricingAnonymousUserClick(3, details, this.dataStorage.globalFeatureCodes.COMMUNICATION)
      // console.log("priceVal => ",priceVal)
      setTimeout(() => {
        console.log("this.ovpauc => ", this.ovpauc)
        if (this.ovpauc.statusCode == 200) {
          this.openChatDialog(details)
        }
        // statusMessage
      }, 500);
    }
    else {

    }

  }

  share: any
  popoverShare(details: any) {
    let sre: any = this.isFeatureExists(this.dataStorage.globalFeatureCodes.COMMUNICATION)
    let over: any = this.onValidatePricingAnonymousUserClick(2, details, this.dataStorage.globalFeatureCodes.COMMUNICATION); this.chatPopover.hideFromPopover()
    setTimeout(() => {
      console.log("this.share => ", this.share)
      if (this.shareFeedback.statusCode == 200) {
        this.shareProfile(details)
      }
    }, 500);
  }

  isExpanded: boolean[] = [];

  toggleContent(index: number) {
    this.isExpanded[index] = !this.isExpanded[index];
  }

  convertDaysIntoMonths(daysToConvert) {
    // console.log('Days: ', daysToConvert);
    let convertedDays = '';
    if (!!daysToConvert && daysToConvert != 0) {
      let days1 = Math.floor(daysToConvert / 30);
      let days2 = Math.floor(daysToConvert % 30);
      // debugger
      // console.log('Days 1 and Days2: ', days1, days2);
      if (days1 == 0) {
        if (days2 == 0) {
          convertedDays = ' today';
        }
        else if (days2 > 0 && days2 < 30) {
          convertedDays = Math.floor(days2) + ' days ago';
        }
      }
      else if (days1 == 1) {
        if (days2 == 0) {
          convertedDays = 1 + ' month ago';
        }
      }
      else if (days1 > 1 && days1 < 12) {
        convertedDays = Math.floor(days1) + ' months ago';
      }
      else if (days1 >= 12) {
        let months = days1 / 12;
        if (months <= 1) {
          convertedDays = 1 + ' year ago';
        }
        if (months > 1) {
          convertedDays = Math.floor(months) + ' years ago';
        }
      }
      else if (days1 < 0) {
        convertedDays = Math.floor(days1) + ' days ago';
      }
    }
    else {
      convertedDays = ' today';
    }
    // console.log('Converted days: ', convertedDays);
    return convertedDays;

  }

}
// }
