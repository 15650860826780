


import { Component, OnInit, ViewEncapsulation, Inject, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '../../login-dialog.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataStorageService } from '../../../../../services/data-storage.service';
import { LoginService } from 'src/app/components/views/login/login.service';
import { environment } from '../../../../../../environments/environment';
import { PricingService } from 'src/app/components/views/pricing/pricing.service';
import { MyAppHttpService } from '../../../../../services/common/my-app-http.service';
declare var Razorpay: any;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentComponent implements OnInit {

  nextPage: string = '';
  math = Math;

  constructor(private route: Router,
    public dialogService: DialogService, public loginService: LoginService,
    public dialogRef: MatDialogRef<PaymentComponent>, public pricingService: PricingService, public ref: ChangeDetectorRef,
    public myAppService: MyAppHttpService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dataStorage: DataStorageService) {
    this.nextPage = localStorage.getItem('nextPage');

  }

  ngOnInit() {
    if (!!localStorage.getItem("userData")) {
      if (!this.dataStorage.isPaymentDone) {
        var userData = JSON.parse(localStorage.getItem("userData"));
        this.loginService.updatePaymentStatus(userData.enterpriseUserId, 1).subscribe(success => {
          this.dataStorage.setPaymentFlag(true);
        }, error => { });
      }
    }

    setTimeout(() => {
   
    }, 2000);
  }
  onModalClose() {
  

    this.dialogRef.close();
  }

  public next() {
    if (!!localStorage.getItem("userData")) {
      var userData = JSON.parse(localStorage.getItem("userData"));
      this.loginService.updatePaymentStatus(userData.enterpriseUserId, 1).subscribe(success => {
        this.dataStorage.setPaymentFlag(true);
        this.route.navigate(['/home'])
      }, error => { });
    }
    else {
      this.route.navigate(['/home'])
    }

  }

  buyPlan(plan) {
  
    if (navigator.onLine) {
      let userData = JSON.parse(localStorage.getItem("userData"));
      if (!userData) {
        this.dialogService.clear();
        this.route.navigate(['login']);
        return false;
      }
      this.payment(plan);
      this.dialogService.clear();
    }

  }

  payment(plan) {
    var self = this;
    let options = {
      description: 'value',
      image: environment.payment.image,
      currency: 'INR',
      key: environment.payment.key,
      amount: plan.price * 100,
      name: this.dataStorage.loggedInUserData.firstName,
      handler: (response) => {
        this.dataStorage.unreadNotificationsCountBoolean = true;  //notification
        self.successCallback(plan, response.razorpay_payment_id);

      },
      prefill: {
        email: this.dataStorage.loggedInUserData.email,
        name: this.dataStorage.loggedInUserData.firstName
      },
      theme: {
        color: '#99226e'
      },
      modal: {
        ondismiss: () => {
           self.MethodAfterCancel(plan);
        },
      }
    };
    var rzp1 = new Razorpay(options);
    rzp1.open();

  };

  successCallback(plan, payment_id) {
    var requestObj = {
      "enterpriseId": this.dataStorage.loggedInUserData.enterpriseUserId,
      "planId": plan.planId,
      "paymentType": "razorpay",
      "paymentId": payment_id
    };
    this.pricingService.subscribeToPlan(requestObj).subscribe((success) => {
     

      this.dataStorage.membershipCurrentPlan = !!success.planId ? this.dataStorage.membershipPlans.find(x => x.planId == success.planId) : {};
      this.dataStorage.membershipCurrentPlan.planDurationNum = success.planDuration;
      this.dataStorage.membershipCurrentPlan.subscriptionId =success.subscriptionId;
      this.dataStorage.membershipCurrentPlan.priority = this.dataStorage.membershipPlans.find(x=>x.planId == success.planId).priority;

      if (!!success.planId) {
        this.dataStorage.globalAvailableFeatureInfo = JSON.parse(JSON.stringify(MyAppHttpService.AVAILABLE_FEATURE_INFO));
        this.dataStorage.membershipCurrentPlan.featureList.forEach(cf => {
          this.dataStorage.globalAvailableFeatureInfo.forEach(f =>
            (f.featureCode == cf.featureCode) ? f.isAvailable = true : false);
        });
      }

      if (this.dataStorage.getPayments.observers.length > 1){
        this.dataStorage.getPayments.observers = [this.dataStorage.getPayments.observers.pop()];
      }

      if(this.dataStorage.subPostAJob.observers.length > 1){
        this.dataStorage.subPostAJob.observers = [this.dataStorage.subPostAJob.observers.pop()];
      }

      if(this.dataStorage.subMyPlan.observers.length > 1){
        this.dataStorage.subMyPlan.observers = [this.dataStorage.subMyPlan.observers.pop()];
      }

      if(this.data.eventType == this.dataStorage.globalFeatureCodes.POST_A_JOB){
      }
      else{
        this.dataStorage.getPayments.next({ actionName: this.data.actionName, details: this.data.details, flag: this.data.flag, eventType: this.data.eventType });
      }

      if (this.data.actionName == 'MY_PLAN') {
        this.dataStorage.subMyPlan.next();
        // this.dialogRef.close();
      }

      // this.ref.detectChanges();
    }, (error) => { });

  };

  MethodAfterCancel(plan) {
    this.dataStorage.unreadNotificationsCountBoolean = true;  //notification
    console.log('cancelCallback error: ');
    let requestData = {
      'enterpriseUserId': this.dataStorage.loggedInUserData.enterpriseUserId,
      'planId' : plan.planId,
      'paymentType' : 'razorpay'
    };

    this.pricingService.membershipPaymentCancelled(requestData).subscribe(response => {

    });
  }

  openOutlook(emailId) {
    window.open(`mailto:${emailId}?body=`, "_self");
  }

  openGmail(emailId) {
    window.open('https://mail.google.com/mail/u/0/?view=cm&fs=1&to=' + emailId);
  }

  getfeatureData(featureCode,planId){
    var feature =  this.dataStorage.membershipPlans.find(x=>x.planId == planId).featureList.find(x=>x.featureCode == featureCode);
    var returnValue = '';

    if(!!feature){
      if(feature.creditType == 'count'){
        return feature.credits;
      }
      else if(feature.creditType == 'discount'){
        //return 'a'
      return `
      ₹${this.math.floor( (feature.featurePrice - ((!!feature.credits ? (feature.credits) : 0) * feature.featurePrice) / 100))}
      <del>${(feature.featurePrice - ((!!feature.credits ? (feature.credits) : 0) * feature.featurePrice) / 100)  != feature.featurePrice ?  ('(₹' + feature.featurePrice + ')' ) : ''}</del>`;
      }
      else if(feature.creditType == 'boolean'){
        return '<img class="blue-tick-img" src="assets/images/blue_tick_new.png"/>';
      }
      else{
        return '';
      }
    }
    else {
      return '-';
    }

  }

}



