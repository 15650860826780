import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobaldataService {

  profileId : any ;
  flag : any ;
  constructor() { }

  setData(v1, v2){
    this.profileId = v1;
    this.flag = v2;
  }
}
