import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadBrochureComponent } from './download-brochure.component';
import { AppMaterialModule } from 'src/app/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({

  imports: [
    CommonModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule

  ],
  declarations: [DownloadBrochureComponent],
  exports: [DownloadBrochureComponent],
  entryComponents:[DownloadBrochureComponent]
})

export class DownloadBrochureModule { }
