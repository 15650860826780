import { RouterModule } from "@angular/router";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoginModalComponent } from "./login-modal.component";
import { AppMaterialModule } from "src/app/app-material.module";
import { RegisterModalModule } from "../../register/register-modal/register-modal.module";
import { ForgotpasswordmodalModule } from "../../forgotpasswordmodal/forgotpasswordmodal.module";



@NgModule({
    imports: [
        TranslateModule,
        AppMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RegisterModalModule,
        ForgotpasswordmodalModule,
        
    ],
    declarations: [LoginModalComponent],
    exports: [LoginModalComponent],
    entryComponents:[LoginModalComponent]
    
})
  
export class LoginModalModule {}