import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/app-material.module';
import { CommonPipesModule } from 'src/app/pipes/common-pipes/common-pipes.module';
import { MatTabsModule } from '@angular/material';
import { EmployerDetailsComponent } from './employer-details.component';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    CommonPipesModule,
    MatTabsModule
  ],
  declarations: [EmployerDetailsComponent],
  exports: [EmployerDetailsComponent],
  entryComponents: [EmployerDetailsComponent]
})
export class EmployerDetailsModule { }
