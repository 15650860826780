import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnonymousLayoutComponent } from './components/common/layouts/anonymous-layout/anonymous-layout.component';
import { AdminLayoutComponent } from './components/common/layouts/admin-layout/admin-layout.component';
import { UserLayoutComponent } from './components/common/layouts/user-layout/user-layout.component';
import { AuthGuardService } from './services/common/auth-guard.service';
import { AnonymousAnotherLayoutComponent } from './components/common/layouts/anonymous-another-layout/anonymous-another-layout.component';
import { HomeComponent } from './components/views/home/home.component';
import { TourHomeComponent } from './components/views/tour/tour-home/tour-home.component';
import { TourFindprofilesComponent } from './components/views/tour/tour-findprofiles/tour-findprofiles.component';
import { TourPostajobComponent } from './components/views/tour/tour-postajob/tour-postajob.component';
import { StaffingAgenciesComponent } from './components/views/staffing-agencies/staffing-agencies.component';
import { ReskillingPageComponent } from './components/views/reskilling-page/reskilling-page.component';
import { UnsubscribeComponent } from './components/views/unsubscribe/unsubscribe.component';
import { GoogleCalendarComponent } from './components/views/google-calendar/google-calendar.component';
//import { GlobalscheduleinterviewComponent } from './components/views/globalscheduleinterview/globalscheduleinterview.component';


const routes: Routes = [
  {
        path: '',
        component: HomeComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/home/home.module#HomeModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'admin',
        component: AdminLayoutComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                loadChildren: './components/views/home/home.module#HomeModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'user',
        component: UserLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/home/home.module#HomeModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'registration',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/register/register.module#RegisterModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'forgotpassword',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/forgotpassword/forgotpassword.module#ForgotPasswordModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'resetpassword/:token',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/resetpassword/resetpassword.module#ResetPasswordModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'login',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/login/login.module#LoginModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'verifyemail/:token',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/verify-email/verify-email.module#VerifyEmailModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'findprofile',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/find-profile/find-profile.module#FindProfileModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'favouriteprofile',
        canActivate: [AuthGuardService],
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/favourite-profile/favourite-profile.module#FavouriteProfileModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'jobs',
        canActivate: [AuthGuardService],
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/jobs/jobs.module#JobsModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'postajob',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/postajob/postajob.module#PostajobModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuardService],
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/dashboard/dashboard.module#DashboardModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'pricing',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/pricing/pricing.module#PricingModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'reskillingpage',
        canActivate: [AuthGuardService],
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/reskilling-page/reskilling-page.module#ReskillingPageModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'meetings',
        // component: MeetingsComponent,
        canActivate: [AuthGuardService],
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/meetings/meetings.module#MeetingsModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'myprofile/:id',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/myprofile/myprofile.module#MyprofileModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'changepassword',
        canActivate: [AuthGuardService],
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/changepassword/changepassword.module#ChangepasswordModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'rolledoutOffers',
        canActivate: [AuthGuardService],
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/rolledout-offers/rolledout-offers.module#RolledoutOffersModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'scheduledInterviews',
        canActivate: [AuthGuardService],
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/scheduledinterviews/scheduledinterviews.module#ScheduledInterviewsModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'terms',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/termspage/termspage.module#TermsPageModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'privacy',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/privacy/privacy.module#PrivacyModule',
               // loadChildren: './components/views/find-profile/find-profile.module#FindProfileModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'help',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/help/help.module#HelpModule',
               // loadChildren: './components/views/find-profile/find-profile.module#FindProfileModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'tour-home',
        component: TourHomeComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/tour/tour-home/tour-home.module#TourHomeModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'tour-findprofiles',
        component: TourFindprofilesComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/tour/tour-findprofiles/tour-findprofiles.module#TourFindprofilesModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'tour-postajob',
        component: TourPostajobComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/tour/tour-postajob/tour-postajob.module#TourPostajobModule',
                data: { title: '' }
            }
        ]
    },
   {
        path: 'chat',
        canActivate: [AuthGuardService],
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/chat/chat.module#ChatModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'staffingAgencies',
        component: StaffingAgenciesComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/staffing-agencies/staffing-agencies.module#StaffingAgenciesModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'contractors',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/contractors/contractors.module#ContractorsModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'notifications',
        canActivate: [AuthGuardService],
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/notifications-inboxlist/notifications-inboxlist.module#NotificationsInboxlistModule',
                data: { title: '' }
            }
        ]
    },
    {
        // path: 'myaccount/:id',
        path: 'myaccount',
        canActivate: [AuthGuardService],
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/myaccount/myaccount.module#MyaccountModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'hiring-id',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/hiringid-profile/hiringid-profile.module#HiringidProfileModule',
               // loadChildren: './components/views/find-profile/find-profile.module#FindProfileModule',
                data: { title: '' }
            }
        ]
    },
    {
        path: 'globalscheduleinterview',
        // canActivate: [AuthGuardService],
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/globalscheduleinterview/globalscheduleinterview.module#GlobalscheduleinterviewModule',
                
                data: { title: '' }
            }
        ]
    },
    {
        path: 'find-partners',
        canActivate: [AuthGuardService],
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/find-partners/find-partners.module#FindPartnersModule',
                
                data: { title: '' }
            }
        ]
    },
    {
        path: 'managefinances',
        canActivate: [AuthGuardService],
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/managefinances/managefinances.module#ManagefinancesModule',
                
                data: { title: '' }
            }
        ]
    },
    {
        path: 'resume-builder',
        canActivate: [AuthGuardService],
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/resume-builder/resume-builder.module#ResumeBuilderModule',

                data: { title: '' }
            }
        ]
    },
    {
        path: 'jobdetails',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/jobdetails/jobdetails.module#JobdetailsModule',
              
                data: { title: '' }
            }
        ]
    },
    {
        path: 'unsubscribe',
        component: UnsubscribeComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/unsubscribe/unsubscribe.module#UnsubscribeModule',
              
                data: { title: '' }
            }
        ]
    },
    {
        path: 'googleCalendar',
        component: AnonymousLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/views/google-calendar/google-calendar.module#GoogleCalendarModule',
              
                data: { title: '' }
            }
        ]
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }








