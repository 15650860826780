import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameByIdFilter'
})
export class NameByIdFilterPipe implements PipeTransform {

  transform(id: any,items: any[]): any {
    if(!!id){
    id = parseInt(id);
  }
    if(!!items && !!id){
        return items.filter(x=>x.noticePeriodId == id).length > 0 ? items.filter(x=>x.noticePeriodId == parseInt(id))[0].noticePeriodDescription: '';
    }
    else{
     return 'Not Available';  
    }
  }

}