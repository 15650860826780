import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DataStorageService } from 'src/app/services/data-storage.service';

@Component({
  selector: 'app-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchFiltersComponent implements OnInit {
  masterData: any;
  searchFiltersForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>,public fb: FormBuilder, 
  public dataStorage: DataStorageService) { }

  ngOnInit() {
    this.masterData = this.dataStorage.masterData;

    this.searchFiltersForm = this.fb.group({
      experiences: this.fb.array([]),
      noticePeriods: this.fb.array([]),
      rockRatings: this.fb.array([]),
      expectedCTCs: this.fb.array([]),
      lastUpdated: this.fb.array([]),
      freelancer: [false]
    })
  }

  updateChkbxArray(id, isChecked, key) {
 
    const chkArray = < FormArray > this.searchFiltersForm.get(key);
    if (isChecked) {
      chkArray.push(new FormControl(id));
    } else {
      let idx = chkArray.controls.findIndex(x => x.value == id);
      chkArray.removeAt(idx);
    }
  }

  submitFilters() {
    this.dialogRef.close(this.searchFiltersForm.value);
  }

}
