import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { LoginService } from './components/views/login/login.service';
import { DataStorageService } from './services/data-storage.service';
import { Router, NavigationStart, Event, RoutesRecognized, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd, NavigationCancel, NavigationError, RouterOutlet } from '@angular/router';
import { MyAppHttpService } from './services/common/my-app-http.service';
import { HomeService } from './components/views/home/home.service';
import { ConnectionService } from 'ng-connection-service';
import { PricingService } from './components/views/pricing/pricing.service';
import { CommonfunctionService } from './services/common/commonfunction.service';
import { fader, slider, stepper } from './animation';
import { WebsocketService } from './ws/websocket.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ // <-- add your animations here
     fader,
  
  ]
})
export class AppComponent {
  title = 'RIEnterprise';
  message;

  constructor(
    private translate: TranslateService,
    private http: Http,
    private loginService: LoginService,
    public dataStorage: DataStorageService,
    private route: Router,
    private myAppService: MyAppHttpService,
    private homeService: HomeService,
    private connectionService: ConnectionService,
    private pricingService: PricingService,
    private commonServices: CommonfunctionService,
    private wsService: WebsocketService
   ) {

  

    const config = environment.firebase;

 

    if(localStorage.getItem('userData')){
      this.dataStorage.loggedInUserData = JSON.parse(localStorage.getItem('userData'));
    }
    wsService.messages.subscribe(msg =>{
      });



    translate.setDefaultLang('en');

    this.connectionService.monitor().subscribe(isConnected => {
      var isConnected = isConnected;
      if (isConnected) {
      }
      else {
        window.location.reload();
      }
    })


    this.http.get("/assets/i18n/en.json").subscribe((res: Response) => {
      // observer.next(res.json());
      // observer.complete();


    });

    this.route.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (!!localStorage.getItem("userData")) {
          if(event.url == '/tour-findprofiles' || event.url == '/tour-postajob' || event.url == '/tour-home'){

          }
          else{
            this.commonServices.getCurrentPlanDetails();
          }
          if (!this.dataStorage.isPaymentDone && (event.url == '/jobs' || event.url == '/dashboard' || event.url == '/favouriteprofile' || event.url == '/rolledoutOffers' || event.url == '/scheduledInterviews' || event.url == '/chat' || event.url.indexOf('/myaccount') > -1)) {
            this.route.navigate(['/pricing']);
          }
        }
        else {
          if (event.url == '/jobs' || event.url == '/dashboard' || event.url == '/favouriteprofile' || event.url == '/rolledoutOffers' || event.url == '/scheduledInterviews' || event.url == '/chat' || event.url == '/jobs?tab=opportunity' || event.url.indexOf('/myaccount')  > -1) {
            this.myAppService.showAlertMessage('Please login to view this page');
            this.route.navigate(['/login']);
          }
        }
        //An event triggered when navigation starts.
      } else if (event instanceof RoutesRecognized) {
        //An event triggered when the Router parses the URL and the routes are recognized.
      } else if (event instanceof RouteConfigLoadStart) {
        //An event triggered before the Router lazy loads a route configuration.
      } else if (event instanceof RouteConfigLoadEnd) {
        //An event triggered after a route has been lazy loaded.
      } else if (event instanceof NavigationEnd) {
        if(event.url.indexOf('/verifyemail') > -1 || event.url.indexOf('/login') > -1 || event.url.indexOf('/registration') > -1){
          localStorage.clear();
        }
        this.dataStorage.paginationIndex = 1;
        if(event.url == '/tour-findprofiles' || event.url == '/tour-postajob' || event.url == '/tour-home'){
          document.body.style.overflow = 'hidden';
          this.dataStorage.isInTourPage = true;
        }
        else{
          document.body.style.overflow = 'visible';
          this.dataStorage.isInTourPage = false;
        }
        if (!!window) {
          window.scrollTo(0, 0);
        }

        //An event triggered when navigation ends successfully.
      } else if (event instanceof NavigationCancel) {
        //An event triggered when navigation is canceled. This is due to a Route Guard returning false during navigation.
      } else if (event instanceof NavigationError) {
        //An event triggered when navigation fails due to an unexpected error.
      }
    });


    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });

    this.http.post("/assets/i18n/en.json",
      '{ "Title": "Translation example", "Intro": "Hello I am Praveen, I am 27 years old."  }', options).subscribe((res: Response) => {
      
      });

  }


  ngAfterViewInit() {
    setTimeout(() => {
      document.getElementById('footer').style.display = 'block';
      document.getElementById('NotificationDiv').style.display = 'none';
    }, 2000);

    let openedBrowser = this.findBrowser();

    // if(!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)){
    if(openedBrowser == 'chrome') {

    }
    else{
      document.getElementById('divChromeToast').style.display = 'block';
    }

    setTimeout(() => {
      document.getElementById('divChromeToast').style.display = 'none';
    }, 10000);

    setTimeout(() => {
      this.dataStorage.isTourRendered = true;
    }, 1000);

  }

  ngOnInit() {


 
    this.dataStorage.isNotificationsBlocked = true;


    if (!!localStorage.getItem("userData")) {
      var userData = JSON.parse(localStorage.getItem("userData"));
      this.loginService.isPaidUser(userData.enterpriseUserId).subscribe(success => {
        console.log(success)
        if(success != undefined)
        {
          if (!success.isPaidUser && (this.route.url == '/jobs' || this.route.url == '/dashboard' || this.route.url == '/favouriteprofile' || this.route.url == '/rolledoutOffers' || this.route.url == '/scheduledInterviews' || this.route.url.indexOf('/myaccount') > -1)) {
            this.route.navigate(['/pricing']);
          }
          this.dataStorage.setPaymentFlag(success.isPaidUser);
        }
        
      }, error => { });

     this.commonServices.getUnreadNotifications();

    }

    this.homeService.getMasterSkillsDesignationTools().subscribe(success => {
      if (!!success) { //  && !!success.skillsToolsResponseDto

        this.dataStorage.globalSkills = JSON.parse(JSON.stringify(success.technologyList));
        this.dataStorage.globalSkillsAndJobTitles = this.dataStorage.globalSkills;//[...skillsTechDesignation1, ...skillsTechDesignation2];

      }
    }, error => {
      this.myAppService.showAlertMessage(error.message);
    });

    if (this.dataStorage.globalLocations.length == 0 || true) {
      this.homeService.getMasterLocation().subscribe(success => {
        if (!!success) {
          this.dataStorage.globalLocations = success.locationsList;
        }
        
      }, error => {
        this.myAppService.showAlertMessage(error.message);
      });
    }

    this.commonServices.getMemberPlanInfo();
  }

  getNotificationBody(msg) {
    if (!!msg.value) {
      let message = msg.value.notification.body;
      return (message.length > 70 ? message.substr(0, 65) + '...' : message);
    }
  }

  redirectToNotification(){

  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  onChromeToastClose(){
    document.getElementById('divChromeToast').style.display = 'none';
  }

  findBrowser() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edg') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

}

declare global {
  interface Window { chrome: any; }
}

