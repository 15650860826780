// import { NgModule } from '@angular/core';
// import {
//     MatToolbarModule,
//     MatFormFieldModule,
//     MatInputModule, 
//     MatSelectModule, 
//     MatButtonModule, 
//     MatCheckboxModule, 
//     MatPaginatorModule, 
//     MatIconModule, 
//     MatCardModule, 
//     MatListModule,
//     MatSnackBarModule,
//     MatMenuModule, 
//     MatPaginatorIntl} from '@angular/material';
// import { FlexLayoutModule } from '@angular/flex-layout';
// // import { MatSelectSearchModule } from './mat-select-search/mat-select-search.module';

// @NgModule({
//     imports: [],
//       exports:[ MatFormFieldModule,MatInputModule,
//         MatSelectModule,
//         MatButtonModule,
//         MatCheckboxModule,
//         MatCardModule,
//         MatPaginatorModule,
//         MatIconModule,
//         MatListModule,
//         MatToolbarModule,
//         FlexLayoutModule,
//         MatSnackBarModule,
//         MatMenuModule
//     ]
// })

// export class AppMaterialModule {

//  }

import { NgModule } from '@angular/core';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import { ReactiveFormsModule } from '@angular/forms';
import {
    // MatToolbarModule,
    MatFormFieldModule,
    // MatInputModule, 
    // MatSelectModule, 
    // MatButtonModule, 
    // MatCheckboxModule, 
    // MatPaginatorModule, 
    // MatIconModule, 
    // MatCardModule, 
    // MatListModule,
    // MatSnackBarModule,
    // MatMenuModule, 
    MatPaginatorIntl,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    
    
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

// import { MatSelectSearchModule } from './mat-select-search/mat-select-search.module';

@NgModule({
    imports: [],
      exports:[ MatFormFieldModule,MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatCheckboxModule,
        MatCardModule,
        MatPaginatorModule,
        MatIconModule,
        MatListModule,
        MatToolbarModule,
        FlexLayoutModule,
        MatSnackBarModule,
        MatMenuModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        ScrollingModule,
        ReactiveFormsModule
    ]
})

export class AppMaterialModule {

 }
