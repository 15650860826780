import { Component, OnInit, Inject,ViewChild, ChangeDetectorRef, ViewEncapsulation, Renderer2, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ScheduleMockinterviewService } from './schedule-mockinterview.service';
import { TypeaheadSettings } from 'ngx-type-ahead';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DialogService } from '../login-dialog.service';
import { ScheduleinterviewConfirmationComponent } from '../scheduleinterview-confirmation/scheduleinterview-confirmation.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ScheduleinterviewPaymodeComponent } from '../scheduleinterview-paymode/scheduleinterview-paymode.component';
import { HomeService } from '../../../views/home/home.service';
import { DropDownListComponent } from '@syncfusion/ej2-ng-dropdowns';
import { CommonConfirmModalComponent } from '../common-confirm-modal/common-confirm-modal.component';

declare var Razorpay: any;

@Component({
  selector: 'app-schedule-mockinterview',
  templateUrl: './schedule-mockinterview.component.html',
  styleUrls: ['./schedule-mockinterview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScheduleMockinterviewComponent implements OnInit {


  scheduleInterviewForm: FormGroup;
  skills: any[];
  userData: any;
  selectedTechnologiesIdList: any = [];
  submitted: boolean = false;
  minDate: any;
  maxDate: any;
  scheduleInterviewMode: any;
  resume: any;
  resumePath: any = '';
  customSettings: Partial<TypeaheadSettings> = {
    suggestionsLimit: Infinity
  };

  fileName: any = '';
  enterpriseUserId: any;
  dtError: boolean = false;
  mobileError: boolean = false;

  isInterviewAlreadyScheduled: boolean = false;
  existingInterviewDetails: any = {};
  disableApplyBtn: boolean = false;

  scheduleInterviewPaymodeDialogRef;
  meetingLink: any;

  expYears:any =[];
  expMonths:any = [];
  
  editorConfiguration: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    placeholder: 'What areas to be covered?',
    translate: 'no',
    height: '6rem',
    maxHeight: '6rem',
    width: '100%'
  };

  noticePeriods: any[];
  searchData: any = {};
  experiences: any[];
  location = [];
  expectedCTCs: any[];
  salaryRange: any;

  selectedLocations: any = [];
  locationsArray: any = [];

  showMeetingLinksSection: boolean = false;


  @ViewChild('ddlLocations')
  public dropDownLocationObject: DropDownListComponent;


  validation_messages = {
    'technologies': [
      { type: 'required', message: "Please enter Technologies" }
    ],
    'intDateAndTime': [
      { type: 'required', message: "Please select Date & Time" }
    ],
    'uName': [
      { type: 'required', message: 'Please enter Name' },
      { type: 'pattern', message: 'PAGES.SCHEDULE_INTERVIEW.ERRORS.USERNAME.PATTERN' },
 
    ],
    'experiences': [
      { type: 'required', message: 'Please select Exp. in Years & Months'}, // 'Please enter Experience' },
    ],
    'currentCTC': [
      { type: 'required', message: 'Please select Current CTC' },
    ],
    'noticePeriods': [
      { type: 'required', message: 'Please select Notice Period' },
    ],
    'location': [
      { type: 'required', message: 'Please select Location' },
    ],
    'expectedCTC':[
      { type: 'required', message: 'Please select Expected CTC' },
    ],
    'email': [
      { type: 'required', message: 'Please enter Email' },
      { type: 'email', message: 'PAGES.SCHEDULE_INTERVIEW.ERRORS.EMAIL.PATTERN' },
      { type: 'pattern', message: 'PAGES.SCHEDULE_INTERVIEW.ERRORS.EMAIL.PATTERN' },
      { type: 'minlength', message: 'PAGES.REGISTRATION.ERRORS.EMAIL.MINLENGTH.LABEL' },
      { type: 'maxlength', message: 'PAGES.REGISTRATION.ERRORS.EMAIL.MAXLENGTH.LABEL' }
    ],
    'mobileNumber': [
      { type: 'required', message: 'PAGES.REGISTRATION.ERRORS.MOBILENUMBER.REQUIRED.LABEL' },
      { type: 'pattern', message: 'PAGES.REGISTRATION.ERRORS.MOBILENUMBER.PATTERN.LABEL' },
      { type: 'minlength', message: 'PAGES.REGISTRATION.ERRORS.MOBILENUMBER.MINLENGTH.LABEL' },
      { type: 'maxlength', message: 'PAGES.REGISTRATION.ERRORS.MOBILENUMBER.MAXLENGTH.LABEL' }
    ],
    'resume': [
      { type: 'required', message: 'Please Upload Resume' }
    ],
    'comment': [
      { type: 'maxlength', message: 'Comments should be only 500 characters.' }
    ],
    'meetingLink': [
      { type: 'maxlength', message: 'Please upload Meeting Link' }
    ],
  };

  jobSkills: any = [];
  dialog: MatDialogRef<any>;
  meetingStatuses: any[];

  constructor(private fb: FormBuilder, public dataStorage: DataStorageService, private myAppService: MyAppHttpService,
    public dialogRef: MatDialogRef<ScheduleMockinterviewComponent>, private scheduleInterviewService: ScheduleMockinterviewService,
    private router: Router, @Inject(MAT_DIALOG_DATA) public data: any, public dialogService: DialogService,private renderer: Renderer2, private el: ElementRef,
    public cdr: ChangeDetectorRef, private homeService: HomeService) {
      console.log('Data: ', data);

      for(let i=0;i<=40;i++){
          if(i<13){
            this.expMonths[i] ={
              "experienceId": i,
              "experienceDescription": `${i} Months`,
              "gte": 0,
              "lte": 0
          }
          }
          this.expYears[i] ={
            "experienceId": i,
            "experienceDescription": `${i} Years`,
            "gte": 0,
            "lte": 0
        }
        }
    
   
    this.skills = this.dataStorage.globalSkills;

    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.enterpriseUserId = this.userData.enterpriseUserId;
 

    this.minDate = this.addDays(new Date(), 1);
    this.maxDate = this.addDays(new Date(), 90);

  
    this.scheduleInterviewMode = this.dataStorage.ScheduleInterviewForExistingOrNonExistingUsers;
    this.scheduleInterviewForm = this.fb.group({
      'technologies': ['', Validators.compose([Validators.required])],
      'intDateAndTime': ['', (this.data.dialogFor != this.dataStorage.scheduleInterviewPopupFor.applyJob) ? Validators.required : ''],
      'uName': ['', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.NAME_WITH_SPACE_AND_FULL_STOP)])],
      'email': ['', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.EMAIL)])],
      'mobileNumber': ['', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.MOBILE_NUMBER)])],
      'resume': [''], // ['', Validators.compose([Validators.required])],
      'comment': ['', Validators.compose([Validators.maxLength(500)])],
      'meetingLink' : [''],
      'noticePeriods': ['',Validators.compose([Validators.required]),(!!this.searchData ? (!!this.searchData.noticePeriods ? this.searchData.noticePeriods : "") : "")],
      'expYears': ['',Validators.compose([Validators.required])],
      'expMonths': ['',Validators.compose([Validators.required])],
      'location': [,Validators.compose([Validators.required])],
      'currentCTC': ['',Validators.compose([Validators.required])],
      'expectedCTC': ['',Validators.compose([Validators.required])],
      'comments': ['', Validators.compose([Validators.maxLength(500)])]
    });

   
    
    if(this.data.dialogFor == this.dataStorage.scheduleInterviewPopupFor.applyJob
      || this.data.dialogFor == this.dataStorage.globalFeatureCodes.INTERVIEW_RI)
      {
      this.data.jobSkillIds = [];
      this.jobSkills = [];
      if(this.skills)
      this.skills.filter(x => {
        if(this.data.jobSkills)
        this.data.jobSkills.forEach(skillName => {
          if (x.name === skillName) {
            if (this.data.dialogFor === this.dataStorage.scheduleInterviewPopupFor.applyJob) {
              this.jobSkills.push(x.id);
            }
            this.data.jobSkillIds.push(x.id);
          }
        });
      });

    console.log('Data: ', this.data);
      this.scheduleInterviewForm.controls['technologies'].setValue(this.jobSkills);
    }

    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  getNgclassandids()
  {
    // const parentElement:any = document.getElementById('ddTechnologies')//("txtLocations-ng-select");
    // const childElements = parentElement.querySelectorAll("*");
    
    const parentElement:any = document.getElementById("txtLocations-ng-select");//('ddTechnologies');
const childElements = parentElement.querySelectorAll('*');

const childClasses = [];
const childIds = [];

childElements.forEach((element) => {
  if (element.classList.length > 0) {
    childClasses.push(...element.classList);
  }
  
  if (element.id) {
    childIds.push(element.id);
  }
  
  // Check if the element has child elements
  if (element.hasChildNodes()) {
    const childChildElements = element.querySelectorAll('*');
    let temp:any = [],i:number=0

    childChildElements.forEach((childElement) => {
      if (childElement.classList.length > 0) {
        childClasses.push(...childElement.classList);
        // console.log(i,childClasses)
        if(childClasses.includes('ng-input'))
        {
          temp[i] = 'ng-input'
          if(i == 2)
          {
             // Find the ng-input class element
    const ngInputElement = this.el.nativeElement.querySelector('.ng-input');

    // Find the input element inside ng-input class
    const inputElement = ngInputElement.querySelector('input');
    console.log(ngInputElement,inputElement)

    // Manipulate the input element
    this.renderer.setAttribute(inputElement, 'value', 'New value');
    this.renderer.setStyle(inputElement, 'color', 'red');
    // You can perform other manipulations using Renderer2 methods

    // Manipulate the parent div element
    this.renderer.setStyle(ngInputElement, 'background-color', 'yellow');
    // You can perform other manipulations using Renderer2 methods
          }
          console.log(i,temp)
          i++
        }
      }
      
      if (childElement.id) {
        childIds.push(childElement.id);
      }
    });
  }
});

console.log('Child Classes:', childClasses);
console.log('Child IDs:', childIds);

  }

  ngOnInit() 
  {

    this.scheduleInterviewForm.controls['location'].valueChanges.subscribe((value) => {
      this.selectedLocations = value;
    });

    this.scheduleInterviewForm.controls['intDateAndTime'].valueChanges.subscribe((value) => {
      this.getDateAndTime();
    });

    this.scheduleInterviewForm.controls['mobileNumber'].valueChanges.subscribe((value) => {
     
      if (value) {
        this.isInterviewAlreadyScheduled = false;
        if (value.toString().charAt(0) <= 4) {
          this.mobileError = true;
        }
        else {
          this.mobileError = false;

          if (value.length == 10) {
            this.isUserExistsToSchedule(value);
          }
        }
      }
    });

    //location

    if (this.dataStorage.globalLocations.length == 0) {
      this.homeService.getMasterLocation().subscribe(success => {
        if (!!success) {
          this.location = success.locationsList;
        }
        // console.log('Location', this.location);
        this.dataStorage.globalLocations = this.location;
      }, error => {
        this.myAppService.showAlertMessage(error.message);
      });
    }
    else {
      this.location = this.dataStorage.globalLocations;
    }

    this.homeService.getMasterServices().subscribe(success => {
      if (!!success) {
        this.experiences = success.experienceListDetails.experienceList;
        //this.expMonths =  success.experienceListDetails.experienceList;
        
        console.log('exp',this.experiences,this.expMonths,this.expYears)
        this.noticePeriods = success.noticePeriodListDetails.noticePeriodList;
        this.salaryRange = success.expectedCTCListDetails.expectedCTCList;
        //this.rockRatings = success.rockRatingListDetails.rockRatingList.sort((a, b) => (a.rockRatingId < b.rockRatingId) ? 1 : -1);
        //this.jobTypes = success.jobTypeListDetails.jobTypeList;
        this.expectedCTCs = success.expectedCTCListDetails.expectedCTCList;
        //this.probabilityofJoinings = success.pojListDetails.pojList;
        //this.lastUpdates = success.lastUpdatedListDetails.lastUpdatedList;
      }
    }, error => {
      this.myAppService.showAlertMessage(error.message);
    });

    if (this.dataStorage.globalSkillsAndJobTitles.length == 0) {
      // this.findProfileServie.getMasterSkillsDesignationTools().subscribe(success => {
      this.homeService.getMasterSkillsDesignationTools().subscribe(success => {
        if (!!success) {
          this.dataStorage.globalSkills = JSON.parse(JSON.stringify(success.technologyList));
          this.skills =  JSON.parse(JSON.stringify(this.dataStorage.globalSkills));
          this.dataStorage.globalSkillsAndJobTitles = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));



        }
      }, error => {
        this.myAppService.showAlertMessage(error.message);
      });
    }
    else {
  
      this.skills = JSON.parse(JSON.stringify(this.dataStorage.globalSkills));
     
      console.log("else");

    }

    if(this.data.dialogFor != this.dataStorage.scheduleInterviewPopupFor.applyJob){ 
      this.scheduleInterviewService.showMeetingLinksSectionOrNot();
      setTimeout(() => {
        this.meetingStatuses = this.scheduleInterviewService.meetingStatuses;
        this.showMeetingLinksSection = this.scheduleInterviewService.showMeetingLinksSectionValue;
        console.log('Meeting status value: ', this.meetingStatuses, this.showMeetingLinksSection);
      }, 10);
    }

// setTimeout(() => {
//   this.getNgclassandids()
// }, 2000);
  }

  clearDropdownLocationValue() {
    this.dropDownLocationObject.value = null;
  }

  public waterMark1: string = 'Location';

  public Loc_fields: object = { text: 'locationName', value: 'locationId' };

  onLocationTypeAheadChanges(values) {
  }

  isUserExistsToSchedule(mobileNumer) {
    this.isInterviewAlreadyScheduled = false;
    this.existingInterviewDetails = {};
    if(this.data.dialogFor != this.dataStorage.scheduleInterviewPopupFor.applyJob){
      var requestData = {
        "enterpriseUserId": this.userData.enterpriseUserId,
        "mobileNumber": mobileNumer
      }
      this.scheduleInterviewService.isUserExistsToSchedule(requestData).subscribe(response => {
        if (response.interviewExists) {
          this.isInterviewAlreadyScheduled = true;
          this.existingInterviewDetails = response.interviewInfo;
        }
      }, (error) => {

      });
    }
    else{
      // To check whether user has already applied for the job
      var inputData = {
        "mobileNumber": mobileNumer,
        "jobId": this.data.jobId
      }
      this.scheduleInterviewService.isUserExistsForApplyJob(inputData).subscribe(response => {
      
        if(response.statusCode == MyAppHttpService.RESPONSE_CODES.JOB_APPLIED_ALREADY){
       
            this.disableApplyBtn = true;
            this.myAppService.showAlertMessage(response.message);
         
        }
        else {
          this.disableApplyBtn = false;
          // To get user details if enterprise user has applied the user details for other opportunity
          var inputData1 = {
            "appliedEntUserId": this.userData.enterpriseUserId,
            "mobileNumber": mobileNumer
          }
          this.scheduleInterviewService.getUserdetailsForPostAJOB(inputData1).subscribe(response => {
            console.log('user details response: ', response);
            if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
              this.disableApplyBtn = false;
              let modalRef = this.dialogService.open(CommonConfirmModalComponent, {
                data: { message: 'Hey! You have already applied this profile for another job.', message1: 'Do you want to fill the same data?' }
              });
              modalRef.afterClosed().subscribe(value => {
                if (value) {
                  var name = response.name != null ? response.name : '';
                  var email = response.emailId != null ? response.emailId : '';
                  var location = response.location != null ? response.location : '';
                  var noticePeriod = response.noticePeriod != null ? response.noticePeriod : '';
                  var comments = response.comments != null ? response.comments : '';
                  var currentCTC = response.currentCTC != null ? response.currentCTC : '';
                  var expectedCTC = response.expectedCTC != null ? response.expectedCTC : '';
                  var expInYears = response.experience != null ? Math.floor(response.experience / 12) : ''; 
                  var expInMonths = response.experience != null ? response.experience % 12 : '';
                  var resumePath = !!response.resumePath ? response.resumePath : '';
                  this.resumePath = resumePath;
                  var comments = response.comments != null ? response.comments : '';
                  
                  this.scheduleInterviewForm.controls['uName'].setValue(name);
                  this.scheduleInterviewForm.controls['email'].setValue(email);
                  this.scheduleInterviewForm.controls['location'].setValue(location);
                  this.scheduleInterviewForm.controls['noticePeriods'].setValue(noticePeriod);
                  this.scheduleInterviewForm.controls['currentCTC'].setValue(currentCTC);
                  this.scheduleInterviewForm.controls['expectedCTC'].setValue(expectedCTC);
                  this.scheduleInterviewForm.controls['comment'].setValue(comments);
                  this.scheduleInterviewForm.controls['expYears'].setValue(expInYears);
                  this.scheduleInterviewForm.controls['expMonths'].setValue(expInMonths);
                  this.scheduleInterviewForm.controls['comments'].setValue(comments);
                  // this.scheduleInterviewForm.controls['resume'].setValue(resumePath);
                  // this.scheduleInterviewForm.controls.resume.setValue(resumePath);
                  this.fileName = resumePath ? this.getFileNameFromResumePath(resumePath) : '';
                  // this.fileName = resumePath.substring(resumePath.lastIndexOf('/') + 1)
                  // this.fileName = 'sdf';
                  console.log('Filename from resume path: ', this.fileName, expInYears, expInMonths);
                  console.log('this.scheduleInterviewForm.value ',this.scheduleInterviewForm.value);
                  this.cdr.detectChanges();
                }
              });
            }
            else {
              this.disableApplyBtn = false;
              this.scheduleInterviewForm.controls['uName'].setValue('');
              this.scheduleInterviewForm.controls['email'].setValue('');
              this.scheduleInterviewForm.controls['location'].setValue('');
              this.scheduleInterviewForm.controls['noticePeriods'].setValue('');
              this.scheduleInterviewForm.controls['currentCTC'].setValue('');
              this.scheduleInterviewForm.controls['expectedCTC'].setValue('');
              this.scheduleInterviewForm.controls['comment'].setValue('');
              this.scheduleInterviewForm.controls['expYears'].setValue('');
              this.scheduleInterviewForm.controls['email'].setValue('');
              // this.fileName = '';
            }
          });
        }
      });

      

    }
   
  }

  onTechnologyValueChanges(event) {
    this.selectedTechnologiesIdList = event.map(x => x.id);
    this.scheduleInterviewForm.controls['technologies'].setValue(this.selectedTechnologiesIdList);
  }

  scheduleInterview() {
 
    let formValue = this.scheduleInterviewForm.value;
    this.getDateAndTime();
  
    if (this.scheduleInterviewMode == 'existing') {
      if (!this.userData ||
        (this.scheduleInterviewForm.controls['intDateAndTime'].invalid) ||
        (this.scheduleInterviewForm.controls['technologies'].invalid)) {
        if (this.scheduleInterviewForm.controls['intDateAndTime'].invalid) {
          this.myAppService.showAlertMessage("Please select interview date and time within 90 days.");
        }
        this.submitted = true;
   
      }
      else if (this.dtError) {
        this.myAppService.showAlertMessage("Please select interview date and time within 90 days.");
        this.submitted = true;
      }
      else if(this.data.jobSkillIds.filter(element => formValue.technologies.includes(element)).length == 0 ){
        this.dialog = this.dialogService.open(ScheduleinterviewConfirmationComponent, {

        });
        this.dialog.afterClosed().subscribe(response => {
          
          if(response){
            this.fnScheduleInterview(formValue);
          }
        });
      }
      else {
        this.fnScheduleInterview(formValue);
      }
    }
    else {
      if (!this.userData || this.scheduleInterviewForm.invalid || this.mobileError || this.scheduleInterviewForm.controls['comment'].invalid) {
        if (this.scheduleInterviewForm.controls['intDateAndTime'].invalid) {
          this.myAppService.showAlertMessage("Please select interview date and time within 90 days.");
        }
        this.submitted = true;
       
      }
      else if (this.dtError) {
        this.myAppService.showAlertMessage("Please select interview date and time within 90 days.");
        this.submitted = true;
      }
      else {
      
        this.fnScheduleInterview(formValue);
      }
    }

  }

  fnScheduleInterview(formValue) {
  
    this.submitted = false;
    if (this.scheduleInterviewMode == 'existing') {
      let inputData = {
        enterpriseUserId: this.userData.enterpriseUserId,
        userId: this.dataStorage.profileIdForScheduleInterview,
        interviewDateTime: this.convertDateAndTimeToCustomFormat(formValue.intDateAndTime),
        technologyIds: formValue.technologies,
        comment: formValue.comment,
        meetingLink : formValue.meetingLink,
        interviewLevel:formValue.interviewLevel, 
        shareMentorFeedbackStatus:formValue.shareMentorFeedbackStatus? 2:0 
      }

      this.scheduleInterviewService.scheduleInterviewForExistingUser(inputData).subscribe((response) => {
        
        if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SUCCESS ||
          response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_JOBSEEKER ||
          response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_EXISTING_RI_USER) {
          if(this.dataStorage.subGetAllProfiles.observers.length > 1){
            this.dataStorage.subGetAllProfiles.observers = [this.dataStorage.subGetAllProfiles.observers.pop()];
          }

          this.dataStorage.subGetAllProfiles.next({});

          this.myAppService.showAlertMessage('Interview Scheduled Successfully.');
          this.dialogRef.close(true);
         
        }
        else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_INTERVIEWER) {
          this.myAppService.showAlertMessage("Can't Schedule Interview for existing interviewer.");
        }
        else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_PRESCREENED) {
          this.myAppService.showAlertMessage("Can't Schedule Interview for existing Pre-Screened User");
        }
        else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_INTERVIEW_ALREADY_ASSIGNED) {
          this.myAppService.showAlertMessage("You have already scheduled interview for this user");
        }
        else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_INTERVIEW_ALREADY_ASSIGNED) {
          this.myAppService.showAlertMessage("You have already scheduled interview for this user");
        }
        else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_DATE_IS_BEFORE_SCHEDULED_DATE) {
          this.myAppService.showAlertMessage("You can schedule interview only after completion of previous scheduled interview");
        }
        else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_GAP_2_HOURS) {
          this.myAppService.showAlertMessage("You can schedule interview only after 2 hours of previous interview completion");
        }
        else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_PREVIOUS_INTERVIEW_NOT_COMPLETED) {
          this.myAppService.showAlertMessage("You can schedule interview only after completion of previous scheduled interview");
        }
      });
    }
    else {
      
      this.fnValidateScheduleInterviewForNonExistingUser(formValue);
    }

  }


  payment(price, formValue) {
    var self = this;
     let options = {
       description: 'value',
       image: environment.payment.image,
       currency: 'INR',
       key: environment.payment.key,
       amount: price * 100,
       name: this.dataStorage.loggedInUserData.firstName,
       handler:  (response)=> {
         self.successCallback(formValue, response.razorpay_payment_id);
        
       },
       prefill: {
         email: this.dataStorage.loggedInUserData.email,
         name: this.dataStorage.loggedInUserData.firstName
       },
       theme: {
         color: '#99226e'
       },
       modal: {
         ondismiss: () => {
           self.MethodAfterCancel();
         },
       }
     };
     var rzp1 = new Razorpay(options);
     rzp1.open();
   
 };

 successCallback(formValue, payment_id) {
  this.fnScheduleInterviewForNonExistingUser(formValue, payment_id);
 };

 MethodAfterCancel() {
  
 }

 fnValidateScheduleInterviewForNonExistingUser(formValue){
  
 
  let inputData = {
    enterpriseUserId: this.userData.enterpriseUserId,
    name: formValue.uName,
    mobilenumber: formValue.mobileNumber,
    email: formValue.email,
    technologyIds: formValue.technologies,
    interviewTimeStamp: this.convertDateAndTimeToCustomFormat(formValue.intDateAndTime),
    resumePath: this.resumePath, 
    comment: formValue.comment,
    meetingLink : formValue.meetingLink
  }
  this.scheduleInterviewService.validateScheduleInterviewForNonExistingUser(inputData).subscribe(response => {
    
    if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SUCCESS ||
      response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_JOBSEEKER ||
      response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_EXISTING_RI_USER) {
      var featureInfo = this.dataStorage.membershipCurrentPlan.featureList.find(x => x.featureCode == this.dataStorage.globalFeatureCodes.INTERVIEW_OWN);
      var finalScheduleInterviewPrice = featureInfo.featurePrice - ((!!featureInfo.credits ? parseInt(featureInfo.credits) : 0) * featureInfo.featurePrice) / 100;
      
      this.scheduleInterviewPaymodeDialogRef =this.dialogService.open(ScheduleinterviewPaymodeComponent);
      this.scheduleInterviewPaymodeDialogRef.afterClosed().subscribe(paymode => {
        if(paymode){
          if(paymode == MyAppHttpService.PAYMENT_MODES.PAY_NOW){
            this.payment(Math.floor(finalScheduleInterviewPrice), formValue);
          }
          else{
            this.fnScheduleInterviewForNonExistingUser(formValue, 'invoice');
          }
        }
      });
     
    }
    else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_INTERVIEWER) {
      this.myAppService.showAlertMessage("Can't Schedule Interview for existing interviewer.");
    }
    else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_PRESCREENED) {
      this.myAppService.showAlertMessage("Can't Schedule Interview for existing Pre-Screened User");
    }
    else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_INTERVIEW_ALREADY_ASSIGNED) {
      this.myAppService.showAlertMessage("You have already scheduled interview for this user");
    }
    else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_INTERVIEW_ALREADY_ASSIGNED) {
      this.myAppService.showAlertMessage("You have already scheduled interview for this user");
    }
    else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_DATE_IS_BEFORE_SCHEDULED_DATE) {
      this.myAppService.showAlertMessage("You can schedule interview only after completion of previous scheduled interview");
    }
    else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_GAP_2_HOURS) {
      this.myAppService.showAlertMessage("You can schedule interview only after 2 hours of previous interview completion");
    }
    else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_PREVIOUS_INTERVIEW_NOT_COMPLETED) {
      this.myAppService.showAlertMessage("You can schedule interview only after completion of previous scheduled interview");
    }
  });
 }



  fnScheduleInterviewForNonExistingUser(formValue, payment_id) {
    let inputData = {
      enterpriseUserId: this.userData.enterpriseUserId,
      name: formValue.uName,
      mobilenumber: formValue.mobileNumber,
      email: formValue.email,
      technologyIds: formValue.technologies,
      interviewTimeStamp: this.convertDateAndTimeToCustomFormat(formValue.intDateAndTime),
      resumePath: this.resumePath, 
      paymentId: payment_id,
      comment: formValue.comment,
      meetingLink : formValue.meetingLink
    }
    this.scheduleInterviewService.scheduleInterviewForNonExistingUser(inputData).subscribe(response => {
    
      if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SUCCESS ||
        response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_JOBSEEKER ||
        response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_EXISTING_RI_USER) {


        
        if(this.dataStorage.subReloadScheduledInterviews.observers.length > 1){
          this.dataStorage.subReloadScheduledInterviews.observers = [this.dataStorage.subReloadScheduledInterviews.observers.pop()];
        }

        this.dataStorage.subReloadScheduledInterviews.next({});


        this.myAppService.showAlertMessage('Interview Scheduled Successfully.');
        this.dialogRef.close(true);
        this.fileName = '';
      }
      else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_INTERVIEWER) {
        this.myAppService.showAlertMessage("Can't Schedule Interview for existing interviewer.");
      }
      else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_PRESCREENED) {
        this.myAppService.showAlertMessage("Can't Schedule Interview for existing Pre-Screened User");
      }
      else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_INTERVIEW_ALREADY_ASSIGNED) {
        this.myAppService.showAlertMessage("You have already scheduled interview for this user");
      }
      else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_INTERVIEW_ALREADY_ASSIGNED) {
        this.myAppService.showAlertMessage("You have already scheduled interview for this user");
      }
      else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_DATE_IS_BEFORE_SCHEDULED_DATE) {
        this.myAppService.showAlertMessage("You can schedule interview only after completion of previous scheduled interview");
      }
      else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_GAP_2_HOURS) {
        this.myAppService.showAlertMessage("You can schedule interview only after 2 hours of previous interview completion");
      }
      else if (response.statusCode == MyAppHttpService.RESPONSE_CODES.SCHEDULE_INTERVIEW_IS_PREVIOUS_INTERVIEW_NOT_COMPLETED) {
        this.myAppService.showAlertMessage("You can schedule interview only after completion of previous scheduled interview");
      }
    });
  }

  cancelInterview() {
    console.log("Cancel Interview.");
    this.dialogRef.close(false);
  }

  // To add 90 days to present date
  addDays(date: any, days: number): Date {
    if (!days) return date;

    date.setDate(date.getDate() + days);

    return date;
  };

  convertDateAndTimeToCustomFormat(d) {
    let date = new Date(d),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      hours = ("0" + date.getHours()).slice(-2),
      mins = ("0" + date.getMinutes()).slice(-2);
    return ([date.getFullYear(), month, day].join("-") + ' ' + [hours, mins].join(':') + ':00');
  }

  convertDate(d) {
    let date = new Date(d);
    let t1;

    t1 = date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2) + 'T' + ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);
   

    return t1;
  }

  getFile(event) {
   
    this.fileName = '';
    this.resume = event.target.files;
    let name = '';
    let fileExt = '';
    let size = 0;
    let mobile: any;
    if (!!this.resume) {
      name = this.resume[0].name;
      fileExt = name.split('.').pop();
      size = this.resume[0].size;

    }
    console.log("resume : ", this.resume);
    if (this.resume === undefined) {
      this.scheduleInterviewForm.controls['resume'].reset();

    } else if (this.resume.length == 0) {
      this.scheduleInterviewForm.controls['resume'].reset();
      this.myAppService.showAlertMessage("File not selected.");

    } else if (size == 0) {
      this.scheduleInterviewForm.controls['resume'].reset();
      this.myAppService.showAlertMessage("File not supported, Please select another file.");

    } else if (fileExt.toLowerCase() != "pdf" && fileExt.toLowerCase() != "docx" && fileExt.toLowerCase() != "doc") {
      this.scheduleInterviewForm.controls['resume'].reset();
      this.myAppService.showAlertMessage("Please select only pdf or word file.");

    } else if (size > 2097152) {
      this.scheduleInterviewForm.controls['resume'].reset();
      this.myAppService.showAlertMessage("Please select file less than 2 MB.");

    }
  
    else {
      mobile = this.scheduleInterviewForm.controls['mobileNumber'].value;
      this.fileName = name;
      let fileData = new FormData();
      let fileName: string = '';
    

      if (this.resume.length > 0) {
        fileName = mobile + '_' + this.resume[0].name;
     
        fileData.append("file", this.resume[0], fileName);
      }

      let xhr = new XMLHttpRequest();
      let apiUrl = '';
      if(this.data.dialogFor == this.dataStorage.scheduleInterviewPopupFor.applyJob){
        apiUrl = environment.url + MyAppHttpService.REQUESTS.uploadResumeForApplyJob + '/' + this.enterpriseUserId;
      }
      else{
        apiUrl = environment.url + MyAppHttpService.REQUESTS.UploadResume;
      }
      
      var accessToken = localStorage.getItem('token');

      xhr.open('POST', apiUrl, true);
      xhr.setRequestHeader("token", accessToken);
      xhr.onload = () => {
        if (xhr.status == 200) {
          let response = JSON.parse(xhr.response);
          this.resumePath = response.uploadedURL;
         
        }
      }

      xhr.send(fileData);
    }
  }

  getDateAndTime() {
    
    let intDate = new Date(this.scheduleInterviewForm.controls['intDateAndTime'].value);
    if (this.scheduleInterviewForm.controls['intDateAndTime'].value) {
      if (intDate >= this.minDate && intDate <= this.maxDate) {
        this.dtError = false;
      }
      else {
        this.dtError = true;
      }
    }

  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  applyJob(){
// debugger
    console.log('Form value : ', this.scheduleInterviewForm.value, this.resumePath);
    let formValue = this.scheduleInterviewForm.value;

     if(this.scheduleInterviewForm.invalid || this.resumePath == ''){
      this.submitted = true;
    }
    else{

      if(this.data.jobSkillIds.filter(element => formValue.technologies.includes(element)).length == 0 ){
        this.myAppService.showAlertMessage('Select atleast one skill from the posted job skill(s)');
        return false;
      }

      this.submitted = false;
      let requestData = {
        jobId: this.data.jobId,
        enterpriseUserId: this.enterpriseUserId,
        name: formValue.uName,
        mobilenumber: formValue.mobileNumber,
        email: formValue.email,
        technologyIds: formValue.technologies,
        resumePath: this.resumePath,
        meetingLink : formValue.meetingLink,
        expCTC: formValue.expectedCTC.toString(),
        currentCTC: formValue.currentCTC.toString(),
        noticePeriod: formValue.noticePeriods.toString(),
        experience: this.calculateYearsToMonths(formValue.expYears, formValue.expMonths),
        location: formValue.location,
        comments: formValue.comments
      }

      console.log('Apply job Request data: ', requestData);
      this.scheduleInterviewService.applyJob(requestData).subscribe(response => {
     
        if(response.statusCode == 200){
          this.myAppService.showAlertMessage(response.message);
          this.dialogRef.close(true);
        }
        else if(response.statusCode == MyAppHttpService.RESPONSE_CODES.JOB_APPLIED_ALREADY){
          this.myAppService.showAlertMessage(response.message);
        }
      });
    }
  }

  calculateYearsToMonths(yrs, mnths) {
    console.log('Exp in Years and Months: ', yrs, mnths);
    let expInMnths = 0, yrsInMnths = 0;
    if(!!yrs) {
      yrsInMnths = yrs * 12;
    }
    expInMnths = yrsInMnths + mnths;

    return expInMnths;
  }

  getFileNameFromResumePath(resumePath) {
    console.log('Resume path: ', resumePath);
    let name, resumeFileName;
    if (resumePath) {
      name = resumePath.split('/');
      resumeFileName = name.pop();
    }

    return resumeFileName;
  }
 


}