import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleinterviewConfirmationComponent } from './scheduleinterview-confirmation.component';
import { AppMaterialModule } from 'src/app/app-material.module';

@NgModule({
  declarations: [ScheduleinterviewConfirmationComponent],
  imports: [
    CommonModule,
    AppMaterialModule
  ],
  exports: [ScheduleinterviewConfirmationComponent],
  entryComponents: [ScheduleinterviewConfirmationComponent]
})
export class ScheduleinterviewConfirmationModule { }
