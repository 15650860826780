import { Injectable } from '@angular/core';
import { SendReceiveService } from '../../../services/common/sendReceive.service';
import { MyAppHttpService } from '../../../services/common/my-app-http.service';
import { httpFactory } from '@angular/http/src/http_module';

@Injectable({
  providedIn: 'root'
})
export class OpportunitiesService {

  profileData:any = []
  storeId:number;
  constructor(private sendReceiveService: SendReceiveService) { }

  collapse(){
    console.log(this.storeId!=undefined?this.profileData[this.storeId].expanddata = false:'')
    // this.storeId!=undefined?this.profileData[this.storeId].expanddata = false:''
  }

  getAllOpportunities(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetAllOpportunities, requestData);
  }

  getOpportunitiesJobDetails(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetOpportunitiesDetails, requestData);
  }

  shareOpportunity(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.ShareAnOpportunity, requestData);
  }

  getOpportunitiesDetails(requestData){
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.getOpportunitiesDetailsByJobId, requestData);
  }
}


