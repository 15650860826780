import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/app-material.module';
import { CommonPipesModule } from 'src/app/pipes/common-pipes/common-pipes.module';
import { StarRatingModule } from 'angular-star-rating';
import { MatTabsModule } from '@angular/material';
import { ScheduleinterviewPaymodeComponent } from './scheduleinterview-paymode.component';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    CommonPipesModule,
    MatTabsModule
  ],
  declarations: [ScheduleinterviewPaymodeComponent],
  exports: [ScheduleinterviewPaymodeComponent],
  entryComponents: [ScheduleinterviewPaymodeComponent]
})
export class ScheduleinterviewPaymodeModule { }
