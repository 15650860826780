import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from 'src/app/app-material.module';
import { TourFindprofilesRoutes } from './tour-findprofiles.route';
import { TourFindprofilesComponent } from './tour-findprofiles.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonPipesModule } from '../../../../pipes/common-pipes/common-pipes.module';
import { StarRatingModule } from 'angular-star-rating';
import { PopoverModule } from 'ngx-smart-popover';

@NgModule({
  declarations: [TourFindprofilesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(TourFindprofilesRoutes),
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CommonPipesModule,
    StarRatingModule.forRoot(),
    PopoverModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TourFindprofilesModule { }
