import { Injectable } from "@angular/core";
import {SendReceiveService} from '../../../services/common/sendReceive.service';
import {MyAppHttpService} from '../../../services/common/my-app-http.service';
import { DataStorageService } from '../../../services/data-storage.service';

@Injectable()
export class HomeService{
  
    constructor(private sendReceiveService: SendReceiveService,private dataStorage: DataStorageService) { }

       
    getMasterServices() {
        var enterpriseUserId = 0;
        if(!!localStorage.userData){
            enterpriseUserId = JSON.parse(localStorage.userData).enterpriseUserId;
        }
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.MasterServices + '?enterpriseUserId='+enterpriseUserId+'');  
    }

    getMasterLocation() {
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllLocations);  
    }

    getMasterSkillsDesignationTools() {
      
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllTechnologiesWithKeywords);
    }

//priya
    getInterviewLevels() {
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllInterviewLevels);
    }
    getMasterMatchedData() {
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllProfilesCount);  
    }

    saveReskillingDetails(inputData) {

        var value=this.dataStorage.isUserLoggedIn.valueOf();
       
        var enterpriseUserId = null;
        var requestData={
        firstName:inputData.firstName,
        lastName:inputData.lastName,
        email:inputData.email,
        isEmployeeAssessmentChecked:inputData.isEmployeeAssessmentChecked,
        isUpSkillChecked:inputData.isUpSkillChecked,
        isMentoringChecked:inputData.isMentoringChecked,
        enterpriseUserId: value ? JSON.parse(localStorage.userData).enterpriseUserId: null
        

        }
    
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.saveReskillingDetails,requestData);  
    }


BuildTeams(inputData) {
 
    
    var value=this.dataStorage.isUserLoggedIn.valueOf();

    var requestData={
            companyName: inputData.companyName,
            email:inputData.email,
            projectType: inputData.projectType,
            expectedStartDate: inputData.expectedStartDate,
            techIds: inputData.techIds,
            noOfPeople: inputData.noOfPeople,
            enterpriseUserId: value ? JSON.parse(localStorage.userData).enterpriseUserId: null
        }
      
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.BuildTeamsRemotelyDetails,requestData);  
}

}

