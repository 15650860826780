import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ForgotPasswordModalService } from './forgotpasswordmodal.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { DialogService } from '../login-dialog.service';

@Component({
  selector: 'app-forgotpasswordmodal',
  templateUrl: './forgotpasswordmodal.component.html',
  styleUrls: ['./forgotpasswordmodal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ForgotpasswordmodalComponent implements OnInit {

  public forgotPasswordForm: FormGroup;
  public submitted: boolean = false;
  public invalidCredentials: boolean = false;
  public emailNotVerified: boolean = false;

  validation_messages = {
    'email': [
      { type: 'required', message: 'PAGES.FORGOT_PASSWORD.ERRORS.EMAIL.REQUIRED.LABEL' },
      { type: 'email', message: 'PAGES.FORGOT_PASSWORD.ERRORS.EMAIL.PATTERN.LABEL' },
      { type: 'pattern', message: 'PAGES.FORGOT_PASSWORD.ERRORS.EMAIL.PATTERN.LABEL' },
      { type: 'minLength', message: 'PAGES.FORGOT_PASSWORD.ERRORS.EMAIL.MINLENGTH.LABEL' },
      { type: 'maxLength', message: 'PAGES.FORGOT_PASSWORD.ERRORS.EMAIL.MAXLENGTH.LABEL' }
    ]
  };

  constructor(private dialogService: DialogService,public forgotPasswordService: ForgotPasswordModalService, public router: Router, private snackBar: MatSnackBar, private translate: TranslateService, private myAppService: MyAppHttpService) {
    this.forgotPasswordForm = new FormBuilder().group({
      'emailId': ["", Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.EMAIL)])]
      //"password": ["", Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
  }

  clkSendVerification() {
    //this.submitted = true;
    if (this.forgotPasswordForm.valid) {
      var forgotPasswordData = this.forgotPasswordForm.value;
      this.forgotPasswordService.forgotPassword(forgotPasswordData).subscribe(success => {
        if (success.statuscode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
          this.myAppService.showAlertMessage(this.translate.instant('PAGES.FORGOT_PASSWORD.ERRORS.SEND_VERIFICATION_SUCCESS.LABEL'));
          this.router.navigate(['/login']); 
        }
        else if (success.statuscode === MyAppHttpService.RESPONSE_CODES.CONFLICT) {
          this.myAppService.showAlertMessage(this.translate.instant('PAGES.FORGOT_PASSWORD.ERRORS.EMAIL_NOT_REGISTERED.LABEL'));
        }
        else if (success.statuscode === MyAppHttpService.RESPONSE_CODES.EMAIL_NOT_VERIFIED) {
          this.myAppService.showAlertMessage(this.translate.instant('PAGES.LOGIN.ERRORS.NOT_VERIFIED.LABEL'));
        }
        else if (success.statuscode === MyAppHttpService.RESPONSE_CODES.ALREADY_LOGGED_IN) {
          this.myAppService.showAlertMessage(this.translate.instant('PAGES.LOGIN.ERRORS.ALREADY_LOGIN.LABEL'));
        }else if (success.statuscode === MyAppHttpService.RESPONSE_CODES.NOT_FOUND) {
          this.myAppService.showAlertMessage(this.translate.instant('PAGES.LOGIN.ERRORS.EMAIL_NOT_REGISTERED.LABEL'));
        }

      }, error => {
        console.log(error);
        this.myAppService.showAlertMessage(error.message);
      });
    }
  }


  closeDialog(){
    this.dialogService.clear();
  }

}

