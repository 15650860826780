import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourModalComponent } from './tour-modal.component';
import { AppMaterialModule } from 'src/app/app-material.module';

@NgModule({
  
  imports: [
    CommonModule,
    AppMaterialModule
    
  ],
  declarations: [TourModalComponent],
  exports: [TourModalComponent],
  entryComponents:[TourModalComponent]
})
export class TourModalModule { }
