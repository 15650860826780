import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from 'src/app/app-material.module';
import { TourHomeComponent } from './tour-home.component';
import { TourHomeRoutes } from './tour-home.route';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AutoSearchFilterPipe } from 'src/app/pipes/auto.search.filter';
import { CommonPipesModule } from 'src/app/pipes/common-pipes/common-pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';

@NgModule({
  declarations: [TourHomeComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(TourHomeRoutes),
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CommonPipesModule,
    // AngularFontAwesomeModule,
    NgbModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // providers: [AutoSearchFilterPipe]
})
export class TourHomeModule { }
