import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppMaterialModule } from 'src/app/app-material.module';
import { PaymentComponent } from './payment.component';
import { PopoverModule } from 'ngx-smart-popover';


@NgModule({
  declarations: [PaymentComponent],
  imports: [
    CommonModule,

    AppMaterialModule,
    PopoverModule
  ],
  exports: [PaymentComponent],
  entryComponents:[PaymentComponent],
})
export class PaymentModule { }
