import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonConfirmModalComponent } from './common-confirm-modal.component';
import { AppMaterialModule } from '../../../../app-material.module';

@NgModule({
  declarations: [CommonConfirmModalComponent],
  imports: [
    CommonModule,
    AppMaterialModule
  ],
  exports: [CommonConfirmModalComponent],
  entryComponents: [CommonConfirmModalComponent]
})
export class CommonConfirmModalModule { }
