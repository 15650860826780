import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from 'src/app/components/common/modal/login-dialog.service';
import { Router } from '@angular/router';
import { TypeaheadSettings, TypeaheadSuggestions } from 'ngx-type-ahead';
import { DropDownListComponent } from '@syncfusion/ej2-ng-dropdowns';
import { AutoSearchFilterPipe } from 'src/app/pipes/auto.search.filter';
import { ViewEncapsulation } from '@angular/core';
import { DownloadBrochureComponent } from '../../../common/modal/download-brochure/download-brochure.component';
import { CommonfunctionService } from '../../../../services/common/commonfunction.service';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';

declare var $:any;

@Component({
  selector: 'app-tour-home',
  templateUrl: './tour-home.component.html',
  styleUrls: ['./tour-home.component.scss'],
  providers: [AutoSearchFilterPipe],
  encapsulation: ViewEncapsulation.None
})
export class TourHomeComponent implements OnInit {


    experiences: any[];
    // skillsTechDesignation: TypeaheadSuggestions = [];
    booleanArray = [{ value: true, display: 'Yes' }, { value: false, display: 'No' }, { value: '', display: 'None' }];
    searchTypeArray = [{ value: true, display: 'Exact' }, { value: false, display: 'Broad' }];
    location = [];
    matchedData: any;
    preScreened: any;
    exactMatch: any;
    profileHired: any;
    noticePeriods: any[];
    rockRatings: any[];
    jobTypes: any[]
    expectedCTCs: any[];
    probabilityofJoinings: any[];
    lastUpdates: any[];
    searchForm: FormGroup;
    isLogin: boolean = false;
    advFltr: boolean = false;
    advFilter: boolean = false;
    txtExpectedCTC: any;
    txtUpdates: any;
    name;
    slideshowImageList = ['assets/images/bg-image-2-1239x548_1.png','assets/images/image_3_1.png'];			
  
  
    skillsTechDesignation: any = [];
    skillsTechDesignation1: any = [];
    skillsTechDesignation2: any = [];
  
    selectedSkillsandDesignations: any = [];
    selectedLocations: any = [];
    locationsArray: any = [];
    txtExperience = '';
    txtNoticePeriod = '';
    txtRockRatings = '';
    isTooltipSkills: boolean = false;
    isTourHome: boolean;
    
    @ViewChild('ddlLocations')
    public dropDownLocationObject: DropDownListComponent;
  
    customSettings: Partial<TypeaheadSettings> = {
      suggestionsLimit: Infinity
    };
    EnterpriseId: any;
  
    @ViewChild('skillsAndTitles') skillsAndTitles : TypeaheadSuggestions;
  
  
    constructor(private dialogService: DialogService, public router: Router, private commonFunction: CommonfunctionService){
      
      if (!!localStorage.getItem('token')  && localStorage.getItem('token') != 'anonymous' && localStorage.getItem('token') != 'null') {
        this.isLogin = true;
        if(!!localStorage.getItem('userData')){
          this.name = JSON.parse(localStorage.getItem('userData')).firstName;
          this.EnterpriseId = JSON.parse(localStorage.getItem('userData')).enterpriseUserId;
        }
        
      } else {
        this.isLogin = false;
      }
      this.searchForm = new FormBuilder().group({
        'skillsTechDesignation': [""],
        'location': [""],
        'preScreened': [""],
        'experiences': [""],
        'noticePeriods': [""],
        'rockRatings': [""],
        'jobTypes': [""],
        'expectedCTS': [""],
        'probabilityofJoining': [""],
        'trainedProfiles': [""],
        'exactSearch': [""],
        'brandSearch': [""],
        'lastUpdates': [""],
      });
    }
  
    ngOnInit() {
      this.isTourHome = true;
      $('#carouselProfiles').carousel({		
        interval: 5000		
      });
  
  
    }
  
    Homepage() {
      this.router.navigate(['/']);
    }
  
    searchProfiles() {
    }
  
    advanceFilter() {
      if (this.advFltr === false) {
        this.advFilter = true;
        this.advFltr = true;
        return this.advFltr;
      }
      else {
        this.advFilter = false;
        this.advFltr = false;
        return this.advFltr;
      }
  
    }
   
    next(){
      console.log("Next method");
      this.isTooltipSkills = false;
      this.isTourHome = false;
      this.router.navigate(['/tour-findprofiles']);
    }

    onSkillsTypeAheadChange(values) {
    }

    onLocationTypeAheadChanges(values){
      }

    openDownloadBrochure(){
      this.commonFunction.openDownloadBrochure();
    }
  
  
  }
  