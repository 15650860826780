import { Component, OnInit, Inject, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';

@Component({
  selector: 'app-share-opportunity',
  templateUrl: './share-opportunity.component.html',
  styleUrls: ['./share-opportunity.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShareOpportunityComponent implements OnInit {

  jobDetails: any = [{
    name: 'Sneha', companyName: 'Rock INterview', jobTitle: 'UI Developer', openJobs: 10, websiteLink: 'https://www.rockinterview.in',
    videoLink: 'https://www.youtube.com/watch?v=Cud39QOR_Pk', aboutCompany: 'Nothing',
    skills: 'Angular,C,C++,Spring boot, Core java, Advance Java, Advanced java', experience: 'Fresher',
    location: 'Bangalore', jobType: 'Full Time', salaryRange: '4-6 LPA', jobDescription: 'Job description'
  }];

  toEmail: FormControl;
  submitted: boolean;
  jobType = 'Full Time';

  validation_messages = {
    'toEmail': [
      { type: 'required', message: 'PAGES.SCHEDULE_INTERVIEW.ERRORS.EMAIL.REQUIRED' },
      { type: 'email', message: 'Please enter valid email id' },
      { type: 'pattern', message: 'Please enter valid email id' },
      { type: 'minlength', message: 'PAGES.REGISTRATION.ERRORS.EMAIL.MINLENGTH.LABEL' },
      { type: 'maxlength', message: 'PAGES.REGISTRATION.ERRORS.EMAIL.MAXLENGTH.LABEL' }
    ]
  };

  constructor(public dialogRef: MatDialogRef<ShareOpportunityComponent>, @Inject(MAT_DIALOG_DATA) public data: any, 
  public cdr: ChangeDetectorRef) { 
    this.jobDetails = [data.jobDetails];
 
    // console.log('Job details : ', this.jobDetails);
    this.jobType = this.jobDetails[0].jobType.split('/')[0];

    this.toEmail = new FormControl('', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.EMAIL)]));;
    console.log('Data from share opportunity popup: ', this);
    setTimeout(() => {
      this.cdr.detectChanges();  
    }, 10);
    
  }


  ngOnInit(): void {
   
  }

  returnSkillsArray(skills) {
    return  skills ? skills.split(',') : [];
  }

  closeDialog() {
      this.dialogRef.close();

  }

  shareOpportunity() {
    // console.log('Email : ', this.toEmail);
    if(this.toEmail.invalid) {
      this.submitted = true;
    }
    else {
      this.submitted = false;
      this.dialogRef.close(this.toEmail.value);
    }
  }

}
