import { Injectable } from "@angular/core";
import { SendReceiveService } from '../../../services/common/sendReceive.service';
import { MyAppHttpService } from '../../../services/common/my-app-http.service';

@Injectable({
  providedIn: 'root'
})
export class MyProfileService {

  constructor(private sendReceiveService: SendReceiveService) { }

  getMyProfileServices(data) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetMyProfileDetails +'/'+ data);
  }

  updateMyProfileServices(data , requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.UpdateMyProfile +'/'+ data, requestData);
  }

  getRoles(){
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.GetAllRoles)
  }

  getMyPlanData(){
    var enterpriseUserId = 0;
    if(!!localStorage.userData){
        enterpriseUserId = JSON.parse(localStorage.userData).enterpriseUserId;
    }
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, `${MyAppHttpService.REQUESTS.GetMyPlanData}?enterpriseUserId=${enterpriseUserId}`)
  }
}