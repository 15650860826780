
import { Component, OnInit, Inject, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { HomeService } from '../home.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';

import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { DataStorageService } from '../../../../services/data-storage.service';
import { MyAppHttpService } from '../../../../services/common/my-app-http.service';







@Component({
  selector: 'app-build-teams-remotely',
  templateUrl: './build-teams-remotely.component.html',
  styleUrls: ['./build-teams-remotely.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BuildTeamsRemotelyComponent implements OnInit {



    TeamsForm:FormGroup;
    submitError: boolean = false;

    project: any[] = [
      { valueSending: 1, viewValue: 'Short Term' },
      { valueSending: 2, viewValue: 'Long Term ' },
      { valueSending: 3, viewValue: 'Out Sourcing ' },
    ];

  skills: any[];
  enterpriseUserId:any;

  minDate: any;
  maxDate: any;

  validation_messages = {

    'companyName': [
      { type: 'required', message: 'Please enter Company Name' },
      { type: 'pattern', message: 'Company Name should be alphanumberic with 1 space in between.' }
    ],
    'email': [
      { type: 'required', message: 'Please enter your Email Address' },
      { type: 'pattern', message: 'Please enter a valid Email' },
      { type: 'minLength', message: 'Email minimum length should be greate than 0' },
      { type: 'maxLength', message: 'Email maximum length should not be less than 500 characters' }
    ],


  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>,public fb: FormBuilder,
  public dataStorage: DataStorageService,public homeService: HomeService,public myAppHttp: MyAppHttpService){
    this.minDate = this.addDays(this.getDate(), 0);
    this.maxDate = this.addDays(new Date(), 90);

    this.skills = this.dataStorage.globalSkills;
    this.enterpriseUserId = this.data ? this.data.EnterpriseId : null;

    var userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')): null;

     this.TeamsForm = this.fb.group({
      companyName: [userData ? userData.company : '', Validators.compose([ Validators.required,Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.alphanumericWithOneSpace), Validators.maxLength(50)])],
      email: [userData ? userData.email : '', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.EMAIL)])],
      //companyName: [!!this.dataStorage.loggedInUserData ? (this.dataStorage.loggedInUserData.company ? this.dataStorage.loggedInUserData.company : '') : '', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.alphanumericWithOneSpace), Validators.maxLength(50)])],
      projectType: [null,],
      expectedStartDate: ['',],
      techIds: ['',],
      noOfPeople: ['',],

    })
    }

    onSkillsTypeAheadChange(event){

    }

    getDate(){
      return new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate())
     }

    addDays(date: any, days: number): Date {
      if (!days) return date;

      date.setDate(date.getDate() + days);

      return date;
    };

  ngOnInit() {

    // this.submitted=false;
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if ( charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

Contact(){


  if(this.TeamsForm.invalid)
      {
        this.submitError = true;
      }else{
      let datasending = this.TeamsForm.value;
      this.submitError = false;
       let inputData = {
        companyName: datasending.companyName,
        email:datasending.email,
        projectType: datasending.projectType,
        expectedStartDate: datasending.expectedStartDate,
        techIds: datasending.techIds ? datasending.techIds : null,
        noOfPeople: datasending.noOfPeople,
        enterpriseUserId:this.enterpriseUserId,
      }
      //  this.submitted=true;

      this.homeService.BuildTeams(inputData).subscribe(response => {
        if(response.statusCode==200)
        {
          this.dialogRef.close();
          this.myAppHttp.showAlertMessage("Thanks for your interest. We will get back to you shortly.");
        }else{
          this.dialogRef.close();
          this.myAppHttp.showAlertMessage("Something went wrong, please try again.");
        }

      })
    }
}




}
