
import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { WebsocketService } from 'src/app/ws/websocket.service';
import { ChatService } from '../../views/chat/chat.service';
import { ConfirmModalComponent } from '../modal/confirm-modal/confirm-modal.component';
import { DialogService } from '../modal/login-dialog.service';
import { CommonfunctionService } from 'src/app/services/common/commonfunction.service';
import { PricingConfirmationComponent } from '../modal/pricing-confirmation/pricing-confirmation.component';
import { PaymentComponent } from '../modal/payment/payment/payment.component';

@Component({
  selector: 'app-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatDialogComponent implements OnInit {

  chatForm: FormGroup;
  submitted: boolean = false;
  loggedIn: boolean = false;
  divWithForm: boolean = false;
  showStartChat: boolean = true;
  //hideStartChat: boolean = false;
  hideMessageWindow: boolean = false;
  dialogRef: MatDialogRef<any>;
  @ViewChild('txtChat') txtChat: ElementRef;


  validation_messages = {
    'uname': [
      { type: 'required', message: 'Please enter name' },
      { type: 'pattern', message: 'PAGES.SCHEDULE_INTERVIEW.ERRORS.USERNAME.PATTERN' }
    ],
    'emailId': [
      { type: 'required', message: 'Please enter a email id' },
      { type: 'email', message: 'Please enter a valid email id' },
      { type: 'pattern', message: 'Please enter a valid email id' },
      { type: 'minLength', message: 'PAGES.ERRORS.EMAIL.MINLENGTH.LABEL' },
      { type: 'maxLength', message: 'PAGES.ERRORS.EMAIL.MAXLENGTH.LABEL' }
    ]

  };

  // selectedUserInfo = {
  //   userName: "Sneha",
  //   lastMessage: "Hi",
  //   userId: 101,
  //   userRole: "Jobseeker",
  //   from: "admin",
  //   body: "<p>Hi</p>",
  //   createdDate: new Date()
  // };

  chatList = [{
    from: "admin",
    body: "<p style='margin-bottom: 0px;'>Welcome to <span style='color: #99226e;'>Rock Enterprise!</span> We are here to help you to solve your queries.</p>",
    createdDate: new Date()
  },
  // {
  //   from: "",
  //   body: "<p style='margin-bottom: 0px;'>Contrary to popular belief, Lorem ipsum is not simple random text. It has roots in a piece of classical. </p>",
  //   createdDate: new Date()
  // },
  // {
  //   from: "admin",
  //   body: "<p>Hi</p>",
  //   createdDate: new Date()
  // },
  // {
  //   from: "",
  //   body: "<p style='margin-bottom: 0px;'>Contrary to popular belief, Lorem ipsum is not simple random text. It has roots in a piece of classical. </p>",
  //   createdDate: new Date()
  // },
  // {
  //   from: "admin",
  //   body: "<p>Hi</p>",
  //   createdDate: new Date()
  // },
  // {
  //   from: "admin",
  //   body: "<p>Hi</p>",
  //   createdDate: new Date()
  // },
  {
    from: "notification",
    // body: "<p>Do you want to chat with this user</p><br> <div> <button>Accept</button><button>Reject</button> </div>",
    createdDate: new Date()
  }

  ]
  messageToSend: string;
  selectedUserInfo: any = {};
  havePlan = false;

  constructor(public router: Router,
    private dialogService: DialogService, public dataStorage: DataStorageService,
    public myAppHttp: MyAppHttpService,
    private wsService: WebsocketService,
    public chatService: ChatService,
    public commonService: CommonfunctionService
    // , public snackBar : MatSnackBar
  ) {

    setTimeout(() => {
      this.txtChat && this.txtChat.nativeElement.focus();
    }, 10);
  }

  ngOnInit() {
    this.dataStorage.chatMinimised = false;
    this.dataStorage.chatClosed = false;
    this.chatForm = new FormBuilder().group({
      'uname': ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.ALPHABETS_WITH_ONE_SPACE)])],
      'emailId': ['', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.EMAIL)])],
      'inputFile': [''],
      'messageToSend': ['']
    });

    this.havePlan = this.dataStorage.membershipCurrentPlan.planDurationNum && this.dataStorage.membershipCurrentPlan.planDurationNum >= 0;

    if (this.router.url == '/' || this.router.url == '/staffingAgencies') {
      if (!!localStorage.getItem("userData")) {
        this.dataStorage.globalAdminChatConversationList = [];
        this.dataStorage.globalChatUserInfo = this.dataStorage.globalAdminchatUserInfo;
        this.divWithForm = false;
        this.showStartChat = false;
        if (!!this.dataStorage.globalUnread_ChatIdInfo.adminChatId) {
          var inputData = {
            chatId: this.dataStorage.globalUnread_ChatIdInfo.adminChatId,
            userId: this.dataStorage.loggedInUserData.enterpriseUserId,
            toUserId: this.dataStorage.adminUserId,
            toUserType: MyAppHttpService.chatUserTypes.READMIN
          }
          this.getChatConversations(inputData);
        }
      }
      else {
        if (this.dataStorage.globalAdminChatConversationList.length > 0) {
          this.divWithForm = false;
          this.showStartChat = false;
          if (!!this.dataStorage.globalGuestUserProfileData) {
            var inputData1 = {
              chatId: this.dataStorage.globalUnread_ChatIdInfo.adminChatId,
              userId: this.dataStorage.globalGuestUserProfileData.userId,
              toUserId: this.dataStorage.adminUserId,
              toUserType: MyAppHttpService.chatUserTypes.READMIN
            }
            this.dataStorage.globalChatUserInfo = this.dataStorage.globalGuestUserProfileData;
            // Added - 30/03/2020 - Praveen (update chatId to globalChatUserInfo obj)
            this.dataStorage.globalChatUserInfo.chatId = this.dataStorage.globalUnread_ChatIdInfo.adminChatId;
            // End
            this.getChatConversations(inputData1);
          }
        }
        else {
          this.divWithForm = true;
          this.showStartChat = true;
        }

      }
    }
    else {
      if (!!localStorage.getItem("userData")) {
        this.divWithForm = false;
        this.showStartChat = false;
      }
    }

  }

  getChatConversations(inputData) {
    
    this.chatService.getChatDetails(inputData).subscribe((response) => {
   
      if (response && response.Items) {
        this.chatList = this.dataStorage.globalAdminChatConversationList = [];
        this.chatList = response.Items;
        this.dataStorage.globalAdminChatConversationList = response.Items;

        setTimeout(() => {
          if (!!document.getElementById('chatbody')) {
            document.getElementById('chatbody').scrollTop = document.getElementById('chatbody').scrollHeight;
          }
        }, 100);

      }
    });
  }

  startChat1() {
   
    if (this.divWithForm) {
      if (this.chatForm.invalid) {
        this.submitted = true;
        this.showStartChat = true;
      }
      else {

        this.submitted = false;
        this.showStartChat = false;
      
        this.dataStorage.userNameFromChatForm = this.chatForm.value.uname;


        var randomNumber = Math.random() * Date.now()
  
        var data = {
          userId: Math.round(randomNumber),
          userType: MyAppHttpService.chatUserTypes.RE,
          userName: this.chatForm.value.uname,
          userEmailId: this.chatForm.value.emailId
        };
        this.dataStorage.globalGuestUserProfileData = data;
        // this.dataStorage.globalChatUserInfo = data;
        this.wsService.invokeWebSocketFunction(data, MyAppHttpService.chatEventNames.UPDATE);
      }
    }
    else {
      this.showStartChat = false;
      // this.hideStartChat = true;

    }

  }

  hideWindow() {
    this.dataStorage.globalChatDialogUnreadCount = 0;
    this.hideMessageWindow = !this.hideMessageWindow;
    this.dataStorage.chatMinimised = this.hideMessageWindow;
    if (!this.hideMessageWindow) {
      setTimeout(() => {
        if (!!document.getElementById('chatbody')) {
          document.getElementById('chatbody').scrollTop = document.getElementById('chatbody').scrollHeight;
        }
      }, 100);
    }
  }


  onInsertMessageClick(messageToSend) {

    if (!this.dataStorage.havePlan() && this.router.url != '/' && this.router.url != '/staffingAgencies') {
      this.showPricingConfirmationDialog(this.dataStorage.globalFeatureCodes.COMMUNICATION, messageToSend, 'block');
    }
    else {
      if (messageToSend && messageToSend.trim().length != 0) {
        if (this.router.url == '/' || this.router.url == '/staffingAgencies') {
          let userId = localStorage.getItem('userData') ? (this.dataStorage.loggedInUserData.enterpriseUserId) : ((this.router.url == '/' || this.router.url == '/staffingAgencies') ? this.dataStorage.globalGuestUserProfileData.userId : this.dataStorage.globalChatUserInfo.userId);
          let userName = localStorage.getItem('userData') ? (this.dataStorage.loggedInUserData.fullName) : ((this.router.url == '/' || this.router.url == '/staffingAgencies') ? this.dataStorage.globalGuestUserProfileData.userName : this.chatForm.value.uname);
          let adminChatId = this.dataStorage.newAdminChatId ? this.dataStorage.newAdminChatId : '';
          var message = {
            data: {
              userId: userId, userType: MyAppHttpService.chatUserTypes.RE,
              userName: userName,
              chatId: this.dataStorage.globalUnread_ChatIdInfo.adminChatId,
              toUserId: this.dataStorage.adminUserId,
              toUserType: MyAppHttpService.chatUserTypes.READMIN,
              toUserName: MyAppHttpService.chatUserTypes.READMIN,
              message: messageToSend,
              companyName: JSON.parse(localStorage.getItem('userData')) ? this.dataStorage.loggedInUserData.company : 'Guest user'
            },
            message: MyAppHttpService.chatEventNames.SEND_MESSAGE
          }
          this.wsService.messages.next(message);
        }
        else {
          if ((!this.router.url.includes('/jobs')) && (!this.router.url.includes('/find-partners'))) {
            var message1 = {
              data: {
                userId: this.dataStorage.loggedInUserData.enterpriseUserId,
                userType: MyAppHttpService.chatUserTypes.RE,
                userName: this.dataStorage.loggedInUserData.fullName,
                chatId: this.dataStorage.globalUnread_ChatIdInfo.userChatId,
                toUserId: (this.dataStorage.globalExpandedData ? this.dataStorage.globalExpandedData[0].profileId : ''),
                toUserType: MyAppHttpService.chatUserTypes.RI,
                toUserName: (this.dataStorage.globalExpandedData ? this.dataStorage.globalExpandedData[0].profileName : ''),
                toEmailId: (this.dataStorage.globalExpandedData ? this.dataStorage.globalExpandedData[0].emailId : ''),
                toMobileNumber: (this.dataStorage.globalExpandedData ? this.dataStorage.globalExpandedData[0].mobileNumber : ''),
                message: messageToSend,
                companyName: this.dataStorage.loggedInUserData.company
              },
              message: MyAppHttpService.chatEventNames.SEND_MESSAGE
            };
            this.wsService.messages.next(message1);
          } else {
            var message2 = {
              data: {
                userId: this.dataStorage.loggedInUserData.enterpriseUserId,
                userType: MyAppHttpService.chatUserTypes.RE,
                userName: this.dataStorage.loggedInUserData.fullName,
                chatId: this.dataStorage.globalUnread_ChatIdInfo.userChatId,
                toUserId: (this.dataStorage.globalExpandedData ? this.dataStorage.globalChatUserInfo.toUserId : ''),
                toUserType: this.dataStorage.globalChatUserInfo.toUserType,//MyAppHttpService.chatUserTypes.RE,
                toUserName: (this.dataStorage.globalExpandedData ? this.dataStorage.globalChatUserInfo.toUserName : ''),
                toEmailId: this.dataStorage.globalChatUserInfo.toEmailId,
                toMobileNumber: this.dataStorage.globalChatUserInfo.toMobileNumber,
                fromEmailId: JSON.parse(localStorage.getItem('userData')).email,
                fromMobileNumber: JSON.parse(localStorage.getItem('userData')).mobileNo,
                message: messageToSend,
                companyName: this.dataStorage.loggedInUserData.company
              },
              message: MyAppHttpService.chatEventNames.SEND_MESSAGE
            };
            this.wsService.messages.next(message2);
          }



        }
      }
    }
  }

  onSendMessageKeyup(event, message) {
   
    if (event.keyCode == 13 && !event.altKey) {
      if (!message.trim()) {
        this.messageToSend = '';
        return false;
      }
      message = message.replace( /[\r\n]+/gm, "" ); 
      this.onInsertMessageClick(this.urlify(message));
      this.messageToSend = '';
    }
    if (event.keyCode == 13 && event.altKey) {
      this.messageToSend = this.messageToSend + "\r\n";
    }
  }

  urlify(text) {
    var urlRegexWithHttp = /(https?:\/\/[^\s]+)/g;
    var urlRegex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi

    if (text.match(urlRegex)) {
      var temptext = text.toLowerCase()
      if (!temptext.match(urlRegexWithHttp)) {
        text = "http://" + text;
      }
    }
    return text.replace(urlRegex, function (url) {
      // return '<a target="_blank" href="' + url + '">' + url + '</a>';
      return '<p class="mylink" name="urltype" id="' + url + '">' + url + '</p>';
        
      // For Attachment files
      // return '<p class="mylink" name="filetype" id="' + url + '">' + filename + '</p>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

  checkOnline() {

    if (!navigator.onLine) {
      this.myAppHttp.showAlertMessage("App is Offline.");
    }
 
  }

  ConvertToDateTime(eventTime) {
    return !!eventTime ? (new Date(eventTime)) : new Date();
  }

  blockChat(block, userInfo) {
    if (!this.dataStorage.havePlan() && this.router.url != '/' && this.router.url != '/staffingAgencies') {
      this.showPricingConfirmationDialog(this.dataStorage.globalFeatureCodes.COMMUNICATION, block, 'block');
    }
    else {
      let toUserType;
      toUserType = (this.router.url == '/' || this.router.url == '/staffingAgencies') ? MyAppHttpService.chatUserTypes.READMIN :
        ((!this.router.url.includes('/jobs') && (!this.router.url.includes('/find-partners'))) ? MyAppHttpService.chatUserTypes.RI : this.dataStorage.globalChatUserInfo.toUserType);
      if (block == 0) {
        this.dialogService.open(ConfirmModalComponent, {
          data: {
            condition: 'block',
            toUserId: (this.router.url == '/' || this.router.url == '/staffingAgencies') ? this.dataStorage.adminUserId : userInfo.toUserId,
            //userInfo.destinationId.split('_').length > 0 ? userInfo.destinationId.split('_')[0] : userInfo.destinationId,
            toUserName: (this.router.url == '/' || this.router.url == '/staffingAgencies') ? 'Ricruit Support' : userInfo.toUserName,
            chatId: userInfo.chatId,
            toUserType: toUserType,
            userName: this.dataStorage.loggedInUserData ? this.dataStorage.loggedInUserData.fullName : this.chatForm.value.uname
          }
        });
      }
      else {
        this.dialogService.open(ConfirmModalComponent, {
          data: {
            condition: 'unblock',
            toUserId: (this.router.url == '/' || this.router.url == '/staffingAgencies') ? this.dataStorage.adminUserId : userInfo.toUserId,
            toUserName: (this.router.url == '/' || this.router.url == '/staffingAgencies') ? 'Ricruit Support' : userInfo.toUserName,
            chatId: userInfo.chatId,
            toUserType: toUserType,
            userName: this.dataStorage.loggedInUserData ? this.dataStorage.loggedInUserData.fullName : this.chatForm.value.uname
          }
        });
      }
    }
  }

  clearChat(userInfo) {
  }

   
  showPricingConfirmationDialog(feature, data, event) {
    this.dialogRef = this.dialogService.open(PricingConfirmationComponent, {
      data: {
        message: 'Hey, you need to have a plan to use this! Do you want to Upgrade?'
      }
    });

    this.dialogRef.afterClosed().subscribe(response => {
     
      if(response == true) {
        this.dialogService.open(PaymentComponent, {
          data: { actionName: feature, details: data, eventType: event }
        });

        this.dataStorage.getPayments.subscribe((data) => {
          if (data.actionName == feature) {

            if(event == 'message') {
              this.onInsertMessageClick(data.details);
            }
            else if (event == 'block') {
              this.blockChat(data.details, this.selectedUserInfo);
            }
            
            // this.dataStorage.havePlan = true;
            // return this.dataStorage.havePlan();
          }

    });
  }
    });
  }

  onCloseClick(){
    this.dataStorage.chatClicked = false;  this.dataStorage.globalChatUserInfo = {}; this.dataStorage.chatClosed = true;
    this.dataStorage.globalChatMessageCount =  this.dataStorage.globalUnread_ChatIdInfo.RIUnreadCount ;
  }

  onmessageclick(msg) {
   
    let regex = new RegExp(/<([^\s]+).*?id="([^"]*?)".*?>(.+?)<\/\1>/gi);
    let matches = msg.message.match(regex);
    let i: any;
    let results = null;
    for (i in matches) {
      let parts = regex.exec(matches[i]);
      results = parts[2];
    }
    if (results != null) {
      window.open(results);
    }
  }

}
