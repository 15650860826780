import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from 'src/app/app-material.module';
import { TourPostajobComponent } from './tour-postajob.component';
import { TourPostajobRoutes } from './tour-postajob.route';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [TourPostajobComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(TourPostajobRoutes),
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TourPostajobModule { }
