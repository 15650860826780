import { Injectable } from '@angular/core';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(public sendReceiveService: SendReceiveService) { }

  insertMessage(requestData){
    return this.sendReceiveService.send(MyAppHttpService.httpVerb.Post, MyAppHttpService.REQUESTS.Chat, requestData);
  }

  getChatUsersList(requestData){    
    return this.sendReceiveService.send(MyAppHttpService.httpVerb.Post, MyAppHttpService.REQUESTS.Chat, requestData, MyAppHttpService.MWType.CHAT);
  }

  getChatDetails(requestData){
    return this.sendReceiveService.send(MyAppHttpService.httpVerb.Post, MyAppHttpService.REQUESTS.ConversationByChatId, requestData, MyAppHttpService.MWType.CHAT)
  }

  getProfileInformation(requestData){
    return this.sendReceiveService.send(MyAppHttpService.httpVerb.Post, MyAppHttpService.REQUESTS.GetProfileInformation, requestData);
  }

  getEnterprisePostedJobsList(requestData) {
    return this.sendReceiveService.send(MyAppHttpService.httpVerb.Get, `${MyAppHttpService.REQUESTS.ChatEnterprisePostedJobs}?enterpriseUserId=${requestData.enterpriseUserId}`)
  }

  getJobDetails(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetJobDetailsByJobId, requestData);
  }

}
