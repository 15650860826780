import { Injectable } from '@angular/core';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { DataStorageService } from '../../../../services/data-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleMockinterviewService {

  public showMeetingLinksSectionValue: boolean = false;
  public meetingStatuses: any[] = [];
  public noOfActiveMeetingOptions: number = 0;
  public noOfInActiveMeetingOptions: number= 0;
  public modifiedMeetingOptions: any[] = [];
  public showWhereByOption: boolean = false;
  public showZoomOption: boolean = false;
  public showAddYourLinkOption: boolean = false;
  public showNoneOption: boolean = false;

  constructor(private sendReceiveService: SendReceiveService, private dataStorage: DataStorageService) { }

  scheduleInterviewForExistingUser(requestData) {
    requestData.featureId = this.dataStorage.membershipCurrentPlan.featureList.find((features) => {
      return features.featureCode == this.dataStorage.globalFeatureCodes.INTERVIEW_RI;
    }).featureId
    requestData.subscriptionId = this.dataStorage.membershipCurrentPlan.subscriptionId;


    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.ScheduleInterviewforExistingUser, requestData);
  }

  scheduleInterviewForNonExistingUser(requestData) {
    requestData.featureId = this.dataStorage.membershipCurrentPlan.featureList.find((features) => {
      return features.featureCode == this.dataStorage.globalFeatureCodes.INTERVIEW_OWN;
    }).featureId
    requestData.subscriptionId = this.dataStorage.membershipCurrentPlan.subscriptionId;
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.ScheduleInterviewForNonExistingUser, requestData);
  }

  validateScheduleInterviewForNonExistingUser(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.ValidateScheduleInterviewForNonExistingUser, requestData);
  }

  uploadResume(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.UploadResume, requestData);
  }

  isUserExistsToSchedule(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.IsUserExistsToSchedule, requestData);
  }

  isUserExistsForApplyJob(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.IsExistingUserForJobApply, requestData);
  }

  getUserdetailsForPostAJOB(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.getUserdetailsForPostAJOB, requestData);
  }

  applyJob(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.ApplyJob, requestData);
  }

  getAllMentorsAddedByEnterprise(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.getAllMentorsAddedByEnterprise, requestData);
  }

  AddMentorByUserId(requestData) {

    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.AddMentorByEnterpriseUserId, requestData);
  }

  UpdateMentorByUserId(requestData) {

    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.UpdateInterviewerData, requestData);
  }

  deleteInterviewer(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.DeleteInterviewer, requestData);
  }

  getMeetingLinksStatusToDisplay() {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Get, MyAppHttpService.REQUESTS.getMeetingLinksStatus);
  }

  showMeetingLinksSectionOrNot() {
    this.getMeetingLinksStatusToDisplay().subscribe(res => {
        if(!!res && res.statuscode == MyAppHttpService.RESPONSE_CODES.SUCCESS) {
          console.log('Meeting statuses: ', res.meetingStatus);
            this.meetingStatuses = res.meetingStatus;
            if(!!this.meetingStatuses && this.meetingStatuses.length > 0) {
               this.noOfActiveMeetingOptions = 0;
               this.noOfInActiveMeetingOptions = 0;
                
                this.meetingStatuses.forEach(ms => {
                  if(ms.status == '1') {
                    ms.showMeetingOption = true;
                    this.noOfActiveMeetingOptions++;
                    if(ms.meetingName == 'whereBy') {
                      this.showWhereByOption = true;
                    }
                    if(ms.meetingName == 'zoom') {
                      this.showZoomOption = true;
                    }
                    if(ms.meetingName == 'addYourLink') {
                      this.showAddYourLinkOption = true;
                    }
                    if(ms.meetingName == 'none') {
                      this.showNoneOption = true;
                    }
                    
                  }
                  else {
                    ms.showMeetingOption = false;
                    this.noOfInActiveMeetingOptions++;

                    if(ms.meetingName == 'whereBy') {
                      this.showWhereByOption = false;
                    }
                    if(ms.meetingName == 'zoom') {
                      this.showZoomOption = false;
                    }
                    if(ms.meetingName == 'addYourLink') {
                      this.showAddYourLinkOption = false;
                    }
                    if(ms.meetingName == 'none') {
                      this.showNoneOption = false;
                    }
                  }
                  
                })
                console.log("Meeting options counts: ", this.meetingStatuses, this.noOfActiveMeetingOptions, this.noOfInActiveMeetingOptions);
                this.showMeetingLinksSectionValue = this.noOfActiveMeetingOptions > 0 ? true : false;
            }
        }
    })
  }

}
