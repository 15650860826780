import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SendReceiveService } from 'src/app/services/common/sendReceive.service';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { ChangepasswordComponent } from 'src/app/components/views/changepassword/changepassword.component';
import { Router, RouterOutlet } from '@angular/router';
import { DialogService } from '../../modal/login-dialog.service';
import { ScheduleMockinterviewComponent } from '../../modal/schedule-mockinterview/schedule-mockinterview.component';
import { PaymentComponent } from '../../modal/payment/payment/payment.component';
import { fader, slider, stepper } from '../../../../animation';
import { NotificationsInboxlistService } from 'src/app/components/views/notifications-inboxlist/notifications-inboxlist.service';
import { OpportunitiesService } from 'src/app/components/views/opportunities/opportunities.service';


@Component({
  selector: 'app-anonymous-layout',
  templateUrl: './anonymous-layout.component.html',
  styleUrls: ['./anonymous-layout.component.scss'],
  providers: [ChangepasswordComponent],
  animations: [ // <-- add your animations here
     fader,
    // slider,
    // transformer,
    //stepper
  ]
})
export class AnonymousLayoutComponent implements OnInit {
  buttonhelight = 0

  isLogin: boolean=false;
  element: any;

  @ViewChild('leftsidemenuElement') leftsidemenuElement: ElementRef;
  notificationsList = [
    // {heading: 'Weekly Newsletter', body: 'This weeks round up is the kjlijs', notificationTime: 'Few Seconds ago'},
    // {heading: 'Weekly Newsletter', body: 'This weeks round up is the kjlijs', notificationTime: '10 mins ago'},
    // {heading: 'Weekly Newsletter', body: 'This weeks round up is the kjlijs', notificationTime: '2 hours ago'},
    // {heading: 'Weekly Newsletter', body: 'This weeks round up is the kjlijs', notificationTime: '4 days ago'}
  ];

  //name: any;
  constructor(private sendReceiveService: SendReceiveService, public dataStorage: DataStorageService,public opportunitiesService:OpportunitiesService,
     private router : Router, private dialogService: DialogService, public notificationsService: NotificationsInboxlistService ) {
    if (!!localStorage.getItem('token') && localStorage.getItem('token') != 'anonymous' && localStorage.getItem('token') != 'null'
    && !!localStorage.getItem('userData')) {
      this.isLogin = true;
      this.dataStorage.isUserLoggedIn = true;
    //  this.name = ;
      this.dataStorage.loggedInUserName = JSON.parse(localStorage.getItem('userData')).fullName;
      this.dataStorage.loggedInUserData = JSON.parse(localStorage.getItem('userData'));

    } else {
      this.dataStorage.isUserLoggedIn = false;
      this.dataStorage.loggedInUserName = '';
      this.dataStorage.loggedInUserData = {};

      this.isLogin = false;

    }
   }

  ngOnInit() {
  }

  closeNav()
  {
    this.element = document.getElementById("mySidenav").style.width = "0";
    // this.element = document.getElementById("anonymous_layout").style.backgroundColor = "transparent";

  }
  openNav()
  {
    this.element =  document.getElementById("mySidenav").style.width = "220px";
    // this.element =  document.getElementById("anonymous_layout").style.backgroundColor = "rgba(0,0,0,0.4)";


  }
  meetings(){
    this.router.navigate(['/meetings'])
  }
  openChat()
  {
    this.dataStorage.globalChatMessageCount = 0;
    this.router.navigate(['/chat']);
  }
  myAccount()
  {
    this.router.navigate(['/myaccount']);
  }
  offerReleased()
  {
    this.router.navigate(['/rolledoutOffers'])
  }
  favorite() 
  {
    this.router.navigate(['/favouriteprofile'])
  }
  Pricing()
  {
    this.router.navigate(['/pricing']);
  }
  public dashboard() {
    // localStorage.setItem('nextPage','dashboard');
    this.router.navigate(['dashboard']);
  }

  public myjobs() {
    // localStorage.setItem('nextPage','myjobs');
    this.router.navigate(['myjobs']);
  }

  public favourite() {
    // localStorage.setItem('nextPage','favouriteprofile');
    this.router.navigate(['favouriteprofile']);
  }

  public offersReleased(){
    this.router.navigate(['rolledoutOffers']);
  }

  public chat(){
    this.router.navigate(['chat']);
  }

  public postajob(){
    this.router.navigate(['/postajob']);
  }
  scheduledInterviews(){
    this.router.navigate(['/scheduledInterviews']);
  }
  public findprofiles(){
    this.router.navigate(['/findprofile']);
  }

  public resumebuilder(){
    this.router.navigate(['/TestForResume']);
  }

  public pricing(){
    this.router.navigate(['/pricing']);
  }


  logout() {
    this.sendReceiveService.logoutService();
  }

  changePassword(){
    this.router.navigate(['/changepassword']);
  }

  help(){
    // this.router.navigate(['/help']);
    this.router.navigate([]).then(result => {  window.open('/help', '_blank'); });
  }

  scheduleInterview(){
    if(!!this.dataStorage.membershipCurrentPlan.planId && this.dataStorage.membershipCurrentPlan.planDurationNum > -1){
      this.fnOpenScheduleMockInterviewDialog();
    }
  else{
    this.dialogService.open(PaymentComponent, {
      data: { actionName: this.dataStorage.globalFeatureCodes.INTERVIEW_OWN, popupFlag: true, details: null, flag: null, eventType: null }
    });
  }
  }

  Dashboard()
  {
    this.router.navigate(['/dashboard']);
  }

  scheduledinterviews()
  {
    this.router.navigate(['/scheduledInterviews']);
  }


  fnOpenScheduleMockInterviewDialog(){
    this.router.navigate(['/globalscheduleinterview']);
    // this.dataStorage.ScheduleInterviewForExistingOrNonExistingUsers = "nonExisting";
    //   this.dialogService.open(ScheduleMockinterviewComponent, {
    //     data: { dialogFor: this.dataStorage.globalFeatureCodes.INTERVIEW_OWN, jobSkills: [], jobSkillIds: [] }
    //   })
  }

  myprofile(){
    // this.router.navigate(['myprofile',JSON.parse(localStorage.getItem('userData')).enterpriseUserId]);
    //this.router.navigate(['myaccount', JSON.parse(localStorage.getItem('userData')).enterpriseUserId]);
    this.router.navigate(['myaccount']);
  }

  public ReskillingNew(){
    this.router.navigate(['reskillingpage']);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  getAllNotifications(menuTrigger) {
    if (this.isLogin) {
      this.dataStorage.unreadNotificationsCountBoolean = false;
      let requestData = {
        enterpriseUserId: this.dataStorage.loggedInUserData.enterpriseUserId,
        limit: 4
      };

      this.notificationsService.getAllNotifications(requestData).subscribe(response => {
      
        if (response.statusCode == 200) {
          this.notificationsList = response.notifications;

          if(this.notificationsList.length == 0){
            this.router.navigate(['/notifications'],  {
              queryParams : {
                'noticationId' : 0
              }
            });
          }
          else{
            menuTrigger.openMenu();
          }
        }
      });
    }
  }

  convertToPlainString(content){
    content = content ? content.replace( /(<([^>]+)>)/ig, ''): '';

    return content ? content.replace(/&nbsp;/g, ' ') : '';
  }

  showMoreNotifications(notf) {
   
    if (notf != null){
      let inputData = {
        id: notf.id,
        read: true
      };
      this.notificationsService.readNotification(inputData).subscribe(response => {
        
      });
    }
    this.router.navigate(['/notifications'],  {
      queryParams : {
        'noticationId' : notf ? notf.id : 0
      }
    });

  }

}
