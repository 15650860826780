import { Injectable } from "@angular/core";
import { SendReceiveService } from "src/app/services/common/sendReceive.service";
import { MyAppHttpService } from "src/app/services/common/my-app-http.service";


@Injectable({
    providedIn: 'root'
  })
export class ForgotPasswordModalService{
    //HMACKey: string;
    constructor(private sendReceiveService: SendReceiveService) { }

    // public setHMACKey(input) {
    //     this.HMACKey = input;
    // }

   
    forgotPassword(requestData) {
        var forgotPasswordData={
            emailId:requestData.emailId
        }
        return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.ForgotPassword,forgotPasswordData);  
    }

}

