import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { LoginModalService } from '../login/login-modal/login-modal.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyAppHttpService } from 'src/app/services/common/my-app-http.service';
import { DataStorageService } from 'src/app/services/data-storage.service';

@Component({
  selector: 'app-chat-modal',
  templateUrl: './chat-modal.component.html',
  styleUrls: ['./chat-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatModalComponent implements OnInit {

  chatForm: FormGroup;
  submitted: boolean = false;
  loggedIn: boolean = false;
  divWithForm: boolean = false;
  showStartChat: boolean = true;
  hideStartChat: boolean = false;
  hideMessageWindow: boolean = false;


  validation_messages = {
    'uname': [
      { type: 'required', message: 'Please enter name' },
      { type: 'pattern', message: 'PAGES.SCHEDULE_INTERVIEW.ERRORS.USERNAME.PATTERN' }
    ],
    'emailId': [
      { type: 'required', message: 'Please enter Email Id' },
      { type: 'email', message: 'Please enter valid Email id' },
      { type: 'pattern', message: 'Please enter  valid Email id' },
      { type: 'minLength', message: 'PAGES.ERRORS.EMAIL.MINLENGTH.LABEL' },
      { type: 'maxLength', message: 'PAGES.ERRORS.EMAIL.MAXLENGTH.LABEL' }
    ]

  };

  selectedUserInfo = {
    userName: "Sneha",
    lastMessage: "Hi",
    userId: 101,
    userRole: "Jobseeker",
    from: "admin",
    body: "<p>Hi</p>",
    createdDate: new Date()
  };

  chatList = [{
    from: "admin",
    body: "<p style='margin-bottom: 0px;'>Welcome to <span style='color: #99226e;'>Rock Enterprise!</span> We are here to help you to solve your queries.</p>",
    createdDate: new Date()
  },

    {
      from: "notification",
      createdDate: new Date()
    }

  ]

  constructor(public dialogRef: MatDialogRef<ChatModalComponent>, public router: Router, 
    private dialogService: LoginModalService, public dataStorage: DataStorageService
    // , public snackBar : MatSnackBar
    ) {

  }

  ngOnInit() {

    if (!localStorage.getItem("userData")) {
      this.divWithForm = true;
    }
    else {
      this.divWithForm = false;
    }

    if(this.router.url != "/"){
      this.showStartChat = false;
      this.hideStartChat = true;
    }
    else{
      this.showStartChat = true;
      this.hideStartChat = false;
    }

    this.chatForm = new FormBuilder().group({
      'uname': ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.NAME)])],
      'emailId': ['', Validators.compose([Validators.required, Validators.pattern(MyAppHttpService.VALIDATION_PATTERNS.EMAIL)])],
      'inputFile' : [''],
      'messageToSend' : ['']
    });
  }

  startChat1() {
    if (this.divWithForm) {
      if (this.chatForm.invalid) {
        this.submitted = true;
        this.showStartChat = true;
        this.hideStartChat = false;
      }
      else {
        this.submitted = false;
        this.showStartChat = false;
        this.hideStartChat = true;
        // console.log("Form value : ", this.chatForm.value);
      }
    }
    else {
      this.showStartChat = false;
      this.hideStartChat = true;
    }

  }

  hideWindow(){
    this.hideMessageWindow = !this.hideMessageWindow;
  }
 

  onInsertMessageClick(messageToSend){
  
  }


}
